import { Directive, ElementRef, OnInit, Input, ViewChild, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appResizable]', // Attribute selector
    exportAs: 'appResizable',
})

export class ResizableDirective implements OnInit {


    @Input() resizableGrabWidth = 8;
    @Input() resizableMinWidth = 270;
    dragging = false;
    fuller = false;
    @Input('maxEvent') set maxEvent(value) {
        if (value.event == true) {
            this.fuller = true;
        }
    }
    constructor(private el: ElementRef,
        private render: Renderer2) {

        const self = this;

        const EventListenerMode = { capture: true };

        function preventGlobalMouseEvents() {
            document.body.style['pointer-events'] = 'auto';
        }

        function restoreGlobalMouseEvents() {
            document.body.style['pointer-events'] = 'auto';
        }
        function maxDivMouseEvents() {
            document.body.style['pointer-events'] = "ew-resize";
        }


        const newWidth = (wid) => {
            document.body.style['user-select'] = "none";
            const newWidth = Math.max(this.resizableMinWidth, wid);
            el.nativeElement.style.width = (newWidth) + "px";
        }


        const mouseMoveG = (evt) => {
            if (!this.dragging) {
                return;
            }
            document.body.style['user-select'] = "none";
            newWidth(evt.clientX - el.nativeElement.offsetLeft)
            evt.stopPropagation();
        };

        const dragMoveG = (evt) => {
            if (!this.dragging) {
                return;
            }
            const newWidth = Math.max(this.resizableMinWidth, (evt.clientX - el.nativeElement.offsetLeft)) + "px";
            el.nativeElement.style.width = (evt.clientX - el.nativeElement.offsetLeft) + "px";
            evt.stopPropagation();
        };

        const mouseUpG = (evt) => {
            if (!this.dragging) {
                return;
            }
            document.body.style['user-select'] = "auto";
            restoreGlobalMouseEvents();
            this.dragging = false;
            evt.stopPropagation();
        };

        const mouseDown = (evt) => {
            if (this.inDragRegion(evt)) {
                this.dragging = true;
                preventGlobalMouseEvents();
                evt.stopPropagation();
            }
        };


        const mouseMove = (evt) => {
            if (this.inDragRegion(evt) || this.dragging) {
                el.nativeElement.style.cursor = "ew-resize";
            } else {
                el.nativeElement.style.cursor = "default";
            }
        }


        document.addEventListener('mousemove', mouseMoveG, true);
        document.addEventListener('mouseup', mouseUpG, true);
        el.nativeElement.addEventListener('mousedown', mouseDown, true);
        el.nativeElement.addEventListener('mousemove', mouseMove, true);
    }

    ngOnInit(): void {
        this.el.nativeElement.style["border-right"] = this.resizableGrabWidth + "px solid #142c44";
    }

    inDragRegion(evt) {
        return this.el.nativeElement.clientWidth - evt.clientX + this.el.nativeElement.offsetLeft < this.resizableGrabWidth;
    }

}
