import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Import Containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { UnauthorisedAccessComponent } from './error/UnauthorisedAccess.component'
import { LoginComponent } from './login/login.component';
import { UserProfileComponent } from './users/user-profile/user-profile.component';
import { RegisterComponent } from './views/register/register.component';
import { AuthGuard } from './auth.guard';
import { pageNotFoundComponent } from './error/pageNotFound.component';
import { UseractivationComponent } from './login/useractivation/useractivation.component';
import { ResetpasswordComponent } from './login/resetpassword/resetpassword.component';
import { EditorComponent } from './editor/editor.component';
import { ManageFeaturesComponent } from './manage-features/manage-features.component';
import { ArticlePersonalisedComponent } from './article/article-personalised/article-personalised.component';
import { ControlPanelComponent } from './control-panel/control-panel.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboards',
        pathMatch: 'full',
    },
    {
        path: 'userprofiles',
        redirectTo: 'userprofiles',
        pathMatch: 'full',
    },
    {
        path: 'editor/:id',
        component: EditorComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {
            title: 'RVLaTeX'
        }
    },
    {
        path: 'editor/:id/:sid',
        component: EditorComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {
            title: 'RVLaTeX'
        }
    },
    {
        path: 'editor/:id/:sid/merge-changes',
        component: EditorComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {
            title: 'RVLaTeX'
        }
    },
    {
        path: 'users-main',
        redirectTo: 'users',
        pathMatch: 'full',
    },
    {
        path: 'usersrolelist',
        redirectTo: 'userroles',
        pathMatch: 'full',
    },

    {
        path: '404',
        component: P404Component,
        data: {
            title: 'Page 404'
        }
    },
    {
        path: '500',
        component: P500Component,
        data: {
            title: 'Page 500'
        }
    },
    {
        path: 'login',
        component: LoginComponent,
        data: {
            title: 'breadcrumb.Login Page'
        }
    },
    {
        path: 'unauthorisedaccess',
        component: UnauthorisedAccessComponent,
        data: {
            title: 'breadcrumb.Login Page'
        }
    },
    {
        path: '',
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {
            title: 'breadcrumb.Home'
        },
        children: [
            {
                path: 'dashboards',
                loadChildren: './dashboards/dashboards.module#DashboardsModule'
            },
            {
                path: 'stages',
                loadChildren: './stages/stages.module#StagesModule'
            },
            {
                path: 'journal',
                loadChildren: './journal/journal.module#JournalModule'
            },
            {
                path: 'email-templates',
                loadChildren: './email-templates/email-templates.module#EmailTemplatesModule'
            },
            {
                path: 'article-upload',
                loadChildren: './articleupload/articleupload.module#ArticleuploadModule'
            },
            {
                path: 'article-upload/:id',
                loadChildren: './show-directories/show-directories.module#ShowDirectoriesModule'
            },
            {
                path: 'users',
                loadChildren: './users/users.module#UsersModule'
            },
            {
                path: 'userroles',
                loadChildren: './userroles/userroles.module#UserrolesModule'
            },
            {
                path: 'articles',
                loadChildren: './article/article.module#ArticleModule'
            },
            // { 
            //     path: 'settings',
            //     loadChildren: './settings/settings.module#SettingsModule'
            // },
            // {
            //     path: 'user_activity',
            //     loadChildren: './login/login.module#LoginModule'
            // },
            {
                path: 'buttons',
                loadChildren: './views/buttons/buttons.module#ButtonsModule'
            },
            {
                path: 'charts',
                loadChildren: './views/chartjs/chartjs.module#ChartJSModule'
            },
            {
                path: 'userprofiles',
                component: UserProfileComponent,
            },
            {
                path: 'dashboards',
                loadChildren: './dashboards/dashboards.module#DashboardsModule'
            },
            {
                path: 'dashboard',
                loadChildren: './views/dashboard/dashboard.module#DashboardModule'
            },

            {
                path: 'managefeatures',
                component: ManageFeaturesComponent,
                data: {
                    title: 'breadcrumb.Manage feature'
                }
            },
            {
                path: 'workflow',
                loadChildren: './workflow/workflow.module#WorkflowModule'
                // loadChildren: './articleupload/articleupload.module#ArticleuploadModule'
            },
            {
                path: 'my-articles',
                component: ArticlePersonalisedComponent,
                data: {
                    title: 'breadcrumb.My articles'
                }
            },
            {
                path: 'clients',
                loadChildren: './client/client.module#ClientModule'
            },
            {
                path: 'forms',
                loadChildren: './dynamic-forms/dynamic-forms.module#DynamicFormsModule'
            },
            {
                path: 'package',
                loadChildren: './package/package.module#PackageModule'
            },
            {
                path: 'control-panel',
                component: ControlPanelComponent
            }
        ]
    },
    {
        path: 'useractivation/:usertoken',
        component: UseractivationComponent,
        data: {
            title: 'breadcrumb.User Activation'
        }
    },
    {
        path: 'resetpassword/:usertoken',
        component: ResetpasswordComponent,
        data: {
            title: 'breadcrumb.Reset password'
        }
    },
    {
        path: '**',
        component: pageNotFoundComponent,
        data: {
            title: 'breadcrumb.Page Not Found'
        }
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }