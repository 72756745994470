import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvService } from './service/env.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(private http: HttpClient, private env: EnvService) { }


    _tile_url = 'tile_counts';
    getTilesCounts(): Observable<any> {
        return this.http.get(this.env.baseAPIUrl + '/dashboard/tile_counts')
    }

    getJournalArticleCounts(): Observable<any> {
        return this.http.get(this.env.baseAPIUrl + '/dashboard/getJournalArticleCounts')
    }

    getUserActivityLogs(): Observable<any> {
        return this.http.get(this.env.baseAPIUrl + '/dashboard/getUserActivityLogs')
    }

    getAllUsers(): Observable<any> {
        return this.http.get(this.env.baseAPIUrl + '/dashboard/getAllUsers')
    }

    getRoleCount(): Observable<any> {
        return this.http.get(this.env.baseAPIUrl + '/dashboard/getRoleCount')
    }

    getUserCount(): Observable<any> {
        return this.http.get(this.env.baseAPIUrl + '/dashboard/getUserCount')
    }

    checkUsernameExists(username) {
        return this.http.get(this.env.baseAPIUrl + '/checkIfUserExists/' + username)
    }
    checkEmailExists(email) {
        return this.http.get(this.env.baseAPIUrl + '/checkIfEmailExists/' + email)
    }

    getMyArticlesTileSvs(data:any=null){
        return this.http.post<any>(this.env.baseAPIUrl + '/get-my-articles-tile', data);
    }
    getJournalTileSvs() {
        return this.http.get<any>(this.env.baseAPIUrl + '/get-my-journals-tile');
    }
}
