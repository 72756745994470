import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../service/env.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class JournalService {

    constructor(private http: HttpClient, private env: EnvService) { }

    getJournalsForCleanSettingsSvs(data): Observable<any> {
        return this.http.post(this.env.baseAPIUrl + '/getJournalsForCleanSettings', data);
    }
    //  function for except current journal
    getJournalShare($data) {
        return this.http.get(this.env.baseAPIUrl + '/getJournalShare/' + $data);
    }
    //to get all block code journal patterns
    getBlockCodeJnlPatterns($journalId){
        return this.http.post(this.env.baseAPIUrl + '/get-blockcode-patterns', $journalId);
    }
    //function for getting journal clean patterns from db
    getRVCleanPatterns($journalId) {
        return this.http.post(this.env.baseAPIUrl + '/get-rvclean-patterns', $journalId);
    }
    create_journal($data) {
        return this.http.post(this.env.baseAPIUrl + '/create_journal', $data);
    }
    getall_journals($data) {
        return this.http.post(this.env.baseAPIUrl + '/getall_journals', $data);
    }
    delete_journals($id) {
        return this.http.get(this.env.baseAPIUrl + '/delete_journals/' + $id);
    }
    journal_edit($data) {
        return this.http.get(this.env.baseAPIUrl + '/journal_edit/' + $data);
    }
    // checking for jaorunal code is existing in db
    checkIfJournalCodeExists($data) {
        return this.http.get(this.env.baseAPIUrl + '/checkIfJournalCodeExists/' + $data);
    }
    get_allstages() {
        return this.http.get(this.env.baseAPIUrl + '/get_allstages');
    }
    change_jrnlStatus($jnlid: string, $status: boolean) {
        var jsonObject = {};
        jsonObject['jnlid'] = $jnlid;
        jsonObject['status'] = $status;
        return this.http.post(this.env.baseAPIUrl + '/change_jrnlStatus', jsonObject);
    }
    stages_edit($data) {
        return this.http.get(this.env.baseAPIUrl + '/JournalstagesEdit/' + $data);
    }
    get_extentions() {
        return this.http.get(this.env.baseAPIUrl + '/get_extentions');
    }
    get_extentions_edit($data) {
        return this.http.get<any>(this.env.baseAPIUrl + '/get_extentions/' + $data);
    }
    get_jnl_file_extentions($jnl_code) {
        return this.http.get<any>(this.env.baseAPIUrl + '/file_extentions_jnl_code/' + $jnl_code);
    }
    getPublisherClientsSvs() {
        return this.http.get<any>(this.env.baseAPIUrl + '/getPublisherClients');
    }
    getJournalDetailsSvs(jnl_id: object) {
        return this.http.post<any>(this.env.baseAPIUrl + '/getJournalDetails', jnl_id);
    }
    getJournalFormatsSvs() {
        return [
            { code: 'single', value: 'Single column' },
            { code: 'double', value: 'Double column' },
        ];
    }

    getLliscenceTypesSvs() {
        return [
            { value: '1', typeName: 'Open access' },
            { value: '2', typeName: 'Subscription' },
            { value: '3', typeName: 'Both' }
        ];
    }

    getJournalCategorySvs() {
        return [
            { value: '1', typeName: 'journal-1' },
            { value: '2', typeName: 'journal-2' }
        ];
    }
    //reference starts
     getReferenceCodeJnlPatterns($journalId){
        return this.http.post(this.env.baseAPIUrl + '/get-referencecode-patterns', $journalId);
    }
}
