import { forEach } from "@angular/router/src/utils/collection";
import * as XRegExp from "xregexp";
import { debug } from "util";

declare global {
  interface Window {
    DOMParser: any;
    ActiveXObject: any;
  }
}

declare global {
  interface Window {
    DOMParser: any;
    ActiveXObject: any;
  }
}

export class AutoRegexUtilities {

  static onCustomCode(patternId: string, matchText: string) {
    if (patternId == "au-aff") {
      matchText = matchText.split(new RegExp("\\$[^\\$]*\\$")).join("");
      matchText = matchText.split(new RegExp("\\\\textit\\{")).join("");
      matchText = matchText.split(new RegExp("\\\\textbf\\{")).join("");
      matchText = matchText.split(new RegExp("\\\\textbf\\{\\*\\}")).join("");
      matchText = matchText.split(new RegExp("\\}")).join("");
      matchText = matchText.split(new RegExp("\\\\begin\\{center}")).join("");
      matchText = matchText.split(new RegExp("\\\\\textit\\{E-mail address:}")).join("");
      matchText = matchText.split(new RegExp("\\\\bibitem\\{")).join("");
      matchText = matchText.split(new RegExp("\\(")).join("");
      matchText = matchText.split(new RegExp("\\)")).join("");
    }
    return matchText;
  }

  public static onPostClean(matchText: string) {
    //matchText = matchText.split(new RegExp("(?<!\\n)\\n(?!\\n)")).join("");
    return matchText;
  }

  public static onPreClean(matchText: string) {
    matchText = matchText.split(new RegExp("\\\\textendash\\{")).join("");
    matchText = matchText.split(new RegExp("\\\\textendash\\{\\}")).join("/");
    matchText = matchText.split(new RegExp("\\\\underline\\{")).join("");
    matchText = matchText.split(new RegExp("\\\\textbf\\{")).join("");
    matchText = matchText.split(new RegExp("\\\\textit\\{")).join("/");
    matchText = matchText.split(new RegExp("\\\\textnormal\\{")).join("");
    matchText = matchText.split(new RegExp("\\\\${\hyphenbullet}$\\{")).join("-");
    matchText = matchText.split(new RegExp("\\[\\[")).join("");
    matchText = matchText.split(new RegExp("\\]\\]")).join("/");
    matchText = matchText.split(new RegExp("\\[")).join("");
    matchText = matchText.split(new RegExp("\\]")).join("/");
    matchText = matchText.split(new RegExp("\\\\item\\{")).join("");
    matchText = matchText.split(new RegExp("\\\\foreignlanguage{}\\{")).join("");
    matchText = matchText.split(new RegExp("\\\\foreignlanguage\\{")).join("");
    matchText = matchText.split(new RegExp("\\english")).join("");
    matchText = matchText.split(new RegExp("\\\\textcolor{color-12}\\{")).join("");
    matchText = matchText.split(new RegExp("\\\\newline")).join("");
    matchText = matchText.split(new RegExp("\\\\colorbox{color-1}{\\{")).join("");
    matchText = matchText.split(new RegExp("\\\\textcolor{color-3}\\{")).join("");
    matchText = matchText.split(new RegExp("\\}")).join("/");
    matchText = matchText.split(new RegExp("\\\\\item")).join("");
    matchText = matchText.split(new RegExp("\\:")).join("");
    matchText = matchText.split(new RegExp("\\et al")).join("etal");
    matchText = matchText.split(new RegExp("\\.\\~")).join(",");
    matchText = matchText.split(new RegExp("\\{")).join("/");
    matchText = matchText.split(new RegExp("\\''")).join("/");
    matchText = matchText.split(new RegExp("\\``")).join("/");
    matchText = matchText.split(new RegExp("\\\\em")).join("");
    matchText = matchText.split(new RegExp("\\pp")).join("");
    matchText = matchText.split(new RegExp("\\\\selectlanguage")).join("");
    // matchText = matchText.split(new RegExp("\\\\&")).join("/");
    matchText = matchText.split(new RegExp("\\(")).join("/");
    matchText = matchText.split(new RegExp("\\)")).join("/");
    matchText = matchText.split(new RegExp("\\;")).join("/");
    matchText = matchText.split(new RegExp("\\etal")).join("");
    matchText = matchText.split(new RegExp("\\-\\-")).join("/");
    matchText = matchText.split(new RegExp("\\\\bibitem")).join("");
    matchText = matchText.split(new RegExp("\\\\og")).join("/");
    matchText = matchText.split(new RegExp("\\\\emph")).join("/");
    matchText = matchText.split(new RegExp("\\\\fg")).join("/");
    matchText = matchText.split(new RegExp("\\\\it")).join("/");
    matchText = matchText.split(new RegExp("\\\\bf")).join("/");
    matchText = matchText.split(new RegExp("\\\\'")).join("");
    matchText = matchText.split(new RegExp("\\\\normalfont")).join("");
    matchText = matchText.split(new RegExp("\\\\em'")).join("/");
    matchText = matchText.split(new RegExp("\\\\textcolor{color-2}\\{")).join("");
    matchText = matchText.split(new RegExp("\\\\colorbox")).join("");
    matchText = matchText.split(new RegExp("\\\\color-2")).join("");
    matchText = matchText.split(new RegExp("\\\\&")).join("");
    matchText = matchText.split(new RegExp("\\\\cdr")).join("");
    return matchText;
  }

  static matchSingleExpression(regexMatchResult, parameters: any[], output: any[]) {

    try {
      if (regexMatchResult == null) {
        return;
      } else {
        const thisResult = this.cleanOutput(parameters, this.parseOutput(
          regexMatchResult.pattern,
          parameters,
          output,
          regexMatchResult.groups
        ));
        if (thisResult != null) {
          return ({
            parameters: regexMatchResult.groups,
            output: thisResult,
          });
        }
      }
    } catch (err) {
      console.log(err);
      return;
    }
  }

  static match(type: string, group: string, matchText: string, expressions = [], parseFromXml = false, patterns = null) {
    const matchTexts = matchText.split(/\n\s*\n/);
    const results = [];
    let i = 0;
    for (let thisMatchText of matchTexts) {
      let outputs = [];
      if (parseFromXml === true) {
        const xmlResult = AutoRegexUtilities.parseXml(thisMatchText);
        let thisPattern;
        if (patterns === null) {
          thisPattern = this.PATTERNS.filter(item => { return item.name === xmlResult.pattern })[0];
        } else {
          thisPattern = patterns.filter(item => { return item.name === xmlResult.pattern })[0];
        }
        const singleExpressionMatchResult = this.matchSingleExpression(xmlResult, thisPattern["parameters"], thisPattern["output"]);
        if (singleExpressionMatchResult !== null && singleExpressionMatchResult !== undefined) {
          outputs.push(singleExpressionMatchResult);
        }
      } else {
        let thisPattern;
        if (patterns === null) {
          thisPattern = this.PATTERNS.filter(item => { return item.name === group })[0];
        } else {
          thisPattern = patterns.filter(item => { return item.name === group })[0];
        }
        if (thisPattern.id == "au-aff") {
          thisMatchText = AutoRegexUtilities.onCustomCode(thisPattern.id, thisMatchText);
        }
        let expressionsToTest = [];

        /*
        if (Array.isArray(expressions) && expressions.length) {
          expressionsToTest = expressions;
        } else {
          thisPattern["expressions"].forEach((expression) => {
            expressionsToTest.push(expression);
          });
        }
        */

        expressionsToTest = this.getExpressionsToTest(thisPattern);
        expressionsToTest.forEach((expression) => {
          const regexMatchResult = XRegExp.exec(thisMatchText.trim(), this.formatExpression(
            thisPattern["parameters"],
            expression,
          ));
          const singleExpressionMatchResult = this.matchSingleExpression(regexMatchResult, thisPattern["parameters"], thisPattern["output"]);
          if (singleExpressionMatchResult !== null && singleExpressionMatchResult !== undefined) {
            outputs.push(singleExpressionMatchResult);
          }
        });
      }
      outputs.push({
        parameters: "null",
        output: thisMatchText,
      })
      results.push({
        outputs: outputs,
        source: thisMatchText,
        index: i++
      });
    }
    return results;
  }

  static cleanOutput(parameters: any[], output: string) {
    for (let parameter of parameters) {
      output = output.replace("${" + parameter.name + "}", "");
    }
    return output;
  }

  static getAutoRegexTypes(): any[] {
    return this.PATTERNS;
  }

  static formatExpression(parameters, expression) {
    let output = expression;
    parameters.forEach((element) => {
      output = output.replace("${" + element.name + "}", "(?<" + element.name + ">" + element.expression + ")");
    });
    return XRegExp("^" + output);//.replace("/", "//")
  }

  static parseOutputForTags(type, parameters) {

    let result = "<" + type + ">\n";
    for (let [key, value] of Object.entries(parameters)) {
      result += "<" + key + ">" + value + "</" + key + ">\n";
    }
    result += "</" + type + ">";
    return result;
  }

  static formatOutputText(parameter: any): string {
    switch (parameter['name']) {
      case "bibitem":
        return "\\" + parameter['name'] + "{${" + parameter['name'] + "}}" + "  \n";
      case "c_names":
      case "c_a_names":
        return "${" + parameter['name'] + "}" + "  \n";
      default:
        return "\\info[" + parameter['name'] + "]" + "{${" + parameter['name'] + "}}" + "\n";
    }
    return "";
  }

  static getParameterById(referencePatterns: any[], id): any {
    if (!referencePatterns) {
      return undefined;
    }
    return referencePatterns.find(data => {
      return data['id'] === id;
    });
  }

  static referenceOutputText(type: string, parameters: any[], outputOrder: any[], values = {}): string {
    if (!outputOrder) {
      return "";
    }
    let referenceOutputText = ""
    for (var i = 0; i < outputOrder.length; i++) {
      const parameter = AutoRegexUtilities.getParameterById(parameters, outputOrder[i]);
      if (Object.entries(values).length == 0 || parameter.name in values) {
        referenceOutputText += this.formatOutputText(parameter);
      }
    }
    if (!!type) {
      referenceOutputText += "\\reftype{" + type + "}\n";
    }
    referenceOutputText += "\\endref"
    return referenceOutputText;
  }

  static parseOutput(type: string, parameters: any, outputOrder, params) {
    let output = this.referenceOutputText(type, parameters, outputOrder, params) as any;
    output = output.replaceAll('\n', '\n');
    for (let [key, value] of Object.entries(params)) {
      if (key.startsWith('c_')) {
        switch (key) {
          case 'c_aff': {
            let replaceString = "";
            let split = (value as string).split(new RegExp('\\n'));
            for (let index = 0; index < split.length; index++) {
              const name = split[index];
              const nameSplit = name.trim().split(',');
              let dept = "";
              let inst = "";
              let cnty = "";
              switch (nameSplit.length) {
                case 0:
                  break;
                case 1:
                  dept = nameSplit[0];
                  break
                case 2:
                  dept = nameSplit[0];
                  inst = nameSplit[1];
                  break;
                case 3:
                  dept = nameSplit[0];
                  inst = nameSplit[1];
                  cnty = nameSplit[2];
                  break
                default:
                  dept = nameSplit.slice(0, nameSplit.length - 2).join(", ");
                  inst = nameSplit[nameSplit.length - 2];
                  cnty = nameSplit[nameSplit.length - 1];
              }
              replaceString += '\\aff[' + (index + 1) + ']{\\dept{' + dept + '},\n' + ']{\\inst{' + inst + '},\n' + ']{\\cnty{' + cnty + '},\n';
            }
            output = output.replace("${" + key + "}", replaceString);
            continue;
          }

          case 'c_a_names': {
            let replaceString = "";
            let split = (value as string).split(new RegExp(',|;'));
            for (let index = 0; index < split.length; index++) {
              const name = split[index];
              const nameSplit = name.trim().split(' ');
              replaceString += '\\author[' + (index + 1) + ']{\\gname{' + nameSplit[0] + '}\n';
              if (nameSplit[1] !== undefined) {
                replaceString += '\\sname{' + nameSplit[1] + '}\n';
              }
              if (index == 0) {
                if (Object.keys(params).indexOf('corresp')) {
                  // replaceString += '\\corresp{\\COR{' + name + '},' + params["corresp"] + '\n';
                  //replaceString += '\\corresp{\\COR{' + name + '},' + params["corresp"]+ '\\email{' + params["email"]+'}\n';
                  replaceString += '\\corresp{\\COR{' + name + '},' + params["corresp"] + '\\email{' + params["email"] + '},' + '\\Tel\\fax' + params["fax"] + '\n';
                }
              }
            }
            output = output.replace("${" + key + "}", replaceString);
            continue;
          }
          case 'c_names': {
            let replaceString = "";
            // let split = (value as string).split(new RegExp(',|;|and|~'));
            let split = (value as string).replace("and", ",").replace("~", ",").split(/[\,,\;,\~]/);

            // for (let index = 0; index < split.length; index++) {
            // const name = split[index];
            for (const name of split) {
              const nameSplit = name.trim().split(/[\s,\r]/);
              //  const nameSplit = name.trim().split(' ');
              replaceString += '\n\\name[surname]{' + nameSplit[0] + '}';
              if (nameSplit[1] !== undefined) {
                replaceString += '\n\\name[given]{' + nameSplit[1] + '}';
              }
            };

            if ('etal' in params) {
              replaceString += '\n\\name[etal]{}';
            }
            // if (Object.keys(params).indexOf('etal')) {
            //   replaceString += '\n\\name[etal]{}';
            // }
            output = output.replace("${" + key + "}", replaceString);
            continue;
          }
          //c_e_names
          case 'c_e_names': {
            let replaceString = "";
            let split = (value as string).replace("and", ",").replace("\&", ",").split(/[\,,\;,\~]/);
            for (const name of split) {
              const nameSplit = name.trim().split(/[\s,\r]/);
              replaceString += '\n\\editor[surname]{' + nameSplit[0] + '}';
              if (nameSplit[1] !== undefined) {
                replaceString += '\n\\editor[given]{' + nameSplit[1] + '}';
              }
            };
            if ('c_e_etal' in params) {
              replaceString += '\n\\editor[etal]{}';
            }
            output = output.replace("${" + key + "}", replaceString);
            continue;
          }
          //ends
        }
      }

      output = output.replace("${" + key + "}", value);
    }
    return output;
  }

  static parseXml(matchText: string) {
    var stringToXml;
    if (typeof window.DOMParser != "undefined") {
      stringToXml = function (matchText) {
        return (new window.DOMParser()).parseFromString(matchText, "text/xml");
      };
    } else if (typeof window.ActiveXObject != "undefined" &&
      new window.ActiveXObject("Microsoft.XMLDOM")) {
      stringToXml = function (matchText) {
        var xmlDoc = new window.ActiveXObject("Microsoft.XMLDOM");
        xmlDoc.async = "false";
        xmlDoc.loadXML(matchText);
        return xmlDoc;
      };
    } else {
      throw new Error("No XML parser found");
    }
    const xmlObject = AutoRegexUtilities.xmlToJson(stringToXml(matchText));
    if (Object.keys(xmlObject)) {
      return { pattern: Object.keys(xmlObject)[0], groups: AutoRegexUtilities.removeEmptyProperties(xmlObject[Object.keys(xmlObject)[0]]) };
    } else {
      return null;
    }
  }

  static removeEmptyProperties(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || Object.keys(obj[propName]).length === 0) {
        delete obj[propName];
      }
    }
    return obj;
  }

  static xmlToJson(xml) {
    let jsonData = { attributes: null };
    if (xml.nodeType === 1) {
      if (xml.attributes.length > 0) {
        jsonData.attributes = {};
        for (let j = 0; j < xml.attributes.length; j++) {
          const attribute = xml.attributes.item(j)
          jsonData.attributes[attribute.nodeName] = attribute.nodeValue;
        }
      }
    }
    const textNodes = [].slice.call(xml.childNodes).filter(node => node.nodeType === 3)
    if (xml.hasChildNodes() && xml.childNodes.length === textNodes.length) {
      jsonData = [].slice.call(xml.childNodes).reduce((text, node) => text + node.nodeValue, '')
    } else if (xml.hasChildNodes()) {
      for (let i = 0; i < xml.childNodes.length; i++) {
        const item = xml.childNodes.item(i)
        const nodeName = item.nodeName.replace(':', '_')
        if (nodeName !== '#text') {
          if (typeof jsonData[nodeName] === 'undefined') {
            jsonData[nodeName] = this.xmlToJson(item)
          } else {
            if (typeof jsonData[nodeName].push === 'undefined') {
              const old = jsonData[nodeName]
              jsonData[nodeName] = []
              jsonData[nodeName].push(old)
            }
            jsonData[nodeName].push(this.xmlToJson(item))
          }
        }
      }
    }
    return AutoRegexUtilities.removeEmptyProperties(jsonData)
  }

  static getExpressionsToTest(pattern: any): string[] {
    const expressions = [];
    const parameters = pattern.parameters;
    for (var thisOrder of (pattern.order || [])) {
      let expression = "";
      for (let index = 0; index < thisOrder.order.length; index++) {
        const thisParameter = parameters.find((item) => item.id === thisOrder.order[index]);
        expression += `(?<${thisParameter.name}>${thisParameter.expression}${thisParameter.is_optional ? '?' : ''})`;
        if (index != thisOrder.order.length - 1) {
          expression += `(?:[\\/\\s]*)?`;
        }
      }
      expressions.push(expression);
    }
    return expressions;
  }

  static readonly PATTERNS = [
    {
      id: "journal",
      name: "Journal",
      output: "\\bibitem{${bibitem}} ${c_names} \n\\reftype{journal} \n\\info[art-title]{${art_title}} \n\\info[jnl-title]{${jnl_title}} \n\\info[year]{${year}} \n\\info[volume]{${volume}} \n\\info[issue]{${issue}} \n\\info[first-page]{${first_page}} \n\\info[last-page]{${last_page}} \n\\info[article_no]{${article_no}} \n\\info[collab_name]{${collab_name}} \n\\info[end_comment]{${end_comment}} \n\\endref\n",
      parameters: [
        {
          name: "bibitem",
          expression: "([\\d\\w]+)",
          order: 1
        },
        {
          name: "group"
        },
        {
          name: "c_names",
          expression: "([\\w\\s\\,\\~\\.]+)",
          order: 2
        },
        {
          name: "art_title",
          expression: "([\\-\\w\\s\\:\\[\\]\\,]*)",
          order: 3
        },
        {
          name: "jnl_title",
          expression: "([\\w\\s\\,\\:\\-]*)",
          order: 4
        },
        {
          name: "year",
          expression: "([\\d]{4})",
          order: 5
        },
        {
          name: "volume",
          expression: "([\\d]+)",
          order: 6
        },
        {
          name: "issue",
          expression: "([\\d]+)",
          order: 7
        },
        {
          name: "first_page",
          expression: "([\\d]+)",
          order: 8
        },
        {
          name: "last_page",
          expression: "([\\d]+)",
          order: 9
        },
        {
          name: "article_no",
          expression: "([\\d]+)",
          is_optional: true,
          order: 10
        },
        {
          name: "end_comment",
          expression: "([\\w\\:\\.\\s\\,\\d\\(\\)\\/]*)",
          is_optional: true,
          order: 11
        },
        {
          name: "doi",
          expression: "([\\d\\.\\/\\w\\:]*)",
          is_optional: true,
          order: 12
        },
        {
          name: "collab_name",
          expression: "([\\w\\s\\-\\d]*)",
          order: 13
        }
      ],
      //expressions: [
      // '(bibitem>[\\d]*)(?:\\.\\s)(?<c_names>[\\w\\s\\,]*)(?:\\.\\s)(?<art_title>[\\w\\s\\-\\,]*)(?:\\.\\s)(?<jnl_title>[\\w\\s]*)(?:[\\.\\,\\s]*)(?<year>[\\d]{4})(?:[\\;\\,\\s]*)(?<volume>[\\d]*)(?:\\-)(?<issue>[\\d]*)(?:\\s*)(?<first_page>[\\d]*)(?:\\-)(?<last_page>[\\d]*)(?:\\.)',
      // '(?<bibitem>[\\d]*)(?:\\.\\s)(?<c_names>[\\w\\s\\,]*)(?:\\.\\s)(?<art_title>[\\w\\s\\-]*)(?:\\.\\s)(?<jnl_title>[\\w\\s]*)(?:[\\.\\,\\s]*)(?<year>[\\d]{4})(?:[\\;\\,\\s]*)(?<volume>[\\d]*)(?:\\-)(?<issue>[\\d]*)(?:\\s*)(?<first_page>[\\d]*)(?:\\-)(?<last_page>[\\d]*)(?:\\.)',
      // '(?<bibitem>[\\d]*)(?:\\.\\s)(?<c_names>[\\w\\s\\,]*)(?:\\.\\s)(?<art_title>[\\w\\s\\-\\,]*)(?:\\.\\s)(?<jnl_title>[\\w\\s]*)(?:[\\.\\,\\s]*)(?<year>[\\d]{4})(?:[\\;\\,\\s]*)(?<volume>[\\d]*)(?:\\-)(?<issue>[\\d]*)(?:\\s*)(?<first_page>[\\d]*)(?:\\-)(?<last_page>[\\d]*)(?:\\.)',
      // '(?<bibitem>[\\d]*)(?:\\.\\s)(?<c_names>[\\w\\s\\,\\.]*)(?:\\-)(?<art_title>[\\w\\s\\-]*)(?:[\\,\\.\\s]*)(?<jnl_title>[\\w\\s\\,]*)(?:[\\s\\w]*)(?:\\.)(?<volume>[\\d]*)(?:\\,\\s)(?<first_page>[\\d]*)(?:\\-*)(?<last_page>[\\d]*)(?:\\,\\s)(?<year>[\\d]{4})(?:[\\,\\s\\w]*)(?:\\s)(?<doi>[\\w\\.\\/\\d]*)'

      // '${bibitem}(?:\\.\\s*)${c_names}(?:\\.\\s*)${art_title}(?:\\.\\s*)${jnl_title}(?:\\.\\s*)${year}(?:\\;\\s*)${volume}(?:\\()${issue}(?:\\))(?:\\:\\s*)${first_page}(?:\\-)${last_page}(?:\\.)',
      // '(?:\\\\item)(?:\\[\\[)${bibitem}(?:\\]\\])(?:\\s*)${c_names}(?:\\.\\s*)${art_title}(?:\\.\\s*)${jnl_title}(?:\\,\\s*)${year}(?:\\,\\s*)${volume}(?:\\()${issue}(?:\\))(?:\\:)${first_page}(?:\\-)${last_page}(?:\\.)',
      /* '(?:\\\\item)(?:\\[)${bibitem}(?:\\.\\])(?:\\s*)${c_names}(?:\\,\\s)(?:\\s*)(?:et\\sal\\.)(?:\\s*)${art_title}(?:\\.\\s*)${jnl_title}${year}(?:\\;)${volume}(?:\\:\\s*)${first_page}(?:\\\\textendash\\{\\})${last_page}',
       '${bibitem}(?:\\.)(?:\\s*)${c_names}(?:\\,\\s*)(?:\\`\\`)${art_title}(?:\\,)(?:\\\'\\\')(?:\\s*)(?:\\{\\\\em\\s*)${jnl_title}(?:\\}\\,\\s*)(?:vol\\.\\~)${volume}(?:\\,\\s*pp\\.\\~)${first_page}(?:\\-\\-)${last_page}(?:\\,\\s*)${year}(?:\\,\\s*)(?:doi\\:\\~)${doi}(?:\\.)',
       '(?:\\[)${bibitem}(?:\\])(?:\\s+)${c_names}${art_title}(?:\\\\textit)(?:\\{)${jnl_title}(?:\\})(?:\\s*)(?:\\\\textbf\\{)${volume}(?:\\})(?:\\()${issue}(?:\\))(?:\\s*)(?:\\()${year}(?:\\))(?:\\,\\s*)${first_page}(?:\\-)${last_page}(?:\\.)',
       '(?:BD\\sJournal:)(?:\\s*)${bibitem}(?:\\.\\s*)(?<c_names>[\\w\\s\\,]+)(?:\\.\\s)${art_title}(?:\\.\\s*)${jnl_title}(?:\\.\\s*)${year}(?:\\;)${volume}(?:\\()${issue}(?:\\))(?:\\:)${first_page}(?:\\-)${last_page}(?:\\.)',
       '(?:BIR)(?:\\s*)(?:\\&\\s)(?:JRS)(?:\\s)(?:Journal:)(?:\\s)(?:\\\\)(?:item)(?<c_names>[\\w\\s\\,]+)(?:\\.\\s)(?<art_title>[\\w\\s]*)(?:\\.\\s)${jnl_title}(?:\\.\\s)${year}(?:\\;)${volume}(?:\\()${issue}(?:\\))(?:\\:)${first_page}(?:\\-)${last_page}(?:\\.)',
       '(?:\\\\)(?:bibitem)(?:\\{)(?<bibitem>[\\w\\d]+)(?:\\})(?:\\s)(?<c_names>[\\w\\s\\,\\.\\~]+)(?:\\{\\\\normalfont\\s\\\\emph\\{et\\~al\\.\\}\\}\\,)(?:\\s)(?:\\{)(?:\\\\og)(?<art_title>[\\w\\s\\:\\[\\]\\.\\,]*)(?:\\\\fg)(?:\\})(?:\\,)(?:\\s)(?:\\\\emph)(?:\\{)(?<jnl_title>[\\w\\s\\.]*)(?:\\})(?:\\s)(?:\\\\)(?:textbf)(?:\\{)(?<volume>[\\d]+)(?:\\})(?:\\s)(?:\\()(?<year>[\\d]{4})(?:\\))(?:\\,)(?:\\s)(?:p\\.\\~)(?<first_page>[\\d]+)(?:\\-)(?<last_page>[\\d]+)(?:\\.)',
       '(?:\\\\)(?:bibitem)(?:\\{)(?<bibitem>[\\w\\d]*)(?:\\})(?<c_names>[\\w\\.\\~\\.\\,\\s]*)(?:\{\\\\og\\s)(?<art_title>[\\w\\s\\:\\{\\}a]*)(?:\\\\fg)(?:\\})(?:\\,)(?:\\s)(?:\\\\emph)(?:\\{)(?<jnl_title>[\\w\\s\\.]*)(?:\\})(?:[\\s]*)(?:\\\\)(?:textbf)(?:\\{)(?<volume>[\\d]*)(?:\\})(?:\\s)(:?\\()(?<year>[\\d]+)(?:\\))(?:)(?:\\,\\s)(?:p\\.\\~)(?<article_no>[\\d]+)(?:\\.)',
       '(?:\\\\)(?:bibitem)(?:\\{)(?<bibitem>[\\w\\d]*)(?:\\})(?:\\s)(?<c_names>[\\w\\.\\s\\~]*)(?<etal>[\{\\\\it\\set\\sal\\.\\}]*)(?:\\s)(?:\\()(?<collab_name>[\\w\\s\\-\\d]*)(?:\\))(?:\\,)(?<jnl_title>[\\s\\.\\w\\\\]*)(?:\{\\\\bf\\s)(?<volume>[\\d]+)(?:\\})(?:\\s)(?<article_no>[\\d]+)(?:\\s)(?:\\()(?<year>[\\d]{4})(?:\\))(?:\\.)',
       '(?:\\\\bibitem)(?:\\{)(?<bibitem>[\\w\\:\\d]*)(?:\\})(?<c_names>[\\w\\.\\~\\s]*)(?:\\,)(?:\\s)(?:et\\~al\\.\\,)(?:\\s\\{)(?<art_title>[\\w\\s\\$\\^\\-\\_\\\\\\\!\\{\\}]*)(?:\\,)(?:\\s)(?<jnl_title>[\\w\\.\\s]*)(?<volume>[\\d]*)(?:\\s)(?:\\()(?<year>[\\d]{4})(?:\\))(?:\\s)(?<article_no>[\\d]*)(?:\\,)(?:\\s)(?:\\[)(?<end_comment>[\\w\\:\\.\\s\\,\\d\\(\\)]*)(?:\\])(?:\\.)',
       '(?<bibitem>[\\d\\.]*)(?:\\s)(?<c_names>[\\w\\\\\\\'\\{\\}\\s\\.]*)(?:\\.)(?<art_title>[\\w\\:\\s\\,\\-]*)(?:\\.)(?<jnl_title>[\\w\\s]*)(?:\\.)(?:\\s)(?<year>[\\d]{4})(?:\\;)(?<volume>[\\d]*)(?:\\:)(?<first_page>[\\d]*)(?:\\\\textendash)(?:\\{\\})(?<last_page>[\\d]*)(?:\\.)',
       '(?<bibitem>[\\d]*)(?:\\.)(?:\\s)(?<c_names>[\\w\\s]*)(?:\\.)(?:\\s)(?<art_title>[\\w\\s]*)(?:\\.)(?:\\s)(?<jnl_title>[\\w\\s]*)(?:\\.)(?:\\s)(?<year>[\\d]{4})(?:\\;)(?<volume>[\\d]*)(?:\\s)(?<issue>[\\d]*)(?:\\:)(?<first_page>[\\d]*)(?:\\-)(?<last_page>[\\d]*)(?:\\.)(?:\\s)(?<end_comment>[\\w\\:\\d\\.\\/]*)(?:\\.)'
     */
      //],
    },
    {
      id: "book",
      name: "book",
      output: "\\bibitem{${bibitem}} \n\\reftype{book} ${c_names} \n\\info[book-title]{${book_title}} ${c_e_names} \n\\info[publisher-ioc]{${publisher_ioc}} \n\\info[year]{${year}} \n\\info[publisher]{${publisher}} \n\\info[edition]{${edition}}  \n\\info[end_comment]{${end_comment}} \n\\endref\n",
      parameters: [
        {
          name: "bibitem",
          expression: ["([\\d]+)"],
          order: 1
        },
        {
          name: "c_names",
          expression: ["([\\w\\s\\,\\.\\~]+)"],
          order: 2
        },
        {
          name: "book_title",
          expression: ["([\\w\\s]*)"],
          order: 3
        },
        {
          name: "publisher_ioc",
          expression: ["([\\w\\s]*)"],
          order: 4
        },
        {
          name: "year",
          expression: ["([\\d]{4})"],
          order: 5
        },
        {
          name: "publisher",
          expression: ["([\\w\\s]*)"],
          order: 6
        },
        {
          name: "c_e_names",
          expression: ["([\\w\\s\\,]*)"],
          isOptional: true,
          order: 7
        },
        {
          name: "edition",
          expression: ["([\\w\\d]*)"],
          is_optional: true,
          order: 8

        },
        {
          name: "end_comment",
          expression: ["([\\w\\:\\d]*)"],
          isOptional: true,
          order: 9
        }

      ],


      //expressions: [
      //   '(?:\\\\)(?:item)(?:\\s)(?<c_names>[\\w\\s\\.]*)(?:\\,)(?:\\s)(?:\\\\)(?:textit)(?:\\{)(?<book_title>[\\w\\s]*)(?:\\}\\,)(?<publisher_ioc>[\\w\\s]*)(?:\\,)(?:\\s)(?<publisher>[\\w\\s]*)(?:\\s)(?<year>[\\d]{4})(?:\\.)',
      //   '(?:\\\\item)(?:\\[\\[)(?<bibitem>[\\d]*)(?:\\]\\])(?:\\s*)(?:\\\\)(?:[\\w\\{]*)(?:\\})(?:\\{)(?<c_names>[\\w\\s]*)(?:\\})(?:\\s)(?:\\\\)(?:[\\w\\{]*)(?:\\})(?:\\{)(?:[\\\\\\w]*)(?:\\{)(?<book_title>[\\w\\s]*)(\\}\\})(?:\\s)(?:\\\\)(?:[\\w\\{]*)(?:\\})(?:\\{)(?<c_e_names>[\\w\\s\\,]*)(?:\\s)(?:\\()(?<publisher_ioc>[\\w]*)(?:\\:)(?<publisher>[\\w\\s]*)(?:\\))',
      //   '(?:\\\\)(?:item)(?:\\[)(?:\\\\)(?:[\\w\{\\-\\d]*)(?:\\})(?:\\{\\[)(?<bibitem>[\\d]*)(?:\\]\\}\\])(?:\\\\)(?:[\\w\\{\\-\\d]*)(?:\\})(?:\\{)(?<c_names>[\\w\\.\\s]*)(?:\\,)(?:\\s)(?<book_title>[\\w\\s]*)(?:\\,)(?:\\s)(?<publisher>[\\w\\s\\\\\&\s]*)(?:\\,\\s)(?<year>[\\d]{4})(?:\\.\\})',
      //   '(?:\\[)(?<bibeitem>[\\d]*)(?:\\]\\s)(?<c_names>[\\w\\s\\.]*)(?:\\,\\s)(?<book_title>[\\w\\s]*)(?:\\,\\s)(?<edition>[\\w\\d]*)(?:\\s)(?:[\\w\\,]*)(?:\\s)(?<publisher>[\\w\\s\\{\\\\\&\\}\\,]*)(?:\\.\\,\\s)(?<publisher_ioc>[\\w\\,\\s]*)(?<year>[\\d]{4})(?:\\.)',
      //   '(?<bibitem>[\\d]*)(?:\\.)(?<c_names>[\\w\\,\\s]*)(?:\\.)(?:\\s)(?<book_title>[\\w\\s]*)(?:\\.)(?:\\s)(?<publisher_ioc>[\\w\\s\\,]*)(?:\\:)(?:\\s)(?<publisher>[\\w\\s]*)(?:\\;)(?:\\s)(?<year>[\\d]{4})(?:\\.)(?:\\s)(?<end_comment>[\\w\\:\\d]*)'
      //],
    },
    {
      id: "inbook",
      name: "inbook",
      output: "\\bibitem{${bibitem}} \n\\reftype{inbook} ${c_names} \n\\info[art-title]{${art_title}} \n\\info[book-title]{${book_title}} ${c_e_names} \n\\info[publisher-ioc]{${publisher_ioc}} \n\\info[year]{${year}} \n\\info[publisher]{${publisher}} \n\\info[first-page]{${first_page}} \n\\info[last-page]{${last_page}} \n\\endref\n",
      parameters: [
        {
          name: "bibitem",
          expression: ["([\\d]+)"],
          order: 1
        },
        {
          name: "c_names",
          expression: ["([\\w\\s\\,\\.\\~]+)"],
          order: 2
        },
        {
          name: "art_title",
          expression: ["([\\w\\s\\-]*)"],
          order: 3
        },
        {
          name: "book_title",
          expression: ["([\\w\\s]*)"],
          order: 4
        },
        {
          name: "c_e_names",
          expression: ["([\\w\\s\\,]*)"],
          order: 5
        },
        {
          name: "publisher",
          expression: ["([\\w\\s]*)"],
          order: 6
        },
        {
          name: "publisher_ioc",
          expression: ["([\\w\\s\\,]*)"],
          order: 7
        },

        {
          name: "year",
          expression: ["([\\d]{4})"],
          order: 8
        },


        {
          name: "first_page",
          expression: ["([\\d]+)"],
          order: 9
        },
        {
          name: "last_page",
          expression: ["([\\d]+)"],
          is_optional: true,
          order: 10
        },

      ],

      //expressions: [
      /*
      '(?:\\[)(?<bibitem>[\\d]*)(?:\\])\\s*(?<c_names>(.+?\\s\\w*\\sand\\s.+?))\\,\\s*(?<art_title>[\\w\\s\\-]*\\.)\\s*(?:[I|i]n\\:)\\s*(?<c_e_names>[\\w\\.\\s\\w\\,]*)\\,\\s(?:.+\\s)(?:\\\\textit\\{)(?<book_title>[\\w\\s]*)(?:\\}\\.)\\s*(?<publisher_loc>[\\w\\,\\s\\w]*)(?:\\:)\\s*(?<publisher>[\\w\\s\\w]*)(?:\\,)\\s*(?<year>[\\d]*)(?:\\.)',
      '(?:\\\\item)(?:\\[)(?:\\s*)(?:\\[)(?<bibeitem>[\\d]*)(?:\\])(?:\\])(?:\\\\)(?:selectlanguage\\{\\}\\s*)(?:[\\w\\,\\s\\\\\&]*)(?:\\s)(?:[\\\\\w]*)(?:\\{)(?<c_names>[\\w\\,\\s\\.]*)(?:\\s)(?:\\()(?<year>[\\d]{4})(?:\\))(?:\\.)(?:\\s)(?<book_title>[\\w\\-]*)(?:\\.\\s)(?:[\\w]*)(?:\\s)(?<c_e_names>[\\w\\s\\.\\\\\&]*)(?:[\\(\\w\\.\\)\\,\\}]*)',
      '(?<bibitem>[\\d]*)(?:\\.\\s)(?<c_names>[\\w\\,\\s\\;\\\\]*)(?:\\.\\s)(?<art_title>[\\w\\s]*)(?:\\.\\s)(?:[\\s\\w\\\\]*)(?:\\{)(?<book_title>[\\w\\s\\(\\)]*)(?:\\}\\;\\s)(?<year>[\\d]{4})(?:\\;)(?:[\\s\\w\\.]*)(?:\\s)(?<first_page>[\\d]*)(?:\\-)(?:\\-)(?<last_page>[\\d]*)(?:\\.)',
      //'(?<bibitem>[\\d]*)(?:\\.\\s)(?<c_names>[\\w\\,\\s\\.\\;\\\\]*)(?:\\s)(?<art_title>[\\w\\s]*)(?:\\.)(?:\\s)(?:[\\w\\s\\\\]*)(?:\\{)(?<book_title>[\\w\\s\\(\\)]*)(?:\\}\;\\s)(?<year>[\\d]{4})(?:\\;\\s)(?:[\\w\\.]*)(?:\\s)(?<first_page>[\\d]+)(?:\\-*)(?<last_page>[\\d]+)(?:\\.)',
 
      '(?:\\[)(?<bibitem>[\\d]*)(?:\\])(?:\\s)(?<c_names>[\\w\\.\\s]*)(?:\\,\\s)(?<art_title>[\\w\\s\\-]*)(?:[\\.\\s\\w]*)(?:\\:\\s)(?<c_e_names>[\\w\\.\\,\\s]*)(?:\\,\\s)(?:[\\w\\.\\s\\\\]*)(?:\\{)(?<book_title>[\\w\\s]*)(?:\\}\\.\\s)(?<publisher_ioc>[\\w\\s\\,]*)(?:\\:\\s)(?<publisher>[\\w\\s]*)(?:\\,\\s)(?<year>[\\d]{4})(?:\\.)',
      '(?:\\[)(?<bibitem>[\\d]*)(?:\\])(?:\\s)(?<c_names>[\\w\\s\'\\,]*)(?:\\.\\s)(?<art_title>[\\w\\s\\-]*)(?:\\,\\s)(?<book_title>[\\w\\s]*)(?:\\.\\s)(?<publisher>[\\w]*)(?:\\,\\s)(?<year>[\\d]{4})(?:\\:)(?<first_page>[\\d]+)(?:\\-)(?<last_page>[\\d]+)(?:\\.)',
      '(?<bibitem>[\\d]*)(?:\\.\\s)(?<c_names>[\\w\\s]*)(?:[\\,\\s\\w\\-]*)(?:\\,\\s)(?:[\\w\\s]*)(?:\\.\\s)(?<art_title>[\\w\\s\\(\\)]*)(?:\\.\\s)(?:[\\w\\:]*)(?:\\s)(?<c_e_names>[\\w\\s\\,]*)(?:[\\,\\s\\w]*)(?:\\.\\s)(?<book_title>[\\w\\s\\:\\,]*)(?:\\.\\s)(?<publisher_ioc>[\\w]*)(?:\\:\\s)(?<publisher>[\\w]*)(?:\\;\\s)(?<year>[\\d]{4})(?:[\\.\\s\\w]*)(?:\\s)(?<first_page>[\\d]+)',
      '(?<bibitem>[\\d]*)(?:\\.\\s)(?<c_names>[\\w\\s\\,]*)(?:\\.\\s)(?<art_title>[\\w\\s]*)(?:\\.\\s)(?<book_title>[\\w\\s]*)(?:\\.\\s)(?<publisher>[\\w]*)(?:\\;)(?:\\s)(?<year>[\\d]{4})(?:\\.\\s\\w*)(?:\\.\\s*)(?<first_page>[\\d]+)(?:\\-)(?<last_page>[\\d]+)(?:\\.)',
      '(?<bibitem>[\\d]*)(?:\\.\\s)(?<c_names>[\\w\\s]*)(?:\\.\\s)(?<art_title>[\\w\\s\\:]*)(?:\\.\\s)(?:[\\w\\:]*)(?:\\s)(?<c_e_names>[\\w\\s\\,\\.]*)(?:\\,)(?:\\s\\w*)(?:\\.\\s)(?<book_title>[\\w\\s]*)(?:\\.\\s)(?<year>[\\d]{4})(?:[\\.\\s\\w]*)(?:\\.\\s)(?<first_page>[\\d]+)(?:\\-)(?<last_page>[\\d]+)(?:\\.)',
      '(?:[\\\\\w]*)(?:[\\[\\[]*)(?<bibitem>[\\d]*)(?:[\\]\\]]*)(?<c_names>[\\w\\.]*)(?:\\,\\s)(?<art_title>[\\w\\s]*)(?:[\\.\\s\\w]*)(?:\\:)(?<book_title>[\\w\\:\\s]*)(?:\\.\\s)(?:[\\w]*)(?:\\s)(?:[\\w]*)(?:\\s)(?<c_e_names>[\\w\\s\\,]*)(?:\\s)(?<etal>[\\w]*)(?:\\:\\s)(?<publisher_ioc>[\\w]*)(?:\\;\\s)(?<publisher>[\\w\\s]*)(?:\\,\\s)(?<year>[\\d]{4})(?:[\\;\\s\\w]*)(?:\\.\\s)(?<fitst_page>[\\d]*)(?:\\-)(?<last_page>[\\d]*)(?:\\.)'
      */
      //],
    },
    {
      id: "other",
      name: "other",
      output: "\\bibitem{${bibitem}} \n\\reftype{other} ${c_names} \n\\info[other_title]{${other_title}} \n\\info[misc]{${misc}} \n\\endref\n",
      parameters: [
        {
          name: "bibitem",
          expression: ["([\\d]+)"],
          order: 1
        },
        {
          name: "c_names",
          expression: ["([\\w\\s\\,\\.\\~]+)"],
          order: 2
        },
        {
          name: "other_title",
          expression: ["([\\w\\s\\-]*)"],
          order: 3
        },
        {
          name: "misc",
          expression: ["([\\w\\-\\\/\\d\:\\s\\(\\)\\.]*)"],
          is_optional: true,
          order: 4

        },
        {
          name: "tagyr",
          expression: ["([\\d]{4})"],
          is_optional: true,
          order: 5
        }
      ],

      //expressions: [
      /* '(?:\\\\item)(?:\\[\\[)(?<bibitem>[\\d]*)(?:\\]\\])(?:\\\\)(?:[\\w\\{\\}]*)(?:\\s)(?<other_title>[\\w\\s\\{\\\\\_\\d\\.\\}\(\\:\\)]*)',
       '(?:\\\\item)(?:\\[\\[)(?<bibitem>[\\d]*)(?:\\]\\])(?:\\s*)(?:\\\\)(?:[\\w\{\\}]*)(?:\\{)(?<c_names>[\\w\\s]*)(?:\\s)(?<other_title>[\\w\\-\\s]*)(?:\\})',
       '(?<c_names>[\\w\\s\\,]*)(?:\\.)(?<misc>[\\w\\-\\\/\\d\:\\s\\(\\)\\.]*)(?:\\.)',
       '(?<bibitem>[\\d]*)(?:\\.\\s)(?<c_names>[\\w\\s]*)(?:[\\,\\s\\w\-]*)(?:\\.\\s)(?<misc>[\\w\\s\\d\\.\\\\\{\\:\\/\\/]*)(?:\\})',
       '(?<bibitem>[\\d]*)(?:\\.\\s)(?<c_names>[\\w\\s\\;\\-\\u00C0-\\u00D6\\u00D8-\\u00f6\\u00f8-\\u00ff]*)(?:\\s)'
       */
      //],
    },
    {
      id: "au-aff",
      //  name: "Au-Aff",
      name: "au-aff",
      output: "${c_a_names} \\n${c_aff}",
      parameters: [
        {
          name: "c_a_names",
          expression: [""],
          order: 1
        },
        {
          name: "c_aff",
          expression: [""],
          order: 2
        },
        {
          name: "corresp",
          expression: ["([\\w\\s\\,\\.]*)"],
          order: 3
        },
        {
          name: "email",
          expression: ["([\\w\\.\\@]*)"],
          order: 4
        },
        {
          name: "fax",
          expression: ["([\\w\\:\\+\\s\\d]*)"],
          order: 5
        }


      ],

      //expressions: [
      /*//'(?<c_a_names>[^\\n]*)(?:\\n)(?<c_aff>[^\\*]*)(?:[^\\:]*)(?:\\:\\s)(?<corresp>[\\w\\s\\,]*)(?:[^\\:]*)(?:\\:\\s)(?<email>[\\w\\.\\@]*)',
      // '(?<c_a_names>[^\\n]*)(?:\\n*)(?<c_aff>[^\\n]*)(?:\\n*)(?:[^\\:]*)(?:\\:\\s)(?<corresp>[\\w\\s\\,]*)(?:[^\\:]*)(?:\\:\\s)(?<email>[\\d\\w\\@\\.]*)',
      '(?<c_a_names>[^\\n]*)(?:\\n*)(?<c_aff>[^\\*]*)(?:[^\\:]*)(?:\\:\\n*)(?<corresp>[\\w\\s\\n\\d\\-\\,\\+]*)(?:[^\\s]*)(?:\\s)(?<fax>[\\w\\:\\+\\s\\d]*)(?:[^\\s]*)(?:\\s)(?<email>[\\w\\@\\.\\-]*)'
``      */
      //],
    },

    //thesis
    {
      id: "thesis",
      name: "thesis",
      output: "\\bibitem{${bibitem}} \n\\reftype{thesis} ${c_names} \n\\info[misc]{\\tagatitle{${c_misc} \n\\tagthesis{${tag_theis}} \n\\tagpublisher{${tag_publisher}} \n\\tag_place{${tag_place}} \n\\tagyr{${tag_yr}} \n\\endref\n",
      parameters: [
        {
          name: "bibitem",
          expression: ["([\\d\\w]+)"],
          order: 1
        },
        {
          name: "c_names",
          expression: ["([\\w\\s\\,\\.\\~]+)"],
          order: 2
        },

        {
          name: "c_misc",
          expression: ["([\\w\{\\\\\'\\}\\s\\(\\d\\,]*)"],
          order: 3
        },
        {
          name: "tag_theis",
          expression: ["([\\w]*)"],
          order: 4
        },
        {
          name: "tag_publisher",
          expression: ["([\\\\\w\\{\'\\}\\s]*)"],
          order: 5
        },
        {
          name: "tag_place",
          expression: ["([\\w\\s]*)"],
          order: 5
        },
        {
          name: "tag_yr",
          expression: ["([\\d]{4})"],
          order: 6
        }

      ],

      //expressions: [
      //  '(?:\\\\bibitem)?(?:\\{)(?<bibitem>[\\w]*)(?:\\})(?:\\s*)(?<c_names>[\\w\\.\\~\\s]*)(?:\\,)(?:\\s*)(?:[\\{\\\\\w]*)(?:\\s)(?<misc>[\\w\{\\\\\'\\}\\s\\(\\d\\,]*)(?:[\\}\\,\\\\\w\\)]*)(?:\\s\\\\*)(?:\\w)(?:\\w)(?:\\w)(?<tag_theis>[\\w]*)(?:\\,\\s)(?<tag_publisher>[\\\\\w\\{s\'\\}\\s]*)(?:\\n)(?:\\s*)(?<tag_place>[\\w\\s]*)(?:\\,\\s)(?<tag_yr>[\\d]{4})(?:\\.)'
      //],
    },
    //report

    {
      id: "report",
      //  name: "Au-Aff",
      name: "report",
      output: "\\bibitem{${bibitem}} \n\\reftype{report} ${c_names} \n\\info[year]{${year} \n\\info[art-title]{${art_title}} \n\\info[book-title]{${book_title}} \n\\info[techrep-no]]{${techrep_no]}}  \n\\endref\n",
      parameters: [
        {
          name: "bibitem",
          expression: ["([\\d]+)"],
          is_optional: true,
          order: 1
        },
        {
          name: "c_names",
          expression: ["([\\w\\s\\,\\.\\~]+)"],
          order: 2
        },

        {
          name: "art_title",
          expression: ["([\\w\{\\\\\'\\}\\s\\(\\d\\,]*)"],
          order: 3
        },
        {
          name: "book_title",
          expression: ["([\\w]*)"],
          order: 4
        },

        {
          name: "techrep_no",
          expression: ["([\\w\\s\\/\\-\\d]*)"],
          order: 5
        },
        {
          name: "year",
          expression: ["([\\d]{4})"],
          order: 6
        }

      ],

      //expressions: [
      // '?:\\s*)?(?<c_names>[\\w\\,\\s\\\\&\\.]*)(?:\\s*)(?:\\()?(?<year>[\\d]*)(?:\\))?(?:\\.)(?:\\s*)(?<art_title>[\\w\\s\\:]*\\.)(?:\\s*)(?<book_title>[\\w\\s]*)\\,(?:\\s*)?(?<techrep_no>[\\w\\s\\/\\-]*\\.)'
      //],
    },
  ]

}