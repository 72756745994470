import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManageArticleuploadComponent } from './manage-articleupload/manage-articleupload.component';
import { AddArticleComponent } from './add-article/add-article.component';
import { EditarticleuploadComponent } from './editarticleupload/editarticleupload.component';

const routes: Routes = [
    {
        path: '',
        data: {
            title: 'breadcrumb.Manage article upload'
        },
        children: [
            {
                path: '',
                component: ManageArticleuploadComponent,
                data: {
                    title: ''
                }

            },
            {
                path: 'addarticle',
                component: AddArticleComponent,
                data: {
                    title: 'breadcrumb.Add article'
                }

            },
            {
                path: 'editarticle/:id',
                component: EditarticleuploadComponent,
                data: {
                    title: 'breadcrumb.Edit article'
                }

            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ArticleUploadRoutingModule { }
