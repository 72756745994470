import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { EnvService } from './env.service';

const TOKEN = 'TOKEN';
const Proxy_Status = 'Proxy_Status';

const LANGUAGE = 'LANGUAGE';

@Injectable({
    providedIn: 'root'
})
export class UserTokenService {

    constructor(private http: HttpClient, private env: EnvService) { }
    public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public response = [];
    public user_data: Subject<any>;
    public token = "";

    setToken(token: string): void {
        localStorage.setItem(TOKEN, token);
    }

    setLanguage(lang: string): void {
        localStorage.setItem(LANGUAGE, lang);
    }

    getLanguage() {
        return localStorage.getItem(LANGUAGE);
    }

    isLogged(): Observable<any> {
        const token = this.getToken();
        if (token) {
            return this.http.get(this.env.baseAPIUrl + '/user/get_user_data').map(user => user).catch(err => Observable.of(false));
        }
        return Observable.of(false);
    }

    getToken() {
        return localStorage.getItem(TOKEN);
    }

    removeToken() {
        // remove user from local storage to log user out
        localStorage.removeItem(TOKEN);
        localStorage.removeItem(Proxy_Status);
        localStorage.removeItem('BACKUP_TOKEN');
        localStorage.removeItem('BACKUP_ACCESS');
        localStorage.removeItem('BACKUP_PERMISSIONS');
        localStorage.removeItem('BACKUP_FEATURE');
        localStorage.removeItem('manage_feature');
        localStorage.removeItem("FEATURE");
        localStorage.removeItem("ACCESS");
        localStorage.removeItem("current_logined_userId");
        localStorage.removeItem("PRIVILEGES");
        localStorage.removeItem("MENU_PERMISSIONS");
        localStorage.removeItem("PERMISSIONS");
        localStorage.removeItem("guestToken");
        localStorage.removeItem("TOKEN");
        localStorage.removeItem("guestUser");
        localStorage.removeItem("articles");
        localStorage.removeItem("activeFiles");
    }
}