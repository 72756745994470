import { Component, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { UserrolesService } from '../userroles/userroles.service';
import { UserTokenService } from '../service/user-token.service';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { userMenus } from '../_user_menudb';
import { NotifierService } from 'angular-notifier';
import { InjectableGlobal } from '../permissions';
import { EnvService } from '../service/env.service';
import { SharedService } from '../shared/shared.service';
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl
} from "@angular/forms";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent {
    loginForm: FormGroup;
    forgotPasswordForm: FormGroup;
    submitted = false;
    private subject = new Subject<any>();
    public usermenu = userMenus;
    username = '';
    password = '';
    message = '';
    show = true;
    functionality = [];
    permissions = [];
    features = [];
    menu_url = [];
    base_url: string;
    LoginSuccess: boolean = false;
    navigateToOnLoginSuccess = '/dashboards';
    private readonly notifier: NotifierService;
    current_year = (new Date()).getFullYear();
    constructor(
        private sharedService: SharedService,
        private userroleService: UserrolesService,
        private fbb: FormBuilder,
        private InjectableGlobal: InjectableGlobal,
        private api: LoginService,
        private user: UserTokenService,
        private router: Router,
        public env: EnvService,
        notifierService: NotifierService) {
        this.notifier = notifierService;
    }

    ngOnInit() {
        this.sharedService.showSpinner('loginspinner');
        this.user.isLogged().subscribe(
            res=>{
                this.sharedService.hideSpinner('loginspinner');
                if(res){
                    this.base_url = this.env.baseUploadUrl;
                    if(JSON.parse(atob(localStorage.getItem('ACCESS'))).length > 0){
                        this.router.navigateByUrl(JSON.parse(atob(localStorage.getItem('ACCESS')))[0]);
                    }
                }
            },
            err=> {}
        );
        // if (this.user.isLogged()) {
        //     // this.router.navigateByUrl('/dashboards');
        //     this.base_url = this.env.baseUploadUrl;
        // }
        this.loginForm = this.fbb.group({
            username: ["", [Validators.required]],
            password: ["", Validators.required]
        });
        this.forgotPasswordForm = this.fbb.group({
            email: ["", [Validators.required]]
        });
    }
    get login_function() {
        return this.loginForm.controls;
    }

    get forgotPassword_function() {
        return this.forgotPasswordForm.controls;
    }

    forgotPassword(data) {
        this.submitted = true;
        if (this.forgotPasswordForm.valid) {
            this.api.forgotPassword(data).subscribe(ret => {
                if (ret.status == '200') {
                    this.notifier.notify(ret.message_type, ret.message);
                    this.forgotPasswordForm.reset();
                } else {
                    this.notifier.notify(ret.message_type, ret.message);
                }
            });
        }
    }

    toggle() {

        if (this.show) {
            this.show = false;
        }
        else {
            this.show = true;
        }
    }

    onSubmit() {
        this.submitted = true;
        if (this.loginForm.valid) {
            this.api.login(
                this.username,
                this.password
            )
                .subscribe(
                    (r: any) => {
                        if (r.status == '200') {

                            if (r.guest_token !== null) {
                                localStorage.setItem('guestToken', r.guest_token);
                                localStorage.setItem('guestUser', 'true');
                            }
                            this.functionality = r['user_data']['user_functionality'].map(a => { return "" + a + ""; });

                            this.userroleService.getFeatures().subscribe(data => {
                                if (data != '') {
                                    const filteredUserMenus = data['data'].filter(userMenus => {

                                        if (r['user_data']['usermenu'].indexOf(userMenus.unique_id) != -1) {
                                            this.menu_url.push(userMenus.url);
                                            return true;
                                        }

                                    });

                                    /**Check if the user has permission for accesing dashboard  0-dashboard */
                                    if (!r['user_data']['usermenu'].includes(0)) {
                                        if (r['user_data']['usermenu'].length > 0) {
                                            this.navigateToOnLoginSuccess = filteredUserMenus[0].url;
                                        }
                                        else {
                                            this.notifier.notify("warning", "No privilleges ,Kindly contact the administrator");
                                            return false;
                                        }
                                    }
                                    data['data'].filter(fun => typeof (fun.functionality) != "undefined").map(fun => fun.functionality.map(func => {
                                        if (this.functionality.includes(func.id)) {
                                            this.permissions.push(func.identifier);
                                        }
                                    }));

                                    data['data'].map(data => {

                                        if (data.type == 'F') {
                                            if (r['user_data']['user_feature'].includes(String(data.unique_id))) {
                                                this.features.push(data.name);
                                            }
                                        }

                                    });

                                    localStorage.setItem('ACCESS', btoa(JSON.stringify(this.menu_url)));
                                    localStorage.setItem('PERMISSIONS', btoa(JSON.stringify(this.permissions)));
                                    localStorage.setItem('FEATURE', btoa(JSON.stringify(this.features)));

                                }

                            });
                            if (r.token) {
                                this.notifier.notify(r.message_type, r.message);
                                this.LoginSuccess = true;
                                const redirect = localStorage.getItem('redirectTo');
                                setTimeout(() => {
                                    this.InjectableGlobal.accessibleMenus.length = 0;
                                    this.InjectableGlobal.accessibleMenus = [];
                                    this.InjectableGlobal.loginMessageTimeout = 5000;
                                    if (redirect) {
                                        localStorage.removeItem('redirectTo')
                                        this.router.navigateByUrl(redirect);
                                    } else {
                                        this.router.navigateByUrl(this.navigateToOnLoginSuccess);
                                    }
                                }, 1000);

                            }
                            else {
                                this.notifier.notify(r.message_type, r.message);
                            }

                            this.userroleService.getPreviliegeSvs({
                                role_ids: r.user_data['role_ids'],
                            }).subscribe(response => {
                                localStorage.setItem('PRIVILEGES', btoa(JSON.stringify(response['privileges'])));
                            });
                        }
                        else if (r.status == 401) {
                            this.notifier.notify(r.message_type, 'Username or Password missing');
                        }
                        else if (r.status == 422) {
                            this.notifier.notify(r.message_type, r.message);
                        }
                        else {
                            this.notifier.notify(r.message_type, r.message);
                        }
                    },
                    r => {
                        // console.log(r);
                    });
        }
    }
}