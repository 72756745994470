import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { take, filter, catchError, switchMap, finalize, tap, flatMap } from 'rxjs/operators';
import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from "@angular/common/http";
import { UserTokenService } from "./user-token.service";
import { Router } from '@angular/router';
import { ConditionalExpr } from '@angular/compiler';
import { error } from '@angular/compiler/src/util';
// import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { EnvService } from '../service/env.service';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
    isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private injector: Injector,
        // private ngxService: NgxUiLoaderService,
        private env: EnvService,
    ) { }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {

        if (token != null) {
            //set token header with all request
            return req.clone({ setHeaders: { Authorization: token } });
        }
        else {
            return req;
        }

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        const authService = this.injector.get(UserTokenService);
        /** spinner starts on init */


        // var change_rolestatus = this.env.baseUploadUrl + '/public/api/change_rolestatus';
        // var get_userroles = this.env.baseUploadUrl + '/public/api/get_userroles';
        // var user_exits = this.env.baseUploadUrl + '/public/api/checkIfUserExists';
        // var role_exits = this.env.baseUploadUrl + '/public/api/checkIfUserRoleExists';
        // var role_shortname_exits = this.env.baseUploadUrl + '/public/api/checkRoleshortnameExists';
        // var article_code_exists = this.env.baseUploadUrl + '/public/api/checkArticlecode';
        // var email_log_exists = this.env.baseUploadUrl + '/public/api/getemailLogs';
        // var templates_exists = this.env.baseUploadUrl + '/public/api/getmailTemplates';
        // var adhoc_email_exists = this.env.baseUploadUrl + '/public/api/getuserEmail';
        // var active_emailtemplates_exists = this.env.baseUploadUrl + '/public/api/getactivemailTemplates';
        // var rolenames_exists = this.env.baseUploadUrl + '/public/api/getRoleNames';
        // var tempstatus_exists = this.env.baseUploadUrl + '/public/api/changeTemplateStatus';
        // var article_change_status = this.env.baseUploadUrl + '/public/api/changeArticleStatus';
        // var user_status = this.env.baseUploadUrl+ '/public/api/deactivateUser';
        // var user_table = this.env.baseUploadUrl+ '/public/api/getUserDetailsMatTable';

        // if (req.url.search(user_table) == -1 
        //     && req.url.search(user_status) == -1
        //     && req.url.search(user_exits) == -1
        //     && req.url.search(change_rolestatus) == -1
        //     && req.url.search(get_userroles) == -1
        //     && req.url.search(role_exits) == -1
        //     && req.url.search(role_shortname_exits) == -1
        //     && req.url.search(article_code_exists) == -1
        //     && req.url.search(email_log_exists) == -1
        //     && req.url.search(article_change_status) == -1
        //     && req.url.search(templates_exists) == -1
        //     && req.url.search(tempstatus_exists) == -1
        //     && (req.url.search(adhoc_email_exists) == -1
        //         && req.url.search(rolenames_exists) == -1
        //         && req.url.search(active_emailtemplates_exists) == -1)) {       
        //    this.ngxService.start(); // start foreground spinner of the master loader with 'default' taskId           
        // }

        return next.handle(this.addToken(req, authService.getToken())).pipe(
            tap(
                event => {

                    if (event instanceof HttpResponse) {
                        // this.ngxService.stop();
                        return this.success200(req, next, event.body);
                    }
                },
                (err: any) => {
                    // this.ngxService.stop();
                    return err;
                }
            ),
        );


    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        this.tokenSubject.next(null);
        return this.tokenSubject.pipe(
            switchMap(token => {
                return next.handle(this.addToken(req, token));
            }));
    }


    //All Suceess request comes here
    success200(req: HttpRequest<any>, next: HttpHandler, response) {
        const authService = this.injector.get(UserTokenService);
        const routerService = this.injector.get(Router);

        if (response != null) {
            //401 status means User token missmatch call with new token
            if (response.status == "403") {
                return next.handle(this.addToken(req, authService.getToken()));
            }
            //401 status means User token time expired logout 
            else if (response.status == "401") {
                authService.removeToken();
                routerService.navigateByUrl('/login');
                return
            }
            // else if (response.status == "402") {

            //     console.log(response);
            //     return false;
            // }
            else if (response.status == "200") {
                if (response.token != undefined) {
                    authService.setToken(response.token);
                }
                return;
            }
            else {

                return;
            }
        }
        return
    }
}
