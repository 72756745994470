import { Component, Input, Directive, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from '../../login/login.service';
import { UserTokenService } from '../../service/user-token.service';
import { Router } from '@angular/router';
import { Subscription, timer, pipe } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { languageArray } from '../../app.global';
import * as moment from 'moment';
import { userMenus } from '../../_user_menudb';
import { InjectableGlobal } from '../../permissions'
import { UserrolesService } from '../../userroles/userroles.service'
import { EnvService } from '../../service/env.service';
import { NotifierService } from 'angular-notifier';
import { NgxPermissionsService } from 'ngx-permissions';
import { ɵl } from '@coreui/angular';
import { ControlPanelService } from '../../control-panel/control-panel.service';

// for full screen starts
interface FsDocument extends HTMLDocument {
    mozFullScreenElement?: boolean;
    msFullscreenElement?: boolean;
    fullscreenElement;
    webkitFullscreenElement;
    webkitExitFullscreen; initialVolume
    msExitFullscreen?: () => void;
    mozCancelFullScreen?: () => void;

    // webkitFullscreenElement?: Element;
    // fullscreenElement?: Element;
}
export function isFullScreen(): boolean {
    const fsDoc = <FsDocument>document;
    return !!(fsDoc.fullscreenElement || fsDoc.mozFullScreenElement || fsDoc.webkitFullscreenElement || fsDoc.msFullscreenElement);
}

interface FsDocumentElement extends HTMLElement {
    fullscreenElement?: Element;
    msRequestFullscreen?: () => void;
    mozRequestFullScreen?: () => void;
    webkitRequestFullscreen;
}
// for full screen ends

@Component({
    selector: 'app-dashboard',
    templateUrl: './default-layout.component.html',
    styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent {

    items = [];
    public breadcrumbs;
    public languageArray = languageArray;
    public navItems;
    public newnav;
    public all_proxy_users;
    public usermenu = userMenus;
    public sidebarMinimized = true;
    private changes: MutationObserver;
    public element: HTMLElement = document.body;
    public functionality = [];
    public filteredUserMenus = [];
    public title;
    public term;
    Proxy_Status: any;
    user_data: any;
    value: string;
    value_id: string;
    user_id: any;
    proxy_user_value: any;
    permissions = [];
    ngxpermissions = [];
    dbfeatures = [];
    db_features = [];
    features = [];
    menu_url = [];
    navigateToOnLoginSuccess = '/dashboards';
    BACKUP_TOKEN: any;
    BACKUP_ACCESS: any;
    BACKUP_PERMISSIONS: any;
    BACKUP_FEATURE: any;
    logined_user: any;
    current_year: any;
    base_url: string;
    profileImageUrl: string;
    interval: any;
    navBrandMaxi: any;
    navBrandMini: any;
    subroles = [];
    proxy_list: any;
    feature = [];
    isExpand;
    manage_feature = false;
    public proxy_login = false;
    guestUser = false;
    private readonly notifier: NotifierService;
    constructor(private env: EnvService,
        private permissionsService: NgxPermissionsService,
        private InjectableGlobal: InjectableGlobal,
        private api: LoginService,
        private user: UserTokenService,
        private router: Router,
        public translate: TranslateService,
        notifierService: NotifierService,
        private breadcurmbService: ɵl,
        private userroleService: UserrolesService,
        private cPanelService: ControlPanelService,
    ) {

        this.changes = new MutationObserver((mutations) => {
            this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
        });

        this.changes.observe(<Element>this.element, {
            attributes: true
        });

        this.notifier = notifierService;
    }

    public cPanalData: any = {};
    ngOnInit() {
        this.breadcrumbs = this.breadcurmbService.breadcrumbs;
        this.ngxpermissions = JSON.parse(atob(localStorage.getItem('PERMISSIONS')));
        this.dbfeatures = JSON.parse(atob(localStorage.getItem('FEATURE')));
        this.features = this.ngxpermissions.concat(this.dbfeatures);
        this.permissionsService.loadPermissions(this.features);

        this.Proxy_Status = this.readLocalStorageValue('Proxy_Status');
        if (this.Proxy_Status == null) {
            this.Proxy_Status = false;
        }
        this.proxy_list = false;
        this.proxy_list = false;
        this.proxy_list = false;

        this.base_url = this.env.baseUploadUrl;
        this.navBrandMaxi = {};
        this.navBrandMaxi.href = 'dashboards';
        this.navBrandMaxi.src = this.base_url + '/public/assets/loggo1.png';
        this.navBrandMaxi.width = '200';
        this.navBrandMaxi.height = 'auto';
        this.navBrandMaxi.alt = 'RVLaTeX Logo';
        // setting the [navbarBrandMinimized] value 
        this.navBrandMini = {};
        this.navBrandMini.src = this.base_url + '/public/assets/logo_mini.png';
        this.navBrandMini.width = '50';
        this.navBrandMini.height = '50';
        this.navBrandMini.alt = 'RVLaTeX Logo';
    }

    ngAfterViewInit() {
        this.loadintialdata();
        var year = (new Date()).getFullYear();
        this.current_year = year;

        this.cPanelService.getCPanelDataSvs().subscribe(res => {
            if (res['status'] == 200) {
                this.cPanalData = res['data'];
                this.navBrandMaxi.src = this.base_url + '/public/' + this.cPanalData.cp_basic_info.productLogo;
            }
        })
    }

    loadintialdata() {

        this.navItems = [];
        this.api
            .getProfile()
            .subscribe(
                data => {
                    if (data.status == '200') {
                        this.user_data = data['user_data'];
                        this.logined_user = data;
                        localStorage.setItem('current_logined_userId', data['user_data']['_id']);

                        if (data['user_data']['guest_user'] == true) {
                            this.guestUser = true;
                            localStorage.setItem('guestUser', 'true');
                        }

                        this.base_url = this.env.baseUploadUrl;
                        this.profileImageUrl = this.env.baseProfileImageUrl;
                        // var year = (new Date()).getFullYear();
                        // this.current_year = year;
                        localStorage.setItem('MENU_PERMISSIONS', btoa(JSON.stringify(this.logined_user['user_data']['usermenu'])));
                        this.logined_user['user_functionality'] = this.logined_user['user_data']['user_functionality'].map(a => { return "" + a + ""; });
                        this.logined_user['usermenu'] = this.logined_user['user_data']['usermenu'].map(a => { return "" + a + ""; });
                        // this.InjectableGlobal.accessibleMenus.length=0;
                        // this.InjectableGlobal.accessibleMenus=[];


                        // console.log(this.filteredUserMenus = this.usermenu.filter((userMenus) => {
                        // 	if (this.logined_user['usermenu'].indexOf(userMenus.id) != -1) {
                        // 		this.InjectableGlobal.accessibleMenus.push(userMenus.url);
                        // 		return true;
                        // 	}
                        // 	else {
                        // 		return false;
                        // 	}
                        // 	// return this.logined_user['usermenu'].indexOf(userMenus.id) != -1
                        // }));
                        //console.log(this.InjectableGlobal.accessibleMenus);

                        this.userroleService.getFeatures().subscribe(data => {
                            if (data != '') {
                                this.filteredUserMenus = data['data'].filter(userMenus => {
                                    if (this.logined_user['usermenu'].indexOf(String(userMenus.unique_id)) != -1) {
                                        this.InjectableGlobal.accessibleMenus.push(userMenus.url);
                                        return true;
                                    }
                                    else {
                                        return false;
                                    }

                                });

                                this.filteredUserMenus.forEach(item => {
                                    this.translate.stream("menu." + item.name).subscribe((text: string) => { item.name = text.replace('menu.', '') });
                                });
                                this.navItems = this.filteredUserMenus;
                            }

                        });

                        this.userroleService.getRoleNames().subscribe(data => {
                            data['data'].map(data => {
                                this.InjectableGlobal.rolenames[data._id] = data.rl_role_name;
                            })

                            this.db_features = JSON.parse(atob(localStorage.getItem('FEATURE')));
                            if (this.user_data.is_super_admin == true || this.Proxy_Status === 'true' || this.db_features.includes('Proxy')) {
                                this.proxy_login = true;
                                this.proxy_login_roles();
                            }
                            if (this.user_data.is_super_admin == true || localStorage.getItem('manage_feature') === 'true') {
                                this.manage_feature = true;
                            }
                        });

                    }
                    else {
                        this.user.removeToken();
                        this.router.navigateByUrl('/login');
                    }

                });
    }


    readLocalStorageValue(key: string): string {
        return localStorage.getItem(key);
    }

    getRoleNamesFromRoleIds(user_role_json, subroles) {
        var that = this;
        var roleNames = [];
        user_role_json.map(function (key, val, array) {
            if (subroles.includes(key)) {
                roleNames.push(that.InjectableGlobal.rolenames[key]);
            }
        });
        var roles = roleNames.join(`,`);
        return roles;
    }

    public proxy_login_roles() {
        this.api.get_proxy_login_roles().subscribe(data => {
            if (data.status == '200') {
                for (let child of data.data) {
                    this.items.push({
                        'name': {
                            first_name: child.usr_first_name,
                            middle_name: child.usr_middle_name,
                            last_name: child.usr_last_name,
                            roles: this.getRoleNamesFromRoleIds(child.usr_roles, data.subroles)
                        },
                        'value': child.usr_id
                    });
                    // this.items.push({'name' : child.usr_first_name+ ' ' + child.usr_last_name +' ('+ this.getRoleNamesFromRoleIds(child.usr_roles,data.subroles) +')', 'value':child.usr_id});
                }
                this.all_proxy_users = data.data;
            }
        });
    }


    public onProxySelect() {
        this.user_id = this.value_id;
        this.BACKUP_TOKEN = this.readLocalStorageValue('BACKUP_TOKEN');
        this.BACKUP_ACCESS = this.readLocalStorageValue('BACKUP_ACCESS');
        this.BACKUP_PERMISSIONS = this.readLocalStorageValue('BACKUP_PERMISSIONS');
        this.BACKUP_FEATURE = this.readLocalStorageValue('BACKUP_FEATURE');
        if (this.BACKUP_TOKEN === '' || this.BACKUP_TOKEN === null || this.BACKUP_TOKEN === 'null') {
            localStorage.setItem('BACKUP_TOKEN', localStorage.TOKEN);
            localStorage.setItem('BACKUP_ACCESS', localStorage.ACCESS);
            localStorage.setItem('BACKUP_PERMISSIONS', localStorage.PERMISSIONS);
            localStorage.setItem('BACKUP_FEATURE', localStorage.FEATURE);
        }

        this.api.proxy_login(this.user_id).subscribe(r => {
            if (r.status == '200') {

                this.functionality = r['result']['user_functionality'].map(a => { return "" + a + ""; });

                this.userroleService.getFeatures().subscribe(data => {
                    if (data != '') {
                        const filteredUserMenus = data['data'].filter(userMenus => {

                            if (r['result']['usermenu'].indexOf(userMenus.unique_id) != -1) {
                                this.menu_url.push(userMenus.url);
                                return true;
                            }

                        });


                        /**Check if the user has permission for accesing dashboard  0-dashboard */
                        if (!r['result']['usermenu'].includes(0)) {
                            if (r['result']['usermenu'].length > 0) {
                                this.navigateToOnLoginSuccess = filteredUserMenus[0].url;
                            }
                            else {
                                this.notifier.notify("warning", "No privilleges ,Kindly contact the administrator");
                                return false;
                            }
                        }

                        data['data'].filter(fun => typeof (fun.functionality) != "undefined").map(fun => fun.functionality.map(func => {
                            if (this.functionality.includes(func.id)) {
                                this.permissions.push(func.identifier);
                            }
                        }));

                        data['data'].map(data => {
                            if (data.type == 'F') {
                                if (r['result']['user_feature'].includes(String(data.unique_id))) {
                                    this.feature.push(data.name);
                                }
                            }
                        });

                        localStorage.setItem('ACCESS', btoa(JSON.stringify(this.menu_url)));
                        localStorage.setItem('PERMISSIONS', btoa(JSON.stringify(this.permissions)));
                        localStorage.setItem('FEATURE', btoa(JSON.stringify(this.feature)));
                        localStorage.setItem('Proxy_Status', 'true');
                        localStorage.setItem('manage_feature', 'true');
                        localStorage.setItem('TOKEN', r.result.token);
                        this.router.navigate(['/login']);
                    }
                });

            }
            else {
                this.notifier.notify('error', r.message);
            }
        });
    }

    exit_proxy() {
        this.BACKUP_TOKEN = this.readLocalStorageValue('BACKUP_TOKEN');
        this.BACKUP_ACCESS = this.readLocalStorageValue('BACKUP_ACCESS');
        this.BACKUP_PERMISSIONS = this.readLocalStorageValue('BACKUP_PERMISSIONS');
        this.BACKUP_FEATURE = this.readLocalStorageValue('BACKUP_FEATURE');
        localStorage.setItem('TOKEN', this.BACKUP_TOKEN);
        localStorage.setItem('ACCESS', this.BACKUP_ACCESS);
        localStorage.setItem('PERMISSIONS', this.BACKUP_PERMISSIONS);
        localStorage.setItem('FEATURE', this.BACKUP_FEATURE);
        localStorage.removeItem('Proxy_Status');
        localStorage.removeItem('manage_feature');
        localStorage.removeItem('BACKUP_TOKEN');
        localStorage.removeItem('BACKUP_ACCESS');
        localStorage.removeItem('BACKUP_PERMISSIONS');
        localStorage.removeItem('BACKUP_FEATURE');
        this.router.navigate(['/login']);
    }

    formatDateTime(date: Date): string {
        if (!date) {
            return '';
        }
        return moment(date).format('MMM YYYY');
    }


    logout() {
        this.api.logout().subscribe(
            r => {
                localStorage.removeItem("articles");
                localStorage.removeItem("activeFiles");
                this.user.removeToken();
                this.router.navigateByUrl('/login');
            },
            r => {
                //alert(r.error.error);
            });

    }

    public changeLanguage(lan) {
        this.translate.use(lan);
        this.user.setLanguage(lan);

        var newitem = [];
        this.newnav = [];
        this.newnav.length = 0;
        var i = 0;
        var itemsProcessed = 0;
        this.filteredUserMenus.forEach(item => {
            // console.log(item);
            this.translate.get("menu." + item.name).subscribe((text: string) => {
                // console.log(item.name);
                //  item.name = text.replace('menu.','');
                this.newnav.push(item);


            });
            i++;
            itemsProcessed++;
            //  console.log(itemsProcessed+" " +this.filteredUserMenus.length);
            if (itemsProcessed === this.filteredUserMenus.length) {
                this.navItems = [];
                this.navItems.length = 0;
                this.translate.reloadLang(lan);
                for (var i = 0; i < this.filteredUserMenus.length; i++) {
                    this.navItems[i] = this.filteredUserMenus[i];

                }
            }
        });
    }
    public getProfile() {
        this.api.getProfile().subscribe(
            r => {
                if (r.status) {
                    // this.user.setToken(r.token);
                }
                else {
                    this.user.removeToken();
                    this.router.navigateByUrl('/login');
                }
            },
            r => {
                alert(r.error.error);
            });

    }

    getUserProfile() {
        this.router.navigateByUrl('/userprofiles');
    }

    getUserActivity() {
        this.router.navigateByUrl('/users/user_activity');
    }

    change_proxy_list(inputvalue) {
        if (inputvalue) {
            this.proxy_list = true;
        }
        else {
            this.proxy_list = false;
        }
    }
    select_proxy_user(inputValue, inputText) {
        this.value = inputText;
        this.value_id = inputValue;
        this.proxy_list = false;
    }
    // full screen function
    toggleFullScreen(): void {
        const fsDoc = <FsDocument>document;
        this.isExpand = false;
        if (!isFullScreen()) {
            this.isExpand = true;
            const fsDocElem = <FsDocumentElement>document.documentElement;
            if (fsDocElem.requestFullscreen)
                fsDocElem.requestFullscreen();
            else if (fsDocElem.msRequestFullscreen)
                fsDocElem.msRequestFullscreen();
            else if (fsDocElem.mozRequestFullScreen)
                fsDocElem.mozRequestFullScreen();
            else if (fsDocElem.webkitRequestFullscreen)
                fsDocElem.webkitRequestFullscreen();
        }
        else if (fsDoc.exitFullscreen)
            fsDoc.exitFullscreen();
        else if (fsDoc.msExitFullscreen)
            fsDoc.msExitFullscreen();
        else if (fsDoc.mozCancelFullScreen)
            fsDoc.mozCancelFullScreen();
        else if (fsDoc.webkitExitFullscreen)
            fsDoc.webkitExitFullscreen();
    }

}
