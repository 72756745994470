import { Component } from '@angular/core';
import { EnvService } from '../service/env.service';

@Component({
    templateUrl: 'UnauthorisedAccess.component.html'
})
export class UnauthorisedAccessComponent {
    base_url: string;
    constructor(private env: EnvService) {
        this.base_url = this.env.baseUploadUrl;

    }

}
