/**
 * CHECK THIS COMMENT SECTION FOR THE NEXT MENU ID AND FUNCTIONALITY ID
 * NEXT MENU ID: 9
 * NECT FUNCTIONALITY ID: F37
 */

export const userMenus = [
    {
        id: '0',
        name: 'dashboard',
        url: '/dashboards',
        icon: 'icon-speedometer',
        functionality: [
            {
                identifier: 'dashboardtiles',
                id: 'F1',
                name: 'Dashboard tiles'
            },
            {
                identifier: 'viewuser',
                id: 'F8',
                name: 'View users in Dashboard'
            },
        ]
    },
    {
        id: '1',
        name: 'userroles',
        url: '/userroles',
        icon: 'icon-user',
        functionality: [
            {
                identifier: 'createuserrole',
                id: 'F2',
                name: 'Create user role'
            },
            {
                identifier: 'edituserrole',
                id: 'F3',
                name: 'Edit user role'
            },
            {
                identifier: 'duplicateuserrole',
                id: 'F4',
                name: 'Duplicate user role'
            }
        ]
    },
    {
        id: '2',
        name: 'users',
        url: '/users',
        icon: 'icon-people',
        functionality: [
            {
                identifier: 'createuser',
                id: 'F5',
                name: 'Create user'
            },
            {
                identifier: 'edituser',
                id: 'F6',
                name: 'Edit user'
            },            
            {
                identifier: 'deactivateuser',
                id: 'F7',
                name: 'Activate or Deactivate user'
            },
            {
                identifier: 'deleteuser',
                id: 'F37',
                name: 'Delete user'
            }
        ]
    },
    {
        id: '3',
        name: 'journals',
        url: '/journal',
        icon: 'icon-book-open',
        functionality: [
            {
                identifier: 'createjournal',
                id: 'F9',
                name: 'Create journal'
            },
            {
                identifier: 'uploadjournal',
                id: 'F34',
                name: 'Upload journal'
            },
            {
                identifier: 'editjournal',
                id: 'F10',
                name: 'Edit journal'
            },
            {
                identifier: 'deletejournal',
                id: 'F11',
                name: 'Delete journal'
            },
            {
                identifier: 'createvolume',
                id: 'F12',
                name: 'Create volume'
            },
            {
                identifier: 'viewvolume',
                id: 'F13',
                name: 'View volumes'
            },
            {
                identifier: 'addissue',
                id: 'F14',
                name: 'Add issues'
            },
            {
                identifier: 'deleteissue',
                id: 'F15',
                name: 'Delete issues'
            },
            {
                identifier: 'closevolume',
                id: 'F16',
                name: 'Close volume'
            },
            {
                identifier: 'reopenvolume',
                id: 'F17',
                name: 'Reopen volume'
            },
            {
                identifier: 'deletevolume',
                id: 'F18',
                name: 'Delete volume'
            },
            {
                identifier: 'deleteissue',
                id: 'F32',
                name: 'Delete Issue'
            },
            {
                identifier: 'closeissue',
                id: 'F33',
                name: 'Close Issue'
            },            {
                identifier: 'journalsettings',
                id: 'F35',
                name: 'Journal settings'
            }
        ]
    },
    {
        id: '4',
        name: 'articles',
        url: '/articles',
        icon: 'icon-docs',
        functionality: [
            {
                identifier: 'createarticle',
                id: 'F19',
                name: 'Create article'
            },
            {
                identifier: 'viewarticleerrorlog',
                id: 'F20',
                name: 'View article error log'
            },
            {
                identifier: 'deletearticle',
                id: 'F21',
                name: 'Delete article'
            },
        ]
    },
    {
        id: '5',
        name: 'email_templates',
        url: '/email-templates',
        icon: 'icon-envelope',
        functionality: [
            {
                identifier: 'createemailtemplate',
                id: 'F22',
                name: 'Create email template'
            },
            {
                identifier: 'editemailtemplate',
                id: 'F23',
                name: 'Edit email template'
            },
            {
                identifier: 'deleteemailtemplate',
                id: 'F24',
                name: 'Delete email template'
            }
        ]
    }, {
        id: '6',
        name: 'Journal Category',
        url: '/journal-category',
        icon: 'fa fa-list-alt',
        functionality: [
            {
                identifier: 'createjournalcategory',
                id: 'F25',
                name: 'Create journal category'
            },
            {
                identifier: 'editjournalcategory',
                id: 'F26',
                name: 'Edit journal category'
            }

        ]
    },
    {
        id: '7',
        name: 'FAQ',
        url: '/faq',
        icon: 'icon-question',
        functionality: [
            {
                identifier: 'createfaq',
                id: 'F27',
                name: 'Create FAQ'
            },
            {
                identifier: 'editfaq',
                id: 'F28',
                name: 'Edit FAQ'
            },
            {
                identifier: 'deletefaq',
                id: 'F29',
                name: 'Delete FAQ'
            },
            {
                identifier: 'viewfaq',
                id: 'F30',
                name: 'View FAQ'
            },
            {
                identifier: 'disablefaq',
                id: 'F31',
                name: 'Disable FAQ'
            }
        ]
    },
    {
        id: '8',
        name: 'FTP Details',
        url: '/ftp',
        icon: 'icon-globe'
    }, 
    {
        id: '9',
        name: 'article_upload',
        url: '/article-upload',
        icon: 'icon-cloud-upload'
    },
    {
        id: '10', 
        name: 'Settings',
        url: '/settings',
        icon: 'icon-settings'
    }
]


