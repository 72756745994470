import { Component, OnInit } from '@angular/core';
import { LoginService} from '../../login/login.service';
import { UserTokenService } from '../../service/user-token.service';
import { Router} from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.scss']
})
export class UserActivityComponent implements OnInit {

  constructor(private api: LoginService,private user: UserTokenService,private router: Router) { }

  public user_activity_data;

  
  ngOnInit() {
      this.getUserActivity();
  }

  getUserActivity(){
    this.api.getUserActivity().subscribe(
      r => {
        if (r.status) {
         // this.user.setToken(r.token);
          if(r.user_data){
            this.user_activity_data = r.user_data;
          }
        }
        else{
          this.user.removeToken();
          this.router.navigateByUrl('/login');
        }
      },
      r => {
        //alert(r.error.error);
      });
  }

  //logout from all devices
  logoutAll(){
    this.api.logout_all().subscribe(
      res => { 
     
          //this.user.removeToken();
          this.getUserActivity();
          //this.router.navigateByUrl('/dashboards');
      },
      r => {
        //alert(r.error.error);
      });
  }

  logoutEach(token_current:string) {

    //If user token is current user logout from the current admin panel
    if(token_current == this.user.getToken()){
      this.api.logout().subscribe(
        res => {
            this.user.removeToken();
            this.router.navigateByUrl('/login');
        },
        r => {
          //alert(r.error.error);
        });
    }
    //logout from other devices
    else{
      this.api.logout_selected(token_current).subscribe(
        r => {
          if (r.status) {
            this.getUserActivity();
          }
          else{
            this.user.removeToken();
            this.router.navigateByUrl('/login');
          }
        },
        r => {
          //alert(r.error.error);
        });
    }
    
    
  }

  formatDateTime(date): string {
		if (!date) {
			return '';
    }
		return  moment(date).format('DD-MMM-YYYY HH:mm:ss');
	}

}
