import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule }   from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { UsersRoutingModule } from './users-routing.module';
import { CreateuserComponent } from './createuser/createuser.component';
import { UserListComponent } from './user-list/user-list.component';
import { DataTablesModule } from 'angular-datatables';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedModule } from '../shared/shared.module';
import { UserActivityComponent } from './user-activity/user-activity.component';
import { AvatarModule } from "ngx-avatar";
import { MatButtonModule , MatFormFieldModule, MatInputModule, MatIconModule,MatSelectModule } from '@angular/material';	
import { MatTabsModule , MatTableModule , MatPaginatorModule,MatSortModule} from '@angular/material';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule} from '@angular/material/checkbox'; 
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
//import { MatTooltipModule } from '@angular/material/tooltip';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  imports: [
    CommonModule,
    UsersRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ModalModule.forRoot(),
    DataTablesModule,
    NgMultiSelectDropDownModule.forRoot(),
    TabsModule.forRoot(),
    NgxPermissionsModule.forChild(), 
    SharedModule,
    AvatarModule,
    MatButtonModule ,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatTabsModule, 
    MatTableModule, 
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    NgbModule.forRoot(),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger', // set defaults here
      cancelButtonType: 'outline-secondary'
    })
  ],
  declarations: [ CreateuserComponent,UserListComponent, UserProfileComponent,UserActivityComponent],
  exports: [ UserListComponent,CreateuserComponent,MatTableModule,MatSortModule,MatPaginatorModule,MatFormFieldModule,MatCheckboxModule]

})
export class UsersModule { }
