import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/delay';
import { EnvService } from '../service/env.service';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class articleUploadsService {
    public refresh_list: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    constructor(private http: HttpClient, private env: EnvService) { }

    uploadArticle(articleData) {
        return this.http.post(this.env.baseAPIUrl + "/uploadArticle", articleData);
    }
    articleList() {
        return this.http.get(this.env.baseAPIUrl + "/articleList");
    }
    removeArticle(elementid) {
        return this.http.post(this.env.baseAPIUrl + "/removeArticle", { id: elementid });
    }
    changeArticleStatus(elementid, status) {
        return this.http.post(this.env.baseAPIUrl + "/changeArticleStatus", { id: elementid, status: status });
    }
    getArticlebyId(elementid) {
        return this.http.post(this.env.baseAPIUrl + "/articleById", { id: elementid });
    }
    updateArticle(updateData) {
        return this.http.post(this.env.baseAPIUrl + "/updatearticle", updateData);
    }
    articleCodecheck(value, id = '') {
        return this.http.post(this.env.baseAPIUrl + "/checkArticlecode", { article_code: value, article_id: id });
    }
    saveArticle(articleData) {     
        return this.http.post(this.env.baseAPIUrl + "/article_save", articleData);
    }

    primaryUpload(uploadfile) {
        return this.http.post(this.env.baseAPIUrl + "/article_primary_upload", uploadfile);
    }
    getFolderlist(element_id) {
        return this.http.get(this.env.baseAPIUrl + "/folderList/" + element_id);
    }
    createFolder(file, current_root, base_folder, current_path) {
        return this.http.post(this.env.baseAPIUrl + "/createFolder", { file: file, current_root: current_root, base_folder: base_folder, current_path: current_path });
    }
    uploadFile(param_id, file_data, currentPath, jnl_ententions) {
        let input = new FormData();
        // Add your values in here
        input.append('param_id', param_id);
        input.append('file_data', file_data);
        input.append('currentPath', currentPath);
        input.append('jnl_ententions', jnl_ententions);
        return this.http.post(this.env.baseAPIUrl + "/uploadFile", input);
    }
    createFile(param_id, element_name, current_path) {
        return this.http.post(this.env.baseAPIUrl + "/createFile", { param_id: param_id, element_name: element_name, current_path: current_path });
    }
    removeElement(param_id, element_name, isFolder, current_path) {
        return this.http.post(this.env.baseAPIUrl + "/removeElement", { param_id: param_id, element_name: element_name, isFolder: isFolder, current_path: current_path });
    }
    moveElement(param_id, element_name, move_element, current_path) {
        return this.http.post(this.env.baseAPIUrl + "/moveElement", { param_id: param_id, element_name: element_name, move_element: move_element, current_path: current_path });
    }
    renameElement(param_id, name, renameTo, is_folder, current_path) {
        return this.http.post(this.env.baseAPIUrl + "/renameElement", { param_id: param_id, name: name, renameTo: renameTo, is_folder: is_folder, current_path: current_path });
    }
    getAllJournal(){
        return this.http.get(this.env.baseAPIUrl + "/getJournal");
    }
    getJrnlWorkflow($data){
        return this.http.get(this.env.baseAPIUrl + '/getWorkflow/' + $data);
        
    }
    
}