import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EditorService } from '../editor/editor.service';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from "@ngx-translate/core";
import { JournalService } from "../journal/journal.service";
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { EnvService } from '../service/env.service';
import { ToolbarComponent } from '@syncfusion/ej2-angular-navigations';
import { 
  Validators,
  FormGroup, 
  FormControl, 
  ValidationErrors, 
  ValidatorFn, 
  AbstractControl 
} from "@angular/forms";
import {
  GridComponent, 
  SelectionSettingsModel, 
  EditSettingsModel, 
  ToolbarItems,
  SaveEventArgs, 
  PageSettingsModel
} from '@syncfusion/ej2-angular-grids';
import { inputs } from '@syncfusion/ej2-angular-inputs/src/slider/slider.component';

@Component({
  selector: 'app-rvclean',
  templateUrl: './rvclean.component.html',
  styleUrls: ['./rvclean.component.scss']
})
export class RvcleanComponent implements OnInit {

	constructor(
		private editorService: EditorService,
		private notifier: NotifierService,
		public translate: TranslateService,
        private journalService: JournalService,
        private env: EnvService
		) { }

		@Input() currentJournalCode;
		@ViewChild('grid') public gridob: GridComponent;
		public gridSelectionOptions: SelectionSettingsModel;
		public gridData: object[];
		public gridPageSettings: PageSettingsModel;
		public gridEditSettings: EditSettingsModel;
		public gridToolbarOptions: ToolbarItems[] | object;
		public rvCleanSettingsForm: FormGroup;
		public currentGridAction;
		public currentData;
		public currentRowData: object;
		public patternCategory = [];
		searchGridText = new FormControl('');
		tableSearchGridText = new FormControl('');
		multiSelectionToolbarItem = ['delete', 'autoreplace', 'patternstatus', 'sharejournal', 'export'];
		editOptionSelection = ['edit'];
		journals = [];
		ifValueFound: boolean = true;
		patternDetails;
		
	ngOnInit() {
		this.gridToolbarOptions = [
            'Add', 'Edit', 'Delete', 'Update', 'Cancel', 'Export', 'Search',
            // 'Search', 'Add', 'Delete', 'Update', 'Cancel', 'Edit', 
            { id: 'sharejournal' }, { id: 'autoreplace' }, { id: 'statuspattern' }
		];
        this.gridSelectionOptions = { checkboxOnly: false, checkboxMode: 'ResetOnRowClick' };
        this.gridPageSettings = { pageSize: 12, pageSizes: true };
        this.gridEditSettings = { allowEditing: true, allowAdding: true, allowDeleting: true };
        this.journalService.getJournalsForCleanSettingsSvs({ journal_code: this.currentJournalCode }).subscribe(
            response => {
                if (response['status'] === 'success') {
                    this.journals = response['data'];
                    this.gridData = this.addSerialNumber(response.current_journal_settings_pattenrns);
                    this.patternCategory = response.pattern_category;
                }
            });
        this.getJournalShare(this.currentJournalCode);
	}


	created(event) {
		console.log('grid create');
		this.trigger();
	}

	trigger() {
		window.dispatchEvent(new Event('resize'));
	}

	load() {
		this.trigger();
		console.log(this.gridob);
		console.log('load');
		const rowHeight: number = this.gridob.getRowHeight();  // height of the each row
		const gridHeight: any = this.gridob.height;  // grid height
		const pageSize: number = this.gridob.pageSettings.pageSize;   // initial page size
		const pageResize: any = (gridHeight - (pageSize * rowHeight)) / rowHeight; // new page size is obtained here
		this.gridob.pageSettings.pageSize = pageSize + Math.round(pageResize);
	}

	// calling this function while begining any action in syncfusion grid
	actionBegin(args: SaveEventArgs): void {
		// console.log(args.rowData);
		this.currentGridAction = args.requestType;
		// If edit or add action begins
		if (args.requestType === 'beginEdit' || args.requestType === 'add') {
			// If adding new record then set status and autoreplace false.          
			if (args.requestType === 'add') {
				args.rowData['status'] = false;
				args.rowData['clnAutoReplace'] = false;
				args.rowData['clnOrder'] = '';
				args.rowData['clnReplace'] = '';
				args.rowData['clnSearch'] = '';
				args.rowData['clnName'] = '';
				args.rowData['clnCategory'] = '';

				let hideItems = ['Status', 'Auto Replace'];
				this.gridob.hideColumns(hideItems);

				// If no pattern exist then set serial number 1. otherwise calculate serial number by increment 1 to highest serial number
				// if (this.gridData.length < 1) {
				// 	args.rowData['sl'] = 1;
				// } else {
				// 	args.rowData['sl'] = Math.max.apply(Math, this.gridData.map(function (o: any) { return o.sl; })) + 1;
				// }
			} else {
				let hideItems = ['Status', 'Auto Replace'];
				this.gridob.hideColumns(hideItems);
			}
			this.currentData = args.rowData;
			// create formgroup with given patternd data
			this.rvCleanSettingsForm = this.createFormGroup(args.rowData);
		} else if (args.requestType === 'save') {
			console.log("save1")
			if (this.rvCleanSettingsForm.valid) {
				if (this.gridData == null) {
					this.gridData = [this.rvCleanSettingsForm.value];
				}
			} else {
				args.cancel = true;
			}
		}
	}

	// calling this function while completing any action in syncfusion grid
	// actionComplete(args: DialogEditEventArgs): void {
	actionComplete(args: any): void {
		this.currentGridAction = args.requestType;
		if (args.requestType === 'beginEdit' || args.requestType === 'add') {
			this.gridob.toolbarModule.enableItems(['update', 'cancel'], true); // Enable toolbar items.
			// Set initail Focus
			(args.form.elements.namedItem('clnCategory') as HTMLInputElement).focus();
		} else if (args.requestType === 'save') {
			console.log("save2")
			// console.log(args.rowData);
			this.patternDetails = args.data;
			this.saveRvCleanPatterns('add');
			let test = ['Status', 'Auto Replace']
			this.gridob.showColumns(test);
		} else if (args.requestType === 'delete') {
			this.removeRvCleanPatterns();
		} else if (args.requestType == 'cancel') {
			let test = ['Status', 'Auto Replace']
			this.gridob.showColumns(test);
			this.gridob.toolbarModule.enableItems(['update', 'cancel'], false);
			if (this.gridob.getSelectedRecords().length > 0) {
				this.gridob.toolbarModule.enableItems(this.multiSelectionToolbarItem, true);
			} else {
				this.gridob.toolbarModule.enableItems(this.multiSelectionToolbarItem, false);
			}
		} else {
			this.gridob.toolbarModule.enableItems(['update', 'cancel', 'edit', 'delete', 'autoreplace', 'patternstatus', 'sharejournal', 'export'], false);
		}
	}

	// creating reactive formgroup for adding & editing grid data
	createFormGroup(data1): FormGroup {
		return new FormGroup({
			clnCategory: new FormControl(data1.clnCategory, [Validators.required, this.noWhitespaceValidator]),
			clnName: new FormControl(data1.clnName, [Validators.required, this.noWhitespaceValidator]),
			clnSearch: new FormControl(data1.clnSearch, [Validators.required, this.checkDuplicateSearch(this.gridData, this.currentGridAction, this.currentData), this.noWhitespaceValidator]),
			// clnReplace: new FormControl(data1.clnReplace, [Validators.required, this.noWhitespaceValidator]),
			clnReplace: new FormControl(data1.clnReplace),
			clnOrder: new FormControl(data1.clnOrder, [Validators.required, Validators.pattern("^[0-9]*$")]),
			status: new FormControl(data1.status),
			clnAutoReplace: new FormControl(data1.clnAutoReplace)
		});
	}

	public noWhitespaceValidator(control: FormControl) {
		const isWhitespace = (control.value || '').trim().length === 0;
		const isValid = !isWhitespace;
		return isValid ? null : { 'whitespace': true };
	}

	// Custom validation for search pattern. Check whether given search pattern already exist or not.
	// Return null if valid. Otherwise return true and error message.
	public checkDuplicateSearch(gridData, gridAction, formData): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			var duplicate = [];
			if (gridData.length > 0) {
				duplicate = gridData.filter((pattern) => pattern.clnSearch === control.value);
				if (duplicate.length > 0) {
					if (gridAction != 'beginEdit')
						return { 'duplicate': true, 'requiredValue': this.translate.instant("rvclean.duplicate-search-pattern") };
					else {
						if (duplicate.length > 1)
							return { 'duplicate': true, 'requiredValue': this.translate.instant("rvclean.duplicate-search-pattern") };
						else if (duplicate[0].sl == formData.sl) {
							return null;
						} else {
							return { 'duplicate': true, 'requiredValue': this.translate.instant("rvclean.duplicate-search-pattern") };
						}
					}
				} else
					return null;
			}
			else {
				return null;
			}

		}
	};

	public focusIn(target: HTMLElement): void {
		target.parentElement.classList.add('e-input-focus');
	}
	public focusOut(target: HTMLElement): void {
		target.parentElement.classList.remove('e-input-focus');
	}

	// Return all form control of RVClean.
	get f() { return this.rvCleanSettingsForm.controls; }

	// calling when changing the autoreplace or status
	triggerPatternSettings(event, data, field) {
		var index = this.gridData.findIndex((element: any) => element.sl == data.sl);
		if (index != -1) {
			data[field] = event.checked;
			var selecteRow: any = this.gridData[index];
			selecteRow[field] = event.checked;
			this.saveRvCleanPatterns('status');
		} else {
			this.notifier.notify('error', this.translate.instant("rvclean.settings-change-error"));
		}

	}

	// add serial number field in griddata
	addSerialNumber(gridData) {
		if (gridData) {
			gridData.forEach((element, index) => {
				element['sl'] = index + 1;
			});
		} else {
			gridData = [];
		}
		return gridData;
	}

	// Sharing article pattern
	ShareJournal(jnl, gridRow) {
		if (!jnl.jnl_clean_patterns) {
			jnl.jnl_clean_patterns = [];
		}
		// if (jnl.jnl_clean_patterns.filter((pattern) => pattern.clnSearch == gridRow.clnSearch).length == 0) {
			var pattern = {
				clnCategory: gridRow.clnCategory,
				clnAutoReplace: gridRow.clnAutoReplace,
				clnName: gridRow.clnName,
				clnOrder: gridRow.clnOrder,
				clnReplace: gridRow.clnReplace,
				clnSearch: gridRow.clnSearch,
				status: gridRow.status,
				id: gridRow.id
			}
			const formData = new FormData();
			formData.append('journal_code', jnl.jnl_code);
			formData.append('jnls', JSON.stringify(Array(pattern)));
			this.editorService.jnlShareSave(formData).subscribe(
				response => {
					if (response['message_type'] == 'success') {
						this.notifier.notify('success', this.translate.instant("rvclean.pattern-share-success"));
						this.getJournalShare(this.currentJournalCode);
					} else {
						// this.notifier.notify('error', this.translate.instant("rvclean.pattern-share-error"));
						this.notifier.notify('error', response['message']);

					}
				});
		// } else {
		// 	this.notifier.notify('error', this.translate.instant("rvclean.duplicate-search-pattern"));
		// }  
	}

	// Click handler for toolbar item.
	clickHandler(args: ClickEventArgs): void {
		var arg = this.gridob.getSelectedRecords();
		switch (args.item.id) {
			case 'cancel': { this.gridob.editModule.closeEdit(); break; }
			case 'update': { this.gridob.editModule.batchSave(); break; }
			case 'edit': { this.gridob.startEdit(); break; }
			case 'delete': { this.gridob.deleteRecord(); break; }
			case 'add': { this.gridob.addRecord(); break; }
			case 'export': { this.exportpattern(arg); break; }
		}
	}

	saveRvCleanPatterns(action) {
		console.log(action)
		// console.log('sss');
		var saveData = {
			clnJournal: this.currentJournalCode,
			clnPatterns: this.gridData,
			pattern: this.patternDetails,
			action: action
		}
		this.editorService.saveRvCleanPatterns(saveData).subscribe(
			response => {
				if (response.status === "success") {
					if(action == 'add'){
						var index = this.gridData.findIndex((item: any) => item.clnSearch == response.pattern.clnSearch);
						this.gridData[index] = response.pattern;
					}
					this.notifier.notify('success', this.translate.instant('rvclean.save-success'));
					this.patternCategory = response.pattern_category;
					this.gridob.refresh();
				} else {
					this.notifier.notify('error', this.translate.instant("rvclean.error-message"));
				}
			},
			error => {
				console.log("Error");
			}
		);
	}

	removeRvCleanPatterns() {

        var saveData = {
            clnJournal: this.currentJournalCode,
			clnPatterns: this.gridData,
			action: 'remove'
        }
        this.editorService.saveRvCleanPatterns(saveData).subscribe(
            response => {
                if (response.status === "success") {
                    this.notifier.notify('success', 'Removed successfully');
                    this.patternCategory = response.pattern_category;
                    this.gridob.refresh();
                } else {
                    this.notifier.notify('error', this.translate.instant("rvclean.error-message"));
                }
            },
            error => {
                console.log("Error");
            }
        );
	}
	filedownloadUrl;
    downloadFileUrl = this.env.baseAPIUrl + '/downloadPattern? url=  ';
	
	//exporting pattern
    exportpattern(args) {
        var arrayToString = JSON.stringify(Object.assign({}, args));  // convert array to string
        var journalcode = this.currentJournalCode;
        this.editorService.downloadFile(arrayToString, journalcode).subscribe(
            response => {
                if (response['status'] == 200) {
                    this.filedownloadUrl = response['value']['destination'] + response['value']['file'];
                    var link = document.createElement("a");
                    link.setAttribute("href", this.downloadFileUrl + this.filedownloadUrl);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            },
            error => { }
        );
	}
	
	journalShare;
	// Share 
    getJournalShare(jnlCode) {
        this.journalService.getJournalShare(jnlCode).subscribe(
            response => {
                this.journalShare = response;
            });
	}
	
	// Calling the function while changing the selection of grid record.
    // If more than one record selected then enable some toolbar items('delete', 'edit','autoreplace','patternstatus','sharejournal')
    rowSelectionChange($event) {
        if (this.gridob.getSelectedRecords().length > 0) {
            this.gridob.toolbarModule.enableItems(this.multiSelectionToolbarItem, true);
        } else {
            this.gridob.toolbarModule.enableItems(this.multiSelectionToolbarItem, false);
        }
        if (this.gridob.getSelectedRecords().length !== 1) {
            this.gridob.toolbarModule.enableItems(this.editOptionSelection, false);
        } else {
            this.gridob.toolbarModule.enableItems(this.editOptionSelection, true);
        }
	}
	
	// Changing the status(active, deactive) and changing the status of auto replace of multiple file
    toggleMultiplePatternSettins(field, value) {
        var selectedRows = this.gridob.getSelectedRecords();
        if (selectedRows.length > 0) {
            selectedRows.forEach((element: any) => {
                var index = this.gridData.findIndex((item: any) => item.sl == element.sl);
                this.gridData[index][field] = value;
            });
            // save the content into db
            this.saveRvCleanPatterns('status');
        } else {
            this.notifier.notify('error', this.translate.instant("rvclean.empty-record-selection"));
        }
	}
	
	//click settings icon in journal pattern
    checkShareButton(event) {
        if ($(event.target.parentNode.parentNode).find("input.pattern-name-class").attr('data-value') != "") {
            this.ifValueFound = true;
        } else {
            this.ifValueFound = false;
        }
	}
	
	// Delete pattern
    removePattern(gridRow) {
        var index = this.gridData.findIndex((item: any) => item.sl == gridRow.sl);
        (this.gridob.dataSource as object[]).splice(index, 1);
        // Save the content into db
        this.removeRvCleanPatterns();
	}
	
	// Search functionality in RVClean
    searchCleaningGrid() {
        const searchText = this.searchGridText.value;
        this.gridob.search(searchText);
	}

	// Sharing morethan one pattern to journal.
    ShareMultipleJournal(jnl) {
        if (this.gridob.getSelectedRecords().length > 0) {
            const formData = new FormData();
            formData.append('journal_code', jnl.jnl_code);
            console.log(jnl);
            // Check for duplicate pattern
			var duplicationResult = this.checkForDuplicateInMultipleShare(this.gridob.getSelectedRecords(), jnl.jnl_clean_patterns);
			console.log(duplicationResult);
            // If unique pattern exist then share only the unique patterns and reject the duplicate patterns.
            if (duplicationResult.valid.length >= 0) {
                if (duplicationResult.duplicate.length > 0) {
                    formData.append('jnls', JSON.stringify(duplicationResult.valid));
                    this.notifier.notify('error', this.translate.instant("rvclean.duplicate-share-pattern"));
                } else {
                    formData.append('jnls', JSON.stringify(this.gridob.getSelectedRecords()));
                }
                this.editorService.jnlShareSave(formData).subscribe(
                    response => {
                        if (response['message_type'] == 'success') {
                            this.notifier.notify('success', this.translate.instant("rvclean.pattern-share-success"));
                            this.getJournalShare(this.currentJournalCode);
                        } else {
                            this.notifier.notify('error', this.translate.instant("rvclean.pattern-share-error"));
                        }
                    });
            } else {
                this.notifier.notify('error', this.translate.instant('rvclean.duplicate-search-pattern'));
            }
        } else {
            this.notifier.notify('error', this.translate.instant("rvclean.empty-record-selection"));
        }
	}

	// Check for duplicate patterns exist or not while sharing morethan one pattern.
    // listing and returning duplicate and unique pattern in separate array. 
    checkForDuplicateInMultipleShare(source, destination) {
		var duplicate = [];
		var updation = [];
        var validPattern = [];
        if (!destination) {
            destination = [];
        }
        source.forEach(element => {
			if (destination.some(s => s.id == element.id)) {
				updation.push(element);
			}else if (destination.some(s => s.clnSearch == element.clnSearch)) {
                duplicate.push(element);
            } else {
                validPattern.push(element);
            }
        });
        return { duplicate: duplicate, valid: validPattern, updation: updation };
	}
	
	journalChange(journalId) {
        this.currentJournalCode = journalId;
        this.editorService.getAllRVCleanPatterns({ journal: journalId }).subscribe(
            response => {
                if (response.status == 'success') {
                    this.gridData = this.addSerialNumber(response.jnl_clean_patterns);
                } else {
                    this.gridData = [];
                }
            },
            error => { }
        );
        this.getJournalShare(journalId);

	}
	/**
	 * Share the changes of the pattern to all the shared journals
	 * @param $event 
	 * @param data 
	 * @author Arathy S V
	 */
	shareChanges($event, gridRow){
		var sharedData = {
			clnCategory: gridRow.clnCategory,
			clnAutoReplace: gridRow.clnAutoReplace,
			clnName: gridRow.clnName,
			clnOrder: gridRow.clnOrder,
			clnReplace: gridRow.clnReplace,
			clnSearch: gridRow.clnSearch,
			status: gridRow.status,
			id: gridRow.id
		};
		this.editorService.sharePatternChanges({pattern: sharedData}).subscribe(
			response => {
				if(response.status == 'success'){
					this.notifier.notify('success', 'Successfully updated the changes to all shared journals');
				}
			},
			error => {}
		);
	}

}
