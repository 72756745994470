import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../service/env.service';

@Injectable({
    providedIn: 'root'
})
export class WorkflowService {

    constructor(private http: HttpClient, private env: EnvService) { }

    saveWorkflowBasicDetailsApi($data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/save-workflow-basic-details', $data);
    }

    getWorkflowStageData(journalId: any, workflowStageId: number = null) {
        return this.http.get<any>(this.env.baseAPIUrl + "/get-workflow-stage-data/" + journalId + "/" + workflowStageId);
    }

    loadWorkflowSvs(journalId, workflowCode) {
        return this.http.get<any>(this.env.baseAPIUrl + "/load-workflow/" + journalId + "/" + workflowCode);
    }

    // getAllStagesSvs(id: number){
    //   return this.http.get<any>(this.env.baseAPIUrl + "/get-all-stages/" + id);
    // }

    checkWorkflowCodeSvs($data) {
        return this.http.post<any>(this.env.baseAPIUrl + "/check-workflow-code", $data);
    }

    getParentChildStagesSvs(data: any) {
        return this.http.post<any>(this.env.baseAPIUrl + "/get-parent-child-stages", data);
    }

    saveWorkflowStageSvs($data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/save-workflow-stage', $data);
    }

    removeWorkflowStageSvs(journalId: string, workflowCode: string, stageId: string) {
        return this.http.delete<any>(this.env.baseAPIUrl + '/remove-workflow-stage' + '/' + journalId + '/' + workflowCode + '/' + stageId);
    }
    // get All Workflow
    getWorkflowData() {
        return this.http.get<any>(this.env.baseAPIUrl + '/get-workflow');
    }

    // get workflow by id
    getWorkflowByIdSvs(journalId, wfCode) {
        return this.http.get<any>(this.env.baseAPIUrl + '/get-workflow/' + journalId + '/' + wfCode);
    }

    // edit workflow
    editWorkflowDataSvs(editForm, journalId, wfCode) {
        return this.http.post<any>(this.env.baseAPIUrl + '/edit-workflow/' + journalId + '/' + wfCode, editForm);
    }

    // delete workflow
    deleteWorkflowSvs(journalId, wfCode) {
        return this.http.delete<any>(this.env.baseAPIUrl + '/delete-workflow/' + journalId + '/' + wfCode);
    }

    saveWorkflowLayoutSvs($data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/save-workflow-layout', $data);
    }
}
