import * as io from 'socket.io-client';
import { Observable } from 'rxjs/Observable';
import { EnvService } from '../service/env.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })

export class CollabService {
    private url = 'http://localhost:3000';
    private socket;    

    constructor(private env: EnvService,) {
        this.socket = io.connect(this.env.realtimeServer, {secure: true});
    }
    /*
     * Notify collaborators; iam joined
     * @Author Yadu Chandran
    */
    iamConnected(userData){
        this.socket.emit('notifyAllJoined', userData);
    }
    /*
     * get recent connected user
     * @Author Yadu Chandran
    */
    getUsers = () => {
        return Observable.create((observer) => {
            this.socket.on('notifyAllClients', (message) => {
                observer.next(message);
            });
        });
    }
    /*
     * send cursor data:updated
     * @Author Yadu Chandran
    */
    sendCursorData(cursorData){
        this.socket.emit('sendCursorData', cursorData);
    }
    /*
     * send cursor data:updated
     * @Author Yadu Chandran
    */
    sendSelectionData(selectionData){
        this.socket.emit('sendSelectionData', selectionData);
    }

    // send scroll view data
    sendScrollData(scrollData) {
        this.socket.emit('sendScrollData', scrollData);
    }

    // send closed radar data
    sendClosedRadarData(closedData){
        this.socket.emit('sendClosedRadarData', closedData); 
    }
    /*
     * get cursor data:updated
     * @Author Yadu Chandran
    */
    getUpdatedCursor(){
        return Observable.create((observer) => {
            this.socket.on('updateCursorData', (data) => {
                observer.next(data);
            });
        });
    }
    /*
     * get selection data:updated
     * @Author Yadu Chandran
    */
    getUpdatedSelection(){
        return Observable.create((observer) => {
            this.socket.on('updateSelectionData', (data) => {
                observer.next(data);
            });
        });
    }

    // get updated scroll data 
    getUpdatedScroll() {
        return Observable.create((observer) => {
            this.socket.on('updateScrollData', (data) => {
                observer.next(data);
            });
        });
    }
    getUpdatedClosedRadar() {
        return Observable.create((observer) => {
            this.socket.on('updateClosedRadarData', (data) => {
                observer.next(data);
            });
        });
    }
    /*
     * send my data to socket io
     * @Author Yadu Chandran
    */
    sendMyTextData(data){
        this.socket.emit('sendTextData', data);
    }
    /*
     * get text data:updated
     * @Author Yadu Chandran
    */
    getUpdatedText(){
        return Observable.create((observer) => {
            this.socket.on('sendTextDataToAll', (data) => {
                observer.next(data);
            });
        });
    }
}