import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class EnvService {
    // API URL
    public baseAPIUrl = '';

    // Uplaod URL
    public baseUploadUrl = '';

    // Profile image path
    public baseProfileImageUrl = '';

    public assetsUrl = '';
    public snippetsAPIUrl = '';
    public snippetsUrl = '';
    public journalId = '';
    public owneredBy = '';
    public realtimeServer = '';
    constructor() {
    }

}