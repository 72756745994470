import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageArticleuploadComponent } from './manage-articleupload/manage-articleupload.component';
import { ArticleUploadRoutingModule } from './articleupload-routing.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DataTablesModule } from 'angular-datatables';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MatButtonModule, MatFormFieldModule, MatInputModule, MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule, MatDialogModule, MatSelectModule, MatCardModule } from '@angular/material';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { AddArticleComponent } from './add-article/add-article.component';
import { EditarticleuploadComponent } from './editarticleupload/editarticleupload.component';
import { TreeModule } from 'angular-tree-component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { MatRadioModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SweetAlertService } from 'angular-sweetalert-service';
import { RestrictKeywordValidatorDirective } from './add-article/restrict-keyword-validator.directive';
@NgModule({
    declarations: [ManageArticleuploadComponent, AddArticleComponent, EditarticleuploadComponent,
        RestrictKeywordValidatorDirective],
    imports: [
        CommonModule,
        EditorModule,
        ArticleUploadRoutingModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
        DataTablesModule,
        ModalModule.forRoot(),
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatTableModule,
        MatSortModule,
        //MatTooltipModule,
        TreeModule.forRoot(),
        MatCardModule,
        MatPaginatorModule,
        MatSelectModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        ConfirmationPopoverModule.forRoot({
            confirmButtonType: 'danger',
            cancelButtonType: 'outline-secondary' // set defaults here
        }),
        NgbModule.forRoot(),
        NgxDropzoneModule,
        AngularFileUploaderModule,
        ModalModule.forRoot(),
        MatRadioModule,
        MatCheckboxModule,
        NgxFileDropModule

    ],
    exports: [
        ManageArticleuploadComponent,
        MatSortModule,
        MatPaginatorModule

    ],
    providers: [
        SweetAlertService,

    ]
})
export class ArticleuploadModule { }
