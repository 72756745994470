import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../service/env.service';

@Injectable({
    providedIn: 'root'
})
export class ArticleService {

    constructor(private http: HttpClient, private env: EnvService) { }
    createArticle($data) {
        return this.http.post(this.env.baseAPIUrl + '/saveArticle', $data);
    }

    updateStatusArticle($data) {
        return this.http.post(this.env.baseAPIUrl + '/updateStatusArticle', $data);
    }

    getJournals($data) {
        return this.http.post(this.env.baseAPIUrl + '/journalcodeArticleLists', $data);
    }

    getJournalVolumes($data) {
        return this.http.post(this.env.baseAPIUrl + '/journalVolumesIssues', { journalCode: $data });
    }

    // get  article data
    getArticlesData(data: any) {
        return this.http.post(this.env.baseAPIUrl + '/getArticleData', data);
    }

    getRecentArticles(data: any) {
        return this.http.post(this.env.baseAPIUrl + '/getRecentArticles', data);
    }

    uploadArticleCoverImage($data) {
        return this.http.post(this.env.baseAPIUrl + '/uploadArticleCoverImage', $data);
    }

    toProcessArticles($data) {
        return this.http.post(this.env.baseAPIUrl + '/toProcessArticles', $data);
    }

    // getMyArticlesSvs($data)
    // {
    //     return this.http.post(this.env.baseAPIUrl + '/get-my-articles', $data);
    // }

    moveNextStageSvs($data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/move-next-stage', $data);
    }

    markCompleteSvs(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/mark-complete', data)
    }

    public getNextStageButtonsSvs($data: any) {
        return this.http.post(this.env.baseAPIUrl + '/get-next-stage-buttons', $data);
    }

    getArticleByArticleId(data) {
        return this.http.post(this.env.baseAPIUrl + '/get-article-by-articleId', data);
    }

    //save all the accepeted rvclean patterns for an article
    saveAcceptedPatterns(data) {
        return this.http.post(this.env.baseAPIUrl + '/save-accepted-art-patterns', data);
    }

    hasCheckedOutSvs(data) {
        return this.http.post(this.env.baseAPIUrl + '/has-checked-out', data)
    }

    getArticleDetails(data) {
        return this.http.post(this.env.baseAPIUrl + '/get-article-details', data)
    }
   
    getFromArticle(data) {
        return this.http.post(this.env.baseAPIUrl + '/get-from-article', data)
    }
    postArticleLastActiveEvent(data){
        return this.http.post(this.env.baseAPIUrl + '/article-last-active-event', data)
    }
    postFetchLastActiveEventForArticles(data){
        return this.http.post(this.env.baseAPIUrl + '/last-active-event-for-articles', data)
    }

    shareArticleToSvs(data){
        return this.http.post(this.env.baseAPIUrl + '/share-article-to', data)
    }
    
    shareReassignToSvs(data){
        return this.http.post(this.env.baseAPIUrl + '/share-reassign-to', data)
    }

    exportToSvs(data){
        return this.http.post(this.env.baseAPIUrl + '/export-to', data)
    }
}
