import { Component } from '@angular/core';
import { EnvService } from '../service/env.service';

@Component({
    templateUrl: 'pageNotFound.component.html'
})
export class pageNotFoundComponent {
    base_url: string;
    constructor(private env: EnvService) {
        this.base_url = this.env.baseUploadUrl;

    }

}
