import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserActivityComponent } from './user-activity/user-activity.component';
import { UseractivationComponent } from './useractivation/useractivation.component';


const routes: Routes = [
  {
    path: '',
    component:UserActivityComponent,
    data: {
      title: 'Active Login'
    }  
  },
  {
    path: 'useractivation/:usertoken',
    component:UseractivationComponent,
    data: {
      title: 'User Activation'
    }  
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
