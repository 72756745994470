import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, FormArray, Validators, AbstractControl } from '@angular/forms';
import { SharedService } from '../../shared/shared.service';
import { WorkflowService } from '../workflow.service';
import { UserrolesService } from '../../userroles/userroles.service';
import { NotifierService } from 'angular-notifier';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatStepper } from '@angular/material';
import { jsPlumb } from 'jsplumb';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { emailTemplatesService } from '../../email-templates/email-templates.service';
import { StagesService } from '../../stages/stages.service';

@Component({
    selector: 'app-workflow-create',
    templateUrl: './workflow-create.component.html',
    styleUrls: ['./workflow-create.component.scss'],
    providers: [{
        provide: STEPPER_GLOBAL_OPTIONS,
        useValue: {
            displayDefaultIndicatorType: false,
            showError: true,
        }
    }]
})
export class WorkflowCreateComponent implements OnInit {

    @ViewChild('stepper') stepper: MatStepper;

    public journalId: any = []; // = '5cd10b325586e9122761f675';
    currentWorkflowCode: any; // = 'wf1';
    currentStageId: string = null;

    stages: [];

    journalData: any;
    // WorkflowForm: FormGroup;
    journalStages: any;

    firstJournalStage: any;
    selectedItems = [];
    workflow_name: string;
    closeResult: string;

    // count: any;
    // currentTemplate: number;
    submit_type: string = 'add';
    type_status: string = 'dropdown';
    stage_status: boolean = true;
    submitted: boolean = false;
    closest_parent_status: boolean = false;
    // reminder_array_count: number = 0;
    remind_status: boolean = false;
    reminder_email_status: boolean = false;
    admin_checked: boolean = false;
    dropdown_checked: boolean = false;
    // alert_email_status: boolean = true;
    isSave: boolean;

    modalTitle: string = '';
    workflowFormType: string = '';
    selectedUserRoles = [];
    selectedTemplates = [];
    selectedReminderEmail = [];

    editflag: boolean = false;
    editable: Boolean = true;
    draggable_status: boolean = false;
    jsPlumbInstance;

    userRolesList = [];
    userRolesSettings = {};
    selectedParentStages = [];
    parentStagesSettings = {};
    childStagesSettings = {};
    // stagesList = [];
    parentStagesList = [];
    selectedChildStages = [];
    childStagesList = [];
    parent_stage_name: string;

    // workflow stage id
    wf_stage_id: string;

    // workflow creation form
    workflowCreateForm = new FormGroup({
        workflowJournal: new FormControl('', Validators.required),
        workflowName: new FormControl('', Validators.required),
        workflowCode: new FormControl('',
            {
                validators: [Validators.required],
                asyncValidators: this.checkWorkflowCode.bind(this),
                updateOn: 'blur'
            }),
        workflowFirstStage: new FormControl({ value: '' }, Validators.required),
        workflowDiscription: new FormControl('')
    });

    // Set Form Group
    WorkflowForm = new FormGroup({
        'workflow_stage_id': new FormControl(null),
        'workflow_id': new FormControl(null),
        'parent_stage_code': new FormControl(null),
        'parent_stage_name': new FormControl(null, [Validators.required]),
        'child_stage_name': new FormControl(null),
        'stage_code': new FormControl(null, [Validators.required]),
        'stage_name': new FormControl(null, [Validators.required]),
        'user_role': new FormControl(null, [Validators.required]),
        'email_template': new FormControl(null),
        'button_label': new FormControl(null, [Validators.required]),
        'stage_tat': new FormControl(null, [Validators.min(1), Validators.max(1000)]),
        'jumb_to_any_level_admin': new FormControl(null),
        'jumb_to_any_level_dropdown': new FormControl(null),
        'reminder_email': new FormControl(null),
        'reminder_days': new FormArray([], [Validators.min(0), Validators.max(999)])
    });

    templateSettings = {};

    constructor(private workflowService: WorkflowService,
        private userrolesService: UserrolesService,
        private sharedService: SharedService,
        private notifier: NotifierService,
        private modalService: NgbModal,
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private emailTemplatesService: emailTemplatesService,
        private staegeService: StagesService
    ) { }

    ngOnInit() {
        this.getAllJournals();
        // this.getAllStages();

        // User roles multiselect initial settings
        this.userRolesSettings = {
            singleSelection: false,
            idField: '_id',
            textField: 'rl_role_name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 5,
            allowSearchFilter: true
        };

        // Parent stages multiselect initial settings
        this.parentStagesSettings = {
            singleSelection: false,
            idField: 'wf_stage_id',
            textField: 'wf_stage_name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true
        };

        // Child stages multiselect initial settings
        this.childStagesSettings = {
            singleSelection: false,
            idField: 'wf_stage_id',
            textField: 'wf_stage_name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true
        };

        this.templateSettings = {
            singleSelection: false,
            idField: '_id',
            textField: 'templateName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 5,
            allowSearchFilter: true
        };
        this.getEmailTemplates()
        // this.getAllUserRoles();
        this.getFirstStage();
    }

    // Set 'reminder_days' as FormArray
    get reminder_days(): FormArray { return this.WorkflowForm.get('reminder_days') as FormArray; }

    // Push 'reminder_days' to FormArray while selecting 'reminder email' from multiselect
    reminder_array_count: number = 0;
    onReminderSelect(item: any) {
        this.remind_status = true;
        this.reminder_array_count = this.reminder_array_count + 1;
        this.reminder_days.push(new FormControl('1', [Validators.min(0), Validators.max(999)]));
    }
    // Remove 'reminder_days' to FormArray while deselecting 'reminder email' from multiselect
    onReminderDeSelect(item: any) {
        if (this.selectedReminderEmail.length == 0) {
            this.reminder_array_count = 0;
            this.reminder_days.removeAt(0);
        } else {
            this.reminder_array_count = this.reminder_array_count - 1;
            const last_array_count = document.getElementById('email_reminder').children.length - 1;
            this.reminder_days.removeAt(last_array_count);
        }
    }

    // get all journal lists
    public getAllJournals() {
        this.route.params.subscribe(params => {
            this.sharedService.getJournalsSvs(null).subscribe(
                response => {
                    if (response.status === 200) {
                        this.journalData = response.journal_data;
                        this.journalStages = response.journal_stages;

                        // edit workflow function
                        this.patchWorkflowDetails();
                    }
                }
            );
        });
    }

    public getFirstStage() {
        this.staegeService.getFirstStageSvs().subscribe(data => {
            this.firstJournalStage = data['res']

            if (Object.keys(this.firstJournalStage).length == 1) {
                let firstStageCode = this.firstJournalStage[0].bs_stage_code
                let firstStageName = this.firstJournalStage[0].bs_stage_name

                this.workflowCreateForm.patchValue({
                    workflowFirstStage: firstStageCode + '::' + firstStageName,
                });
            }
        });
    }

    public checkWorkflowCode(control: AbstractControl) {
        let postData = {
            editJnlId: this.route.snapshot.paramMap.get('id'),
            jnlChosed: this.journalId,
            workflowCode: control.value
        }
        var that = this;
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                that.workflowService.checkWorkflowCodeSvs(postData).subscribe(response => {

                    if (response != '0' || response !== 0) {
                        resolve({ 'duplicateWorkflowCode': true });
                    } else {
                        resolve(null);
                    }
                });
            }, 100);
        });
    }

    get workflowCreateFormControls() {
        return this.workflowCreateForm.controls;
    }

    move(index: number) {
        this.stepper.selectedIndex = index;
    }

    public currentJournalStages: any = []
    public getCurrentJournalStages() {
        let selJournals = this.workflowCreateForm.get('workflowJournal').value
        let that = this
        this.currentJournalStages = []
        let tmpStgId = []
        selJournals.forEach(jnlId => {
            this.journalStages[jnlId].forEach(stg => {
                if (tmpStgId.indexOf(stg._id) == -1) {
                    that.currentJournalStages.push(stg)
                }
                tmpStgId.push(stg._id)
            });
        })
    }

    /* saves basic workflow details to journal */
    saveWorkflowBasicDetails() {
        this.getCurrentJournalStages()
        this.editable = false;
        this.submitted = true;
        if (this.workflowCreateForm.valid) {
            const that = this;
            this.workflowService.saveWorkflowBasicDetailsApi(this.workflowCreateForm.value).subscribe(
                response => {
                    if (response.status === 200) {
                        this.notifier.notify('success', 'Settings saved successfullly');

                        this.currentWorkflowCode = that.workflowCreateForm.value.workflowCode;
                        that.move(1);
                    } else {
                        this.notifier.notify('error', 'Error');
                    }
                });
        }
    }

    // patching values into the fields for edit functionality
    patchWorkflowDetails() {
        this.route.params.subscribe(params => {
            if (typeof (params.id && params.code) !== 'undefined') {
                this.editflag = true;
                this.workflowService.getWorkflowByIdSvs(params.id, params.code).subscribe(
                    res => {

                        if (res.workflow_data.length > 0) {
                            this.journalId.push(res.workflow_data[0]._id.$oid);
                            this.currentWorkflowCode = res.workflow_data[0].wf_code;
                            this.wf_stage_id = res.workflow_data[0].wf_stages[0].wf_stage_id;

                            // this.getFirstStage();
                            // this.currentJournalStages = this.journalStages[res.workflow_data[0]._id.$oid];

                            this.workflowCreateForm.patchValue({
                                workflowJournal: [res.workflow_data[0]._id.$oid],
                                workflowName: res.workflow_data[0].wf_name,
                                workflowCode: res.workflow_data[0].wf_code,
                                workflowFirstStage: res.workflow_data[0].wf_stage_code[0] + '::' + res.workflow_data[0].wf_stage_name[0],
                                workflowDiscription: res.workflow_data[0].wf_discription,
                            });
                        } else {
                            this.router.navigate(['/workflow']);
                        }
                    },
                    err => {
                        this.notifier.notify('error', 'Internal Server Error !');
                    }
                );
            }
        });
    }

    // update workflow details
    updateWorkflowBasicDetails() {
        this.submitted = true;
        this.route.params.subscribe(params => {
            if (typeof (params.id && params.code) !== 'undefined') {
                if (this.workflowCreateForm.valid) {

                    const editForm = this.workflowCreateForm.value;
                    editForm.workflowStageId = this.wf_stage_id;

                    this.workflowService.editWorkflowDataSvs(editForm, params.id, params.code).subscribe(
                        res => {
                            if (res.status === 200) {
                                this.notifier.notify('success', 'Settings Updated Successfully');
                                this.router.navigate(['/workflow']);
                            } else {
                                let msg = 'Internal Server Error'
                                if (res.msg != undefined) {
                                    msg = this.translate.instant('manage.workflow.' + res.msg)
                                }
                                this.notifier.notify('error', msg);
                            }
                        },
                        err => {
                            // console.log(err);
                            this.notifier.notify('error', 'Internal Server Error !');
                        }
                    );
                }
            } else {
                this.router.navigate(['/workflow']);
            }
        });
    }

    // Get modal dismiss reason
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    // Function to open the modal for creation / updation
    openWorkflowForm(content: any) {
        this.modalService.open(content, { size: 'lg' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    // Get all email templates
    public templatesList: any;
    public getEmailTemplates() {
        this.emailTemplatesService.geEmailTemplateHooks(null).subscribe(res => {
            this.templatesList = res;
        });
    }

    // Get data for create/ edit
    public redirectToCreate = (workflow_stage_id: number) => {
        this.submit_type = 'add';
        this.closest_parent_status = false;
        this.type_status = 'dropdown';
        this.WorkflowForm.enable();
        this.isSave = true;
        this.stage_status = true;
        this.reminder_email_status = false;
        this.remind_status = false;
        this.admin_checked = false;
        this.dropdown_checked = false;
        this.selectedUserRoles = [];
        this.selectedTemplates = [];
        this.selectedReminderEmail = [];
        this.resetForm();
        this.WorkflowForm.controls['parent_stage_name'].disable();
        this.WorkflowForm.patchValue({ stage_tat: '1' });
        this.getCurrentJournalStages()

        // get user role data if there is'nt any
        if (this.userRolesList.length == 0) {
            this.userrolesService.getUserRoles().subscribe((response: any) => {
                if (response.status == 200) {
                    this.userRolesList = response.data;
                }
            }
            );
        }

        this.workflowService.getWorkflowStageData(this.journalId, workflow_stage_id)
            .subscribe(
                response => {
                    if (response.status == 200) {
                        // this.selectedUserRoles = JSON.parse(response.stage_data.selected_user_roles);
                        this.workflowFormType = 'create';
                        this.modalTitle = this.translate.instant('manage.stages.add_stage');
                        this.currentStageId = response.stage_data.wf_stage_id;
                        this.parent_stage_name = response.stage_data.bs_stage_name;
                        this.parent_stage_code = response.stage_data.bs_stage_code;
                        this.getParentChildStages(response.stage_data.wf_stage_id, 'create');
                    }
                }
            );
    }


    parent_stage_code: any;
    reminderEmailList: any;
    parent_stage_ids: [];
    // Get data for edit
    public redirectToDetails = (workflow_stage_id: number, type: string) => {

        this.submit_type = 'edit';
        this.closest_parent_status = false;
        this.type_status = 'text';
        this.resetForm();
        if (type == 'details') {
            this.isSave = false;
            this.WorkflowForm.disable();
        } else {
            this.isSave = true;
            this.WorkflowForm.enable();
            this.WorkflowForm.controls['parent_stage_name'].disable();
            this.WorkflowForm.controls['stage_code'].disable();

            // get user role data if there is'nt any
            if (this.userRolesList.length == 0) {
                this.userrolesService.getUserRoles().subscribe((response: any) => {
                    if (response.status == 200) {
                        this.userRolesList = response.data;
                    }
                }
                );
            }
        }

        this.workflowService.getWorkflowStageData(this.journalId, workflow_stage_id)
            .subscribe(
                response => {
                    if (response.status == 200) {
                        this.workflowFormType = 'update';
                        // manage.stages.manage_stage
                        this.modalTitle = this.translate.instant('manage.stages.update_stage') + ' : ' + response.stage_data.wf_stage_name;
                        this.selectedUserRoles = JSON.parse(response.stage_data.selected_user_roles);
                        this.WorkflowForm.patchValue({
                            workflow_stage_id: response.stage_data.wf_stage_id,
                            stage_code: response.stage_data.wf_stage_code,
                            stage_name: response.stage_data.wf_stage_name,
                            button_label: response.stage_data.wf_button_label,
                            stage_tat: response.stage_data.wf_stage_tat
                        });
                        if (response.stage_data.wf_jump_to_any_level == 'y') {
                            this.admin_checked = true;
                        } else {
                            this.admin_checked = false;
                        }
                        if (response.stage_data.wf_show_jump_to_any_level == 'y') {
                            this.dropdown_checked = true;
                        } else {
                            this.dropdown_checked = false;
                        }
                        if (response.stage_data.wf_parent_stages.length == 0) {
                            this.stage_status = false;
                            this.parent_stage_ids = response.stage_data.wf_parent_stages;
                        } else {
                            this.stage_status = true;
                            this.parent_stage_ids = response.stage_data.wf_parent_stages;
                        }
                        this.getParentChildStages(response.stage_data.wf_stage_id, 'edit');

                        this.selectedReminderEmail = [];
                        this.reminderEmailList = [];

                        this.getEmailTemplates();

                        while (this.reminder_days.length) {
                            this.reminder_days.removeAt(0);
                        }
                        if (response.stage_data.wf_stage_tat > 1) {
                            this.reminder_email_status = true;
                        }
                        if (response.stage_data.wf_email_reminder_key != null
                            && response.stage_data.wf_stage_tat != null
                            && response.stage_data.wf_stage_tat > 1) {
                            this.remind_status = true;
                            this.selectedReminderEmail = JSON.parse(response.stage_data.wf_reminder_emails);
                            for (let i = 0; i < response.stage_data.wf_email_reminder_days.length; i++) {
                                if (type == 'details') {
                                    this.reminder_days.push(new FormControl({
                                        value: response.stage_data.wf_email_reminder_days[i],
                                        disabled: true
                                    }));
                                } else {
                                    this.reminder_days.push(new FormControl(response.stage_data.wf_email_reminder_days[i],
                                        [Validators.min(0), Validators.max(999)]));
                                }
                            }
                        }

                        if (
                            response.stage_data.wf_email_template_key != null &&
                            response.stage_data.wf_email_template_key.length > 0
                        ) {
                            this.selectedTemplates = JSON.parse(response.stage_data.selected_templates);
                        } else {
                            this.selectedTemplates = [];
                        }

                    }
                }
            );
    }

    // Get all parent stages
    public getParentChildStages(stage_id: string, type: string) {
        let data = {
            journal_id: this.journalId,
            workflow_code: this.currentWorkflowCode,
            stage_id: stage_id,
            operation_type: type
        };

        this.workflowService.getParentChildStagesSvs(data)
            .subscribe(
                response => {
                    this.selectedParentStages = [];
                    this.parentStagesList = [];

                    this.selectedChildStages = [];
                    this.childStagesList = [];

                    if (response.status == 200) {
                        this.parentStagesList = response.stages.all_parent_stages;
                        this.selectedParentStages = response.stages.selected_parent_stage;

                        this.childStagesList = response.stages.all_child_stages;
                        this.selectedChildStages = response.stages.selected_child_stage;

                        const child_stage_control = this.WorkflowForm.get('child_stage_name');
                        if (this.submit_type == 'edit') {
                            if (response.stages.selected_child_stage != undefined &&
                                response.stages.selected_child_stage.length === 0) {
                                child_stage_control.setValidators(null);
                            } else {
                                child_stage_control.setValidators([Validators.required]);
                            }
                        } else {
                            child_stage_control.setValidators(null);
                        }
                    }
                }
            );
    }

    // Reset form controls & remove form array
    resetForm() {
        // Reset form
        this.WorkflowForm.reset();
        this.submitted = false;
        Object.keys(this.WorkflowForm.controls).forEach(key => {
            this.WorkflowForm.controls[key].setErrors(null);
        });

        // Remove existing 'reminder_days' form array
        // while (this.reminder_days.length) {
        //   this.reminder_days.removeAt(0);
        // }
        this.remind_status = false;

    }

    // Get all form controls
    get workflowControls() {
        return this.WorkflowForm.controls;
    }

    public onStepChange($event) {
        // return false;
        this.getWorkflowStages();
    }

    public workflow_stage_id: string;

    // Get all workflow stage data
    public getWorkflowStages() {
        if (this.jsPlumbInstance != null) {
            this.jsPlumbInstance.reset();
        }

        this.workflowService.loadWorkflowSvs(this.journalId, this.currentWorkflowCode).subscribe(
            response => {
                this.workflow_name = response.workflow_data.workflow_name.name;
                this.workflow_stage_id = response.workflow_data.workflow_stages[0].wf_stage_id;
                this.stages = response.workflow_data.workflow_stages;

                let last_node: boolean = false;
                // Initialize jsplumb instance
                this.jsPlumbInstance = jsPlumb.getInstance();
                // this.jsPlumbInstance.reset();
                this.draggable_status = false;

                this.jsPlumbInstance.setContainer('.rvt_canvas .window');

                // Connect nodes
                var i = 0;
                response.workflow_data.workflow_stages.forEach((value: any) => {
                    i++;
                    if (i == response.workflow_data.workflow_stages.length) {
                        last_node = true;
                    }
                    if (value.wf_child_stages.length > 0) {

                        setTimeout(() => {
                            if (value.wf_child_stages.length > 1) {
                                value.wf_child_stages.forEach((child_value: any) => {
                                    this.connectNodes(value.wf_stage_id, child_value, last_node);
                                });
                            } else {
                                this.connectNodes(value.wf_stage_id, value.wf_child_stages, last_node);
                            }
                        }, 500);
                    }
                });
            },
            error => {
                this.notifier.notify('error', 'Error');
            }
        );
    }

    // Connect two nodes in the flowchart using jsplumb
    public connectNodes(source_node: string, target_node: string, last_node: boolean) {
        // Basic settings for connection
        var connectionSettings = {
            DragOptions: { cursor: 'pointer', zIndex: 1000 },
            connector: 'Flowchart',
            hoverPaintStyle: { strokeStyle: '#dbe300' },
            endpoint: 'Blank',
            anchor: 'Continuous',
            overlays: [['Arrow', { location: 1, width: 15, length: 12 }]]
        };

        // Connect source & target nodes
        this.jsPlumbInstance.connect({
            source: source_node,
            target: target_node
        }, connectionSettings);
        // If last node reached, make all nodes as draggable. Since, we can only initialize drag after all nodes are connected.
        if (last_node === true) {
            if (this.draggable_status == false) {
                this.draggable_status = true;
                var windows = this.jsPlumbInstance.getSelector(".rvt_canvas .window");
                this.jsPlumbInstance.draggable(windows);
            }
        }

    }

    // Show error while deselected it's closest parent stage
    public parentChange() {
        if (this.selectedParentStages.length == 0) {
            this.closest_parent_status = true;
        } else {
            if (this.selectedParentStages.find(e => e.stage_code === this.parent_stage_code)) {
                this.closest_parent_status = false;
            } else {
                this.closest_parent_status = true;
            }
        }
    }

    // Get stage details while selecting a stage
    public getBasicStageData(stage_code: string) {
        this.submitted = false;
        this.sharedService.getBasicStageDataSvs({ stage_code: stage_code })
            .subscribe(
                response => {
                    if (response.status == 200) {
                        this.selectedUserRoles = JSON.parse(response.stage_data.selected_user_roles);
                        this.selectedTemplates = JSON.parse(response.stage_data.selected_templates);
                        this.WorkflowForm.patchValue({
                            stage_code: response.stage_data.bs_stage_code,
                            button_label: response.stage_data.bs_button_label,
                            stage_tat: response.stage_data.bs_stage_tat,
                            stage_name: response.stage_data.bs_stage_name,
                        });
                        //                 if (response.stage_data.stage_tat > 1) {
                        //                     this.reminder_email_status = true;
                        //                 }
                        //                 this.getEmailTemplates();
                        //                 this.selectedReminderEmail = [];

                        //                 // Remove existing 'reminder_days' form array
                        //                 while (this.reminder_days.length) {
                        //                     this.reminder_days.removeAt(0);
                        //                 }
                        //                 if (response.stage_data.stage_tat > 1) {
                        //                     this.reminder_email_status = true;
                        //                 }
                        //                 if (response.stage_data.stage_tat > 1 && response.stage_data.email_reminder_key != null) {
                        //                     this.remind_status = true;
                        //                     this.selectedReminderEmail = JSON.parse(response.stage_data.reminder_emails);
                        //                     // Push 'reminder_days' form control to form array
                        //                     for (let i = 0; i < response.stage_data.email_reminder_days.length; i++) {
                        //                         this.reminder_days.push(new FormControl(response.stage_data.email_reminder_days[i], [Validators.min(0), Validators.max(999)]));
                        //                     }
                        //                 }

                        //                 this.getEmailTemplates();

                        if (response.stage_data.email_template_key != null) {
                            this.selectedTemplates = JSON.parse(response.stage_data.selected_templates);
                        } else {
                            this.selectedTemplates = [];
                        }

                    }
                }
            );
    }

    // Service to save form data
    public saveWorkflowStage() {
        this.submitted = true;

        if (this.parent_stage_code != null) {
            this.submitted = false;
            if (this.selectedParentStages.length != 0) {
                // If parent stage code exist & not selected then don't allow to submit unless the closedt parent stage is selected
                if (this.selectedParentStages.find(e => e.wf_stage_id === this.parent_stage_code)) {
                    this.submitted = true;
                } else {
                    this.submitted = false;
                }
            }
        }

        if (this.submitted == true) {
            if (this.WorkflowForm.valid) {
                // Formatting user roles array
                let role_save_arr = [];
                for (let i = 0; i < this.WorkflowForm.value.user_role.length; i++) {
                    role_save_arr.push(this.WorkflowForm.value.user_role[i].user_role_id);
                }
                // Formatting reminder emails array
                let email_save_arr = [];
                if (this.WorkflowForm.value.reminder_email != null) {
                    if (this.WorkflowForm.value.reminder_email.length > 0) {
                        for (let i = 0; i < this.WorkflowForm.value.reminder_email.length; i++) {
                            email_save_arr.push(this.WorkflowForm.value.reminder_email[i].email_template_id);
                        }
                    }
                }

                // Formatting alert emails array
                let alert_email_arr = [];
                if (this.WorkflowForm.value.email_template != null) {
                    if (this.WorkflowForm.value.email_template.length > 0) {
                        for (let i = 0; i < this.WorkflowForm.value.email_template.length; i++) {
                            alert_email_arr.push(this.WorkflowForm.value.email_template[i].email_template_id);
                            // ?
                        }
                    }
                }

                let user_data = JSON.parse(localStorage.getItem('currentUser'));
                var data = {
                    // current_user_id: user_data['user_id'], 
                    // user_roles: role_save_arr,
                    // reminder_email: email_save_arr,
                    // alert_email: alert_email_arr,
                    form_data: this.WorkflowForm.getRawValue(),
                    form_type: this.workflowFormType,
                    journal_id: this.journalId,
                    workflow_code: this.currentWorkflowCode,
                    parent_stage_id: this.currentStageId,
                };

                this.workflowService.saveWorkflowStageSvs(data).subscribe(
                    response => {
                        if (response.status === 200) {
                            // if (response.wid) {
                            //     this.workflow_id = response.wid;
                            //     let encrypted_id = CryptoJS.AES.encrypt(JSON.stringify(this.workflow_id), 'Workflow_id').toString();

                            //     var url = this.router.url;
                            //     var lastPart = url.split('/').pop();
                            //     var stateObj = { lastPart: encrypted_id };
                            //     history.replaceState(stateObj, 'title', 'manage/workflow/add/' + encrypted_id)
                            // }
                            this.draggable_status = false;
                            this.jsPlumbInstance.reset();
                            this.getWorkflowStages();
                            // this.notifierService.notify('success', this.translate.instant('manage.workflow.workflow_success_msg'));
                            this.notifier.notify('success', this.translate.instant('manage.workflow.workflow_success_msg'));
                            this.resetForm();
                            this.modalService.dismissAll();
                        }
                        // else if (response.status === 422 && response.message_type == 'status_name_error') {
                        //     this.notifierService.notify('error', this.translate.instant('manage.stages.status_name_required'));
                        // } else {
                        //     this.notifierService.notify('error', this.translate.instant('error_msg'));
                        // }
                    }, error => {
                        // this.notifierService.notify('error', error.error.message);
                    }
                );
            }
        }
    }

    public removeWorkflowStage(stageId: string) {
        this.workflowService.removeWorkflowStageSvs(this.journalId, this.currentWorkflowCode, stageId).subscribe(response => {
            if (response.status === 200) {
                this.jsPlumbInstance.reset();
                this.getWorkflowStages();
                this.notifier.notify('success', this.translate.instant('manage.stages.stage_deleted_msg'));
            } else {
                this.notifier.notify('error', this.translate.instant('error_msg'));
            }
        });
    }

    public saveWorkflowLayout(jnlCode, wfCode) {
        let stages = document.getElementById('canvas').children;
        let positions = [];

        for (let i = 0; i < stages.length; i++) {
            let stage = stages[i] as HTMLElement;

            positions[i] = {
                stageId: stages[i].getAttribute('id'),
                left: stage.style['left'],
                top: stage.style['top']
            };
        }

        let postData = {
            jnl_id: jnlCode,
            workflow_code: wfCode,
            positions: positions
        }

        this.workflowService.saveWorkflowLayoutSvs(postData).subscribe(response => {
            if (response.status === 200) {
                this.notifier.notify('success', this.translate.instant('manage.stages.save_layout_success'));
            } else {
            }
        });
    }
}


