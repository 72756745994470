export const Globals= {
    baseAPIUrl: 'http://localhost/rv_admin/public/api',
        baseAPPUrl : 'Super Admin',
    }
    
    export const Global_upload=    {
        baseUploadUrl: 'http://localhost/rv_admin'
    }
    
    
    export const languageArray= {
        "gb": 'English',
        "fr": 'French',
        'es': 'Spanish',
        'ae': 'Arabic'
    }
    