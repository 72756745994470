import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WorkflowComponent } from './workflow.component';
import { WorkflowListComponent } from './workflow-list/workflow-list.component';
import { WorkflowCreateComponent } from './workflow-create/workflow-create.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'breadcrumb.Workflow'
    },
    children: [
      {
        path: '',
        component: WorkflowListComponent,
        data: {
          title: ''
        },
      },
      {
        path: 'new-workflow',
        component: WorkflowCreateComponent,
        data: {
          title: 'breadcrumb.Create workflow'
        }
      },
      {
        path: 'editworkflow/:id/:code',
        component: WorkflowCreateComponent,
        data: {
            title: 'breadcrumb.Edit workflow'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkflowRoutingModule { }
