import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateuserComponent } from './createuser/createuser.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserActivityComponent } from './user-activity/user-activity.component'
const routes: Routes = [
    {
        path: '',
        data: {
            title: 'breadcrumb.Users'
        },
        children: [
            {
                path: '',
                component: UserListComponent,
                data: {
                    title: ''
                }
            },
            {
                path: 'users',
                component: UserListComponent,
                data: {
                    title: 'breadcrumb.Users'
                }
            },
            {
                path: 'newuser',
                component: CreateuserComponent,
                data: {
                    title: 'breadcrumb.Create user'
                }
            },
            {
                path: 'edituser/:id',
                component: CreateuserComponent,
                data: {
                    title: 'breadcrumb.Edit user'
                }
            },
            {
                path: 'userprofiles',
                component: UserProfileComponent,
                data: {
                    title: 'breadcrumb.User profile'
                }
            },
            {
                path: 'user_activity',
                component:UserActivityComponent,
                data: {
                  title: 'breadcrumb.Active Login'
                }  
              },
        ]
    }
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UsersRoutingModule { }
