import { Component, OnInit, TemplateRef, ViewEncapsulation, OnDestroy, QueryList, ViewChildren, Input, ContentChildren, forwardRef, ElementRef } from '@angular/core';
import { FormBuilder, AbstractControl, FormGroup, FormArray, Validators, FormControl, FormGroupDirective, NgForm, } from "@angular/forms";
import { articleUploadsService } from '../articleupload.service';
import { HttpClient } from '@angular/common/http';
import { UserTokenService } from '../../service/user-token.service';
import { NotifierService } from "angular-notifier";
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EnvService } from '../../service/env.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { SharedService } from '../../shared/shared.service';
import swal from 'sweetalert2';
import { ErrorStateMatcher } from '@angular/material/core';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}


@Component({
    selector: 'app-add-article',
    templateUrl: './add-article.component.html',
    styleUrls: ['./add-article.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class AddArticleComponent implements OnInit {

    modalRef: BsModalRef;
    @ViewChild('no_tex') modalNoTex: TemplateRef<any>;
    @ViewChild('main_tex') modalMainTex: TemplateRef<any>;
    @ViewChild('no_main_tex') modalNoMainTex: TemplateRef<any>;
    @ViewChild('list_tex') modalListTex: TemplateRef<any>;
    createArticleUpload: FormGroup;
    fileSelection: FormGroup;
    articleName = '';
    articleCode = '';
    articleDueDate='';
    articleId = '';
    hdnArticleFile = '';
    submitted = false;
    ziperror_valid = false;
    ziperror = false;
    editFlag = false;
    editflag = true;
    article_exists = false;
    journal_exists = true;
    mapper;
    ismapped;
    selectedJournelDetails;

    uploadfile_name = '';
    filetype_error = false;
    public fileUploadQueue: any = [];
    public closeResult: string;
    private readonly notifier: NotifierService;
    public parsedJson: [];
    public tex_files: [];
    public response_msg: string;
    public article_code: string;
    public baCKND_response: any;
    public temp_folder: string;
    public uploaded_zip_name: string;
    public article_status_type: string;
    public journal: any = [];
    public wrkflname: any = [];
    public journal_name;
    public selectedOption;
    loginedUser = localStorage.getItem("current_logined_userId");

    public base_file_check: boolean = false;
    public files: NgxFileDropEntry[] = [];

    public jnl_file_extentions: any = [];
    public zip_extentions: any = [];
    public files_list: any = [];
    public not_allowed_files: any = [];
    public extentionCheck: Boolean = false;
    public checkbox_check: Boolean = true;
    file_path_list;

    public dropped(files: NgxFileDropEntry[]) {
        this.shared_service.showSpinner('articleUpload');
        this.files = files;
        for (const droppedFile of files) {

            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    // Here you can access the real file
                    if (file.type == 'application/zip') {
                        // let url =  this.env.baseAPIUrl + "/article_primary_upload";
                        const formData = new FormData();
                        formData.append('file', file);
                        formData.append('file_name', file.name);
                        this.articleUploadsService.primaryUpload(formData).subscribe(
                            response => {
                                let status_type = response['status_type'];
                                let status = response['status'];
                                this.response_msg = response['message'];
                                this.article_code = response['article_code'];
                                this.temp_folder = response['temp_folder'];
                                this.uploaded_zip_name = response['uploaded_zip_name'];
                                this.tex_files = response['file_list'];
                                this.file_path_list = response['paths'];
                                this.article_status_type = response['status_type'];
                                this.zip_extentions = response['zip_extentions'];
                                this.files_list = response['zip_files'];
                                this.articleCode_check(this.article_code);
                                this.journal_name = this.journalname_check(this.uploaded_zip_name);
                                this.selectedOption = this.journal_name;
                                this.selectChangeHandler(this.journal_name);
                                this.fileSelection.patchValue({
                                    articleCode: this.article_code,
                                    article_jrnl: this.selectedOption
                                });
                                if (status == 422) {
                                    this.shared_service.hideSpinner('articleUpload');
                                    swal.fire({
                                        title: '',
                                        text: this.response_msg,
                                        timer: 4000,
                                        type: 'error',
                                        showConfirmButton: false
                                    });
                                }
                                else {
                                    this.base_file_check = true;
                                    if (status_type == 1) {
                                        this.openModal(this.modalNoTex);
                                        this.fileSelection.get('base_article').enable();
                                    } else if (status_type == 2) {
                                        this.fileSelection.get('base_article').disable();
                                        this.openModal(this.modalMainTex);
                                    } else if (status_type == 3) {
                                        this.fileSelection.get('base_article').enable();
                                        this.openModal(this.modalNoMainTex);
                                    } else {
                                        this.fileSelection.get('base_article').enable();
                                        this.openModal(this.modalListTex);
                                    }
                                    this.shared_service.hideSpinner('articleUpload');
                                }
                            },
                            error => {
                                this.notifier.notify('error', this.translate.instant('error_occured_msg'));
                                this.shared_service.hideSpinner('articleUpload');
                            }
                        );
                    }
                    else {
                        this.shared_service.hideSpinner('articleUpload');
                        swal.fire({
                            title: '',
                            text: 'Accept only zip files',
                            timer: 4000,
                            type: 'warning',
                            showConfirmButton: false
                        });
                    }
                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                this.shared_service.hideSpinner('articleUpload');
                swal.fire({
                    title: '',
                    text: 'Please upload some files',
                    timer: 4000,
                    type: 'warning',
                    showConfirmButton: false
                });
            }
        }
    }

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private articleUploadsService: articleUploadsService,
        private http: HttpClient,
        private UserTokenService: UserTokenService,
        public translate: TranslateService,
        private modalService: BsModalService,
        private env: EnvService,
        private eleRef: ElementRef,
        private shared_service: SharedService,
        notifierService: NotifierService
    ) {
        this.notifier = notifierService;
    }
    public uploaded_files = this.translate.instant('choose_file_label');

    ngOnInit() {
        this.fileSelection = this.formBuilder.group({
            // base_article: new FormControl('', [Validators.required]),
            base_article: new FormControl('', [Validators.required]),
            article_jrnl: new FormControl('', [Validators.required]),
            articleCode: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
            articleDueDate:new FormControl('', [Validators.required]),
            wf_code: new FormControl(''), // inclming
            hdn_jnl_stages: new FormControl(''),
            delete_files: new FormControl(''),
            stage_user: new FormControl('')
        });

        this.articleUploadsService.getAllJournal().subscribe(
            response => {
                this.journal = response['journal'];
                let result = <any>response['journal'];
                this.mapper = result;
            },
        );
    }
    get formfields() {
        return this.fileSelection.controls;
    }

    onSubmit() {
        this.submitted = true;
        if (this.createArticleUpload.invalid || this.article_exists) {
            return;
        }
        const formData = new FormData();
        formData.append('article_name', this.createArticleUpload.get('articleName').value);
        formData.append('article_code', this.createArticleUpload.get('articleCode').value);
        formData.append('article_file', this.createArticleUpload.get('hdnArticleFile').value);
        formData.append('article_jrnl', this.createArticleUpload.get('article_jrnl').value);
        formData.append('wf_code', this.createArticleUpload.get('wf_code').value);
        formData.append('hdn_jnl_stages', this.createArticleUpload.get('hdn_jnl_stages').value);
        this.articleUploadsService.uploadArticle(formData).subscribe(
            response => {
                if (response['status'] === '200') {
                    this.notifier.notify(response['message_type'], response['message']);
                    this.router.navigate(['/article-upload'])

                } else {
                    this.notifier.notify(response['message_type'], response['message']);
                }
            },
            error => {
                this.notifier.notify('error', this.translate.instant('error_occured_msg'));
            }
        );
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
    }

    checkbox_change(event) {
        if (event.checked) {
            this.checkbox_check = true;
        } else {
            this.checkbox_check = false;
        }
    }

    public noWhitespaceValidator(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    onfileSelection(event) {

        if (this.fileSelection.value.delete_files === false) {
            this.checkbox_check = false;
        }

        this.submitted = true;
        let article_code = event.target.querySelector('#articleCode').value;
        let article_Due_date = event.target.querySelector('#articleDueDate').value;
        if (this.fileSelection.invalid || this.article_exists || this.checkbox_check === false) {
            return;
        } else {
            this.shared_service.showSpinner('articleUpload');
            const formData = new FormData();
            formData.append('article_code', this.fileSelection.get('articleCode').value);
            formData.append('article_Due_date', this.fileSelection.get('articleDueDate').value);
            formData.append('base_file', this.fileSelection.get('base_article').value);
            formData.append('article_jrnl', this.fileSelection.get('article_jrnl').value);
            formData.append('wf_code', this.fileSelection.get('wf_code').value);
            formData.append('temporary_folder', this.temp_folder);
            formData.append('uploaded_zip_name', this.uploaded_zip_name);
            formData.append('article_type_status', this.article_status_type);
            formData.append('hdn_jnl_stages', this.fileSelection.get('hdn_jnl_stages').value);
            formData.append('stage_user', this.loginedUser);
            formData.append('not_allowed_files', this.not_allowed_files);
            formData.append('delete_files', this.fileSelection.get('delete_files').value);

            this.articleUploadsService.saveArticle(formData).subscribe(

                response => {
                    this.modalRef.hide();
                    this.notifier.notify(response['message_type'], response['message']);
                    this.shared_service.hideSpinner('articleUpload');
                },
                error => {
                    this.notifier.notify('error', this.translate.instant('error_occured_msg'));
                    this.shared_service.hideSpinner('articleUpload');
                }
            );
        }
    }
    public hasError = (controlName: string, errorName: string) => {
        return this.fileSelection.controls[controlName].hasError(errorName);
    }
    articleCode_check(value) {
        if (value != '') {
            this.articleUploadsService.articleCodecheck(value).subscribe(
                response => {
                    if (response['status'] === 400) {
                        this.article_exists = true;
                    } else {
                        this.article_exists = false;
                    }
                    this.baCKND_response = response;
                },
                error => {
                    this.notifier.notify('error', this.translate.instant('error_occured_msg'));
                }
            );
        }
    }

    // to hide popup modal and reset the form after closing it
    modalHide() {
        this.ngOnInit();
        this.wrkflname = 'null';
        this.modalRef.hide();
        this.extentionCheck = false;
        this.checkbox_check = true;
        this.fileSelection.reset();
    }
    // To get workflow name for selected journal
    public jnlCode = null;
    selectChangeHandler(jnl_code) {
        this.jnlCode = jnl_code
        this.articleUploadsService.getJrnlWorkflow(jnl_code).subscribe(data => {
            if (data[0] !== undefined) {

                // initially this array need to be null when selecting other journal
                this.not_allowed_files = [];

                this.jnl_file_extentions = data[0]['jnl_file_extentions'];
                this.wrkflname = data[0]['jnl_workflow'];

                // to check jnl choosed extentions included in zip file
                const extn_check = this.zip_extentions.every(extn =>
                    this.jnl_file_extentions.includes(extn));
                if (extn_check === false) {
                    this.extentionCheck = true;
                } else {
                    this.extentionCheck = false;
                }

                if (this.extentionCheck === true) {
                    this.fileSelection.get('delete_files').enable();
                } else {
                    this.fileSelection.get('delete_files').disable();
                }
                if (this.extentionCheck === true) {
                    const deltfile = this.fileSelection.get('delete_files');
                    deltfile.setValidators([Validators.required]);
                }
                // to get other file types included in zip
                this.files_list.forEach(elem => {
                    const temp = elem.split('.').slice(-1)[0];
                    if (this.jnl_file_extentions.indexOf('.' + temp) < 0) {
                        this.not_allowed_files.push(elem);
                    }
                });

                this.selectedJournelDetails = this.mapper.find(x => x.jnl_code == jnl_code);
                var obj = this.mapper.filter(function (node) {
                    return node.jnl_code == jnl_code;
                });
                if (this.wrkflname.length === 1) {
                    this.fileSelection.get('wf_code').setValue(this.wrkflname[0].wf_basic_details.wfCode);
                    this.setWorkflow(this.wrkflname[0].wf_basic_details.wfCode)
                }

                /*
                var wfCode = this.wrkflname[0].wf_basic_details.wfCode;
                var wfCode = this.wrkflname[0].wf_basic_details.wfCode;
                var wfCode = this.wrkflname[0].wf_basic_details.wfCode;

                if (obj.length !== 0) {
                    var objs = obj[0].jnl_workflow.filter(function (node) {
                        return node.wf_basic_details.wfCode == wfCode;
                    });
                    var jnl_wrkfl_stages = [];
                    objs[0].wf_stages.forEach((element, index) => {
                        jnl_wrkfl_stages.push({ stage_id: element.wf_stage_id, stage_code: element.wf_stage_code });
                    });
                }
                this.fileSelection.get('hdn_jnl_stages').setValue(JSON.stringify(jnl_wrkfl_stages));
                */
            }
        });
    }

    // To check in which journal the article exists   

    setWorkflow(wfCode){
        let jnl_code = this.jnlCode 
        var obj = this.mapper.filter(function (node) {
            return node.jnl_code == jnl_code;
        });

        if (obj.length !== 0) {
            var objs = obj[0].jnl_workflow.filter(function (node) {
                return node.wf_basic_details.wfCode == wfCode;
            });
            var jnl_wrkfl_stages = [];
            objs[0].wf_stages.forEach((element, index) => {
                jnl_wrkfl_stages.push({ stage_id: element.wf_stage_id, stage_code: element.wf_stage_code });
            });
        }
        this.fileSelection.get('hdn_jnl_stages').setValue(JSON.stringify(jnl_wrkfl_stages));
    }

    journalname_check(journal) {
        let i = 1, a = journal.split(""), b = "", c = "";
        a.forEach(function (e) {
            if (!isNaN(e)) {
                var number = e;
                c += e;
                i++;
            } else {
                b += e;
            }
        });
        var letter = b;
        const names = b.split(".");
        if (i === 0) {
            return false;
        } else {
            this.journal_exists = false;
            return names[0];
        }
    }
}
