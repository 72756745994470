
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LoginService } from '../../login/login.service';
import { UserTokenService } from '../../service/user-token.service';
import { UserService } from '../user.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { formvalidator } from '../../form.validator';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { EnvService } from '../../service/env.service';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})

export class UserProfileComponent implements OnInit {

    customStyle = {
        marginLeft : "28% !important"
     };

    loading: boolean = false;
    submitted = false;
    logined_user: any;
    fileToUpload: File = null;
    passwordFormGroup: FormGroup;
    profileImageUrl: string;
    base_url : any;
    public uploaded_files="Choose a file";

    @ViewChild('fileInput') fileInput: ElementRef;

    selectedFile = File;
    private readonly notifier: NotifierService;
    createuserForm = new FormGroup({
        firstName: new FormControl('', [Validators.required, Validators.minLength(4), this.noWhitespaceValidator]),
        middleName: new FormControl(''),
        lastName: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
        avatar: new FormControl(''),
        userId: new FormControl(null),

    });

    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }

    constructor(
        private router: Router,
        private api: LoginService,
        private user: UserTokenService,
        private postsdata: UserService,
        private formBuilder: FormBuilder,
        notifierService: NotifierService,
        private env: EnvService
    ) {
        this.notifier = notifierService;
        // password change form
        this.passwordFormGroup = this.formBuilder.group({
            current_password: ['', [Validators.required, this.noWhitespaceValidator]],
            password: ['', [Validators.required, Validators.minLength(8) , Validators.maxLength(15),Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&]).{8,}'),this.noWhitespaceValidator]],
            repeatPassword: ['', [Validators.required, this.noWhitespaceValidator]],
            user_id: new FormControl(null),
        }, {
                validator: PasswordValidator.validate.bind(this)
            });

    }

    public noWhitespaceValidator(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    ngOnInit() {
        this.api
            .getProfile()
            .subscribe(
                data => {
                    this.logined_user = data;
                    this.profileImageUrl = this.env.baseProfileImageUrl;
                    this.logined_user = this.logined_user.user_data;
                    this.url = this.profileImageUrl+this.logined_user.usr_image;
                    this.createuserForm.patchValue({
                        firstName: data['user_data']['usr_first_name'],
                        middleName: [data['user_data']['usr_middle_name'] !== 'null'?data['user_data']['usr_middle_name']:''],
                        lastName: data['user_data']['usr_last_name'],
                        userId: data['user_data']['_id'],
                    }),
                        this.passwordFormGroup.patchValue({
                            user_id: data['user_data']['_id'],
                        })
                }
            );
    }

    navigateTo() {
        this.router.navigate(['']);
    }

    get formfields() { 
        return this.createuserForm.controls; 
    }
    
    url = '';
    onFileChange(event) {
        if (event.target.files.length > 0) {
            let file = event.target.files[0];
            this.createuserForm.get('avatar').setValue(file);
            this.uploaded_files = file.name;
        }
        // image preview before upload
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = (event : any) => { // called once readAsDataURL is completed
                this.url = event.target.result;
            }
        }
    }

    private prepareSave(): any {
        let input = new FormData();
        input.append('firstName', this.createuserForm.get('firstName').value);
        input.append('middleName', this.createuserForm.get('middleName').value);
        input.append('lastName', this.createuserForm.get('lastName').value);
        input.append('userId', this.createuserForm.get('userId').value);
        input.append('image', this.createuserForm.get('avatar').value);
        return input;
    }

    onSubmit(event) {
        const formModel = this.prepareSave();
        var that = this;
        //this.submitted = true;
        // stop here if form is invalid
        if (this.createuserForm.invalid) {
            return;
        }
        this.postsdata.updateUser(formModel).subscribe(data => {
            this.notifier.notify('success', 'User details updated successfully');
          
            this.ngOnInit();
        }
        );
    }
    user_id;
    deleteProfilePhoto(){
        this.user_id = this.createuserForm.get('userId').value;
        this.postsdata.deleteProfilePic(this.user_id).subscribe(data => {          
            this.notifier.notify(data['message_type'], data['message']);    
            this.logined_user.usr_image = '';    
            this.url = '';
            this.uploaded_files = "Choose a file";
        });
    }

    clearFile() {
        this.createuserForm.get('avatar').setValue(null);
        this.fileInput.nativeElement.value = '';
        this.url = '';
    }

    // password change submit
    onClickPasswordChange() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.passwordFormGroup.invalid) {
            return;
        }
        this.postsdata.updateUserPassword(this.passwordFormGroup.value).subscribe(data => {          
            this.notifier.notify(data['message_type'], data['message']);         
            this.passwordFormGroup.reset();
            Object.keys(this.passwordFormGroup.controls).forEach(key => {
                this.passwordFormGroup.controls[key].setErrors(null);
            });
        });
    }

}

// password and repeat password check class (validation)
export class PasswordValidator {
    static validate(passwordFormGroup: FormGroup) {
        let password = passwordFormGroup.controls.password.value;
        let repeatPassword = passwordFormGroup.controls.repeatPassword.value;

        if (repeatPassword != null && repeatPassword.length <= 0) {
            return null;
        }

        if (repeatPassword !== password) {
            return {
                doesMatchPassword: true
            };
        }
        return null;
    }
}
