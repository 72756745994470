/* new ace editor initialization starts */
import * as ace from 'ace-builds'; // ace module ..
// language package, choose your own 
// import 'ace-builds/src-noconflict/mode-tex';
import 'ace-builds/src-noconflict/mode-latex';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/ext-searchbox';

// ui-theme package
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-beautify';
import 'ace-builds/src-noconflict/ext-spellcheck';
const THEME = 'ace/theme/chrome';
// const LANG = 'ace/mode/tex';
const LANG = 'ace/mode/latex';
const xmlMode = 'ace/mode/xml';
/* new ace editor initialization ends */
import pdfjs from 'pdfjs-dist/build/pdf.js';
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "../shared/shared.service";
import { languageArray } from "../app.global";
import { NgxCoolDialogsService } from "ngx-cool-dialogs";
import { Router, ActivatedRoute } from "@angular/router";
import { EditorService } from "./editor.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Validators, FormBuilder, FormGroup, FormControl, ValidationErrors, ValidatorFn, AbstractControl, FormArray } from "@angular/forms";
import { JournalService } from "../journal/journal.service";
import { ArticleService } from "../article/article.service";
import { AngularDraggableDirective } from "angular2-draggable";
import { AutoRegexUtilities } from "../utils/AutoRegexUtilities";
import {
    GridComponent,
    SelectionSettingsModel,
    EditSettingsModel,
    SaveEventArgs,
    Column,
    EditEventArgs
} from '@syncfusion/ej2-angular-grids';
import { DataUtil } from '@syncfusion/ej2-data';

declare var require: any;
// declare var ColorSelector: any;
//declare var ace;
import {
    Component, OnInit, ViewChild, AfterViewInit,
    TemplateRef, HostListener, Renderer2, Inject, ElementRef,
    ViewEncapsulation, PipeTransform, Pipe, DoCheck,
    ɵConsole, Input, Output, EventEmitter, OnDestroy, OnChanges, ChangeDetectorRef, ApplicationRef, Query
} from '@angular/core';
import '../../../../public/assets/aceeditor/snippets/tex';
import '../../../../public/assets/aceeditor/snippets/text';
import '../../../../public/assets/aceeditor/snippets/html';
import '../../../../public/assets/aceeditor/snippets/latex';
import '../../../../public/assets/aceeditor/snippets/json';
import '../../../../public/assets/aceeditor/snippets/php';
import '../../../../public/assets/aceeditor/snippets/typescript';
import '../../../../public/assets/aceeditor/snippets/xml';
import '../../../../public/assets/aceeditor/snippets/snippets';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import { NotifierService } from 'angular-notifier';
import { idLocale } from 'ngx-bootstrap/chronos/i18n/id';
import { EnvService } from '../service/env.service';
import { DomSanitizer } from '@angular/platform-browser';
import { select } from '@syncfusion/ej2-base';
import { stringify } from 'querystring';
import { MatDialog, MatDialogConfig, MatDialogRef, MatOption, MAT_DIALOG_DATA, MatPaginatorModule, MatStepper, MatMenuTrigger, MatAutocompleteSelectedEvent } from '@angular/material';
import jsPDF from 'jspdf';
import { DOCUMENT } from '@angular/common';
import { element, textBinding, text } from '@angular/core/src/render3/instructions';
import snippet from '../../../../public/assets/aceeditor/snippets/tex';
import * as eol from 'eol';
import { TextAttribute } from '@angular/compiler/src/render3/r3_ast';
import { DataTableDirective } from 'angular-datatables';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { articleUploadsService } from '../articleupload/articleupload.service';
import { LoginService } from "../login/login.service";
import { SelectEventArgs } from '@syncfusion/ej2-navigations';
import {
    TabComponent,
    TreeViewComponent,
    BeforeOpenCloseMenuEventArgs,
    MenuEventArgs, MenuItemModel,
    ContextMenuComponent, DragAndDropEventArgs, NodeEditEventArgs,
    NodeClickEventArgs
} from '@syncfusion/ej2-angular-navigations';
import { EventHandlerVars } from "@angular/compiler/src/compiler_util/expression_converter";
import 'rxjs/add/operator/toPromise';
import { error } from "util";
import { ResizeEvent } from 'angular-resizable-element';
import { EmitType, detach, isNullOrUndefined, createElement, EventHandler } from '@syncfusion/ej2-base';
import { UploaderComponent, FileInfo, SelectedEventArgs, UploadingEventArgs, RemovingEventArgs } from '@syncfusion/ej2-angular-inputs';
import { NodeData } from "@angular/core/src/view";
declare var snippetCompleter: any;
let langTools = ace.require('ace/ext/language_tools');
var Autocomplete = ace.require("ace/autocomplete");
//prettify initialization
var PR = window["PR"];
const defaultDialogConfig = new MatDialogConfig();
import { Button } from '@syncfusion/ej2-angular-buttons';
// import { TourService, IStepOption } from 'ngx-tour-md-menu';
// import { TourService, IStepOption } from 'ngx-tour-ng-bootstrap';
// import { TourService, IStepOption } from 'ngx-tour-ngx-bootstrap';
import { OrderPipe } from 'ngx-order-pipe';
export class MenuOverviewExample { }
import { ToolbarItems, PageSettingsModel, PageService } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import ChromeTabs from "../../assets/js/chrome-tabs";
import { ResizedEvent } from 'angular-resize-event';
import * as AceCollabExt from '@convergencelabs/ace-collab-ext';
import { OnChange } from 'ngx-bootstrap/utils/decorators';
// uncomment
import { CollabService } from '../collaboration/collab.service';
// import { PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { ToolbarComponent } from '@syncfusion/ej2-angular-navigations';
import { ChatService } from '../collaboration/chat.service';
import { resource, post } from 'selenium-webdriver/http';
// import uiTour from 'angular-ui-tour';
import { interval, Subscription, from } from 'rxjs';
import { saveAs } from 'file-saver';
import { Subject } from "rxjs/Subject";
import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/distinctUntilChanged";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/mergeMap";
import { PackageService } from '../package/package.service';
import { NotesService } from "../notes/notes.service";
import { UserTokenService } from '../service/user-token.service';
import { ControlPanelService } from '../control-panel/control-panel.service';
import { async } from '@angular/core/testing';
import { JoyrideService } from 'ngx-joyride';
// import { unlink } from 'fs';
// import WebViewer from '@pdftron/pdfjs-express';
import WebViewer, { Annotations } from '@pdftron/webviewer';
import { Search } from './search.pipe';

interface IKeyValue { key: string, value: number };
// export interface User {
//     name: string;
//   }
import AceDiff from 'ace-diff';
import { map, startWith } from 'rxjs/operators';
import { Browser } from '@syncfusion/ej2-base';
import { EditService, ToolbarService, DialogEditEventArgs } from '@syncfusion/ej2-angular-grids';
import { Dialog } from '@syncfusion/ej2-angular-popups';

// optionally, include CSS, or use your own
// /var/www/html/rvlatex/rv_admin_panel/node_modules/ace-diff/dist
// import 'ace-diff.min.css';
// Or use the dark mode
// import 'ace-diff/dist/ace-diff-dark.min.css';

@Component({
    selector: 'app-editor',
    templateUrl: './editor.component.html',
    styleUrls: [
        './editor.component.scss',
        './ace-diff.min.css',
        "../../../node_modules/@syncfusion/ej2-base/styles/material.css",
        '../../../node_modules/@syncfusion/ej2-angular-popups/styles/material.css'
    ],
    encapsulation: ViewEncapsulation.None,
    providers: [PageService, Search, ToolbarService, EditService, PageService]
})

export class EditorComponent implements AfterViewInit, OnInit, DoCheck {
    public loginedUser = localStorage.getItem("current_logined_userId");
    @ViewChild('element') tabInstance: TabComponent;
    isReference: boolean;
    referenceOptions: { name: string; checked: any; }[];
    currentData: Object;
    datatype: string[];
    referenceOutput: any[];
    public select(e: SelectEventArgs) {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }
    subscription: Subscription;
    @ViewChild('editor') codeEditorElmRef: ElementRef;
    private editor: ace.Ace.Editor;

    private selectedCleaningReferenceType = null;
    public selectedOrder: any = [];

    public headerText: Object = [{ 'text': '<fa name="exclamation-circle"><i aria-hidden="true" class="fa fa-exclamation-circle"></i></fa> Errors', }, { 'text': '<fa name="exclamation-triangle"><i aria-hidden="true" class="fa fa-exclamation-triangle"></i></fa> Warnings' }, { 'text': '<fa name="exclamation"><i aria-hidden="true" class="fa fa-exclamation"></i></fa> Ref' }];
    is_page_loaded = false;
    public filterBtn: any;
    public editorBeautify;
    public zoomBtn: any;
    public data: Object[];
    public editSettings: Object;
    public toolbar: string[];
    public orderidrules: Object;
    public customeridrules: Object;
    public freightrules: Object;
    public pageSettings: Object;
    public editparams: Object;
    public searchText: string;
    public tableText: string;
    public service: string = 'https://ej2services.syncfusion.com/production/web-services/api/pdfviewer';
    public iconPath = 'assets/file-icons/'
    public document: string = 'PDF_Succinctly.pdf';
    dialogRef: MatDialogRef<JazzDialog> | null;
    lastAfterClosedResult: string;
    lastBeforeCloseResult: string;
    actionsAlignment: string;
    config;
    curMgr;
    selMgr;
    radarView;
    newUser = false;
    radarusers: number = 0;
    numTemplateOpens = 0;
    changeText: boolean;
    @ViewChild(TemplateRef) template: TemplateRef<any>;
    @ViewChild('stepper') stepper;
    height = 150;
    y = 100;
    selectedElement;
    oldY = 0;
    snippetss; snippets = '';
    checked; value;
    valuess; Snippet;
    grabber = false;
    isLinear = false;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    code;
    //for managing sidebar menus
    isExplorer: boolean = true;
    isSettings: boolean = false;
    selectednode: any = [];
    arrayvalue: any = [];
    values: any = [];
    matchedPatterns: Array<String> = [];
    UnMatchedPatterns: Array<String> = [];
    matched = false;
    unmatched = false;
    compileError = true;
    public languageArray = languageArray;
    public selectedName: any;
    public snippet_list: any = [];

    public snippetdata: any = [];
    zoom_to; snippetcontent;
    public selectedValue: any;
    errorData = [];
    refsData = [];
    boxesData = [];
    warningsData = [];
    annotationData=[];
    issueCount = 0;
    errorView = false;
    errorDiv = false;
    annotationDiv = false;
    generate_xml=false
    warningDiv = false;
    infoDiv = false;
    logDiv = false;
    errorText = [];
    warningText = [];
    boxText = [];
    refText = [];
    errorArray = [];
    annotationArray = [];
    errorArrayUnique = [];
    warnArrayUnique = [];
    infoArrayUnique = [];
    annotationArrayUnique = [];
    generateXmlArray=[];
    blockPatternArrayList = [];
    currentUserCollab;
    currentUserColor;
    userData = {};
    // pdfMain = true;
    // xmlEditor;
    hasError = false;
    hasAnotation = false
    hasGenerateXml=false;
    hasWarning = false;
    hasBox = false;
    hasRef = false;
    hasLog = false;
    errorDataLength = "(0)";
    warningsDataLength = "(0)";
    refsDataLength = "(0)";
    boxesDataLength = "(0)";
    annotationDatalength="(0)"
    activeConsoleTab;
    errorLines = [];
    anotationLines = [];
    anotationText = [];
    warningLines = [];
    refsLines = [];
    boxesLines = [];
    isDiff;
    TemplateArray = [];
    patternData = {};
    journalPatterns = [];
    current_count; total_count;
    LoadImage = false;
    LoadObj = false;
    tabImage = "";
    baseImagePath = this.env.baseUploadUrl + '/public/articles/article_files/';
    loading = false;
    page: number = 1;
    checkClicked = false;
    totalPages: number;
    isLoaded: boolean = false;
    userImageUrl = this.env.baseProfileImageUrl;
    userImageId: any;
    // imagerUrl= this.env.baseProfileImageUrl+this.userImageId;
    imagerUrl;
    currentUserName;
    textAreaClickCheck = false;
    textAreasList;
    isTex: boolean = true;
    p: number = 1;
    itemsPerPage = 4;
    noPdf = true;
    activeArticleData: any = null;
    ifValueFound: boolean = true;
    public currentJournalCode;
    patternFlag: Boolean = false;
    iconsHide: Boolean = false;
    pattern_filter_expand = false;
    journalSettingsPattern: any = [];
    Lineclick: Boolean = true;
    inputData = {};
    deleteEnabler = false;
    loadedCursors = [];
    selectType = new FormControl('');
    order: string = 'name';
    sortedCollection: any[];
    referenceTemplates: any = [];
    @ViewChild('suggestionModal') suggestionModal;

    @ViewChild('cleaningReferencePatternOrderModal') cleaningReferencePatternOrderModal;
    selectedCleaningReferencePatternOrderIndex;
    referenceOrders: any[];

    selectedSuggestionIndexes: number[] = [];
    autoMagicSuggestions: any[];
    newOrderData;

    pdfZoom: number;
    editorPdfZoom: number;
    public screenInnerWidth: any;
    asideWidth;
    navBarWidth;
    articleWidth
    byDateAsc: Boolean = false;
    byNameAsc: Boolean = false;

    validationRulesRequired = { required: true };

    maxWidth: Boolean = true;
    widthData = {};
    showBox = false;
    applyingChanges = false;
    // Mapping Tab items Header property
    articleSharePermission = false;
    chatPermission = false;
    AVAILABLE_ACTIONS = Object.freeze(
        {

        }
    )
    actionQueue = [];
    actionSubscriber: Subscription;
    privileges = JSON.parse(atob(localStorage.getItem('PRIVILEGES')));


    constructor(
        private _host: ElementRef,
        private render: Renderer2,
        public translate: TranslateService,
        public sharedService: SharedService,
        private coolDialogs: NgxCoolDialogsService,
        private router: Router,
        private editorService: EditorService,
        private modalService: BsModalService,
        private formBuilder: FormBuilder,
        private http: HttpClient,
        private notifier: NotifierService,
        private env: EnvService,
        private sanitizer: DomSanitizer,
        public route: ActivatedRoute,
        public dialog: MatDialog,
        private journalService: JournalService,
        private articleService: ArticleService,
        // @Inject(DOCUMENT) doc: any,
        private articleUploadsService: articleUploadsService,
        private loginService: LoginService,
        // public tourService: TourService,
        private orderPipe: OrderPipe,
        private cdr: ChangeDetectorRef,
        public appRef: ApplicationRef,
        private readonly joyrideService: JoyrideService,
        // uncomment
        public collabService: CollabService,
        public chatService: ChatService,
        public cPanelService: ControlPanelService,
        private packageService: PackageService,
        private notesService: NotesService,
        private usertoken: UserTokenService,
        private search: Search,
        @Inject(DOCUMENT) doc: any,


    ) {


        this.referenceOrderListForm = this.formBuilder.group({
            orderList: ['', Validators.required]
        });


        this.refrenceTypeForm = this.formBuilder.group({
            referencePatternType: ['', Validators.required]
        });

        this.referenceOptions = [
            { name: "Book", checked: false },
            { name: "InBoook", checked: false },
            { name: "Other", checked: false },
            { name: "Au-Aff", checked: false },
            { name: "Thesis", checked: false },
            { name: "Report", checked: false },
        ];

        this._defaultRotation = this._rotations[0];

        let postData = {
            id: this.route.snapshot.paramMap.get("id"),
            sharedId: this.route.snapshot.paramMap.get("sid")
        }

        this.articleService.hasCheckedOutSvs(postData).subscribe(res => {
            if (res['status'] == 200 && res['checkout'] == false && res['privilege'] == false) {
                this.router.navigate(['/dashboards'])
            }
        })

        this.chatService.connectChatServer();
        this.changeText = false;
        dialog.afterOpen.subscribe(() => {
            if (!doc.body.classList.contains("no-scroll")) {
                doc.body.classList.add("no-scroll");
            }
        });
        dialog.afterAllClosed.subscribe(() => {
            doc.body.classList.remove("no-scroll");
        });
        translate.addLangs(["gb", "fr", "es", "ae"]);
        const selectedLang = this.sharedService.getLanguage();

        if (selectedLang === undefined || selectedLang === "") {
            translate.setDefaultLang("gb");
            this.sharedService.setLanguage("gb");
            const browserLang = translate.getBrowserLang();
            //this.setupSidePanel();
            translate.use(browserLang.match(/gb|fr|es|ae/) ? browserLang : "gb");
        } else {
            translate.setDefaultLang(selectedLang);
            //this.setupSidePanel();
            translate.use(selectedLang.match(/gb|fr|es|ae/) ? selectedLang : "gb");
        }

        // this.tourService.initialize$.subscribe((steps: IStepOption[]) => {
        //     console.log('tour configured with these steps:', steps);
        // });          

        // this.tourService.end$.subscribe((event: any) => {
        //     // console.log('end event trigger', event);
        // });

        // this.tourService.stepShow$.subscribe((event: IStepOption) => {
        //     // console.log('step show', event);
        // });

        // this.tourService.stepHide$.subscribe((event: IStepOption) => {
        //     // console.log('step hide', event);
        //     // if(event.anchorId == 'generatexml') {
        //     //     this.tourService.pause();
        //     // }
        // });

        // this.tourService.pause$.subscribe((event: IStepOption) => {
        //     // console.log('Pause event', event);
        // });

        // this.tourService.resume$.subscribe((event: IStepOption) => {
        //     // console.log('Resume event', event);
        // })

        this.getArticleDetails();
    }




    @ViewChild('snippeteditor') snippeteditor;
    // @ViewChild('editor') editor;
    @ViewChild('xmlEditor') xmlEditorElmRef: ElementRef;
    private xmlEditor: ace.Ace.Editor;
    @ViewChild("editorPdfViewer") private editorPdfViewer;
    @ViewChild("pdfviewer") pdfviewer;
    @ViewChild('diffPdfViewer') diffPdfViewer;
    @ViewChild("comment_text_area") commentTextArea;
    dtOptions: any = {};

    public displayedColumns = ['name', 'type', 'score', 'delete'];
    public dataSource = new MatTableDataSource();
    public snippetTable = [];
    public pageSettingsTable: PageSettingsModel;
    public toolbarTable = [];
    public toolbarOptions: ToolbarItems[]
    @ViewChild(DataTableDirective)
    public text: any;
    public text1: any;
    public snippettext: any;
    public mode: any = "tex";
    public options: any;
    private paginator: MatPaginator;
    private sort: MatSort;
    title = "rvc-app";
    nodes = [];
    split = false;
    editorExpand = false;
    editorSecondExpand = false;
    file = false;
    view = false;
    help = false;
    searchBox = false;
    comment_list = [];
    modalRef: BsModalRef;
    // @ViewChild(MatSort) sort: MatSort;
    // @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
        this.setDataSourceAttributes();
    }

    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();
    }

    setDataSourceAttributes() {
        this.templateList.paginator = this.paginator;
        this.templateList.sort = this.sort;
    }
    // addSnippets: FormGroup = this.formBuilder.group({
    //     name: ["", [Validators.required]],
    //     content: ["", [Validators.required]],
    //     type: ["", [Validators.required]],
    //     score: ["", [Validators.required]],
    // });
    addSnippets = new FormGroup({
        name: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
        content: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
        type: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
        score: new FormControl('', [Validators.required]),

    });
    get snippetForm() {
        return this.addSnippets.controls;
    }
    editSnippet: FormGroup = this.formBuilder.group({
        name: ["", [Validators.required, this.noWhitespaceValidator]],
        content: ["", [Validators.required, this.noWhitespaceValidator]],
        type: ["", [Validators.required, this.noWhitespaceValidator]],
        score: ["", [Validators.required]],
        id: [""],
    });
    textarea: FormGroup = this.formBuilder.group({
        textareaInput: ["", [Validators.required]],

    });
    get editSnippetForm() {
        return this.editSnippet.controls;
    }
    savefile: FormGroup = this.formBuilder.group({
        name: ["", [Validators.required]],

    });
    modalData;
    datas;
    newfileBtnClk = false;
    nodevalue;
    new;
    nodevalues;
    xmlValue = false;
    recompileValue = false;
    rename = false;
    deleteNodeId;
    renameNodeId;
    nodeid;
    editorsvalue;
    tabvalue;
    articledata = [];
    orderData;
    compileStatus = false;
    src = "";
    compilevalue;
    modalFolderGridView = true;
    nodepath;
    pdf;
    name;
    compilevalues = false;
    fileUrl;
    newNodeId;
    fullpath;
    editorchanged;
    activeTab = -1;;
    errorCheck = false;
    marked;
    pdfUrl;
    xmlUrl;
    nodeId;
    line = true;
    snippet = true;
    highlight = true;
    readonly = false;
    tab = "2";
    theme;
    themer;
    fontSize = '';
    font;
    cssClassName;
    gutterLine = [];
    tempGutterIndex;
    gutterIcons = [];
    editorOption = {
        line: true,
        snippet: true,
        behaviours: true,
        highlight: true,
        readonly: false,
        wrapb: true,
        wrap: true,
        auto: false,
        tab: "2",
        theme: this.theme,
        mode: "tex",
        font: this.font,
    };
    themes = [
        "chrome",
        "clouds_midnight",
        "tomorrow",
        "clouds",
        "ambiance",
        "chaos",
        "cobalt",
        "crimson_editor",
        "dawn",
        "dracula",
        "dreamweaver",
        "eclipse",
        "github",
        "gob",
        "gruvbox",
        "idle_fingers",
        "monokai",
        "iplastic",
        "katzenmilch",
        "kr_theme",
        "kuroir",
        "merbivore"
    ];
    lightThemes = [
        "chrome", "tomorrow", "clouds", "crimson_editor", "dawn", "dreamweaver", "eclipse", "github", "iplastic", "katzenmilch", "kuroir",
    ];
    settingsEditor = []
    allJournalspatterns = [];
    journalShare: any;
    activeFiles = [];
    hostUrl: string;
    ajaxSettings: object;
    xmlData;
    xmlEditorOptions;
    snippetOptions;
    modes: any;
    downloadUrl = this.env.baseAPIUrl + '/download? url=  ';
    downloadFileUrl = this.env.baseAPIUrl + '/downloadPattern? url=  ';
    downloadUrlXml = this.env.baseAPIUrl + '/download-xml? id= ';
    treevalue = false;
    isComments = false;
    comment = new FormControl('', Validators.required);
    replyComment = new FormControl('', Validators.required);
    bookmarkForm = new FormControl('', Validators.required);
    textAreaNavCheck = false;
    commentRanges = [];
    markerId = [];
    commentList = [];
    editorComments = [];
    editorCommentIndex;
    resolvedComments;
    isCommentResolved;
    commentType = new FormControl('');
    commentIndex;
    replyBoxId;
    xmlCompileStatus: Boolean;
    userDetails; generatepdfurl; pageNumber = 1;
    xmldata; xmltext; pdfpage;
    editorEnabledFiles = ['png', 'tex', 'jpg', 'jpeg', 'tif', 'gif'];
    uploadType = 'file';
    userName; filedownloadUrl;
    settingsFocused: boolean;
    filterFocused: boolean;
    disableClose: boolean = false;
    allowedFiles = '';
    editorTabs = new ChromeTabs();
    errorTabs = new ChromeTabs();
    navScroll = false;
    editorScroll = false;
    undoStack = [];
    @ViewChild('maincomment') mainComment: ElementRef;
    @ViewChild('comment_in_editor') editorComment: ElementRef;
    @ViewChild('comment_icon') commentIcon: ElementRef;
    @ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef<any>;
    @ViewChild('asideDiv') asideDivver: ElementRef<any>;
    @ViewChild('articleTab') articleTab: ElementRef<any>;
    @ViewChild('navTab') navTab: ElementRef<any>;
    @ViewChild('comment_icon') comment_icon: ElementRef<any>;
    @ViewChild('addCommentBox') addCommentBox: ElementRef<any>;
    @ViewChild('bookmarkBox') bookmarkBox: ElementRef<any>;
    @ViewChild('save_pdf_form_button') save_pdf_form_button: ElementRef<any>;
    @ViewChild('save_pdf_form_box') save_pdf_form_box: ElementRef<any>;
    @ViewChild('comment_in_editor') comment_in_editor: ElementRef<any>;
    @ViewChild('chromeTabs') chromeTabs: ElementRef<any>;
    @ViewChild('rvcleanTab') rvCleanTab: ElementRef<any>;
    resizeStyle: {};
    isPdf: boolean = false;
    isXml: boolean = false;
    rightPannel: string;
    pdfCompiling: boolean = false;
    xmlCompiling: boolean = false;
    openAllowedFiles = ['tex', 'sty', 'pdf', 'gif', 'tif', 'png', 'jpeg', 'jpg', 'txt', 'bib', 'docx', 'doc', 'bbl'];
    editorFiles = ['tex', 'sty', 'txt', 'bib', 'docx', 'doc', 'bbl'];
    images = ['gif', 'tif', 'png', 'jpeg', 'jpg'];
    // public navTab: ElementRef<any>;
    // @ViewChild('navTab') set content(content: ElementRef) {
    //     this.navTab = content;
    //  }
    editorExpandNav: Boolean = false;
    redColorClick = false;
    mainCommenter = false;
    leftPanelTab = new ChromeTabs();
    @ViewChild('contents') contents: TemplateRef<any>;
    public chatServerUrl = this.env.realtimeServer;
    unreadMessages = [];
    guestUser = false;
    public misspelledSuggestion = [];
    backupDeletePopoverMsg = this.translate.instant('backup.delete confirmation') + "<br><small class='error'> * " + this.translate.instant('backup.Click Yes for download and delete') + "<br> * " + this.translate.instant('backup.No for delete') + "</small>";
    styleGuide;
    isStyleGuide = false;
    styleGuideZoom: number = 1.0;
    comparedPdfZoom: number = 1.0;

    /* 
     * generate a random color hex
     * @Author : Yadu Chandran
    */
    getRandomColor() {
        var color = Math.floor(0x1000000 * Math.random()).toString(16);
        return '#' + ('000000' + color).slice(-6);
    }
    /*
     * set cursor in ace editor
     * @Author Yadu Chandran
    */

    // uncomment
    setCursorAce(data) {
        //try catch : if id is already set
        // setting updated cursor with id
        try {
            this.curMgr._getCursor(data['cur_uname'].toLowerCase());
            this.curMgr.setCursor(data['cur_uname'].toLowerCase(), data['cur_position']);
        }
        catch (err) {
            this.curMgr.addCursor(data['cur_uname'].toLowerCase(), data['cur_uname'], data['cur_color'], data['cur_position']);
        }
    }

    /*
     * set selection in ace editor
     * @Author Yadu Chandran
    */

    // uncomment
    setSelectionAce(data) {
        //changing json range to Ace Range Format
        data['cur_ranges'] = AceCollabExt.AceRangeUtil.fromJson(data['cur_ranges']);
        //try catch : if id is already set
        // setting updated cursor with id
        try {
            this.selMgr._getSelection(data['cur_uname'].toLowerCase());
            this.selMgr.setSelection(data['cur_uname'].toLowerCase(), data['cur_ranges']);
        }
        catch (err) {
            this.selMgr.addSelection(data['cur_uname'].toLowerCase(), data['cur_uname'], data['cur_color'], data['cur_ranges']);
        }
    }

    /*
     * set text in ace editor
     * @Author Yadu Chandran
    */

    // uncomment
    setTextAce(data) {
        const pos = this.editor.getSession().getDocument().positionToIndex(data.start);
        this.applyingChanges = true;
        this.editor.getSession().getDocument().applyDelta(data);
        this.applyingChanges = false;
        // switch (data.action) {
        // case "insert":
        //     this.editor.getSession().getDocument().insert(pos, data.lines.join("\n"));
        //     break;
        // case "remove":
        //     textModel.remove(pos, data.lines.join("\n").length);
        //     break;
        // default:
        //     throw new Error("unknown action: " + data.action);
        // }
    }

    /* 
     * subscribe to new users connected
     * @Author : Yadu Chandran
    */

    // uncomment
    // add radar view or create radar view
    setRadarView(data) {

        try {

            this.radarView.hasView(data['cur_uname'].toLowerCase());
            this.radarView.setCursorRow(data['cur_uname'].toLowerCase(), data['cur_position']['row']);
        }
        catch (err) {

            const initialIndices = AceCollabExt.AceViewportUtil.getVisibleIndexRange(this.editor);
            const initialRows = AceCollabExt.AceViewportUtil.indicesToRows(this.editor, initialIndices.start, initialIndices.end);

            this.radarView.addView(data['cur_uname'].toLowerCase(), data['cur_uname'], data['cur_color'], initialRows, 0);
            this.newUser = true;
            this.radarusers = this.radarusers + 1;
        }
    }


    // uncomment
    // set view rows for radarview
    setRadarScroll(data) {

        try {
            this.radarView.setViewRows(data['radar_uname'].toLowerCase(), data['radar_rows']);
        }
        catch (err) {
            console.log(err);
        }
    }

    // uncomment
    clearRadarView(data) {

        try {

            if (this.editorCPanelOptions.collaboration == true || this.editorCPanelOptions.radar == true) {

                // Clear the remote cursor for "uid1" without removing it.
                this.curMgr.clearCursor(data['radar_uname'].toLowerCase());

                // Remove the remote cursor for "uid1".
                this.curMgr.removeCursor(data['radar_uname'].toLowerCase());

                // Nullify the selection without removing the marker.
                this.selMgr.clearSelection(data['radar_uname'].toLowerCase());

                // Remove the remote view indicator for "uid1".
                this.selMgr.removeSelection(data['radar_uname'].toLowerCase());
            }

            if (this.editorCPanelOptions.radar == true) {
                this.radarView.clearView(data['radar_uname'].toLowerCase());

                this.radarView.removeView(data['radar_uname'].toLowerCase());

                this.radarusers = this.radarusers - 1;

                if (this.radarusers == 0) {
                    this.newUser = false;
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    // uncomment
    getUsersConnected() {

        // initial row for setting up radar view
        const initialIndices = AceCollabExt.AceViewportUtil.getVisibleIndexRange(this.editor);
        const initialRows = AceCollabExt.AceViewportUtil.indicesToRows(this.editor, initialIndices.start, initialIndices.end);

        this.collabService.getUsers().subscribe((users: object) => {

            if (this.editorCPanelOptions.collaboration == true || this.editorCPanelOptions.radar == true) {
                this.curMgr.addCursor(users['user_name'].toLowerCase(), users['user_name'], users['color'], users['position']);
                this.selMgr.addSelection(users['user_name'].toLowerCase(), users['user_name'], users['color'], []);
                this.notifier.notify('success', users['user_name'] + ' has joined!!');
            }
            if (this.editorCPanelOptions.radar == true) {
                this.radarView.addView(users['user_name'].toLowerCase(), users['user_name'], users['color'], initialRows, 0);
                this.newUser = true;
                this.radarusers = this.radarusers + 1;
            }

        });
    }

    /* 
     * subscribe to updated cursor data
     * @Author : Yadu Chandran
    */

    // uncomment
    getUpdatedCursor() {
        this.collabService.getUpdatedCursor().subscribe((data: object) => {
            if (this.editorCPanelOptions.collaboration == true || this.editorCPanelOptions.radar == true) {
                this.setCursorAce(data);
            }
            if (this.editorCPanelOptions.radar == true) {
                this.setRadarView(data);
            }
        });

    }

    /* 
     * subscribe to updated cursor data
     * @Author : Yadu Chandran
    */

    // uncomment
    getUpdatedSelection() {

        if (this.editorCPanelOptions.collaboration == true || this.editorCPanelOptions.radar == true) {

            this.collabService.getUpdatedSelection().subscribe((data: object) => {
                this.setSelectionAce(data);
            });
        }

    }

    /* 
     * subscribe to updated scroll view data
    */

    // uncomment
    getUpdatedScroll() {
        if (this.editorCPanelOptions.radar == true) {
            this.collabService.getUpdatedScroll().subscribe((data: object) => {
                this.setRadarScroll(data);
            });
        }
    }

    /*
     * subscribe updated remove radar views 
     */

    // uncomment
    getUpdatedClosedRadar() {
        // if(this.editorCPanelOptions.radar == true) {
        this.collabService.getUpdatedClosedRadar().subscribe((data: object) => {
            this.clearRadarView(data);
        });
        // }    
    }

    /* 
     * subscribe to updated text data
     * @Author : Yadu Chandran
    */

    // uncomment
    getUpdatedText() {
        if (this.editorCPanelOptions.collaboration == true || this.editorCPanelOptions.radar == true) {
            this.collabService.getUpdatedText().subscribe((data: object) => {
                this.setTextAce(data);
            });
        }
    }


    /* 
     * send cursor,uname data to collab user
     * @Author : Yadu Chandran
    */

    // uncomment
    sendMyDataToCollabUser() {

        if (this.editorCPanelOptions.collaboration == true || this.editorCPanelOptions.radar == true) {
            this.userData = {};
            this.userData['user_name'] = this.currentUserCollab;
            this.currentUserColor = this.getRandomColor();
            this.userData['color'] = this.currentUserColor;
            this.userData['position'] = this.editor.getCursorPosition();
            this.collabService.iamConnected(this.userData);
        }

    }
    initializeActionExecutor() {
        const ACTIONS_INTERVAL = 50000, ACTIONS_TO_PICK_AT_ONCE = 4;
        this.actionSubscriber = interval(ACTIONS_INTERVAL).subscribe(() => {
            for (let i = 0; i < ACTIONS_TO_PICK_AT_ONCE; i++) {
                if (this.actionQueue.length == 0) {
                    return;
                }
                let currentAction = this.actionQueue.shift();
                switch (currentAction) {

                }
            }
        })
    }

    setupBackupNotification() {
        const backupNotification = interval(180000);
        this.subscription = backupNotification.subscribe(_val => {
            this.notifier.hide((this.prevNotifId - 1).toString());
            let backupNotifId = this.prevNotifId + 1
            this.notifier.notify(
                'warning',
                this.translate.instant('backup.take backup'),
                backupNotifId.toString());

            this.prevNotifId = Number(backupNotifId) + 1
        });
    }

    hasPermission(permission) {
        return JSON.parse(atob(localStorage.getItem('PERMISSIONS'))).includes(permission);

    }

    get isGuest() {
        return localStorage.getItem('guestUser') == 'true';
    }

    /* 
     * setting ace editor on ngOnInit
     * @Author : Yadu Chandran
    */
    setAceEditor() {
        // setting ace editor - latest version
        var element = this.codeEditorElmRef.nativeElement;
        var editorOptions: Partial<ace.Ace.EditorOptions> = {
            highlightActiveLine: true,
            autoScrollEditorIntoView:true
            // minLines: 10,
            // maxLines: Infinity,
        };
        this.editorBeautify = ace.require('ace/ext/beautify');
        this.editor = ace.edit(element, editorOptions);
        this.editor.getSession().setMode(LANG);
        // uncomment

        // // this.editor.setOption('spellcheck', true);
        this.editor.setOptions({ 'spellcheck': true });

        // // this.editor.setTheme(THEME);
        this.setThemeWhileReload();
        this.editor.getSession().setMode(LANG);
        this.editor.setShowFoldWidgets(true); // for the scope fold feature

        //get collab users connected
        this.getUsersConnected();
        //get updated cursor data
        this.getUpdatedCursor();
        // get updated scroll view data
        this.getUpdatedScroll();
        this.getUpdatedClosedRadar();
        //get updated selection data
        this.getUpdatedSelection();
        //get updated text data
        this.getUpdatedText();
        //notify collaborators; iam joined
        this.sendMyDataToCollabUser();

        if (this.editorCPanelOptions.collaboration == true || this.editorCPanelOptions.radar == true) {
            //sending my cursor change to other user //change cursor event
            this.editor.getSelection().on('changeCursor', (_data) => {
                var cursorData = {};
                cursorData['cur_position'] = this.editor.getCursorPosition();
                cursorData['cur_color'] = this.currentUserColor;
                cursorData['cur_uname'] = this.currentUserCollab;
                this.collabService.sendCursorData(cursorData);
            });
            //sending my selection change to other user //change editor selection event
            this.editor.getSelection().on('changeSelection', (_data) => {
                var rangesJson = AceCollabExt.AceRangeUtil.toJson(this.editor.selection.getAllRanges());
                var ranges = AceCollabExt.AceRangeUtil.fromJson(rangesJson);
                var selectionData = {};
                selectionData['cur_ranges'] = ranges;
                selectionData['cur_color'] = this.currentUserColor;
                selectionData['cur_uname'] = this.currentUserCollab;
                this.collabService.sendSelectionData(selectionData);
            });
        }

        if (this.editorCPanelOptions.radar == true) {

            // sending scroll view data to other user
            this.editor.getSession().on('changeScrollTop', (_scrollTop) => {

                const viewportIndices = AceCollabExt.AceViewportUtil.getVisibleIndexRange(this.editor);
                const rows = AceCollabExt.AceViewportUtil.indicesToRows(this.editor, viewportIndices.start, viewportIndices.end);

                const scrollData = {};
                scrollData['radar_uname'] = this.currentUserCollab;
                scrollData['radar_rows'] = rows;

                this.collabService.sendScrollData(scrollData);
            })
        }

        // uncomment
    }


    // uncomment
    closeRadarView() {
        // if(this.editorCPanelOptions.radar == true){
        const scrollData = {};
        scrollData['radar_uname'] = this.currentUserCollab;
        scrollData['cur_color'] = this.currentUserColor;
        this.collabService.sendClosedRadarData(scrollData);
        // }

    }

    // to beauty editor content
    beautifyContent() {
        if (this.editor && this.editorBeautify) {
            const session = this.editor.getSession();
            this.editorBeautify.beautify(session);
        }
    }

    // uncomment
    settingCollab() {
        if (this.editorCPanelOptions.collaboration == true || this.editorCPanelOptions.radar == true) {
            this.curMgr = new AceCollabExt.AceMultiCursorManager(this.editor.getSession());
            this.selMgr = new AceCollabExt.AceMultiSelectionManager(this.editor.getSession());
        }
        if (this.editorCPanelOptions.radar == true) {
            this.radarView = new AceCollabExt.AceRadarView('target-radar-view', this.editor);
        }
    }


    editorFileList = [];
    all_tex_files = [];
    editorFileListChild = [];
    texFileList: any = [];
    mainDocumentFileName;
    mainTexName;
    previousMainFileName;
    mainArticleName;
    zip_file;
    mainArticleId;
    // public editSettings: Object;

    // compilers
    compilers = ['latex', 'pdflatex', 'xelatex', 'lualatex'];
    compilerName;
    compilerEngine;

    setXmlEditor() {
        var element = this.xmlEditorElmRef.nativeElement;

        var editorOptions: Partial<ace.Ace.EditorOptions> = {
            highlightActiveLine: true,
            readOnly: true,
            showLineNumbers: true,
//autoScrollEditorIntoView:true
        };
        this.editorBeautify = ace.require('ace/ext/beautify');
        this.xmlEditor = ace.edit(element, editorOptions);

        console.log(element,editorOptions)
        this.xmlEditor.setTheme(THEME);
        this.xmlEditor.getSession().setMode(xmlMode);
        this.xmlEditor.setShowFoldWidgets(true); // for the scope fold feature
        this.xmlEditor.setOptions({
            readOnly: true,
            showLineNumbers: true,
            keyboardHandler: null,
            wrapBehavioursEnabled: true,
            autoScrollEditorIntoView: false,
            wrap: true,
            fontSize: '10pt'
        });
    }

    selectionUserList = [];
    selectedAll;
    activeUsersList = [];
    activeList = [];

    sharedUsers = [];
    shareArticleToUsers = [];

    shareTable: FormGroup = new FormGroup({
        checkboxes: new FormControl(''),
        mainCheckbox: new FormControl('')
    });

    realtimeNotifications = [];
    linking;
    journalPackage;
    chatUsersId = [];
    public compilingOptions
    public defaultCompilingOptions
    public prevNotifId: number = 0
    public autoSaveIn: any = null
    public timerPosition;
    public editorCPanelOptions;
    public shared
    public getFigureInfo = null


    public referencePatterns: Object[];
    private referenceInput: string;

    public toolbarTemplate: any;

    public sliderTemplate: any;

    public cleaningReferenceModalFieldData: Object[] = [];
    public cleaningReferenceModalFields: object;
    public state;
    @ViewChild('grid') public grid: GridComponent;
    async ngOnInit() {
        this.state = { projectIdValue: 0, localdata: [] };
        this.editSettings = {
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            mode: 'Dialog'
        };

        this.toolbar = [
            'Add',
            'Edit',
            'Delete',
        ];
        this.initialPage = { pageSize: 5 };
        this.editparams = { params: { popupHeight: '300px' } };
        // await this.getArticleDetails();
        this.sharedService.showSpinner('articleUpload');
        const backupNotification = interval(180000);
        this.subscription = backupNotification.subscribe(_val => {
            this.notifier.hide((this.prevNotifId - 1).toString());
            let backupNotifId = this.prevNotifId + 1

            this.notifier.notify(
                'warning',
                this.translate.instant('backup.take backup'),
                backupNotifId.toString());

            this.prevNotifId = Number(backupNotifId) + 1
        });

        // const cPaneldata =  await this.cPanelService.getCPanelDataSvs().toPromise();
        // this.editorCPanelOptions = cPaneldata['data']['cp_editor'];
        // this.articleSharePermission = cPaneldata['data']['cp_editor']['articleShare'];
        // this.chatPermission = cPaneldata['data']['cp_editor']['chat'];

        // uncomment
        this.currentUserCollab = this.randomDisplayName();

        if (!this.hasPermission('editor')) {
            this.router.navigate(["article-upload"]);
        } else {

            const id = this.route.snapshot.paramMap.get("id");
            const sharedId = this.route.snapshot.paramMap.get("sid");

            //get this article's journal data with package details
            this.editorService.getJournalPackageData(id).subscribe(response => {
                if (response.package_path_data) {
                    this.journalPackage = response.package_path_data.pkg_path;
                    this.compilingOptions = response.package_path_data.pkg_compiling_options.reverse()
                    this.defaultCompilingOptions = response.package_path_data.pkg_compiling_options.filter((elm) => elm['compileDefault'] === true);
                } else {
                    this.journalPackage = 'default/generateXML.sh';
                    this.compilingOptions = [{
                        compileParamName: "pdf",
                        compileKeyword: "pdf",
                        compileOutput: "pdf",
                    }];
                }
            });
            this.editorService.getFolderStructure({ id: [id], sharedId: sharedId }).subscribe(async response => {
                if (response.length < 1) {
                    this.router.navigate(["my-articles"]);
                } else {
                    // Get style guide for the journal
                    this.editorService.getStyleGuide({ journal: response[0]['journal'] }).subscribe(
                        response => {
                            if (response) {
                                this.styleGuide = this.env.baseUploadUrl + '/public/style guide/' + response.jnl_styleGuide_path;
                                this.rightPannel = 'styleGuide';
                                this.isStyleGuide = true;
                            }
                        }
                    );
                    this.chatService.connectChatServer();
                    // listner for chat
                    this.chatListner();
                    this.invitationListner();
                    // this.getJournalId();

                    this.mainDocumentFileName = response[0]['mainDocument'];
                    this.mainTexName = this.mainDocumentFileName.split('/').pop();
                    this.previousMainFileName = response[0]['mainDocument'];
                    this.mainArticleName = response[0]['name'];
                    this.zip_file = response[0]['article_file'];
                    this.mainArticleId = response[0]['code'],
                        this.editorFileList = response[0]['children'];

                    if (response[0]['shared'] != undefined) {
                        this.shared = response[0]['shared']
                    }

                    // get all the tex files with path ;
                    this.all_tex_files = await this.editorService.get_all_tex_files(this.zip_file).toPromise();

                    // let subject = new Subject();
                    // this.editorService.get_all_tex_files(this.zip_file).subscribe(
                    //         res =>{
                    //             this.all_tex_files = res;
                    //             subject.next();
                    //         },
                    //         err =>{
                    //             console.log(err);
                    //         }
                    // );

                    // todo: currenly collabroating user randomly generating. use below code to get correct name 
                    // const userData = await this.loginService.getProfile().toPromise();
                    // this.currentUserCollab = userData['user_data']['usr_first_name'] + '' + userData['user_data']['usr_last_name'];


                    this.loginService.getProfile().subscribe(
                        userDetails => {
                            this.userDetails = userDetails.user_data;
                            this.userImageId = userDetails.user_data.usr_image;
                            if (this.userImageId == '') {
                                this.imagerUrl = '';
                            } else {
                                this.imagerUrl = this.env.baseProfileImageUrl + this.userImageId;
                            }
                            const fname = userDetails.user_data.usr_first_name;
                            const lname = userDetails.user_data.usr_last_name;
                            this.currentUserName = fname + ' ' + lname;
                            // this.currentUserCollab = fname + ' ' + lname;
                            this.chatService.registerUser(userDetails.user_data._id);
                            this.chatService.getUnreadMessage({ receiver: this.userDetails._id }).subscribe(
                                response => {
                                    this.unreadMessages = response;
                                },
                                _error => { }
                            );
                            // this.socket.emit('register-user',{user_id:userDetails.user_data._id, tocken:localStorage.getItem('TOKEN')});
                            // var receiverData = {
                            //     sender: this.userDetails._id,
                            //     article: this.route.snapshot.paramMap.get("id")
                            // }
                            // this.editorService.getChatReceiver(receiverData).subscribe(
                            //     response => {
                            //         console.log(response);
                            //         this.chatUsersId = response.shared_user;
                            //     }, 
                            //     error => {}
                            // );
                            // this.getChatUsersList();
                            this.editorService.getAllUsers().subscribe(
                                response => {
                                    // this.receiverlist = response.data;
                                    this.activeUsersList = response.data;
                                    // this.activeUsersList = response.user_res;
                                    this.getUsersByArticle();
                                    // this.chatService.requestingOnlineUsers();
                                    // this.activeUsersList = response.shared;
                                    // this.receiverlist = response.shared;
                                    //   this.socket.emit('get-online-users');
                                    this.getChatUsersList();
                                },
                                _error => { }
                            );
                            // get notifications
                            this.getNotifications();

                        }
                    );



                    // .subscribe(
                    //     res => {
                    //         console.log(res);
                    //         this.editorCPanelOptions = res['data']['cp_editor'];
                    //         console.log(this.editorCPanelOptions);
                    //     },
                    //     err => {
                    //         console.log(err);
                    //     }
                    // )
                    //setting ace editor
                    this.setAceEditor();

                    // uncomment
                    //setting default for collaboration
                    this.settingCollab();

                    // uncomment
                    // latex editor on change function
                    this.editor.on('change', (data) => {
                        // console.log("change")
                        this.onChangeEditor(data);
                    });

                    // latex editor on change function
                    this.editor.on('change', _.debounce((_data) => {

                        //this.postArticleLastActiveEvent();
                        this.spellChecking();
                        // this.onChangeEditor(data);
                    }, 500));

                    // Debouncing scroll cheange for 0.5 second
                    this.editor.session.on("changeScrollTop", _.debounce(_event => {
                        this.spellChecking();
                    }, 500));

                    // Setting ace editor for xml
                    this.setXmlEditor();
                    //this.sharedService.showSpinner('articleUpload');
                    this.snippetList();
                    this.compileStatus = false;
                    this.editorsvalue = true;
                    let articles = JSON.parse(localStorage.getItem("articles"));
                    if (articles) {
                        if (!articles.includes(id)) {
                            articles.unshift(id);
                        }
                    } else {
                        articles = [id];
                        // files=[article_file]
                    }

                    localStorage.setItem("articles", JSON.stringify(articles));
                    const data = { id: articles };
                    //  console.log(data);

                    //   this.editorService.getFiles(data).subscribe(
                    //       response => {
                    //           console.log(response);
                    //       });
                    this.editorService.getFolderStructure(data).subscribe(
                        response => {
                            this.nodes = response;

                            this.hierarchicalData = [];
                            response.forEach((element, index) => {
                                this.hierarchicalData.push(this.getChildrens(element, index + 1));
                            });
                            // const f = this.replaceHtmlEntities();
                            this.field = { dataSource: this.hierarchicalData, id: 'id', text: 'name', child: 'children', iconCss: 'icon', code: 'code', htmlAttributes: 'hasAttribute', article_file: 'article_file' };

                            this.activeFiles = JSON.parse(localStorage.getItem("activeFiles"));
                            if (!this.activeFiles) {
                                this.activeFiles = [];
                            }
                            let mainIndex = -1;
                            const children = this.nodes[0].children;
                            if (children.length > 0) {

                                if (this.all_tex_files.length > 0) {

                                    const index = this.all_tex_files.findIndex(item => item.tex_file_name == this.mainDocumentFileName);

                                    // const f = this.replaceHtmlEntities(this.mainDocumentFileName);
                                    if (index != -1) {

                                        mainIndex = this.activeFiles.findIndex(
                                            item => item.path == this.mainDocumentFileName && item.id == this.nodes[0].code
                                        );
                                        if (mainIndex == -1) {
                                            this.activeFiles.push({
                                                id: this.nodes[0].code,
                                                path: this.mainDocumentFileName,
                                                commentPath: this.mainDocumentFileName
                                            });
                                            localStorage.setItem(
                                                "activeFiles",
                                                JSON.stringify(this.activeFiles)
                                            );
                                        }
                                    }

                                }

                            }
                            if (this.activeFiles.length > 0) {
                                this.editorService.getActiveFiles(this.activeFiles).subscribe(
                                    response => {
                                        if (response.length > 0) {
                                            response.forEach(element => {
                                                const names = element.path.split("/");
                                                const nodedata = {
                                                    id: Date.now(),
                                                    name: names[names.length - 1],
                                                    path: element.article + "/" + element.path,
                                                    real_path: element.path,
                                                    nodepath: element.path,
                                                    nodeid: element.id,
                                                    content: atob(element.content),
                                                    filetype: "tex",
                                                    saved: true,
                                                    savedContent: atob(element.content),
                                                    comments: element.comments,
                                                    commentPath: element.comment_path,
                                                    journal: element.journal,
                                                    cursorPosition: 0
                                                };
                                                this.selectednode.push(nodedata);
                                                this.addTab({ title: nodedata.name, id: nodedata.id, favicon: nodedata.filetype, tooltip: nodedata.path, nodeid: nodedata.nodeid, real_path: nodedata.real_path });
                                                // this.editorTabs.addTab({title: nodedata.name, id: nodedata.id});
                                            });
                                            if (mainIndex != -1) {
                                                this.activeTab = mainIndex;
                                                var currentTab = document.querySelector('div[data-tab-id="' + this.selectednode[this.activeTab].id + '"]');
                                                this.editorTabs.setCurrentTab(currentTab);
                                            } else {
                                                this.activeTab = response.length - 1;
                                                var currentTab = document.querySelector('div[data-tab-id="' + this.selectednode[this.activeTab].id + '"]');
                                                this.editorTabs.setCurrentTab(currentTab);
                                            }
                                            //Get the Current Positon
                                            var currentPosition = this.editor.selection.getCursor();
                                            //setting value to editor
                                            this.editor.setValue(this.selectednode[this.activeTab].content);
                                            this.editor.clearSelection();
                                            //Set the cursor to the Initial Point
                                            this.editor.gotoLine(currentPosition.row, currentPosition.column, true);
                                            //load remaining
                                            this.loadRemaining();
                                            this.highlightComments(this.selectednode[this.activeTab].comments);
                                            this.getBookmarks(this.selectednode[this.activeTab]);
                                            //this.recompile();
                                            // this.generate_xml_or_pdf('pdf');
                                            if (this.defaultCompilingOptions.length > 0) {
                                                this.generate_xml_or_pdf(
                                                    this.defaultCompilingOptions[0].compileKeyword,
                                                    this.defaultCompilingOptions[0].compileOutput
                                                );
                                            }
                                            setTimeout(() => {
                                                this.startInitialTour();
                                            }, 4000);
                                            // this.sharedService.hideSpinner('articleUpload');
                                        }
                                    },
                                    _error => {
                                        console.error();
                                    }
                                );
                            }
                        },
                        _error => {
                            console.log("Error");
                        }
                    );
                    this.activeFiles = JSON.parse(localStorage.getItem('activeFiles'));
                }
            });
        }
        this.screenInnerWidth = window.innerWidth;
        // this.setThemeWhileReload();
        this.compilerOptionAfterRelaod();
        this.pageSettingsTable = { pageSize: 6, pageSizes: true };
        this.toolbarTable = ['Add', 'Edit', 'Delete', 'Export'];
        this.toolbarOptions = ['Search'];

        //load notes here

        // setTimeout(() => {
        //     this.getNotes()
        // }, 1000);

        (<any>$('#colorselector')).colorselector({
            callback: function (value, _color, _title) {
                // console.log(value,color,title)
                // this.noteBgToSave = value
                // return
                $('#idNoteBgToSave').val(value)
            }
        });

        /**
         * author:vishnu
         * desc:service to get auto save settings value for the user
         */
        this.editorService.getAutoSaveSettingsSvs().subscribe(res => {
            if (res['data'] == null) {
                return
            }

            this.autoSaveSettingsData = res['data']

            // starting auto save timer
            if (this.autoSaveSettingsData &&
                Object.keys(this.autoSaveSettingsData).length &&
                this.autoSaveSettingsData.enable
            ) {
                this.startAutoSaveTimer(this.autoSaveSettingsData.interval)

                this.setTimerPosition()
            }
        })
        // this.filteredOptions = this.myControl.valueChanges
        // .pipe(
        //   startWith(''),
        //   map(value => typeof value === 'string' ? value : value.name),
        //   map(name => name ? this._filter(name) : this.referenceType.slice())
        // );
    }

    // displayFn(user: User): string {
    //     return user && user.name ? user.name : '';
    //   }

    //   private _filter(name: string): User[] {
    //     const filterValue = name.toLowerCase();

    //     return this.referenceType.filter(option => option.name.toLowerCase().includes(filterValue));
    //   }
    /**
     * Get all block code patterns for journal
     * @author Yadu Chandran
    */
    getBlockCodeJnlPatterns() {
        const article_id = this.route.snapshot.paramMap.get("id");
        this.journalService.getBlockCodeJnlPatterns({ article_id: article_id }).subscribe(
            response => {
                this.blockPatternArrayList = [];
                if (response['data'].length) {
                    response['data'].forEach((element, _index) => {
                        this.blockPatternArrayList.push({ id: element.tmplt_template, name: element.tmplt_name });
                        // this.blockPatternArrayList.push({ id: element.tmplt_template, text: element.tmplt_name });
                    });
                    // this.contextmenuEditor.removeItems(['Block Code']);
                    // this.contextmenuEditor.insertAfter([{ text: 'Block Code', iconCss: 'fa fa-code', items: this.blockPatternArrayList }], 'New comment');
                }
            },
            _error => { }
        );
    }
    // Trigger when destroying the component
    ngOnDestroy() {
        // Destroying the time interval for backup notification
        this.subscription.unsubscribe();
        if (this.activeAutoSaveTimer) {
            clearInterval(this.activeAutoSaveTimer);
        }
    }

    // replaceHtmlEntities(str) {
    //     return String(str).replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    // }
    scrollToPage(page: number) {
        this.pdfviewer.pdfViewer.scrollPageIntoView({
            pageNumber: page
        });
    }

    public scrollToTop() {
        this.scroll.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
    }

    public scrollToBottom() {
        this.scroll.nativeElement.scrollTo({ top: 200000000, behavior: 'smooth' });
    }

    compilerOptionAfterRelaod() {
        const compilerName = localStorage.getItem('compilerName');
        if (compilerName !== null) {
            this.compilerName = localStorage.getItem('compilerName');
        } else {
            this.compilerName = 'pdflatex'
        }
        const compilerEngine = localStorage.getItem('compilerEngine');
        if (compilerEngine !== null) {
            this.compilerEngine = localStorage.getItem('compilerEngine');
        } else {
            this.compilerEngine = 'pdftex'
        }
    }

    /** editor double click @synctex @forward-search */
    forwardSync() {
        if (this.activeTab != -1) {
            if (this.compiledNode == this.selectednode[this.activeTab].nodeid) {
                var selectionRange = this.editor.getSelectionRange();
                var startRow = selectionRange.start.row;
                var startColumn = selectionRange.start.column;
                var endColumn = selectionRange.end.column;
                var endRow = selectionRange.end.row;
                var content = this.editor.session.getTextRange(selectionRange);
                const path = this.selectednode[this.activeTab].nodepath;
                const nodedata = this.selectednode[this.activeTab].nodeid;
                /** when PDF is displayed  pdfMain variable used to check pdf displayed or not*/
                if (this.rightPannel == 'viewPdf') {
                    this.editor.selection.moveCursorToPosition({ row: startRow, column: 0 });
                    this.editor.selection.selectLine();
                    var selectedText = this.editor.getSelectedText();
                    this.editorService.forwardSync(startRow + 1, endColumn, nodedata, path).subscribe(datas => {
                        //remove any highlighted element at first if any
                        var elem = document.querySelectorAll('.overlay-div-synctex');
                        elem.length ? elem[0].parentNode.removeChild(elem[0]) : '';
                        //check if pdf window is there
                        if (this.pdfviewer) {
                            //looping over synctex output values
                            datas.forEach((each) => {
                                var pageno = parseInt(each['page']);
                                var pageView = this.pdfviewer.pdfViewer._pages[pageno - 1];
                                var left = parseInt(each['h']);
                                var top = parseInt(each['v']);
                                var width = parseInt(each['W']);
                                var height = parseInt(each['H']);
                                top = pageView.viewport.viewBox[3] - top;
                                var valueArray = [left, top, left + width, top + height];
                                let rect = pageView.viewport.convertToViewportRectangle(valueArray);
                                rect = pdfjs.Util.normalizeRect(rect);
                                var x = Math.min(rect[0], rect[2]), width = Math.abs(rect[0] - rect[2]);
                                var y = Math.min(rect[1], rect[3]), height = Math.abs(rect[1] - rect[3]);
                                const element = document.createElement('div');
                                element.setAttribute("class", "overlay-div-synctex");
                                element.style.left = x + 'px';
                                element.style.top = y + 'px';
                                element.style.width = width + 'px';
                                element.style.height = height + 'px';
                                element.style.position = 'absolute';
                                element.style.backgroundColor = 'rgba(255,255,0,0.5)';
                                document.querySelector('[data-page-number="' + pageno + '"]').appendChild(element);
                                this.pdfviewer.pdfViewer._scrollIntoView({
                                    pageDiv: pageView.div
                                });
                            });
                            setTimeout(() => {
                                document.querySelectorAll(".overlay-div-synctex").forEach(e => e.parentNode.removeChild(e));
                            }, 3000);
                        }
                    });
                }
                /** when XML is displayed */
                else if (this.rightPannel == 'viewXml') {
                    this.editor.selection.moveCursorToPosition({ row: startRow, column: startColumn + 30 });
                    var selectedText = this.editor.getSelectedText();
                    var range = this.xmlEditor.find(selectedText, {
                    });
                }
            }
        }
    }
    //Backword Sync
    backwardSync(event, Filetype) {
        if (Filetype == 'pdf') {

            const offset = $(event.target).closest('div').prev('.canvasWrapper').find('canvas').offset();
            var page_no = parseInt($(event.target).closest('div').prev('.canvasWrapper').parent('div.page').attr('data-page-number'));
            const dx = event.pageX - offset.left
            const dy = event.pageY - offset.top

            var pageView = this.pdfviewer.pdfViewer._pages[page_no - 1];
            const pdfPoint = pageView.viewport.convertToPdfPoint(dx, dy)
            var x = pdfPoint[0];
            var y = pageView.viewport.viewBox[3] - pdfPoint[1];
            const nodedata = this.selectednode[this.activeTab].nodeid;

            this.editorService.backwardSync(x, y, page_no, nodedata, this.mainDocumentFileName).subscribe(datas => {
                this.gotoAceLine(datas.line);
            });
        }
        //For Xml backword Sync
        else {
            var selectionRange = this.xmlEditor.getSelectionRange();
            var startRow = selectionRange.start.row;
            var startColumn = selectionRange.start.column;

            this.xmlEditor.selection.moveCursorToPosition({ row: startRow, column: startColumn + 10, keepDesiredColumn: true });
            var selectedText = this.xmlEditor.getSelectedText();
            var range = this.editor.find(selectedText, {
            });
        }

    }
    //load remianing events after editor value is set
    loadRemaining() {
        this.is_page_loaded = true;
        var __that = this;
        var commentAnchorVIsibility = true;
        var aceDocument = this.editor.session.getDocument();
        this.editor.setAutoScrollEditorIntoView(true);
        var commentAnchor = aceDocument.createAnchor(0, 0);
        commentAnchor.on("change", function (_e) {
            __that.updateCommentIcon(__that.editor.renderer.textToScreenCoordinates(commentAnchor.getPosition().row, commentAnchor.getPosition().column), commentAnchorVIsibility);
        });
        this.editor.session.selection.on('changeSelection', function (_e) {
            var range = __that.editor.getSelectionRange();
            var comment_icon_div = document.getElementById("comment_icon");
            if (!((range.start.row == range.end.row) && (range.start.column == range.end.column))) {
                commentAnchorVIsibility = true;
                commentAnchor.setPosition(range.end.row, range.end.column, true);
            } else {
                comment_icon_div.style.display = 'none';
                commentAnchorVIsibility = false;
            }
        });
        this.editor.session.on("changeScrollTop", function (_scrollTop) {
            __that.updateCommentIcon(__that.editor.renderer.textToScreenCoordinates(commentAnchor.getPosition().row, commentAnchor.getPosition().column), commentAnchorVIsibility);
        });

        this.editors(this.editorOption);
    }
    public noteTree: Object
    public allNotes = []
    public myNotes = []
    public checkedNodes = []
    async ngAfterViewInit() {
        const cPaneldata = await this.cPanelService.getCPanelDataSvs().toPromise();
        this.editorCPanelOptions = cPaneldata['data']['cp_editor'];
        this.articleSharePermission = cPaneldata['data']['cp_editor']['articleShare'];
        this.chatPermission = cPaneldata['data']['cp_editor']['chat'];

        //setting/initializing editor tabs dynamically
        var ChromeTabs: any = document.getElementsByClassName("editor-tabs");
        this.editorTabs.init(ChromeTabs[0]);
        this.setupSidePanel();
        this.sharedService.hideSpinner('articleUpload');
        // this.getBlockCodeJnlPatterns();

        if (this.route.snapshot.paramMap.get("sid") != null &&
            this.route.snapshot.paramMap.get("sharedId") != undefined
        ) {
            let postData = {
                art_ids: [this.selectednode[this.activeTab].nodeid]
            }
            this.sharedService.getDetailsForStageMoveSvs(postData).subscribe(response => {
                if (response.status === 200) {
                    this.activeArticleData = response.articles[0];
                }
            });
            this.fileDiff(this.route.snapshot.paramMap['params'])
        }

        if (
            (
                this.articleInfo['article_complete'] != undefined &&
                this.articleInfo['article_complete'] != null) ||
            (
                this.shared != undefined &&
                this.shared.share_done != undefined &&
                this.privileges.indexOf('super_admin') == -1
            )
        ) {
            this.editors(this.editorOption.readonly = true);
            this.notifier.notify('warning', this.translate.instant('articles.share warning readonly'))
        }

        if (
            this.privileges.includes('super_admin') &&
            this.shared != undefined &&
            this.shared.share_done == undefined
        ) {
            this.mergeShareConfPrefix = this.translate.instant("articles.merge share before save warning")
        }

        this.mergeShareConfSufix = this.translate.instant("articles.merge share conf suffix")
    }

    public mergeShareConfPrefix = null
    public mergeShareConfSufix = null
    public buildNoteTree(allNotes) {
        this.noteTree = {
            dataSource: [
                {
                    noteId: 'default',
                    noteTitle: this.translate.instant("notes.show default"),
                    expanded: true,
                    inNotes: []
                },
                {
                    noteId: 'all',
                    noteTitle: this.translate.instant("notes.show all"),
                    inNotes: []
                },
                {
                    noteId: 'byMe',
                    noteTitle: this.translate.instant("notes.added by me"),
                    inNotes: []
                }
            ],
            id: 'noteId', text: 'noteTitle', child: 'inNotes'
        }

        if (allNotes['status'] == 200) {
            if (allNotes['data']['default_notes'] != undefined &&
                Object.keys(allNotes['data']['default_notes']).length > 0
            ) {
                let notes = allNotes['data']['default_notes']
                this.notes = this.positioningNotes(notes, 0)

                if (Object.keys(notes).length > 0) {
                    this.checkedNodes = ['default']
                }

                Object.keys(notes).forEach((e) => {
                    this.noteTree['dataSource'][0]['inNotes'].push({ noteId: notes[e]['_id'], noteTitle: notes[e]['title'] })
                })
            }

            if (allNotes['data']['all_notes'] != undefined &&
                Object.keys(allNotes['data']['all_notes']).length > 0
            ) {
                let notes = allNotes['data']['all_notes']

                Object.keys(notes).forEach((e) => {
                    this.noteTree['dataSource'][1]['inNotes'].push({ noteId: notes[e]['_id'], noteTitle: notes[e]['title'] })

                    notes[e].position = { 'transform': 'translate3d(0px, 0px, 0px)' }
                    notes[e].show = false
                    this.allNotes.push(notes[e]);
                })
            }

            if (allNotes['data']['my_notes'] != undefined &&
                Object.keys(allNotes['data']['my_notes']).length > 0
            ) {
                let notes = allNotes['data']['my_notes']
                Object.keys(notes).forEach((e) => {
                    this.noteTree['dataSource'][2]['inNotes'].push({ noteId: notes[e]['_id'], noteTitle: notes[e]['title'] })

                    notes[e].position = { 'transform': 'translate3d(0px, 0px, 0px)' }
                    notes[e].show = false
                    this.myNotes.push(notes[e]);
                })
            }
        }
    }

    // Click event on ace editor editor. Displaying corresponding comment by clicking hilighted content.
    editorClick(_e) {
        this.textAreaClickCheck = false;
        this.hideCommentList();
        this.hideCommentBox();
        if (this.activeTab > -1) {
            if (this.editor.getValue().trim() != '') {
                var position = this.editor.selection.getCursor();
                this.commentList = this.selectednode[this.activeTab].comments;
                var targetCommentList = [];
                this.commentRanges.forEach(range => {
                    if (range.contains(position.row, position.column)) {
                        var text = this.editor.session.getTextRange(range);
                        range.length = text.length;
                        targetCommentList.push(range)
                    }
                });
                if (targetCommentList.length > 0) {
                    var targetComment = _.minBy(targetCommentList, 'length')
                    if (this.isCommentResolved == 'unresolved' && this.isComments) {
                        this.scrollToComment(targetComment.id);
                    } else {
                        this.redColorClick = true;
                        this.updateCommentListPosition(targetComment);
                    }
                    var activeFile = this.selectednode[this.activeTab].comments;
                    this.editorCommentIndex = activeFile.findIndex(comment => comment._id == targetComment.id);
                    this.editorComments = activeFile[this.editorCommentIndex];
                    this.comment_list = activeFile[this.editorCommentIndex].comment_list;
                }
            }

        }
    }
    // Scroll to target comment.
    scrollToComment(id) {
        this.replyBoxId = id;
        var target = document.getElementById(id + 'id');
        var divByClass = Array.from(document.querySelectorAll('.comment-box'));
        divByClass.forEach(element => {
            element.classList.remove('comment-box');
        });
        target.classList.add('comment-box');
        // target.scrollIntoView();
        target.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" });

    }

    // Click on comment history
    commentListClick(comment) {
        if (this.replyBoxId !== comment._id) {
            this.textAreaNavCheck = false;
            this.replyComment.reset();
        }
        // var Range = ace.acequire("ace/range").Range;
        // this.editor._editor.selection.setRange(new Range(comment.comment_range.start.row , comment.comment_range.start.column, comment.comment_range.end.row , comment.comment_range.end.column));
        this.editor.scrollToLine(comment.comment_range.start.row + 1, true, true, function () { });
        this.editor.gotoLine(comment.comment_range.start.row + 1, 0, true);
        // this.editor._editor.selection.setRange(comment.comment_range.start.row , comment.comment_range.start.column, comment.comment_range.end.row , comment.comment_range.end.column);
        // this.scrollToComment(comment._id);
    }
    textAreaNaver(event, comment) {
        var divByClass = document.querySelector('.comment-textarea-main');
        divByClass.setAttribute('style', 'margin-bottom:none');
        if (event.isTrusted == true && this.replyBoxId == comment._id) {
            this.textAreaNavCheck = true;
        }
    }

    // Updating the comment list position on editor.
    updateCommentListPosition(range) {
        // initial reset in textarea
        this.mainCommenter = true;
        this.replyComment.reset();

        // when click happen sent a value from component to directive
        const clickValue = { event: this.Lineclick }
        this.inputData = { ...clickValue }

        var position = this.editor.renderer.textToScreenCoordinates(range.end.row + 2, 0);
        var div = this.editorComment.nativeElement;
        if (div) {
            var editorPosition = document.getElementById('ace-outer-div').getBoundingClientRect();
            if (this.editorExpandNav == true && editorPosition.width < 410) {
                position.pageX = editorPosition.width - 200;
            } else {
                position.pageX = editorPosition.width - 400;
            }
            if (position.pageY + 380 >= editorPosition.bottom) {
                position.pageY = editorPosition.bottom - 400;
            }
            div.style.left = position.pageX + 'px';
            div.style.top = position.pageY + 'px';
            div.style.display = "block";
        }
    }

    // click event in comment icon.
    commentMarkerClick(_e) {
        this.comment.reset();
        var range = this.editor.getSelectionRange();
        var pixelPosition = this.editor.renderer.textToScreenCoordinates(range.end.row + 1, 0);
        this.updateCommentPosition(pixelPosition);
        // this.commentTextArea.nativeElement.focus();
    }

    // Updating the comment icon in ace editor while changing the selection
    updateCommentIcon(position, iconVisibility) {
        var div: any = this.commentIcon.nativeElement;//document.getElementById("overlay");
        var editorPosition = document.getElementById('latex_editor').getBoundingClientRect();
        if (iconVisibility) {
            if (position.pageY <= editorPosition.top) {
                div.style.display = 'none';
            } else if ((position.pageX + 30) >= editorPosition.right) {
                position.pageX = editorPosition.right;
            } else {
                div.style.left = position.pageX + 'px';
                div.style.top = position.pageY + 'px';
                div.style.display = 'block';
            }
        } else {
            div.style.display = 'none';
        }
    }

    // updating the comment box position
    updateCommentPosition(position) {
        var div = document.getElementById('tooltip_0');
        if (div) {
            if (position.pageY + 150 >= $(document).height())
                position.pageY = position.pageY - 180;
            position.pageX = position.pageX + 40;
            div.style.left = position.pageX + 'px';
            div.style.top = position.pageY + 'px';
            div.style.display = "block";
        }
    }

    @HostListener('document:click', ['$event']) DocumentClick(event: Event) {
        if (this.blockCodeElement) {
            if (!this.blockCodeElement.nativeElement.contains(event.target)) {
                this.enableBlockCode = false;
            }
        }

        if (this.preCleanElement) {
            if (!this.preCleanElement.nativeElement.contains(event.target)) {
                this.isShowPreCleanContextMenu = false;
            }
        }
        if (this.postCleanElement) {
            if (!this.postCleanElement.nativeElement.contains(event.target)) {
                this.isShowPostCleanContextMenu = false;
            }
        }
        let save_pdf_button = this.save_pdf_form_button;
        let check_pdf_button_element;

        if (save_pdf_button != undefined) {
            check_pdf_button_element = this.save_pdf_form_button.nativeElement.contains(event.target)
        } else {
            check_pdf_button_element = true
        }

        if (this.comment_icon.nativeElement.contains(event.target) ||
            this.addCommentBox.nativeElement.contains(event.target) ||
            this.bookmarkBox.nativeElement.contains(event.target) ||
            this.save_pdf_form_box.nativeElement.contains(event.target) || check_pdf_button_element) {
            // do nothing
        } else {
            this.hideCommentBox();
            this.closeBookmark();
            this.hide_save_pdf_box();
        }
    }

    // Hide the comment box.
    hideCommentBox() {
        var div = document.getElementById('tooltip_0');
        if (div) {
            div.style.display = "none";
        }
    }

    // Click on the cancel button in the comment box.
    cancelButton() {
        var div = document.getElementById('tooltip_0');
        div.style.display = "none";
    }

    addComment() {

        // var cursorPosition = this.editor.getCursorPosition();
        // var i = this.editor.getSelectionRange();
        // console.log(cursorPosition);
        // // Insert text (second argument) with given position
        // this.editor.session.addMarker(i,"highlightErrror","fullLine", true);

        // return;
        if (this.comment.valid && this.comment.value.trim() != '') {
            var div = document.getElementById('tooltip_0');
            div.style.display = "none";
            var comment_div: any = this.commentIcon.nativeElement;
            const messageContent = {
                article_id: this.selectednode[this.activeTab].nodeid,
                document: this.selectednode[this.activeTab].commentPath,
                user: this.userDetails._id,
                message: this.comment.value,
                selectionRange: this.editor.getSelectionRange(),
                image: this.userDetails.usr_image
            };
            this.editorService.addComment(messageContent).subscribe(
                response => {
                    if (response.status == 'success') {
                        comment_div.style.display = 'none';
                        response.content.comment_list[0].first_name = this.userDetails.usr_first_name;
                        response.content.comment_list[0].middle_name = this.userDetails.usr_middle_name;
                        response.content.comment_list[0].last_name = this.userDetails.usr_last_name;
                        this.selectednode[this.activeTab].comments.push(response.content);
                        var range = this.editor.getSelectionRange();
                        range['markerId'] = this.editor.session.addMarker(range, "highlightComment", 'text');
                        range['id'] = response.content._id;
                        range.start = <any>this.editor.session.getDocument().createAnchor(range.start.row, range.start.column);
                        range.end = <any>this.editor.session.getDocument().createAnchor(range.end.row, range.end.column);
                        this.editor.session.selection.clearSelection();
                        this.commentRanges.push(range);
                    }
                },
                _error => {
                    console.error();
                }
            );
        }
    }

    // Highlight the commented portion in editor. Remove all the existing marker. Create new marker & anchor.
    highlightComments(comments) {
        this.commentRanges.forEach(comments => {
            comments.start.detach();
            comments.end.detach();
            this.editor.session.removeMarker(comments.markerId);
        });
        this.commentRanges = [];
        var Range = ace.require("ace/range").Range;

        comments.forEach(element => {
            var old_range = element.comment_range;
            var range = new Range(old_range.start.row, old_range.start.column, old_range.end.row, old_range.end.column);
            range.markerId = this.editor.session.addMarker(range, "highlightComment", 'text');
            range.start = this.editor.session.getDocument().createAnchor(range.start.row, range.start.column);
            range.end = this.editor.session.getDocument().createAnchor(range.end.row, range.end.column);
            range.id = element._id;
            this.commentRanges.push(range);
        });
    }

    // Update the comment details of the currently opened files.
    storeComments() {
        this.selectednode[this.activeTab].comments.forEach((comment, index) => {
            let range = this.commentRanges[index];
            comment.comment_range = {
                start: {
                    row: range.start.row,
                    column: range.start.column
                },
                end: {
                    row: range.end.row,
                    column: range.end.column
                }
            }
        });
    }

    // Reply mesage
    replyMessage(comment, index) {
        if (this.replyComment.valid && this.replyComment.value.trim() != '') {
            const replyData = {
                comment: comment._id,
                user: this.userDetails._id,
                message: this.replyComment.value,
                image: this.userDetails.usr_image
            };
            this.editorService.replyMessage(replyData).subscribe(
                response => {
                    if (response.status == 'success') {
                        response.content.first_name = this.userDetails.usr_first_name;
                        response.content.middle_name = this.userDetails.usr_middle_name;
                        response.content.last_name = this.userDetails.usr_last_name;
                        this.commentList[index].comment_list.push(response.content);
                        this.selectednode[this.activeTab].comments = this.commentList;
                        this.replyComment.reset();
                    }
                },
                _error => {
                    console.error();
                }
            );
        }
    }

    // Resolve the comment
    resolveComment(comment, index) {
        this.textAreaClickCheck = false;
        const commentDetails = { id: comment._id };
        this.editorService.resolveComment(commentDetails).subscribe(
            response => {
                if (response.status = 'success') {
                    this.selectednode[this.activeTab].comments.splice(index, 1);
                    this.commentList = this.selectednode[this.activeTab].comments;
                    this.highlightComments(this.commentList);
                    this.hideCommentList();
                    this.getResolvedComments();
                }
            },
            _error => { }
        );
    }

    // delete resolved comment
    deleteCoemment(comment, _index) {
        this.deleteEnabler = true;
        const commentId = { id: comment._id };
        this.editorService.deleteComment(commentId).subscribe(
            _res => {
                this.getResolvedComments();
                this.deleteEnabler = false;
            },
            err => {
                console.log(err);
            }
        )
    }

    // Hide the comment list in the editor
    hideCommentList() {
        this.textAreaClickCheck = false;
        this.mainCommenter = false;
        var comment_div = document.getElementById('comment_in_editor');
        comment_div.style.display = 'none';
    }
    // Switch to comment history in side bar
    switchComments(explorer, settings, comments) {
        this.isBackupHistory = false;
        this.isChat = false;
        this.isBookmarks = false;
        this.replyComment.reset();
        this.isExplorer = explorer;
        this.isSettings = settings;
        this.isComments = comments;
        this.isSnippets = false
        this.isReference = false
        this.isAutosaveSettings = false
        this.isNotes = false
        this.isPdfAnnotation = false
        if (this.activeTab > -1) {
            this.commentType.setValue('unresolved');
            this.commentStatusChange();
            this.commentList = this.selectednode[this.activeTab].comments;
        } else
            this.commentList = [];
        this.hideCommentList();
    }

    editors(editorOption) {
        this.editor.renderer.setAnimatedScroll(true);
        //this.Collapse();
        this.editor.setOptions({
            enableBasicAutocompletion: editorOption.snippet,
            highlightActiveLine: editorOption.highlight,
            showLineNumbers: editorOption.line,
            enableSnippets: editorOption.snippet,
            enableLiveAutocompletion: editorOption.snippet,
            behavioursEnabled: editorOption.behaviours,
            wrapBehavioursEnabled: editorOption.wrapb,
            autoScrollEditorIntoView: editorOption.auto,
            wrap: editorOption.wrap,
            // fontSize: editorOption.font + 'pt',
            fontSize: this.font + 'pt',
            readOnly: editorOption.readonly,

        });
        //this.editor._editor.$blockScrolling = Infinity;
        this.editor.setShowPrintMargin(false);
        let that = this;
        this.editor.commands.addCommand({
            name: "saveArticle",
            bindKey: "\\",
            exec: function (_e) {
                that.editor.session.insert(that.editor.getCursorPosition(), '\\');
                that.editor.execCommand("startAutocomplete");
            }
        });
        this.editor.commands.addCommand({
            name: "example",
            bindKey: "Ctrl-d",
            exec: function (_e) {
                var position = that.editor.selection.getCursor();
                var text = that.editor.session.getLine(position.row);
                if (text == '') {
                    var position = that.editor.selection.getCursor();
                    var text = that.editor.session.getLine(position.row - 1);
                    that.editor.session.insert(position, text);
                } else {
                    // this.editor._editor.session.setOptions({
                    //     newLineMode: 'auto'
                    // });
                    var position = that.editor.selection.getCursor();
                    console.log(position.row + 1);
                    var text = that.editor.session.getLine(position.row);
                    that.editor.session.insert(position, text);

                }
                that.editor.session.setNewLineMode("unix");
            }
        });
        this.editor.commands.addCommand({
            name: "example-demo",
            bindKey: "Ctrl-b",
            exec: function (_e) {
                var positionArray = [];
                var position = that.editor.selection.getCursor();
                var positionrow = position.row;
                var text = that.editor.session.getLine(position.row);
                positionArray.push(position);
                console.log(positionArray);
                // this.editor._editor.scrollToLine(position.row);

            }
        });
        this.editor.commands.addCommand({
            name: "pre_clean",
            bindKey: "alt-d",
            exec: (_editor) => {
                this.preCleanSearchText = '';

                this.populateReferencePopupMenu();
                this.isShowPreCleanContextMenu = !this.isShowPreCleanContextMenu;
                setTimeout(() => this.preCleanSearchBoxElement.nativeElement.focus(), 0);
            }
        });
        this.editor.commands.addCommand({
            name: "post_clean",
            bindKey: "alt-r",
            exec: (_editor) => {
                this.postCleanSearchText = '';
                this.setPostClean()
                //this.populateReferencePopupMenu();
                this.isShowPostCleanContextMenu = !this.isShowPostCleanContextMenu;
                setTimeout(() => this.postCleanSearchBoxElement.nativeElement.focus(), 0);
            }
        });
        this.editor.commands.addCommand({
            name: "search",
            bindKey: "Ctrl+f",
            exec: function (_e) {
                that.findLastMatchSearch();
            }
        })
        this.editor.commands.addCommand({
            name: "blockCode",
            bindKey: 'alt+b',
            exec: (_e) => {
                this.blockCodeSearch = '';
                this.getBlockCodeJnlPatterns();
                this.enableBlockCode = !this.enableBlockCode;
                setTimeout(() => this.blockCodeSearchBox.nativeElement.focus(), 0);
            }
        });

    }

    saveValue(datas) {
        this.notifier.notify(datas.type, datas.message);
        this.selectednode[this.activeTab].savedContent = this.editor.getValue();
        this.selectednode[this.activeTab].saved = true;
        var currentTab: any = document.querySelector('div[data-tab-id="' + this.selectednode[this.activeTab].id + '"] .chrome-tab-title');
        currentTab.classList.remove('unsaved');
    }
    //Keydown event for ace editor
    // save functionality
    saveFile(data) {
        if (data.ctrlKey && data.keyCode == 83) {
            data.preventDefault();
            this.saveTexFileAndCompile()
        }
        if (data.shiftKey && data.keyCode == 69) {
            console.log("Error");
        }
    }

    // save tex file before compile 
    saveTexFile() {
        this.selectednode[this.activeTab].comments.forEach((comment, index) => {
            var findComment = this.commentRanges.find(item => item.id == comment._id);
            this.selectednode[this.activeTab].comments[index].comment_range = {
                start: { row: findComment.start.row, column: findComment.start.column },
                end: { row: findComment.end.row, column: findComment.end.column }
            }
        });
        this.editorService
            .saveArticle(
                this.editor.getValue(),
                this.selectednode[this.activeTab].name,
                this.selectednode[this.activeTab].path,
                this.selectednode[this.activeTab].nodeid,
                this.selectednode[this.activeTab].nodepath,
                this.selectednode[this.activeTab].comments
            )
            .subscribe(datas => {
                this.saveValue(datas);
            });
    }

    saveTexFileAndCompile() {
        if (
            this.privileges.indexOf('super_admin') == -1 &&
            this.shared != undefined &&
            this.shared.share_done != undefined
        ) {
            this.notifier.notify('warning', this.translate.instant('articles.share save complete warning'))
            return
        }

        this.selectednode[this.activeTab].comments.forEach((comment, index) => {
            var findComment = this.commentRanges.find(item => item.id == comment._id);
            this.selectednode[this.activeTab].comments[index].comment_range = {
                start: { row: findComment.start.row, column: findComment.start.column },
                end: { row: findComment.end.row, column: findComment.end.column }
            }
        });
        this.editorService
            .saveArticle(
                this.editor.getValue(),
                this.selectednode[this.activeTab].name,
                this.selectednode[this.activeTab].path,
                this.selectednode[this.activeTab].nodeid,
                this.selectednode[this.activeTab].nodepath,
                this.selectednode[this.activeTab].comments
            )
            .subscribe(datas => {
                //this.recompile();
                // this.generate_xml_or_pdf('pdf');
                if (this.defaultCompilingOptions.length > 0) {
                    this.generate_xml_or_pdf(
                        this.defaultCompilingOptions[0].compileKeyword,
                        this.defaultCompilingOptions[0].compileOutput
                    );
                }
                this.saveValue(datas);
            });
    }

    closeSecondEditor() {
        this.editorsvalue = false;
    }
    // function for switching settings tab in sidebar */
    switchSettings() {
        this.texFileList = [];
        this.editorFileListChild = [];
        this.editorFileList.forEach(obj => {
            this.editorFileListChild.push(obj.name);
        });
        this.all_tex_files.forEach(elem => {
            this.texFileList.push(elem.tex_file_name);
        });
        // this.isSettings = true;
        // this.isExplorer = false;
        // this.isDiff = false;
        // this.isComments = false;
        // this.hideCommentList();
        // this.isChat = false;
        // this.isBackupHistory = false;
        // this.isBookmarks = false;
    }
    // function for switching explorer tab in sidebar */
    switchExplorer() {
        this.isExplorer = true;
        this.isSettings = false;
        this.isDiff = false;
        this.isComments = false;
        this.isChat = false;
        this.isBackupHistory = false;
        this.isBookmarks = false;
        this.isSnippets = false;
        this.isReference = false;
        this.isAutosaveSettings = false
        this.isNotes = false
        this.isPdfAnnotation = false
        this.hideCommentList()
    }
    switchDiff() {
        this.isDiff = true;
        this.isExplorer = false;
        this.isSettings = false;
        this.isComments = false;
        this.isChat = false;
        this.isBackupHistory = false;
        this.isBookmarks = false;
        this.isReference = false;
    }
    // function open new window
    openwindow() {
        window.open("../../assets/example.pdf", "_blank");
    }
    // function for close window
    closewindow() {
        this.disableClose = true;
        this.config = {
            disableClose: false,
            panelClass: "custom-overlay-pane-class",
            hasBackdrop: false,
            backdropClass: "",
            width: "20",
            height: "",
            minWidth: "",
            minHeight: "",
            maxWidth: defaultDialogConfig.maxWidth,
            maxHeight: "",
            color: "grey",
            position: {
                top: "",
                bottom: "",
                left: "",
                right: ""
            },
            data: {
                message: this.translate.instant("editor.editorExitConfirmation"),
                no: this.translate.instant("editor.cancel"),
                yes: this.translate.instant("editor.ok")
            }
        };
        this.dialogRef = this.dialog.open(JazzDialog, this.config);
        this.dialogRef.beforeClose().subscribe((result: string) => {
            this.lastBeforeCloseResult = result;
            localStorage.removeItem("articles");
            localStorage.removeItem("activeFiles");
            if (result === this.translate.instant("editor.ok")) {
                localStorage.removeItem("articles");
                localStorage.removeItem("activeFiles");
                this.router.navigate(["my-articles"]);
                this.chatService.logout();

                // uncomment
                this.closeRadarView();
            }
        });
        this.dialogRef.afterClosed().subscribe((result: string) => {
            this.disableClose = false;
            this.lastAfterClosedResult = result;
            if (result === this.translate.instant("editor.ok")) {
                localStorage.removeItem("articles");
                localStorage.removeItem("activeFiles");
                this.router.navigate(["my-articles"]);
            }
            this.dialogRef = null;
        });
    }
    // function for language
    public changeLanguage(lan) {
        this.translate.use(lan);
        //Render the context menu with target as Treeview
        this.translate.onLangChange.subscribe((_params) => {
            this.menuItems = [
                { text: this.translate.instant("editor.newFolder"), iconCss: 'fa fa-folder-o' },
                { text: this.translate.instant("editor.newFile"), iconCss: 'fa fa-file-o' },
                { text: this.translate.instant("editor.rename"), iconCss: 'fa fa-pencil-square-o' },
                { text: this.translate.instant("editor.Close"), iconCss: 'fa fa-times' },
                { text: this.translate.instant("editor.delete"), iconCss: 'fa fa-trash-o' },
                { text: this.translate.instant('editor.uploadFile'), iconCss: 'fa fa-upload' },
                { text: this.translate.instant("rvclean.replace"), iconCss: "fa fa-refresh" },
                { text: this.translate.instant("editor.extract here"), iconCss: "fa fa-file-archive-o" }
            ];
        });
        this.sharedService.setLanguage(lan);
        if (lan === "arabic") {
            this.checked = false;
        }
        this.backupDeletePopoverMsg = this.translate.instant('backup.delete confirmation') + "<br><small class='error'> * " + this.translate.instant('backup.Click Yes for download and delete') + "<br> * " + this.translate.instant('backup.No for delete') + "</small>";
    }

    // To open the modal
    openModal(template: TemplateRef<any>) {
        this.hideCommentList();
        // this.newfileBtnClk = false;
        this.modalRef = this.modalService.show(template, {
            animated: true,
            keyboard: true,
            backdrop: true,
            ignoreBackdropClick: true,

        });
    }

    // function for filtering tree
    // filterFn(value: string, treeModel: TreeModel) {
    //   treeModel.filterNodes((node: TreeNode) =>
    //     this.fuzzysearch(value, node.data.name)
    //   );
    // }
    // function for search
    fuzzysearch(needle: string, haystack: string) {
        const haystackLC = haystack.toLowerCase();
        const needleLC = needle.toLowerCase();
        const hlen = haystack.length;
        const nlen = needleLC.length;
        if (nlen > hlen) {
            return false;
        }
        if (nlen === hlen) {
            return needleLC === haystackLC;
        }
        outer: for (let i = 0, j = 0; i < nlen; i++) {
            const nch = needleLC.charCodeAt(i);

            while (j < hlen) {
                if (haystackLC.charCodeAt(j++) === nch) {
                    continue outer;
                }
            }
            return false;
        }
        return true;
    }

    // function for deleting node
    deleteNode(targetNodeId, targetNode) {
        this.coolDialogs
            .confirm(this.translate.instant("editor.confirmFelete"), {
                okButtonText: this.translate.instant("editor.delete"),
                cancelButtonText: this.translate.instant("editor.cancel"),
                title: this.translate.instant("editor.delete")
            })
            .subscribe(res => {
                if (res) {
                    var nodeDetails = this.getFileDetails(targetNodeId);
                    let node = this.treevalidate.getNode(targetNodeId);
                    this.closeActivetabByDelete(targetNode);
                    let commentsPath = this.getLeafNodesComment(targetNode, []);
                    this.treevalidate.removeNodes([targetNodeId]);
                    this.treevalidate.getTreeData(node.parentID.toString())[0].hasChildren = true;
                    let children = this.treevalidate.getTreeData(nodeDetails.id.toString())[0].children;
                    var data = {
                        articleFolder: nodeDetails.articleFolder,
                        id: nodeDetails.code,
                        children: children,
                        path: nodeDetails.path,
                        commentPath: commentsPath
                    };
                    console.log(data)
                    this.editorService.deleteNode(data).subscribe(
                        response => {
                            if (response.status != "success") {
                                this.notifier.notify(
                                    response.message,
                                    this.translate.instant("editor." + response.message)
                                );
                            } else {
                                this.hierarchicalData = this.updateTreeData(this.hierarchicalData, targetNodeId, '', 'delete');
                            }
                        },
                        _error => {
                            console.log("Error");
                        }
                    );
                }
            });
    }

    otherArticle = false;
    // function for getting tab data
    getTabData(nodename, i) {

        // make tex as read only if file from other nodes
        if (nodename.nodeid != this.mainArticleId) {
            this.editor.setOptions({
                readOnly: true
            });
            this.otherArticle = true;
        } else {
            this.editor.setOptions({
                readOnly: false
            });
            this.otherArticle = false;
        }
        this.commentIndex = 0;
        this.replyBoxId = 0;
        if (this.activeTab != -1) {
            const current_file = this.selectednode[this.activeTab];
            if (current_file.filetype != "image" && current_file.filetype != "pdf") {
                this.storeComments();
                this.selectednode[this.activeTab].cursorPosition = this.editor.getCursorPosition().row + 1;
            }
        }
        this.activeTab = i;
        if (this.commentType.value == 'resolved')
            this.getResolvedComments();
        if (nodename.filetype == "image" || nodename.filetype == "pdf") {
            this.isTex = false;
            this.hideCommentBox();
            this.hideCommentList();
            if (nodename.filetype == "image") {
                this.LoadImage = true;
                this.LoadObj = false;
            }
            else {
                this.LoadImage = false;
                this.LoadObj = true;
                this.editorPage = nodename.cursorPosition;
                if (this.editorPdfViewer) {
                    var pageView = this.editorPdfViewer.pdfViewer._pages[nodename.cursorPosition - 1];
                    this.editorPdfViewer.pdfViewer._scrollIntoView({
                        pageDiv: pageView.div
                    });
                }
            }
            this.tabImage = nodename.imagepath;
        }
        else if (nodename.filetype == "tex") {
            this.LoadImage = false;
            this.LoadObj = false;
            this.isTex = true;
            localStorage.setItem("name", JSON.stringify(nodename.name));
            this.editor.setValue(nodename.content);
            this.highlightComments(nodename.comments);
            this.getBookmarks(this.selectednode[this.activeTab]);
            this.commentList = nodename.comments;
            this.hideCommentList();
            if ((nodename.nodeid == this.compiledNode) && (nodename.nodepath == this.mainDocumentFileName)) {
                this.editor.session.setAnnotations(this.annotationArray);
            }
            this.editor.selection.moveCursorToPosition({ row: nodename.cursorPosition - 1, column: 0 });
            this.editor.gotoLine(nodename.cursorPosition, 0, true);
        }
    }

    /**
     * Triggers when clicking on pdf opened on editor
     * @author Arathy S V
     */
    pdfEditorClick(event) {
        // currently clicked page number
        this.editorPage = parseInt($(event.target).closest('div').prev('.canvasWrapper').parent('div.page').attr('data-page-number'));
        // store the page number to corresponding file record
        this.selectednode[this.activeTab].cursorPosition = this.editorPage;
    }

    editorPage
    // function for closing tab value
    closetab(nodename, currentTab) {
        if (!nodename.saved) {
            this.coolDialogs
                .confirm(this.translate.instant("volume.close_volume_label"), {
                    theme: "default",
                    okButtonText: this.translate.instant("Yes"),
                    cancelButtonText: this.translate.instant("No"),
                    color: "light blue",
                    title: this.translate.instant("confirm")
                })
                .subscribe(res => {
                    if (res) {
                        this.editorTabs.removeTab(currentTab);
                        this.closeActiveTab(nodename);
                    }
                });
        } else {
            this.editorTabs.removeTab(currentTab);
            this.closeActiveTab(nodename);
        }
    }
    // function for getting all article values
    getAllArticleData() {
        let articles = JSON.parse(localStorage.getItem("articles"));
        const articleid = { id: articles };
        this.editorService.getAllArticle(articleid).subscribe(datas => {
            if (datas) {
                this.articledata = datas["data"];
                this.orderData = datas['data'];
                // this.newOrderData = datas["data"];
            } else {
                this.editor.setValue('');
            }
        });
    }

    selectOption(_id) {
        const value = this.selectType.value;
        if (value == "byNameDesc") {
            // this.byNameAsc = true;
            this.articledata = this.orderPipe.transform(this.articledata, 'article_name', true, true);
        }
        if (value == "byNameAsc") {
            // this.byNameAsc = false;
            this.articledata = this.orderPipe.transform(this.articledata, 'article_name', false, true);
        }
        if (value == "byDateAsc") {
            // this.byDateAsc = false;
            this.articledata = this.orderPipe.transform(this.orderData, 'article_last_update', false, true);
        }
        if (value == "byDateDesc") {
            // this.byDateAsc = true;
            this.articledata = this.orderPipe.transform(this.orderData, 'article_last_update', true, true);
        }
    }

    // function for highlighting row
    public highlightRow(article) {
        this.selectedName = article;
        this.selectedValue = {
            name: article.article_name,
            code: article._id,
            children: article.article_children,
            hasChildren: true,
            article_file: article.article_file
        };
    }
    // function for article browse
    articlebrowse() {
        let articleId = this.hierarchicalData.length + 1;
        let newArticle = this.getChildrens(this.selectedValue, articleId);
        this.hierarchicalData.push(newArticle);
        if (this.isExplorer)
            this.treevalidate.addNodes([newArticle], '', null);
        else
            this.switchExplorer();
        let articles = JSON.parse(localStorage.getItem("articles"));
        articles.push(this.selectedValue.code);
        localStorage.setItem("articles", JSON.stringify(articles));
    }
    //create random string function 
    randomString() {
        var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
        var string_length = 8;
        var randomstring = '';
        for (var i = 0; i < string_length; i++) {
            var rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum, rnum + 1);
        }
        return randomstring;
    }


    scrollPdfToview() {

        // console.log(this.pdfpage);

        // console.log(this.pdfviewer);

        if (this.activeTab != -1) {
            if (this.compiledNode == this.selectednode[this.activeTab].nodeid) {
                var selectionRange = this.editor.getSelectionRange();
                var startRow = selectionRange.start.row;
                var endColumn = selectionRange.end.column;
                const path = this.selectednode[this.activeTab].nodepath;
                const nodedata = this.selectednode[this.activeTab].nodeid;
                /** when PDF is displayed  pdfMain variable used to check pdf displayed or not*/
                if (this.rightPannel == 'viewPdf') {
                    this.editor.selection.moveCursorToPosition({ row: startRow, column: 0 });
                    this.editor.selection.selectLine();
                    this.editorService.forwardSync(startRow + 1, endColumn, nodedata, path).subscribe(datas => {

                        //check if pdf window is there
                        if (this.pdfviewer) {
                            //looping over synctex output values
                            datas.forEach((each) => {
                                var pageno = parseInt(each['page']);
                                var pageView = this.pdfviewer.pdfViewer._pages[pageno - 1];

                                this.pdfviewer.pdfViewer._scrollIntoView({
                                    pageDiv: pageView.div
                                });
                            });
                        }
                    });
                }
            }
        }

    }
    /**
     * Generate pdf or xml and return output
     * @returns Generated xml/pdf path
     * @author Yadu Chandran
     * modified: vishnu prasad
     * renamed type with compileKeyword, the keword used to define operation in shell script
     * added additional parameter compileOutput, what the output format is and based on that the compiled output is shown/download
    **/

    compiledNode;
    public compiledOutputs: any = []
    texLog;
    isTexlog: boolean = false;
    public pdfOutput = [];
    generate_xml_or_pdf(compileKeywork, compileOutput) {

        const current_main_file = this.selectednode.find(item => item.real_path == this.mainDocumentFileName);

        if (current_main_file && current_main_file.saved == false) {
            this.saveTexFile();
        }

        if (!this.journalPackage) {
            this.notifier.notify('error', `You cannot generate ${compileKeywork}, because no package selected for this journal. Please contact your admin`);
            return;
        }

        const compilingFileName = this.mainDocumentFileName.split('.').slice(0)[0];
        const nodedata = this.selectednode[this.activeTab].nodeid;

        if (this.mainArticleId !== nodedata) {
            return;
        }

        //const content           = this.editor.getValue();
        switch (compileOutput) {
            case 'pdf':
                this.pdfCompiling = true;
                this.errorView = false;
                this.activeConsoleTab = "none";
                this.src = "";
                // this.annotationDiv=false;
                // this.generate_xml=false;
                break;
            case 'xml':
                const node = this.selectednode[this.activeTab].nodeid;
                const path = this.selectednode[this.activeTab].nodepath;
                this.xmlCompiling = true;
                this.rightPannel = 'viewXml';
                // this.annotationDiv=true;
                // this.generate_xml=true;
                break;
            default:
                // console.log("No such data exists!");
                break;
        }
        //calling service for api call
        let postData = {
            'nodedata': nodedata,
            'fileName': compilingFileName,
            'compilerName': this.compilerName,
            'compilerEngine': this.compilerEngine,
            'package': this.journalPackage,
            'type': compileKeywork
        };
        this.editorService.generatePDFXML(postData)
            .subscribe(datas => {
                console.log(datas)
                if (datas.status == 'error') {
                    this.notifier.notify('error', this.translate.instant(datas.message))
                }

                if (datas.log) {
                    this.isTexlog = true;
                    this.texLog = atob(datas.log);
                } else
                    this.isTexlog = false;

                let tmpCompiledOutputs: any = {}
                if (datas.output_file != undefined && (datas.status == 'success' || datas.status == 200)) {
                    if (datas.pdf != undefined) {
                        tmpCompiledOutputs = {
                            'outputFile': datas.output_file,
                            'downloadUrl': datas.pdf
                        }
                    } else if (datas.xml_path != undefined) {
                        tmpCompiledOutputs = {
                            'outputFile': datas.output_file,
                            'downloadUrl': datas.xml_path
                        }
                    } else {
                        tmpCompiledOutputs = {
                            'outputFile': datas.output,
                            'downloadUrl': datas.file_path
                        }
                    }

                    this.compiledOutputs.forEach((e, i) => {
                        if (tmpCompiledOutputs.outputFile != undefined && e.outputFile == tmpCompiledOutputs.outputFile) {
                            this.compiledOutputs[i] = tmpCompiledOutputs
                            tmpCompiledOutputs = {}
                        }
                    })

                    if (Object.keys(tmpCompiledOutputs).length > 0) {
                        this.compiledOutputs.push(tmpCompiledOutputs)
                    }
                }

                this.compiledOutputs = this.compiledOutputs.filter(function (elem, index, self) {
                    return index === self.indexOf(elem);
                })
                if (compileOutput == 'pdf') {
                    // this.annotationDiv = false;
                    // this.generate_xml=false;
                    // this.tourService.start(); 
                    this.pdfCompiling = false;
                    this.pdfUrl = '';
                    this.errorConsole(datas);
                    // console.log(test)
                    //setting error array
                    this.errorArray = datas.error_array;
                    console.log(datas.status)
                    if (datas.status ===200) {
                        console.log(datas.status)
                        this.isPdf = true;
                        this.rightPannel = 'viewPdf';
                        this.src = datas.output + '?' + this.randomString();
                        this.pdfUrl = datas.pdf;
                        this.name = this.selectednode[this.activeTab].path.split('/')[0];//JSON.parse(localStorage.getItem("name"));
                        this.pdfOutput.push(datas.output)
                        console.log(this.pdfOutput,this.pdfUrl)
                    } else {
                        this.isPdf = false;
                        if (datas.errors.length || datas.boxes.length || datas.refs.length || datas.warnings.length)
                            this.showIssuesTab();
                        else if (datas.log) {
                            this.rightPannel = 'texLog'
                        }
                        // this.errorView = true;
                        this.src = "";
                    }
                    this.errorDataLength = "(" + this.errorData.length + ")";
                    this.warningsDataLength = "(" + this.warningsData.length + ")";
                    this.refsDataLength = "(" + this.refsData.length + ")";
                    this.boxesDataLength = "(" + this.boxesData.length + ")";
                    this.annotationDatalength="(" + this.annotationData.length +")"
                }
                else if (compileOutput == 'xml') {
                     // this.annotationDiv = false;
                    // this.generate_xml=false;
                     
                    console.log("xml", datas.status, this.xmlCompiling)
                    // this.tourService.startAt('workspace');
                    this.xmlCompiling = false;
                    this.errorConsole(datas.errors);
                 //   this.xmlEditor.setValue(datas.content);
                    if (datas.status == 'success') {
                        this.isXml = true;
                        this.rightPannel = 'viewXml';
                        this.xmlValue = true;
                        this.xmlUrl = datas['xml_path'];
                        // this.xmlEditor = false;
                        this.text1 = datas.content;
                        this.xmlEditor.setValue(datas.content);
                        // Begin: xml error listing
                        if (datas.errors && datas.errors.annotations) {
                            this.xmlEditor.session.setAnnotations(datas.errors.annotations);
                            this.showIssuesTab();
                            //console.log(this.xmlEditor)
                        }
                        // End: xml error listing
                        localStorage.setItem('xmldata', JSON.stringify(this.text1));
                    } else {
                        if (datas.errors.length || datas.boxes.length || datas.refs.length || datas.warnings.length || datas.annotations.length)
                            this.showIssuesTab();
                        else if (datas.log) {
                            this.rightPannel = 'texLog'
                        }
                        this.isXml = false;
                        this.text1 = '';
                        this.notifier.notify('error', 'Error while generating XML. Please try again.');
                    }
                    this.errorDataLength = "(" + this.errorData.length + ")";
                    this.warningsDataLength = "(" + this.warningsData.length + ")";
                    this.refsDataLength = "(" + this.refsData.length + ")";
                    this.boxesDataLength = "(" + this.boxesData.length + ")";
                    this.annotationDatalength="(" + this.annotationData.length +")"
                }
                else if ((datas.status == 'success' || datas.status == 200) && datas.output != undefined) {
                    let postData = { 'file': datas.output, 'nodedata': nodedata }
                    this.editorService.downloadCompiledOutputSvs(postData).subscribe(res => {
                        console.log(res)
                        if (res) {
                            const blob = new Blob([res], { type: 'application/octet-stream' });
                            console.log(blob, datas.output)
                            saveAs(blob, datas.output);
                        }
                    })
                }
            });
    }

    /**
     * fired when an error/warning/info/badbox tab is selected
     * @author Yadu Chandran
     */
    logTabClicked(event) {
        let tab: number = event.selectedIndex;
        document.querySelectorAll(".e-tab-text fa i.fa.fa-spin").forEach(e => e.classList.remove("fa-spin"));
        document.querySelector('div#e-item_' + tab + ' fa i.fa').classList.add("fa-spin");
    }
    //go to ace line on error/warning/info 
    showErrorLine(ace_line) {
        this.editor.resize(true);
        this.editor.scrollToLine(ace_line, true, true, function () { });
        this.editor.gotoLine(ace_line, 0, true);
    }
    /**
     * onclick show all issues after compile
     * @author Yadu Chandran
    */
    // x: number = 0;
    // y : boolean = false;


    showIssuesTab() {
        if (this.rightPannel == 'viewError' && this.isPdf == true) {
            this.rightPannel = 'viewPdf';
            return;
        } else {
            this.rightPannel = 'viewError';
        }

        this.errorArrayUnique = [];
        this.warnArrayUnique = [];
        this.infoArrayUnique = [];
        this.annotationArrayUnique = [];
        this.generateXmlArray=[]
        console.log(this.annotationArray)
        //get all annotations from ace editor
        let annotations = this.editor.session.getAnnotations();
        this.xmlEditor.session.getAnnotations();
        console.log( this.annotationArray)
        this.annotationArray.forEach((element) => {
            console.log(element.row)
            //check row is NaN
            if (isNaN(element.row)) {
                element.row = 0;
            }
            else {
                element.row = element.row;
            }
            //switch case for classifying error/warning
            switch (element.type) {

                case "Anotation" : 
                if (this.annotationArrayUnique.hasOwnProperty(element.row)) { 
                   this.annotationArrayUnique[element.row].push(element.text);
                } else {
                
                this.annotationArrayUnique[element.row] = [];
                this.annotationArrayUnique[element.row].push(element.text);
                console.log(this.annotationArrayUnique)
                }

                break;
                case 'error' : 
                if (this.errorArrayUnique.hasOwnProperty(element.row)) {
                    this.errorArrayUnique[element.row].push(element.text);

                }
                else {
                    this.errorArrayUnique[element.row] = [];
                    this.errorArrayUnique[element.row].push(element.text);
                }
                break;
                case 'warning' : if (this.warnArrayUnique.hasOwnProperty(element.row)) {
                    this.warnArrayUnique[element.row].push(element.text);
                    console.log(this.warnArrayUnique)
                }
                else {
                    this.warnArrayUnique[element.row] = [];
                    this.warnArrayUnique[element.row].push(element.text);
                    console.log(this.warnArrayUnique)
                }
                break;
                case 'info' : if (this.infoArrayUnique.hasOwnProperty(element.row)) {
                    this.infoArrayUnique[element.row].push(element.text);
                }
                else {
                    this.infoArrayUnique[element.row] = [];
                    this.infoArrayUnique[element.row].push(element.text);
                }
                break;
                default : console.log("No such data exists!");
                break;}

        });
        setTimeout(() => {
            //setting/initializing compile error tabs dynamically
            let ErrorChromeTabs: any = document.getElementsByClassName("error-tab");
            this.errorTabs.init(ErrorChromeTabs[0]);
            let errTabs = document.querySelectorAll(".error-tab .chrome-tab");
            //remove all tabs at first
            errTabs.forEach((element) => {
                this.errorTabs.removeTab(element);
            });
            if (this.errorArrayUnique.length > 0) {
                this.errorTabs.addTab({
                    title: 'Errors',
                    favicon: '<fa name="exclamation-circle" class="inner-icon-div"><i aria-hidden="true" class="fa fa-exclamation-circle"></i></fa>',
                    tooltip: 'tooltip',
                    id: 'error',
                    icon_font: true
                });
            }
            if (this.warnArrayUnique.length > 0) {
                this.errorTabs.addTab({
                    title: 'Warnings',
                    favicon: '<fa name="exclamation-triangle"><i aria-hidden="true" class="fa fa-exclamation-triangle"></i></fa>',
                    tooltip: 'tooltip',
                    id: 'warning',
                    icon_font: true
                });
            }
            if (this.infoArrayUnique.length > 0) {
                this.errorTabs.addTab({
                    title: 'Info',
                    favicon: '<fa name="exclamation"><i aria-hidden="true" class="fa fa-exclamation"></i></fa>',
                    tooltip: 'tooltip',
                    id: 'info',
                    icon_font: true
                });
            }

            if (this.annotationArrayUnique.length > 0) {
                this.errorTabs.addTab({
                    title: 'Anotation',
                    favicon: '<fa name="exclamation-circle" class="inner-icon-div"><i aria-hidden="true" class="fa fa-exclamation-circle"></i></fa>',
                    tooltip: 'tooltip',
                    id: 'anotation',
                    icon_font: true
                });
            }

          
            //set current active tab
            const tab = document.querySelectorAll(".error-tab .chrome-tab")[0]
            console.log(tab)
            if (tab) {
                this.errorTabs.setCurrentTab(tab);
                //add click event listener for added tabs
                let all_tabs = document.querySelectorAll(".error-tab .chrome-tab");
                console.log(all_tabs)
                all_tabs.forEach((element) => {
                    element.addEventListener('click', () => {
                        console.log(element)
                        this.clickErrorTab(element);
                    })
                });
                this.clickErrorTab(all_tabs[0]);
            }

        }, 50);
        // this.errorView = !this.errorView;
    }
    /*
     * @author : Yadu Chandran
     * onclick error tab; load error/warning/info
    */
    clickErrorTab(el) {
        this.errorDiv = false;
        this.warningDiv = false;
        this.infoDiv = false;
        this.logDiv = false;
        this.annotationDiv = false;
        this.generate_xml=false;
        let tab_id = el.getAttribute('data-tab-id');
        console.log(tab_id)
        //switch case for hide/show error/warning/info error div
        switch (tab_id) {
            case 'error':
                this.errorDiv = true;
                break;
           ;
                case 'anotation':
                this.annotationDiv = true;
                break;
            case 'warning':
                this.warningDiv = true;
                break;
            case 'info':
                this.infoDiv = true;
                break;
            default:
                console.log("No such data exists!");
                break;
        }
    }
    /**
     * @author: Raru
     * for error console in the bottom
     */
    errorConsole(datas) {
        //clear all annotations at first if any
        this.editor.session.clearAnnotations();
        this.issueCount = 0;
        this.warningLines.length = 0;
        this.warningLines = [];
        this.warningText = [];
        this.errorLines.length = 0;
        this.errorLines = [];
        this.errorText = [];
        this.refsLines.length = 0;
        this.refsLines = [];
        this.refText = [];
        this.boxesLines.length = 0;
        this.boxesLines = [];
        this.boxText = [];
        this.annotationArray = [];
        this.anotationLines = [];
        this.anotationText = [];
        this.errorText = [];
        //setting box array annotation
        if (datas.boxes) {
            datas.boxes.forEach((_element, index) => {
                let cc = datas.boxes[index].split(":");
                if (cc.length > 2) {
                    cc[1] = cc[1] + ' ' + cc[2];
                }
                if (cc.length == 1) {
                    this.boxesLines.push(1);
                    this.boxText.push(cc[0]);
                }
                else {
                    this.boxesLines.push(cc[0]);
                    this.boxText.push(cc[1]);
                }
            });
        }
        //setting ref array annotation
        if (datas.refs) {
            datas.refs.forEach((_element, index) => {
                let bb = datas.refs[index].split(":");
                if (bb.length > 2) {
                    bb[1] = bb[1] + ' ' + bb[2];
                }
                if (bb.length == 1) {
                    this.refsLines.push(1);
                    this.refText.push(bb[0]);
                }
                else {
                    this.refsLines.push(bb[0]);
                    this.refText.push(bb[1]);
                }
            });
        }
        //setting warning array annotation
        if (datas.warnings) {
            datas.warnings.forEach((_element, index) => {
                let aa = datas.warnings[index].split(":");
                if (aa.length > 2) {
                    aa[1] = aa[1] + ' ' + aa[2];
                }
                if (aa.length == 1) {
                    this.warningLines.push(1);
                    this.warningText.push(aa[0]);
                }
                else {
                    this.warningLines.push(aa[0]);
                    this.warningText.push(aa[1]);
                }
            });
        }
        //setting error array annotation
        if (datas.errors) {
            datas.errors.forEach((_element, index) => {
                let x = datas.errors[index].split(":");
                if (x.length > 2) {
                    x[1] = x[1] + ' ' + x[2];
                }
                if (x.length == 1) {
                    this.errorLines.push(1);
                    this.errorText.push(x[0]);
                }
                else {
                    this.errorLines.push(x[0]);
                    this.errorText.push(x[1]);
                }
            });
        }
        //setting anotation array annotation
        if (datas.annotations) {
            datas.annotations.forEach((_, index) => {
                const annotation = datas.annotations[index];
                if (annotation) {
                    console.log(annotation)
                    this.anotationLines.push({row:annotation.row});
                   // this.anotationText.push(annotation.text);
                  this.anotationText.push(annotation)
                }
           
            });
        }
        console.log( this.anotationLines)
        //setting issue count
        this.issueCount = this.boxesLines.length + this.refsLines.length + this.warningLines.length + this.errorLines.length + this.anotationLines.length;
        //pushing box annotation to common array
        for (var i = 0; i < this.boxesLines.length; i++) {
            this.annotationArray.push({
                row: isNaN(this.boxesLines[i]) ? 0 : this.boxesLines[i] - 1,
                column: 10,
                type: "info",
                text: this.boxText[i]
            });
        }
        //pushing ref annotation to common array
        for (var i = 0; i < this.refsLines.length; i++) {
            this.annotationArray.push({
                row: isNaN(this.refsLines[i]) ? 0 : this.refsLines[i] - 1,
                column: 10,
                type: "info",
                text: this.refText[i]
            });
        }
        //pushing warning annotation to common array
        for (var i = 0; i < this.warningLines.length; i++) {
            this.annotationArray.push({
                row: isNaN(this.warningLines[i]) ? 0 : this.warningLines[i] - 1,
                column: 10,
                type: "warning",
                text: this.warningText[i]
            });
        }
        //pushing error annotation to common array
        var errorLinesArray = this.errorLines;
        var errorMessagesArray = this.errorText;
        for (var i = 0; i < errorLinesArray.length; i++) {
            this.annotationArray.push({
                row: isNaN(errorLinesArray[i]) ? 0 : errorLinesArray[i] - 1,
                column: 10,
                type: "error",
                text: errorMessagesArray[i]
            });
        }
        console.log(this.anotationText);
      //  var anotationLinesArray = this.anotationLines[0].split(";")
        // this.anotationText.forEach(element => {

        //     console.log(element)
        //     this.annotationArray.push({
        //     //    row:parseInt(anotationLinesArray[0]),
        //         column: 10,
        //         type: "Anotation",
        //         text: element
        //     });
        // });
        console.log(  this.anotationText)
        var anotationLinesArray = this.anotationLines
        this.anotationText.forEach(element => {
            this.annotationArray.push({
                row:element.row,
                column: 10,
              //  type: "Anotation",
              type: "error",
                text: element.text
            });
    
        });
        console.log(this.anotationLines)
       

        
        //apply all annotations to the editor
        this.compiledNode = this.selectednode[this.activeTab].nodeid;
        if (this.selectednode[this.activeTab].nodepath == this.mainDocumentFileName)
            this.editor.session.setAnnotations(this.annotationArray);
          //  this.showErrorLine(anotationLinesArray[0]-1);
        //   for(i=0;i<this.anotationLines.length;i++){
            for (var i = 0; i < anotationLinesArray.length; i++) {
              console.log(anotationLinesArray[i].row)
              this.xmlEditor.getSession().setMode(LANG);
            var Range = ace.require('ace/range').Range;
            var range = new Range(anotationLinesArray[i].row-1,0,anotationLinesArray[i].row+1,0);
            this.xmlEditor.session.addMarker(range, "myMarker", 'fullLine'); //""myMarker""
          }
   
 //http://jsbin.com/acotuv/3/edit?html,console,output

        if (this.errorData.length > 0 || this.refsData.length > 0 || this.boxesData.length > 0 || this.warningsData.length > 0 || this.annotationData.length>0
        ) {
            this.errorCheck = true;
        } else {
            this.errorCheck = false;
        }
    }

    consoleView(e) {
        this.activeConsoleTab = e;
        if (e == "errors") {
            this.hasError = true;
            this.hasWarning = false;
            this.hasRef = false;
            this.hasBox = false;
            this.hasLog = false;
        }
        if (e == "warnings") {
            this.hasError = false;
            this.hasWarning = true;
            this.hasRef = false;
            this.hasBox = false;
            this.hasLog = false;
        }
        if (e == "refs") {
            this.hasError = false;
            this.hasWarning = false;
            this.hasRef = true;
            this.hasBox = false;
            this.hasLog = false;
        }
        if (e == "boxes") {
            this.hasError = false;
            this.hasWarning = false;
            this.hasRef = false;
            this.hasBox = true;
            this.hasLog = false;
        }
        if (e == "logs") {
            this.hasError = false;
            this.hasWarning = false;
            this.hasRef = false;
            this.hasLog = true;
            this.hasBox = false;
        }

        if (e == "Anotation") {
            this.hasError = false;
            this.hasWarning = false;
            this.hasRef = false;
            this.hasLog = false;
            this.hasBox = false;
            this.hasAnotation = true;
        }
        // if (e == "Generate xml") {
        //     this.hasError = false;
        //     this.hasWarning = false;
        //     this.hasRef = false;
        //     this.hasLog = false;
        //     this.hasBox = false;
        //     this.hasAnotation = false;
        //     this.hasGenerateXml=true
        // }
    }

    gotoAceLine(ace_line) {
        if (this.selectednode[this.activeTab].nodeid == this.compiledNode && this.selectednode[this.activeTab].nodepath == this.mainDocumentFileName) {
            this.editor.resize(true);
            this.editor.scrollToLine(ace_line, true, true, function () { });
            this.editor.gotoLine(ace_line, 0, true);
        }
    }
    error_gotoaceline(ace_line) {
        console.log(ace_line);
        const aceline = Number(ace_line) + 1;
        if (this.selectednode[this.activeTab].nodeid == this.compiledNode && this.selectednode[this.activeTab].nodepath == this.mainDocumentFileName) {
            this.editor.resize(true);
            this.editor.scrollToLine(aceline, true, true, function () { });
            this.editor.gotoLine(aceline, 0, true);
        }
    }
    ConvertToInt(val) {
        val = Number(val) + 1;
        return val;
    }
    closeConsoleView() {
        this.pdf = true;
        this.activeConsoleTab = "none";
    }
    switchToGrid() {
        this.modalFolderGridView = true;
    }

    switchToList() {
        this.modalFolderGridView = false;
    }
    //load first stepper on load
    loadFirstStepper(_$event) {
        // console.log($event);

    }
    //function to validate tex file with journal template
    current_iteration = 0;
    validateTexWithTemplate(template_name) {
        //this.pdfMain=false;
        //this.xmlEditor=false;
        if (this.selectednode[this.activeTab].filetype == 'tex') {
            this.selectedIndex = 0;
            this.currentJournalCode = this.selectednode[this.activeTab].journal;
            this.loading = false;
            this.code = this.editor.getValue();
            this.journalService.getRVCleanPatterns({ journal_id: this.currentJournalCode }).subscribe(
                response => {
                    this.journalPatterns = response['content'];
                    if (this.journalPatterns && this.journalPatterns.length > 0) {
                        this.isLoaded = false;
                        var first_data_obj = {};
                        first_data_obj['selectedIndex'] = 0;
                        //keep checked pattern filter checkbox
                        this.matched = true;
                        this.unmatched = true;
                        //this.sharedService.showSpinner('articleUpload');
                        this.openModal(template_name);
                        this.sharedService.showSpinner('articleUpload');
                        document.querySelectorAll('.modal-dialog').forEach((el) => {
                            el.classList.add('modal-xl');
                        });
                        this.autoReplace(this.journalPatterns[0]['jnl_autoreplace_pattern']).then(() => {
                            setTimeout(() => {
                                // enable tex highlighting
                                // PR.prettyPrint();
                                // group match found patterns
                                this.groupMatchingPatterns(this.journalPatterns[0]['jnl_clean_patterns']);
                                if (this.TemplateArray[0]) {
                                    this.loadThisMatch(this.TemplateArray[0], 0);
                                }
                                this.sharedService.hideSpinner('articleUpload');
                            }, 100);
                        });
                        this.loading = true;
                        this.isLoaded = false;
                    }
                    else {
                        this.notifier.notify('error', 'No patterns found for this journal');
                    }
                },
                _error => {
                    this.loading = false;
                    console.log("error");

                }
            );

        }
        // this.isPdf = true;
    }
    //load pattern stepper onclick function
    loadPattern(patternData) {
        if (this.TemplateArray[patternData.selectedIndex]) {
            this.highlightSearchText(this.TemplateArray[patternData.selectedIndex].clnSearch);
        }
        setTimeout(() => {
            this.sharedService.hideSpinner('articleUpload');
        }, 5000);
    }
    //highlight search pattern text
    highlightSearchText(searchText) {
        $("#search_replace_body span.span-for-highlight").contents().unwrap();
        var ace_content = document.querySelectorAll('#search_replace_body')[0].innerHTML;
        var paragraph = document.querySelectorAll('#search_replace_body')[0];
        var search = searchText;
        var pattern = new RegExp(search);
        var global_pattern = new RegExp(search, 'mg');
        var matches = ace_content.match(global_pattern);
        this.total_count = matches ? matches.length : 0;
        this.current_count = matches ? 1 : 0;
        var m;
        var count = 1;
        if (search.length > 0) {
            paragraph.innerHTML = ace_content.replace(global_pattern, `<span class="span-for-highlight">$&</span>`);
            var __that = this;
            $("span.span-for-highlight").each(function (index) {
                if (index === 0) {
                    $(this).addClass('highlight-text');
                    __that.scrollToWord('highlight-text');
                }
                $(this).attr('data-id', index + 1);
            });
        }
        else {
            paragraph.innerHTML = ace_content;
        }
    }
    //update matched template array after each accept/reject
    updateTemplateArray(accepted_patterns, status) {
        this.TemplateArray.forEach((each) => {
            if (String(each.clnSearch) === String(accepted_patterns.clnSearch)) {
                each[status] = 1;
            }
            else {
                each[status] = 0;
            }

            $(".replaced-bg").remove();
            $(".highlight-auto-replace").contents().unwrap();
            $(".replace-button-span").remove();

            var ace_content = document.querySelectorAll('#search_replace_body')[0].innerHTML;
            var paragraph = document.querySelectorAll('#search_replace_body')[0];
            var global_pattern = new RegExp(each.clnSearch, 'mg');
            var matches = ace_content.match(global_pattern);
            var match_count = matches ? matches.length : 0;
            if (match_count > 0) {
                each['match'] = true;
                each['match_count'] = matches.length;
            }
            else {
                each['match'] = false;
                each['match_count'] = 0;
            }
        });
    }
    //accept all matches of a pattern
    acceptAllThis(patterns, stepper) {
        this.sharedService.showSpinner('articleUpload');
        const originalTexts = [];
        let orignalTextElements = document.querySelectorAll('.highlight-auto-replace');
        for (let i = 0; orignalTextElements[i]; i++) {
            var text = (orignalTextElements[i] as HTMLElement).innerHTML;
            originalTexts.push(text);
            orignalTextElements[i].remove();
            document.querySelector(".replaced-bg");
            if ($(".replaced-bg").first().contents().length) {
                $(".replaced-bg").first().contents().unwrap().wrap("<span></span>").parent().attr("data-history-id", this.undoStack.length);
            }
            else {
                $(".replaced-bg").first().replaceWith('<span data-history-id="' + this.undoStack.length + '"></span>');
            }
            // $(".replaced-bg").first().contents().unwrap().wrap("<span></span>").parent().attr("data-history-id", this.undoStack.length);
        }

        let index = this.TemplateArray.indexOf(patterns);
        this.undoStack.push({ templateIndex: index, spanId: null, originalTexts: originalTexts });
        this.sharedService.hideSpinner('articleUpload');
        // Update matched template array
        this.updateTemplateArray(patterns, 'accepted')
        this.groupMatchingPatternsTest(this.UnMatchedPatterns, patterns).then(() => {
            if (stepper.selectedIndex < this.TemplateArray.length - 1) {
                this.selectedIndex = this.selectedIndex + 1;
            }
            this.sharedService.hideSpinner('articleUpload');
        });


        document.querySelectorAll<HTMLElement>('span.replace-button-span').forEach((el) => {
            el.style.display = "none";
        });
    }
    //reject all matches of a pattern
    rejectAllThis(patterns, _stepper) {
        this.sharedService.showSpinner('articleUpload');
        const originalTexts = [];
        let orignalTextElements = document.querySelectorAll('.highlight-auto-replace');

        for (let i = 0; orignalTextElements[i]; i++) {
            var text = (orignalTextElements[i] as HTMLElement).innerHTML;
            originalTexts.push(text);
            document.querySelector(".highlight-auto-replace");
            $(".highlight-auto-replace").first().contents().unwrap().wrap("<span></span>").parent().attr("data-history-id", this.undoStack.length);
        }
        $(".replaced-bg").remove();
        let index = this.TemplateArray.indexOf(patterns);
        this.undoStack.push({ templateIndex: index, spanId: null, originalTexts: originalTexts });
        this.sharedService.hideSpinner('articleUpload');
        this.notifier.notify('success', 'Pattern rejected successfully');
        this.updateTemplateArray(patterns, 'rejected');

        document.querySelectorAll<HTMLElement>('span.replace-button-span').forEach((el) => {
            el.style.display = "none";
        });
        //this.goForwardPattern(stepper);
        // Go to next pattern
        if (this.selectedIndex < this.TemplateArray.length - 1) {
            this.selectedIndex = this.selectedIndex + 1;
        }
    }
    //scroll to current pattern word
    scrollToWord(class_name) {
        var highlight_element = document.getElementsByClassName(class_name)[0];
        if (highlight_element)
            highlight_element.scrollIntoView();
    }
    //check for patterns with atleast a match; return matched array
    checkMatchingPatterns(_patterns) {
        console.log(this.matchedPatterns);
        console.log(this.UnMatchedPatterns);
    }
    //check if any matching patterns found;
    //if found; group them seperately
    groupMatchingPatterns(pattern_array) {
        this.matchedPatterns.length = 0;
        this.UnMatchedPatterns.length = 0;
        var ace_content = document.querySelectorAll('#search_replace_body')[0].innerHTML;
        var paragraph = document.querySelectorAll('#search_replace_body')[0];
        if (pattern_array.length) {
            pattern_array.forEach((value) => {
                var global_pattern = new RegExp(value.clnSearch, 'mg');
                var matches = ace_content.match(global_pattern);
                var match_count = matches ? matches.length : 0;
                if (match_count > 0) {
                    value['match'] = true;
                    value['match_count'] = matches.length;
                    this.matchedPatterns.push(value);
                }
                else {
                    value['match'] = false;
                    value['match_count'] = 0;
                    this.UnMatchedPatterns.push(value);
                }
            });
            //setting matching templates for looping in html
            this.TemplateArray = this.matchedPatterns;
        }
    }

    groupMatchingPatternsTest(pattern_array, pattern) {
        var promise = new Promise((resolve, _reject) => {
            var ace_content = document.querySelectorAll('#search_replace_body')[0].innerHTML;
            var paragraph = document.querySelectorAll('#search_replace_body')[0];

            if (pattern_array.length) {
                pattern_array.forEach((value, index) => {
                    var global_pattern = new RegExp(value.clnSearch, 'mg');
                    var matches = ace_content.match(global_pattern);
                    var match_count = matches ? matches.length : 0;
                    if (match_count > 0) {
                        value['match'] = true;
                        value['match_count'] = matches.length;
                        this.matchedPatterns.push(value);
                        this.UnMatchedPatterns.splice(index, 1);
                    }
                    if (index == pattern_array.length - 1) {
                        this.matchedPatterns.sort((a: any, b: any) => a.clnOrder < b.clnOrder ? -1 : 1);
                        this.TemplateArray = this.matchedPatterns;
                        setTimeout(() => {
                            this.selectedIndex = this.TemplateArray.indexOf(pattern);
                            resolve();
                        }, 100);
                    }
                });
                //setting matching templates for looping in html
                // this.TemplateArray = this.matchedPatterns;
            }
            // resolve();
        });
        return promise;
    }
    //validate modal filter pattern checkbox change event
    onChangePatternFilter(_event) {
        this.TemplateArray = [];
        if (this.matched) {
            this.TemplateArray = this.matchedPatterns.slice(0);
        }
        if (this.unmatched) {
            if (this.TemplateArray.length) {
                Array.prototype.push.apply(this.TemplateArray, this.UnMatchedPatterns);
            }
            else {
                this.TemplateArray = this.UnMatchedPatterns.slice(0);
            }
        }
        if (!this.matched && !this.unmatched) {
            Array.prototype.push.apply(this.TemplateArray, this.matchedPatterns);
            Array.prototype.push.apply(this.TemplateArray, this.UnMatchedPatterns);
        }
        var first_data_obj = {};
        first_data_obj['selectedIndex'] = 0;
        this.loadPattern(first_data_obj);
    }

    // 
    setMatchCount(_pattern) {

    }

    getHistoryExcludedRegex(text) {
        // return "(?<!<span data-history-id=\"[0-9]+\">)" + text;
        return text;
    }

    //load and hightlight the match clicked
    loadThisMatch(template, current_item, highlightItem?) {
        this.selectedIndex = current_item;

        $(".replaced-bg").remove();
        $(".highlight-auto-replace").contents().unwrap();
        $(".replace-button-span").remove();
        $(".cleaned-citation").contents().unwrap();

        var ace_content = document.querySelectorAll('#search_replace_body')[0].innerHTML;
        var ace_content_html = document.querySelectorAll('#search_replace_body')[0];
        var global_pattern = new RegExp(this.getHistoryExcludedRegex(template.clnSearch), 'mg');
        var matches = ace_content.match(global_pattern);
        var match_count = matches ? matches.length : 0;
        this.TemplateArray[current_item]['match_count'] = match_count;

        if (template.clnReplace) {
            var replacePattern = template.clnReplace.replace(/\\\\n/g, '\n');
            replacePattern = replacePattern.replace(/\\\\s/g, ' ');
        } else
            var replacePattern: any = '';
        $(".replace-button-span").remove();
        var replace_content = `<span class="highlight-auto-replace">$&</span>` +
            '<span class="replaced-bg">' +
            // template.clnReplace +
            replacePattern +
            '</span><span class="replace-button-span" style="display:none;position: absolute;"><button class="btn btn-success btn-sm btn-accept-only" type="button"> Accept</button><button class="btn btn-warning btn-sm btn-reject-only" type="button"> Reject</button><button type="button" class="close close-accept-reject" aria-label="Close"><span aria-hidden="true">&times;</span></button><br><span class="material-icons previous_btn ">skip_previous</span> <span class="material-icons next_btn ">skip_next</span></span>';
        ace_content_html.innerHTML = ace_content.replace(global_pattern, replace_content);

        document.querySelectorAll<HTMLElement>('span.replace-button-span').forEach((el) => {
            el.style.display = "none";
        });

        var current_index = 1;
        document.querySelectorAll('span.replaced-bg').forEach((el) => {
            el.setAttribute("data-id", 'rep_' + current_index);
            if (el.previousElementSibling) {
                el.previousElementSibling.setAttribute("data-id", 'search_' + current_index);
            }
            // el.previousElementSibling.setAttribute("data-id", 'search_' + current_index);
            current_index++;
        });
        //adding click event for accept reject modal
        document.querySelectorAll('button.close-accept-reject').forEach((el) => {
            el.addEventListener('click', this.clickCloseButton.bind(this));
        });
        //adding mouseover & mouseleave event listener for dynamic added div
        // document.querySelectorAll('span.replaced-bg').forEach((el) => {
        //     el.addEventListener('mouseenter', this.mouseEnteredHighlight.bind(this));
        //     el.addEventListener('mouseleave', this.mouseLeavedHighlight.bind(this));
        // });
        document.querySelectorAll('span.highlight-auto-replace').forEach((el) => {
            el.addEventListener('mouseenter', this.mouseEnteredHighlight.bind(this));
            el.addEventListener('mouseleave', this.mouseLeavedHighlight.bind(this));
        });
        //adding click event to accept this pattern button
        document.querySelectorAll('.btn-accept-only').forEach((el) => {
            el.addEventListener('click', this.acceptThisPatternOnly.bind(this, template));
        });
        //adding click event to reject this pattern button
        document.querySelectorAll('.btn-reject-only').forEach((el) => {
            el.addEventListener('click', this.rejectThisPatternOnly.bind(this, template));
        });
        document.querySelectorAll('.previous_btn').forEach((el) => {
            el.addEventListener('click', this.previousTab.bind(this));
        });
        document.querySelectorAll('.next_btn').forEach((el) => {
            el.addEventListener('click', this.nextTab.bind(this));
        });
        this.scrollToWord('highlight-auto-replace');
        if (highlightItem === undefined || highlightItem === null || isNaN(highlightItem)) {
            highlightItem = 1;
        }
        this.highlightNextMatch(Number(highlightItem));
    }
    //click to close accept-reject modal
    clickCloseButton(event) {
        //console.log(event.target.parentNode.parentNode);
        event.target.parentNode.parentNode.style.display = "none";
    }

    // enable previous accept || reject popup of rv clean review
    previousTab(event) {

        document.querySelectorAll<HTMLElement>('span.replace-button-span').forEach((el) => {
            el.style.display = "none";
        });

        const current_data_id = event.target.parentNode.previousElementSibling.getAttribute('data-id');

        var current_data = current_data_id.match(/\d*$/);

        var previous_data_id = current_data_id.substr(0, current_data.index) + (--current_data[0]);

        const previous_data = document.querySelector("[data-id=" + `${previous_data_id}` + "]");

        // check it contains  previous data or not
        if (previous_data !== null) {

            previous_data.nextElementSibling['style']['display'] = "block";

            previous_data.scrollIntoView({
                behavior: 'smooth', block: 'center', inline: 'center'
            });

            var replace_span_id = previous_data.getAttribute("data-id");

            var splitted_id_array = replace_span_id.split("_");

            // setting data-id key and value
            previous_data.nextElementSibling.setAttribute("data-id", splitted_id_array[1]);

            // get position of previous data
            var position = previous_data.getBoundingClientRect();

            var spanHover = previous_data;
            var span_Hover = previous_data.nextElementSibling;

            span_Hover['style']['left'] = position.left + 'px';
            spanHover['style']['top'] = position.top + 'px';


        } else {
            // none
        }

    }

    //enable next accept || reject popup of rv clean review
    nextTab(event) {
        document.querySelectorAll<HTMLElement>('span.replace-button-span').forEach((el) => {
            el.style.display = "none";
        });

        const current_data_id = event.target.parentNode.previousElementSibling.getAttribute('data-id');

        var current_data = current_data_id.match(/\d*$/);

        var next_data_id = current_data_id.substr(0, current_data.index) + (++current_data[0]);

        const next_data = document.querySelector("[data-id=" + `${next_data_id}` + "]");

        // check it contains  next data or not
        if (next_data !== null) {

            next_data.nextElementSibling['style']['display'] = "block";

            var replace_span_id = next_data.getAttribute("data-id");

            var splitted_id_array = replace_span_id.split("_");

            // setting data-id key and value
            next_data.nextElementSibling.setAttribute("data-id", splitted_id_array[1]);

            // get position of previous data
            var position = next_data.getBoundingClientRect();

            var spanHover = next_data;
            var span_Hover = next_data.nextElementSibling;

            span_Hover['style']['left'] = position.left + 'px';
            spanHover['style']['top'] = position.top + 'px';

            next_data.scrollIntoView({
                behavior: 'smooth',
                block: 'center', inline: 'center'
            });

        } else {
            // none
        }


    }

    /**
     * Highlight the target match
     * @author Arathy S V
     */
    highlightNextMatch(id) {
        var next_data = document.querySelector('[data-id="rep_' + `${id}` + '"]');
        if (next_data !== null) {

            next_data.nextElementSibling['style']['display'] = "block";

            // setting data-id key and value
            next_data.nextElementSibling.setAttribute("data-id", id);

            // get position of previous data
            var position = next_data.getBoundingClientRect();

            var spanHover = next_data;
            var span_Hover = next_data.nextElementSibling;

            span_Hover['style']['left'] = position.left + 'px';
            spanHover['style']['top'] = position.top + 'px';

            next_data.scrollIntoView({
                behavior: 'smooth',
                block: 'center', inline: 'center'
            });

        }
    }

    onUndo() {
        const undoThis = this.undoStack.pop();
        const refElements = $("span[data-history-id='" + this.undoStack.length + "']");
        const template = this.TemplateArray[undoThis.templateIndex];
        for (let i = 0; refElements[i]; i++) {
            refElements[i].replaceWith(undoThis.originalTexts[i]);
        }
        template.accepted = template.rejected = 0;
        template.match_count = Number(template.match_count + refElements.length);
        this.TemplateArray[undoThis.templateIndex] = template;
        this.loadThisMatch(template, undoThis.templateIndex, undoThis.spanId);
    }

    accept_reject_changed: boolean = false;
    //function for accepting single pattern only
    acceptThisPatternOnly(temp, event) {
        this.sharedService.showSpinner('articleUpload');
        let index = this.TemplateArray.indexOf(temp);
        temp.match_count = Number(temp.match_count - 1);
        this.TemplateArray[index] = temp;
        this.selectedIndex = index;

        var parent_span_id = event.target.parentNode.getAttribute('data-id');
        const originalTexts = [];

        let orignalTextElements = document.querySelectorAll("span[data-id='search_" + parent_span_id + "']");
        var text = (orignalTextElements[0] as HTMLElement).innerHTML;
        originalTexts.push(text);
        //orignalTextElements[0].remove();
        $("span[data-id='search_" + parent_span_id + "']").remove();
        if ($("span[data-id='rep_" + parent_span_id + "']").first().contents().length) {
            $("span[data-id='rep_" + parent_span_id + "']").first().contents().unwrap().wrap("<span></span>").parent().attr("data-history-id", this.undoStack.length);
        }
        else {
            $("span[data-id='rep_" + parent_span_id + "']").first().replaceWith('<span data-history-id="' + this.undoStack.length + '"></span>');
        }
        // $("span[data-id='rep_" + parent_span_id + "']").first().contents().unwrap().wrap("<span></span>").parent().attr("data-history-id", this.undoStack.length);
        $("span[data-id='" + parent_span_id + "']").remove();
        this.undoStack.push({ templateIndex: index, spanId: parent_span_id, originalTexts: originalTexts });
        this.sharedService.hideSpinner('articleUpload');

        var current_index = 1;

        document.querySelectorAll('span.replaced-bg').forEach((el) => {
            el.setAttribute("data-id", 'rep_' + current_index);
            el.previousElementSibling.setAttribute("data-id", 'search_' + current_index);
            current_index++;
        });
        this.accept_reject_changed = true;
        // Move to next match after resolving the target match
        if (temp.match_count >= 1 && parent_span_id == (temp.match_count + 1)) {
            this.highlightNextMatch(Number(1));
        } else if (temp.match_count >= parent_span_id) {
            this.highlightNextMatch(Number(parent_span_id));
        }
        this.groupMatchingPatternsTest(this.UnMatchedPatterns, temp).then(() => {
            if (temp.match_count == 0 && this.selectedIndex < this.TemplateArray.length - 1) {
                this.selectedIndex = this.selectedIndex + 1;
            }
        });
    }
    selectedIndex = 0;
    //function for rejecting single pattern only
    rejectThisPatternOnly(temp, event) {
        console.log('reject this pattern only');
        this.sharedService.showSpinner('articleUpload');
        let index = this.TemplateArray.indexOf(temp);
        temp.match_count = Number(temp.match_count - 1);
        this.TemplateArray[index] = temp;
        var parent_span_id = event.target.parentNode.getAttribute('data-id');

        const originalTexts = [];

        let orignalTextElements = document.querySelectorAll("span[data-id='search_" + parent_span_id + "']");

        var text = (orignalTextElements[0] as HTMLElement).innerHTML;
        originalTexts.push(text);
        $("span[data-id='rep_" + parent_span_id + "']").remove();
        $("span[data-id='search_" + parent_span_id + "']").first().contents().unwrap().wrap("<span></span>").parent().attr("data-history-id", this.undoStack.length);

        $("span[data-id='" + parent_span_id + "']").remove();
        this.undoStack.push({ templateIndex: index, spanId: parent_span_id, originalTexts: originalTexts });

        this.sharedService.hideSpinner('articleUpload');

        var current_index = 1;

        document.querySelectorAll('span.replaced-bg').forEach((el) => {
            el.setAttribute("data-id", 'rep_' + current_index);
            el.previousElementSibling.setAttribute("data-id", 'search_' + current_index);
            current_index++;
        });

        // Move to next matche after resolving the target match
        if (temp.match_count >= 1 && parent_span_id == (temp.match_count + 1)) {
            this.highlightNextMatch(Number(1));
        } else if (temp.match_count >= parent_span_id) {
            this.highlightNextMatch(Number(parent_span_id));
        }
        if (temp.match_count == 0 && this.selectedIndex < this.TemplateArray.length - 1) {
            this.selectedIndex = this.selectedIndex + 1;
        }
        this.accept_reject_changed = true;
    }
    // //function called when mouse is entered on a div with highlight text.
    // //used for individual accept/reject a pattern
    mouseEnteredHighlight(event) {
        document.querySelectorAll<HTMLElement>('span.replace-button-span').forEach((el) => {
            el.style.display = "none";
        });

        var viewport_height = window.innerHeight;

        var viewport_diff = viewport_height - event.clientY;

        var adjust_height = 0;

        if (viewport_diff < 150) {
            adjust_height = 90;
        }

        const offset = $('#scroll_div').offset();

        var mouse_left = (event.clientX - offset.left + $('#scroll_div').scrollLeft()) + "px";
        var mouse_top = (event.clientY - offset.top + $('#scroll_div').scrollTop() - adjust_height) + "px";

        // var spanHover = event.target.nextElementsSibling;
        var spanHover = event.target.nextElementSibling.nextElementSibling;
        spanHover.style.left = mouse_left;
        spanHover.style.top = mouse_top;

        event.target.nextElementSibling.nextElementSibling.style.display = "block";

        var replace_span_id = event.target.getAttribute("data-id");
        var splitted_id_array = replace_span_id.split("_");

        event.target.nextElementSibling.nextElementSibling.setAttribute("data-id", splitted_id_array[1]);
    }


    // //function called when mouse is entered on a div with highlight text.
    // //used for individual accept/reject a pattern
    // mouseEnteredHighlight(event) {
    //     document.querySelectorAll<HTMLElement>('span.replace-button-span').forEach((el) => {
    //         el.style.display = "none";
    //     });

    //     var viewport_height = window.innerHeight;

    //     var viewport_diff = viewport_height - event.clientY;

    //     var adjust_height = 0;

    //     if (viewport_diff < 150) {
    //         adjust_height = 90;
    //     }

    //     const offset = $('#scroll_div').offset();

    //     var mouse_left = (event.clientX - offset.left + $('#scroll_div').scrollLeft()) + "px";
    //     var mouse_top = (event.clientY - offset.top + $('#scroll_div').scrollTop() - adjust_height) + "px";

    //     var spanHover = event.target.nextElementSibling;
    //     spanHover.style.left = mouse_left;
    //     spanHover.style.top = mouse_top;

    //     event.target.nextElementSibling.style.display = "block";

    //     var replace_span_id = event.target.getAttribute("data-id");
    //     var splitted_id_array = replace_span_id.split("_");

    //     event.target.nextElementSibling.setAttribute("data-id", splitted_id_array[1]);
    // }
    //function called when mouse is leaved from a div with highlight text.
    //used for individual accept/reject a pattern
    mouseLeavedHighlight(_event) {
        // document.querySelectorAll<HTMLElement>('span.replace-button-span').forEach((el) => {
        //     el.style.display = "none";
        // }); 
    }
    //load next occurence of current word search
    loadNextOccurence(curr_search_num) {
        if (document.querySelectorAll('span.highlight-text').length) {
            var current_item_slno = document.querySelectorAll('span.highlight-text')[0].getAttribute('data-id');
        }
        else {
            current_item_slno = curr_search_num;
        }
        var next_slno = parseFloat(current_item_slno) + 1;
        if (document.querySelectorAll('span.span-for-highlight[data-id="' + next_slno + '"]').length) {
            document.querySelectorAll('span.highlight-text').forEach((el) => {
                el.classList.add('highlight-text');
            });
            document.querySelectorAll('span.span-for-highlight').forEach((elm) => {
                elm.classList.remove('highlight-text');
            });
            document.querySelectorAll('span.span-for-highlight[data-id="' + next_slno + '"]').forEach((e) => {
                e.classList.add('highlight-text');
            });
            this.scrollToWord('highlight-text');
            this.current_count = next_slno;
        }
        else {
            alert('last one');
        }
    }
    public popoverTitle: string = 'Popover title';
    public popoverMessage: string = 'Popover description';
    public confirmClicked: boolean = false;
    public cancelClicked: boolean = false;
    public htmlToAdd;
    // autoReplace(list) {
    //     list.forEach((element, index) => {
    //         var ace_content = document.querySelectorAll('#search_replace_body')[0].innerHTML;
    //         var ace_content_html = document.querySelectorAll('#search_replace_body')[0];
    //         // console.log(ace_content);
    //         var global_pattern = new RegExp(element.clnSearch, 'mg');
    //         // var test = '<button data-toggle="popover" class="btn btn-sm test-btn-new" mwlConfirmationPopover  placement="right" (confirm)="confirmClicked = true" (cancel)="cancelClicked = true">Click me!</button>';
    //         // $("#search_replace_body").html(test);
    //         //console.log(this.confirmationOptions);
    //         var replace_content = `<span class="highlight-auto-replace">$&</span>&nbsp;` +
    //             '<span style="background:lightgreen;">' +
    //             element.clnReplace +
    //             '</span>';
    //         ace_content_html.innerHTML = ace_content.replace(global_pattern, replace_content);
    //         // let popover:any = document.getElementsByClassName('btn-outline-secondary');
    //         // for(let i =0; i< popover.length; i++) {e
    //         //     popover[i].addEventListener('click', (event: Event) => {
    //         //         console.log(popover[i]);
    //         //         console.log(event);
    //         //     });
    //         // }
    //     });
    //     //$("#search_replace_body").html(this.sanitizer.bypassSecurityTrustHtml(test));

    //     //var test = '<button data-toggle="popover" class="btn btn-sm test-btn-new" mwlConfirmationPopover  placement="right" (confirm)="confirmClicked = true" (cancel)="cancelClicked = true">Click me!</button>';
    //     //$("#search_replace_body").html(test);
    // }


    autoReplace(list) {
        var promise = new Promise((resolve, _reject) => {
            var ace_content = this.editor.getValue();
            var ace_content_html = document.querySelectorAll('#search_replace_body')[0];
            list.forEach((element, _index) => {
                var global_pattern = new RegExp(element.clnSearch, 'mg');
                if (element.clnReplace) {
                    var replacePattern = element.clnReplace.replace(/\\\\n/g, '\n');
                    replacePattern = replacePattern.replace(/\\\\s/g, ' ');
                } else
                    var replacePattern: any = '';
                ace_content = ace_content.replace(global_pattern, replacePattern);
            });
            ace_content_html.innerHTML = ace_content;
            resolve();
        });
        return promise;
    }

    //replace a pattern with the new pattern provided
    replaceThisPattern(replace_id) {
        if (document.querySelectorAll('span.highlight-text')['0']) {
            var ace_content = document.querySelectorAll('span.highlight-text')['0'].innerHTML;
            var paragraph = document.querySelectorAll('span.highlight-text')[0];
            var global_pattern = new RegExp(this.TemplateArray[replace_id].clnSearch, 'mg');
            paragraph.innerHTML = ace_content.replace(global_pattern, `<span class="span-replaced">` + this.TemplateArray[replace_id].clnReplace + `</span>`);
            //$("#search_replace_body span.highlight-text").contents().unwrap();
            var current_replace_id = document.querySelectorAll('span.highlight-text')[0].getAttribute('data-id');
            $("span.span-replaced").parent('span.highlight-text').removeClass('span-for-highlight highlight-text');
            this.loadNextOccurence(current_replace_id);
        }
    }
    //load previous occurence of current word search
    loadPreviousOccurence(_curr_search_num) {
        if (document.querySelectorAll('span.highlight-text')[0]) {
            var current_item_slno = document.querySelectorAll('span.highlight-text')[0].getAttribute('data-id');
            var previous_slno = parseFloat(current_item_slno) - 1;
            if (document.querySelectorAll('span.span-for-highlight[data-id="' + previous_slno + '"]').length) {
                document.querySelectorAll('span.span-for-highlight').forEach((elm) => {
                    elm.classList.remove('highlight-text');
                });
                document.querySelectorAll('span.span-for-highlight[data-id="' + previous_slno + '"]').forEach((e) => {
                    e.classList.add('highlight-text');
                });

                this.scrollToWord('highlight-text');
                this.current_count = previous_slno;
            }
            else {
                alert('first one');
            }
        }
    }
    //jump to previous pattern; exit from current word
    goBackPattern(stepper) {
        stepper.previous();
    }
    //jump to next pattern; exit from current word
    goForwardPattern(stepper) {
        stepper.next();
    }
    savePatternClose(_event) {
        this.undoStack = [];
        $("#search_replace_body span[data-history-id]").contents().unwrap();
        $("#search_replace_body span.replaced-bg").remove();
        $("#search_replace_body span.highlight-auto-replace ").contents().unwrap();
        // $("#search_replace_body span").contents().remove();
        $("#search_replace_body .replace-button-span").remove();
        $("#search_replace_body .rp_br").remove();
        $("#search_replace_body span[data-history-id]").remove();
        $(".cleaned-citation").contents().unwrap();
        //         var asd: string = $("#search_replace_body").html();
        // console.log(typeof asd);
        // // this.editor.setValue("def main():\n    # Your code goes here\n\nif __name__ == \"__main__\":\n    main()");
        // // this.editor.setValue('$("#search_replace_body").html()');
        // this.editor.setValue(asd);
        this.editor.setValue($('<textarea />').html($("#search_replace_body").html()).text());
        this.modalRef.hide();
        this.isLoaded = true;
        this.accept_reject_changed = false;

        //         this.editor.find('<br>',{
        //             backwards: false,
        //             wrap: true,
        //             // regExp: true
        //         });
        // this.editor.replaceAll('\r\n');
        // $("#search_replace_body span").contents().unwrap();
        // this.editor.setValue($("#search_replace_body").html());
        // this.modalRef.hide();
        // this.isLoaded = true;
        // this.accept_reject_changed = false;
    }
    //discard changed pattern and close modal
    //changed patterns will be discarded and editor contents will be same as previous
    discardPatternClose(_event) {
        if (this.accept_reject_changed == true) {
            this.coolDialogs
                .confirm("Your changes will be lost. This action cannot be undone! continue?", {
                    okButtonText: "Ok",
                    cancelButtonText: "Cancel",
                    title: "CONFIRM"
                })
                .subscribe(res => {
                    if (res) {
                        $("#search_replace_body span.span-for-highlight").contents().unwrap();
                        this.modalRef.hide();
                        this.isLoaded = true;
                        this.accept_reject_changed = false;
                        this.undoStack = [];
                    } else {
                        return false;
                    }
                });
        } else {

            this.modalRef.hide();
            this.isLoaded = true;
            this.accept_reject_changed = false;
        }
        // if (confirm('Your changes will be lost. This action cannot be undone! continue?')) {
        //     $("#search_replace_body span.span-for-highlight").contents().unwrap();
        //     this.modalRef.hide();
        // } else {
        //     return false;
        // }
    }
    //function for getting path
    getPath(node) {
        const path = [];
        let nodePath = "";
        while (node) {
            if (node.data.code) {
                return {
                    code: node.data.code,
                    path: nodePath,
                    id: node.data.id,
                    articleName: node.data.name,
                    articleFolder: node.data.article_file
                };
            }
            nodePath = node.data.name + "/" + nodePath;
            node = node.parent;
        }
    }
    // mouseout function for tree component
    mouseOutTree(_event, _nodes) { }

    // For setting close contexmenu for article. This function return the article id
    public isArticle = (item: any): boolean => {
        return item.code;
    };

    // function for getting full path of a node
    getPathNode(node) {
        const path = [];
        let nodePath = "";
        while (node) {
            if (node.data.code) {
                return { path: node.data.name + "/" + nodePath };
            }
            nodePath = node.data.name + "/" + nodePath;
            node = node.parent;
        }
    }

    /**
     * Store the cursor position of editor to corresponding file record
     * @author Arathy S V
     */
    storeEditorPosition() {
        if (this.activeTab != -1) {
            const current_file = this.selectednode[this.activeTab];
            if (current_file.filetype != "image" && current_file.filetype != "pdf") {
                this.selectednode[this.activeTab].cursorPosition = this.editor.getCursorPosition().row + 1;
            }
        }
    }

    // click function in tree node. The file willbe open in to the editor, if it is image/pdf or tex file.

    nodeClickEvent(node: NodeClickEventArgs) {
        if (event['button'] === 2 && event['which'] === 3) {
            return false;
        }
        let currentNode: any = this.treevalidate.getTreeData(node.node)[0];
        this.nodevalues = currentNode.name;
        var fileNameExtension = this.getFileExtension(currentNode.name);
        if ((this.openAllowedFiles.includes(fileNameExtension)) && currentNode.hasChildren == false) {
            this.storeEditorPosition();
            this.editor.session.selection.clearSelection();
            this.hideCommentList();
            const fileDetails = this.getFileDetails(currentNode.id);
            this.commentPath = fileDetails.commentPath;
            this.editorsvalue = true;
            this.nodeid = fileDetails.code;
            this.nodepath = fileDetails.path;
            this.nodevalues = currentNode.name;
            localStorage.setItem('name', JSON.stringify(this.nodevalues));
            this.nodevalue = [currentNode.name];
            this.activeTab = this.selectednode.findIndex(
                item => item.path === fileDetails.articleName + '/' + fileDetails.path
            );
            if (this.activeTab == -1) {
                this.nodeid = fileDetails.code;
                this.nodepath = fileDetails.path;
                this.nodevalues = currentNode.name;
                if (fileNameExtension == "pdf" || this.images.includes(fileNameExtension)) {
                    let nodedata;
                    if (fileNameExtension == "pdf") {
                        nodedata = {
                            id: Date.now(),
                            name: currentNode.name,
                            imagepath: this.baseImagePath + fileDetails.articleFolder + '/' + this.nodepath,
                            path: fileDetails.articleName + '/' + fileDetails.path,
                            nodepath: this.nodepath,
                            nodeid: this.nodeid,
                            content: "",
                            filetype: "pdf",
                            saved: true,
                            savedContent: "",
                            cursorPosition: 1
                        };
                    } else {
                        nodedata = {
                            id: Date.now(),
                            name: currentNode.name,
                            imagepath: this.baseImagePath + fileDetails.articleFolder + '/' + this.nodepath,
                            path: fileDetails.articleName + '/' + fileDetails.path,
                            nodepath: this.nodepath,
                            nodeid: this.nodeid,
                            content: "",
                            filetype: "image",
                            saved: true,
                            savedContent: ""
                        };
                    }
                    this.selectednode.push(nodedata);
                    this.activeTab = this.selectednode.length - 1;
                    this.addTab({ id: nodedata.id, title: nodedata.name, favicon: nodedata.filetype, tooltip: nodedata.path, nodeid: nodedata.nodeid, real_path: fileDetails.path });
                    this.updateEditor(nodedata);
                    this.getBookmarks(this.selectednode[this.activeTab]);
                } else if (this.editorFiles.includes(fileNameExtension)) {
                    if (currentNode.isDisabled) {
                        return false;
                    }
                    currentNode['isDisabled'] = true;
                    this.treevalidate.disableNodes([currentNode.id]);
                    this.editorService
                        .getArticle(this.nodevalue, this.nodeid, this.nodepath, this.commentPath)
                        .subscribe(datas => {
                            if (datas.status == 200) {
                                currentNode.isDisabled = false;
                                this.treevalidate.enableNodes([currentNode.id]);
                                this.activeFiles = JSON.parse(
                                    localStorage.getItem("activeFiles")
                                );
                                if (this.activeFiles) {
                                    if (
                                        this.activeFiles.findIndex(
                                            item =>
                                                item.path === this.nodepath && item.id === this.nodeid
                                        ) === -1
                                    ) {
                                        this.activeFiles.push({
                                            id: this.nodeid,
                                            path: this.nodepath,
                                            commentPath: this.commentPath
                                        });
                                    }
                                } else {
                                    this.activeFiles = [];
                                    this.activeFiles.push({ id: this.nodeid, path: this.nodepath, commentPath: this.commentPath });
                                }
                                localStorage.setItem(
                                    "activeFiles",
                                    JSON.stringify(this.activeFiles)
                                );
                                const nodedata = {
                                    id: Date.now(),
                                    name: currentNode.name,
                                    path: fileDetails.articleName + '/' + fileDetails.path,
                                    nodepath: this.nodepath,
                                    real_path: fileDetails.path,
                                    nodeid: this.nodeid,
                                    content: atob(datas.content),
                                    filetype: "tex",
                                    saved: true,
                                    savedContent: atob(datas.content),
                                    comments: datas.comments,
                                    commentPath: this.commentPath,
                                    journal: datas.journal,
                                    cursorPosition: 0
                                };
                                if (datas.content === "") {
                                    this.config = {
                                        disableClose: false,
                                        panelClass: "custom-overlay-pane-class",
                                        hasBackdrop: false,
                                        backdropClass: "",
                                        width: "20",
                                        height: "",
                                        minWidth: "",
                                        minHeight: "",
                                        maxWidth: defaultDialogConfig.maxWidth,
                                        maxHeight: "",
                                        color: "grey",
                                        position: {
                                            top: "",
                                            bottom: "",
                                            left: "",
                                            right: ""
                                        },
                                        data: {
                                            message: this.translate.instant(
                                                "Are you sure you want default template"
                                            ),
                                            yes: this.translate.instant("Yes"),
                                            no: this.translate.instant("No")
                                        }
                                    };
                                    this.dialogRef = this.dialog.open(JazzDialog, this.config);
                                    this.dialogRef.beforeClose().subscribe((result: string) => {
                                        this.lastBeforeCloseResult = result;
                                        if (result === 'Yes') {
                                            this.datas = this.http.get(this.env.assetsUrl + 'example.tex', { responseType: 'text' })
                                                .subscribe(data => {
                                                    nodedata.content = data;
                                                    this.editor.setValue(nodedata.content);
                                                });
                                        }
                                    });
                                    this.dialogRef.afterClosed().subscribe((result: string) => {
                                        this.lastAfterClosedResult = result;
                                        if (result === 'yes') {
                                            this.datas = this.http.get(this.env.assetsUrl + 'example.tex', { responseType: 'text' })
                                                .subscribe(data => {
                                                    nodedata.content = data;
                                                    this.editor.setValue(nodedata.content);
                                                });
                                        }
                                        this.dialogRef = null;
                                    });
                                    this.dialogRef.beforeClose().subscribe((result: string) => {
                                        this.lastBeforeCloseResult = result;
                                    });
                                    this.dialogRef.afterClosed().subscribe((result: string) => {
                                        this.lastAfterClosedResult = result;
                                        this.dialogRef = null;
                                    });
                                }
                                this.addTab({ id: nodedata.id, title: nodedata.name, favicon: nodedata.filetype, tooltip: nodedata.path, nodeid: nodedata.nodeid, real_path: nodedata.real_path });
                                this.selectednode.push(nodedata);
                                this.activeTab = this.selectednode.length - 1;
                                this.updateEditor(nodedata);
                                this.getBookmarks(this.selectednode[this.activeTab]);
                            } else {
                                this.notifier.notify('error', 'File not found');
                                this.editor.setValue('');
                            }
                        });
                }
            }
            else {

                this.updateEditor(this.selectednode[this.activeTab]);
                var id = this.selectednode[this.activeTab].id;
                var currentTab = document.querySelector('div[data-tab-id="' + id + '"]');
                this.editorTabs.setCurrentTab(currentTab);
                // this.loadEditorPosition();

                // set tex file as read only if files from other node
                if (fileDetails.code != this.mainArticleId) {
                    this.editor.setOptions({
                        readOnly: true
                    })
                } else {
                    this.editor.setOptions({
                        readOnly: false
                    })
                }
            }
        }
    }

    /**
     * Set the position for editor content
     * @author Arathy S V
     */
    loadEditorPosition() {
        var currentFile = this.selectednode[this.activeTab];
        if (currentFile.filetype == 'tex') {
            this.editor.selection.moveCursorToPosition({ row: currentFile.cursorPosition, column: 0 });
            this.editor.gotoLine(currentFile.cursorPosition, 0, true);
        } else if (currentFile.filetype == 'pdf') {
            this.editorPage = currentFile.cursorPosition;
            if (this.editorPdfViewer) {
                var pageView = this.editorPdfViewer.pdfViewer._pages[currentFile.cursorPosition - 1];
                this.editorPdfViewer.pdfViewer._scrollIntoView({
                    // pageDiv: pageView.div
                });
            }
        }
    }

    /*
     * send text data to all collab users
     * @Author Yadu Chandran
    */

    // uncomment
    sendTextoCollabUsers(data) {
        this.collabService.sendMyTextData(data);
    }

    // uncomment
    //generate random user name for collaboration
    randomDisplayName() {
        return "User-" + Math.round(Math.random() * 10000);
    }

    //share click -copy url
    copyUrl() {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = window.location.href;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.notifier.notify('success', 'URL Copied!');
    }

    // pageOfItems: Array<any>;

    // onChangePage(pageOfItems: Array<any>) {
    //     // update current page of items
    //     this.pageOfItems = pageOfItems;
    // }

    // share project
    modalShareProject(modal) {
        this.modalRef = this.modalService.show(modal, {
            animated: true,
            keyboard: true,
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-lg'
        });
    }
    getSelection(item) {
        return this.selectionUserList.findIndex(s => s.usr_username === item.usr_username) !== -1;
    }
    changeHandler(item: any, _event: KeyboardEvent) {
        const id = item.usr_username;

        const index = this.selectionUserList.findIndex(u => u.usr_username === id);
        if (index === -1) {
            // ADD TO SELECTION
            this.selectionUserList = [...this.selectionUserList, item];
        } else {
            // REMOVE FROM SELECTION
            this.selectionUserList = this.selectionUserList.filter(user => user.usr_username !== item.usr_username)
        }
    }
    selectAllCheckboxes() {

        for (var i = 0; i < this.shareArticleToUsers.length; i++) {
            this.shareArticleToUsers[i].selected = this.selectedAll;
        }
        if (this.selectedAll === true) {
            this.selectionUserList = this.shareArticleToUsers;
        } else {
            this.selectionUserList = [];
        }
    }
    checkIfAllSelected() {
        this.selectedAll = this.shareArticleToUsers.every(function (item: any) {
            return item.selected == true;
        })
    }
    // clear selction
    clearSelection() {
        this.selectionUserList = [];
        this.selectedAll = false;
        this.shareTable.reset();
    }

    closer() {
        this.selectionUserList = [];
        this.selectedAll = false;
        this.shareTable.reset();
    }

    shareProjectTo(data) {

        const link = window.location.href;
        const article_name = this.mainArticleName;

        let emailDetails = [];
        const shareArticleDetails = [];
        // return console.log(data);
        const check = Array.isArray(data);
        if (check == false) {
            emailDetails.push({ name: data.usr_username, email: data.usr_email, url: link, articleName: article_name });
            shareArticleDetails.push({ sender: this.userDetails._id, receiver: data.usr_id, article: this.mainArticleId, receiver_email: data.usr_email });

        } else {
            data.forEach((value) => {
                emailDetails.push({ name: value.usr_username, email: value.usr_email, url: link, articleName: article_name });
                shareArticleDetails.push({ sender: this.userDetails._id, receiver: value.usr_id, article: this.mainArticleId, receiver_email: value.usr_email });
            });
        }

        this.sharedService.showSpinner('articleUpload');

        // uncomment
        this.editorService.collabrationShare({ email_details: emailDetails, share_article: shareArticleDetails }).subscribe(
            res => {
                if (res.status == 200) {
                    this.sharedService.hideSpinner('articleUpload');
                    this.notifier.notify('success', res.mail_send_message);
                    this.selectionUserList = [];
                    this.getUsersByArticle();
                    this.getChatUsersList();
                }
            },
            err => {
                console.log(err);
                this.notifier.notify('error', 'Something went wrong');
                this.sharedService.hideSpinner('articleUpload');
            }
        )

    }
    guestUsers = [];
    public validators = [this.must_be_email];
    public errorMessages = {
        'must_be_email': 'Enter valid email adress!'
    };
    private must_be_email(control: FormControl) {
        var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
        if (control.value != "" && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
            return { "must_be_email": true };
        }
        return null;
    }
    guestShareErrorMessage;
    guestShareSuccessMessage;
    guestUserList = [];
    allGuestMails = [];

    guestShare() {

        this.guestShareErrorMessage = '';
        this.guestShareSuccessMessage = '';

        if (this.guestUsers.length == 0) {
            this.guestShareErrorMessage = 'Enter valid email..';
            setTimeout(() => {
                this.guestShareErrorMessage = '';
                this.guestShareSuccessMessage = '';
            }, 3000);

        } else {
            this.guestUsers.forEach((item) => {
                const user_name = item.value.split("@");
                this.guestUserList.push({
                    guestEmail: item.value,
                    inviter: this.userDetails._id,
                    article: this.mainArticleId,
                    articleName: this.mainArticleName,
                    userName: item.value,
                    url: window.location.href,
                    firstName: user_name[0]
                });

                this.allGuestMails.push(item.value);
            });
            this.sharedService.showSpinner('articleUpload');
            this.editorService.guestInvitation({ guestList: this.guestUserList, allGuestMails: this.allGuestMails, articleName: this.mainArticleId }).subscribe(
                res => {

                    // if email successfully shared with all users
                    this.sharedService.hideSpinner('articleUpload');
                    if (res.status == 200) {

                        this.notifier.notify('success', 'Email sent successfully');
                        this.guestShareSuccessMessage = res.message;
                        this.getUsersByArticle();
                        setTimeout(() => {
                            this.guestShareSuccessMessage = '';
                            this.guestShareErrorMessage = '';
                        }, 5000);
                    }

                    // if email already shared
                    if (res.status == 208) {

                        this.notifier.notify('error', 'Article already shared');

                        this.guestShareErrorMessage = res.message;
                        setTimeout(() => {
                            this.guestShareErrorMessage = '';
                            this.guestShareSuccessMessage = '';
                        }, 5000);
                    }

                    // some emails already have this and some not
                    if (res.status == 202) {

                        this.notifier.notify('success', 'Email sent successfully');

                        const successMails = res.unSharedMails;
                        const failedMails = res.sharedUsersEmail.toString();

                        this.guestShareErrorMessage = res.mail_error + failedMails;
                        this.guestShareSuccessMessage = res.mail_success + ' ' + successMails;
                        this.getUsersByArticle();
                        setTimeout(() => {
                            this.guestShareErrorMessage = '';
                            this.guestShareSuccessMessage = '';
                        }, 10000);
                    }

                    this.guestUserList = [];
                    this.guestUsers = [];
                    this.allGuestMails = [];
                },
                err => {
                    this.sharedService.hideSpinner('articleUpload');
                    console.log(err);
                    this.guestShareErrorMessage = '';
                    this.guestShareSuccessMessage = '';
                    this.guestUserList = [];
                    this.guestUsers = [];
                    this.allGuestMails = [];
                }
            )
        }
    }

    getShareduser() {

    }

    // Trigger this function, when changing the editor content
    onChangeEditor(data) {

        // uncomment
        if (!this.applyingChanges) {
            //send to collab users what i typed
            this.sendTextoCollabUsers(data);
        }

        var editorContent = this.editor.getValue();
        if (this.activeTab !== -1) {
            var currentTab: any = document.querySelector('div[data-tab-id="' + this.selectednode[this.activeTab].id + '"] .chrome-tab-title');
            this.selectednode[this.activeTab].content = editorContent;
            if (this.selectednode[this.activeTab].savedContent !== editorContent) {
                currentTab.classList.add('unsaved');
                this.selectednode[this.activeTab].saved = false;
            } else {
                currentTab.classList.remove('unsaved');
                this.selectednode[this.activeTab].saved = true;
            }
        }
    }

    // close active tab
    closeActiveTab(nodename) {
        this.gutterLine.length = 0;
        this.gutterLine = [];
        this.errorLines.length = 0;
        this.errorLines = [];
        this.warningLines.length = 0;
        this.warningLines = [];
        this.refsLines.length = 0;
        this.refsLines = [];
        this.boxesLines.length = 0;
        this.boxesLines = [];
        this.hideCommentList();
        this.LoadImage = false;
        const index = this.selectednode.indexOf(nodename);
        if (index !== -1) {
            this.activeFiles = JSON.parse(localStorage.getItem("activeFiles"));
            const activeFileIndex = this.activeFiles.findIndex(
                item => item.path === nodename.nodepath && item.id === nodename.nodeid
            );
            this.activeFiles.splice(activeFileIndex, 1);
            localStorage.setItem("activeFiles", JSON.stringify(this.activeFiles));
            this.selectednode.splice(index, 1);
            const currentNode = this.getActiveTab();
            if (currentNode) {
                this.activeTab = this.selectednode.indexOf(currentNode);
                if (this.activeTab != -1) {
                    this.updateEditor(currentNode);
                    this.getBookmarks(this.selectednode[this.activeTab]);
                }
            } else {
                this.activeTab = -1;
                this.LoadObj = false;
                this.LoadImage = false;
                this.editor.setValue('');
                this.rightPannel = '';
                this.issueCount = 0;
                this.isPdf = false;
                this.isXml = false;
                this.annotationArray = [];
                this.getBookmarks(this.selectednode[this.activeTab]);
            }
        }
        // if (this.selectednode.length >= 1) {
        //     this.recompile();
        // }
    }

    // get path of files
    getLeafNodes(node, leaf) {
        if (node.hasChildren) {
            const children = node.children;
            if (children) {
                children.forEach(element => {
                    this.getLeafNodes(element, leaf);
                });
            }
        } else {
            const path = this.getFileDetails(node.id);
            leaf.push(path.path);
        }
        return leaf;
    }

    // get comment path of files
    getLeafNodesComment(node, leaf) {
        if (node.hasChildren) {
            const children = node.children;
            if (children) {
                children.forEach(element => {
                    this.getLeafNodes(element, leaf);
                });
            }
        } else {
            const fileDetails = this.getFileDetails(node.id);
            leaf.push(fileDetails.commentPath);
        }
        return leaf;
    }

    // Rename active files while renam operation of tree node
    renameActiveFile(node, leafBeforeRename, leafAfterRename) {
        const articleDetails = this.getFileDetails(node.id);
        const id = articleDetails.code;
        const articleName = articleDetails.articleName;
        this.activeFiles = JSON.parse(localStorage.getItem("activeFiles"));
        if (node.hasChildren) {
            for (let i = 0; i < leafBeforeRename.length; i++) {
                const indexOfActiveNode = this.selectednode.findIndex(
                    item => item.nodepath === leafBeforeRename[i] && item.nodeid === id
                );
                if (indexOfActiveNode != -1) {
                    this.selectednode[indexOfActiveNode].path =
                        articleName + "/" + leafAfterRename[i];
                    this.selectednode[indexOfActiveNode].nodepath = leafAfterRename[i];
                    const indexLocal = this.activeFiles.findIndex(
                        item => item.path === leafBeforeRename[i] && item.id === id
                    );
                    if (indexLocal != -1)
                        this.activeFiles[indexLocal].path = leafAfterRename[i];
                    var currentTab = document.querySelector('div[data-tab-id="' + this.selectednode[indexOfActiveNode].id + '"]');
                    this.updateTab(currentTab, this.selectednode[indexOfActiveNode]);
                }
            }
        } else {
            const articleDetails = this.getFileDetails(node.id);
            let activeIndex = this.selectednode.findIndex(
                item =>
                    item.path === articleDetails.articleName + "/" + leafBeforeRename[0]
            );
            if (activeIndex != -1) {
                this.activeTab = activeIndex;
                this.selectednode[this.activeTab].name = node.name;
                this.selectednode[this.activeTab].path =
                    articleDetails.articleName + "/" + leafAfterRename[0];
                this.selectednode[this.activeTab].nodepath = leafAfterRename[0];
                var currentTab = document.querySelector('div[data-tab-id="' + this.selectednode[this.activeTab].id + '"]');
                this.editorTabs.setCurrentTab(currentTab);
                this.updateTab(currentTab, this.selectednode[this.activeTab]);
                this.updateEditor(this.selectednode[this.activeTab]);
                this.getBookmarks(this.selectednode[this.activeTab]);
                const indexLocal = this.activeFiles.findIndex(
                    item => item.path === leafBeforeRename[0] && item.id === id
                );
                if (indexLocal != -1) {
                    this.activeFiles[indexLocal].path = leafAfterRename[0];
                    var commentSplit = this.activeFiles[indexLocal].commentPath.split('/');
                    commentSplit[commentSplit.length - 1] = node.name;
                    this.activeFiles[indexLocal].commentPath = commentSplit.join('/');
                }
            }
        }
        localStorage.setItem("activeFiles", JSON.stringify(this.activeFiles));
    }

    //function for dialog box
    openJazz() {
        this.dialogRef = this.dialog.open(JazzDialog, this.config);

        this.dialogRef.beforeClose().subscribe((result: string) => {
            this.lastBeforeCloseResult = result;
        });
        this.dialogRef.afterClosed().subscribe((result: string) => {
            this.lastAfterClosedResult = result;
            this.dialogRef = null;
        });
    }

    openTemplate() {
        this.numTemplateOpens++;
        this.dialog.open(this.template, this.config);
    }

    // Close active tab while deleting or closing the file or article from menu list
    closeActivetabByDelete(node) {
        const nodeDetails = this.getFileDetails(node.id);
        const leafNodes = this.getLeafNodes(node, []);
        leafNodes.forEach(element => {
            const indexOfActiveNode = this.selectednode.findIndex(
                item => item.nodepath === element && item.nodeid === nodeDetails.code
            );
            if (indexOfActiveNode != -1) {
                var currentTab = document.querySelector('div[data-tab-id="' + this.selectednode[indexOfActiveNode].id + '"]');
                this.editorTabs.removeTab(currentTab);
                this.closeActiveTab(this.selectednode[indexOfActiveNode]);
            }
        });
    }

    toggleVisibility(_e) {
        this.editors(this.editorOption);
        this.mode = this.editorOption.mode;
    }

    // theme set after page reload
    editorThemeOption(theme) {
        this.editors(this.editorOption);
        // this.theme = this.editorOption.theme;
        const themeChcek = this.lightThemes.includes(theme);
        if (themeChcek == false) {
            this.chromeTabs.nativeElement.classList.add('chrome-tabs-dark-theme');
        } else {
            this.chromeTabs.nativeElement.classList.remove('chrome-tabs-dark-theme');
        }
        if (theme == 'cobalt') {
            import('ace-builds/src-noconflict/theme-cobalt').then(elem => {
                if (elem.default.cssClass == 'ace-cobalt') {
                    this.editor.setTheme('ace/theme/cobalt');
                    this.cssClassName = 'ace-cobalt';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'chrome') {
            import('ace-builds/src-noconflict/theme-chrome').then(elem => {
                if (elem.default.cssClass == 'ace-chrome') {
                    this.editor.setTheme(THEME);
                    this.cssClassName = 'ace-chrome';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'clouds_midnight') {
            import('ace-builds/src-noconflict/theme-clouds_midnight').then(elem => {
                if (elem.default.cssClass == 'ace-clouds-midnight') {
                    this.editor.setTheme('ace/theme/clouds_midnight');
                    this.cssClassName = 'ace-clouds-midnight'
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'tomorrow') {
            import('ace-builds/src-noconflict/theme-tomorrow').then(elem => {
                if (elem.default.cssClass == 'ace-tomorrow') {
                    this.editor.setTheme('ace/theme/tomorrow');
                    this.cssClassName = 'ace-tomorrow';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'clouds') {
            import('ace-builds/src-noconflict/theme-clouds').then(elem => {
                if (elem.default.cssClass == 'ace-clouds') {
                    this.editor.setTheme('ace/theme/clouds');
                    this.cssClassName = 'ace-clouds';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'ambiance') {
            import('ace-builds/src-noconflict/theme-ambiance').then(elem => {
                if (elem.default.cssClass == 'ace-ambiance') {
                    this.editor.setTheme('ace/theme/ambiance');
                    this.cssClassName = 'ace-ambiance';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName }
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'chaos') {
            import('ace-builds/src-noconflict/theme-chaos').then(elem => {
                if (elem.default.cssClass == 'ace-chaos') {
                    this.editor.setTheme('ace/theme/chaos');
                    this.cssClassName = 'ace-chaos';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'crimson_editor') {
            import('ace-builds/src-noconflict/theme-crimson_editor').then(elem => {
                if (elem.default.cssClass == 'ace-crimson-editor') {
                    this.editor.setTheme('ace/theme/crimson_editor');
                    this.cssClassName = 'ace-crimson-editor';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'dawn') {
            import('ace-builds/src-noconflict/theme-dawn').then(elem => {
                if (elem.default.cssClass == 'ace-dawn') {
                    this.editor.setTheme('ace/theme/dawn');
                    this.cssClassName = 'ace-dawn';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'dracula') {
            import('ace-builds/src-noconflict/theme-dracula').then(elem => {
                if (elem.default.cssClass == 'ace-dracula') {
                    this.editor.setTheme('ace/theme/dracula');
                    this.cssClassName = 'ace-dracula';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme, },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'dreamweaver') {
            import('ace-builds/src-noconflict/theme-dreamweaver').then(elem => {
                if (elem.default.cssClass == 'ace-dreamweaver') {
                    this.editor.setTheme('ace/theme/dreamweaver');
                    this.cssClassName = 'ace-dreamweaver';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'eclipse') {
            import('ace-builds/src-noconflict/theme-eclipse').then(elem => {
                if (elem.default.cssClass == 'ace-eclipse') {
                    this.editor.setTheme('ace/theme/eclipse');
                    this.cssClassName = 'ace-eclipse';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'github') {
            import('ace-builds/src-noconflict/theme-github').then(elem => {
                if (elem.default.cssClass == 'ace-github') {
                    this.editor.setTheme('ace/theme/github');
                    this.cssClassName = 'ace-github';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'gob') {
            import('ace-builds/src-noconflict/theme-gob').then(elem => {
                if (elem.default.cssClass == 'ace-gob') {
                    this.editor.setTheme('ace/theme/gob');
                    this.cssClassName = 'ace-gob';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'gruvbox') {
            import('ace-builds/src-noconflict/theme-gruvbox').then(elem => {
                if (elem.default.cssClass == 'ace-gruvbox') {
                    this.editor.setTheme('ace/theme/gruvbox');
                    this.cssClassName = 'ace-gruvbox';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'idle_fingers') {
            import('ace-builds/src-noconflict/theme-idle_fingers').then(elem => {
                if (elem.default.cssClass == 'ace-idle-fingers') {
                    this.editor.setTheme('ace/theme/idle_fingers');
                    this.cssClassName = 'ace-idle-fingers';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'monokai') {
            import('ace-builds/src-noconflict/theme-monokai').then(elem => {
                if (elem.default.cssClass == 'ace-monokai') {
                    this.editor.setTheme('ace/theme/monokai');
                    this.cssClassName = 'ace-monokai';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'iplastic') {
            import('ace-builds/src-noconflict/theme-iplastic').then(elem => {
                if (elem.default.cssClass == 'ace-iplastic') {
                    this.editor.setTheme('ace/theme/iplastic');
                    this.cssClassName = 'ace-iplastic';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'katzenmilch') {
            import('ace-builds/src-noconflict/theme-katzenmilch').then(elem => {
                if (elem.default.cssClass == 'ace-katzenmilch') {
                    this.editor.setTheme('ace/theme/katzenmilch');
                    this.cssClassName = 'ace-katzenmilch';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'kr_theme') {
            import('ace-builds/src-noconflict/theme-kr_theme').then(elem => {
                if (elem.default.cssClass == 'ace-kr-theme') {
                    this.editor.setTheme('ace/theme/kr_theme');
                    this.cssClassName = 'ace-kr-theme';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme, },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'kuroir') {
            import('ace-builds/src-noconflict/theme-kuroir').then(elem => {
                if (elem.default.cssClass == 'ace-kuroir') {
                    this.editor.setTheme('ace/theme/kuroir');
                    this.cssClassName = 'ace-kuroir';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
        if (theme == 'merbivore') {
            import('ace-builds/src-noconflict/theme-merbivore').then(elem => {
                if (elem.default.cssClass == 'ace-merbivore') {
                    this.editor.setTheme('ace/theme/merbivore');
                    this.cssClassName = 'ace-merbivore';
                    this.settingsEditor = [
                        { font: this.font },
                        { theme: theme },
                        { cssClass: this.cssClassName },
                    ];
                    localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
                }
            });
        }
    }
    setThemeWhileReload() {

        const settings = JSON.parse(localStorage.getItem('editorSetting'));
        // theme name font
        if (settings) {
            if (settings[1].theme == null) {
                this.theme = 'chrome';
            } else {
                this.theme = settings[1].theme;
            }
            if (settings[0].font == null) {
                this.font = '13';
            } else {
                this.font = settings[0].font;
            }
            const themeChcek = this.lightThemes.includes(settings[1].theme);
            if (themeChcek == false) {
                this.chromeTabs.nativeElement.classList.add('chrome-tabs-dark-theme');
            } else {
                this.chromeTabs.nativeElement.classList.remove('chrome-tabs-dark-theme');
            }
        } else {
            this.theme = 'chrome';
            this.font = '13';
        }

        // editor theme
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-cobalt') {
                import('ace-builds/src-noconflict/theme-cobalt').then((theme) => {
                    if (theme.default.cssClass == 'ace-cobalt') {
                        this.editor.setTheme('ace/theme/cobalt');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-chrome') {
                import('ace-builds/src-noconflict/theme-chrome').then((theme) => {
                    if (theme.default.cssClass == 'ace-chrome') {
                        this.editor.setTheme('ace/theme/chrome');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-clouds-midnight') {
                import('ace-builds/src-noconflict/theme-clouds_midnight').then((theme) => {
                    if (theme.default.cssClass == 'ace-clouds-midnight') {
                        this.editor.setTheme('ace/theme/clouds_midnight');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-tomorrow') {
                import('ace-builds/src-noconflict/theme-tomorrow').then((theme) => {
                    if (theme.default.cssClass == 'ace-tomorrow') {
                        this.editor.setTheme('ace/theme/tomorrow');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-clouds') {
                import('ace-builds/src-noconflict/theme-clouds').then((theme) => {
                    if (theme.default.cssClass == 'ace-clouds') {
                        this.editor.setTheme('ace/theme/clouds');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-ambiance') {
                import('ace-builds/src-noconflict/theme-ambiance').then((theme) => {
                    if (theme.default.cssClass == 'ace-ambiance') {
                        this.editor.setTheme('ace/theme/ambiance');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-chaos') {
                import('ace-builds/src-noconflict/theme-chaos').then((theme) => {
                    if (theme.default.cssClass == 'ace-chaos') {
                        this.editor.setTheme('ace/theme/chaos');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-cobalt') {
                import('ace-builds/src-noconflict/theme-cobalt').then((theme) => {
                    if (theme.default.cssClass == 'ace-cobalt') {
                        this.editor.setTheme('ace/theme/cobalt');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-crimson-editor') {
                import('ace-builds/src-noconflict/theme-crimson_editor').then((theme) => {
                    if (theme.default.cssClass == 'ace-crimson-editor') {
                        this.editor.setTheme('ace/theme/crimson_editor');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-dawn') {
                import('ace-builds/src-noconflict/theme-dawn').then((theme) => {
                    if (theme.default.cssClass == 'ace-dawn') {
                        this.editor.setTheme('ace/theme/dawn');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-dracula') {
                import('ace-builds/src-noconflict/theme-dracula').then((theme) => {
                    if (theme.default.cssClass == 'ace-dracula') {
                        this.editor.setTheme('ace/theme/dracula');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-dreamweaver') {
                import('ace-builds/src-noconflict/theme-dreamweaver').then((theme) => {
                    if (theme.default.cssClass == 'ace-dreamweaver') {
                        this.editor.setTheme('ace/theme/dreamweaver');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-eclipse') {
                import('ace-builds/src-noconflict/theme-eclipse').then((theme) => {
                    if (theme.default.cssClass == 'ace-eclipse') {
                        this.editor.setTheme('ace/theme/eclipse');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-github') {
                import('ace-builds/src-noconflict/theme-github').then((theme) => {
                    if (theme.default.cssClass == 'ace-github') {
                        this.editor.setTheme('ace/theme/github');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-gob') {
                import('ace-builds/src-noconflict/theme-gob').then((theme) => {
                    if (theme.default.cssClass == 'ace-gob') {
                        this.editor.setTheme('ace/theme/gob');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-gruvbox') {
                import('ace-builds/src-noconflict/theme-gruvbox').then((theme) => {
                    if (theme.default.cssClass == 'ace-gruvbox') {
                        this.editor.setTheme('ace/theme/gruvbox');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-idle-fingers') {
                import('ace-builds/src-noconflict/theme-idle_fingers').then((theme) => {
                    if (theme.default.cssClass == 'ace-idle-fingers') {
                        this.editor.setTheme('ace/theme/idle_fingers');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-monokai') {
                import('ace-builds/src-noconflict/theme-monokai').then((theme) => {
                    if (theme.default.cssClass == 'ace-monokai') {
                        this.editor.setTheme('ace/theme/monokai');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-iplastic') {
                import('ace-builds/src-noconflict/theme-iplastic').then((theme) => {
                    if (theme.default.cssClass == 'ace-iplastic') {
                        this.editor.setTheme('ace/theme/iplastic');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-katzenmilch') {
                import('ace-builds/src-noconflict/theme-katzenmilch').then((theme) => {
                    if (theme.default.cssClass == 'ace-katzenmilch') {
                        this.editor.setTheme('ace/theme/katzenmilch');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-kr-theme') {
                import('ace-builds/src-noconflict/theme-kr_theme').then((theme) => {
                    if (theme.default.cssClass == 'ace-kr-theme') {
                        this.editor.setTheme('ace/theme/kr_theme');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-kuroir') {
                import('ace-builds/src-noconflict/theme-kuroir').then((theme) => {
                    if (theme.default.cssClass == 'ace-kuroir') {
                        this.editor.setTheme('ace/theme/kuroir');
                    }
                })
            }
        }
        if (settings) {
            const className: string = settings[2].cssClass;
            if (className == 'ace-merbivore') {
                import('ace-builds/src-noconflict/theme-merbivore').then((theme) => {
                    if (theme.default.cssClass == 'ace-merbivore') {
                        this.editor.setTheme('ace/theme/merbivore');
                    }
                })
            }
        }
    }
    // font size set
    editorFontOption(font) {
        this.settingsEditor = [
            { font: font },
            { theme: this.theme },
            { cssClass: this.cssClassName }
        ];
        localStorage.setItem('editorSetting', JSON.stringify(this.settingsEditor));
        this.editors(this.editorOption);
        this.font = this.editorOption.font;
    }
    changeMainFile(event) {
        const temp = event.split('.').slice(0)[0];
        this.mainDocumentFileName = event;
        this.mainTexName = this.mainDocumentFileName.split('/').pop();
        this.updateMainFileName(event);
        this.getArticleDetails();
        this.generate_xml_or_pdf(
            this.defaultCompilingOptions[0].compileKeyword,
            this.defaultCompilingOptions[0].compileOutput
        );
        // this.recompile()
    }

    // change current compiler
    changeCompiler(event) {
        this.compilerName = event;
        localStorage.setItem('compilerName', event);
        if (event === 'latex') {
            this.compilerEngine = 'pdftex';
            localStorage.setItem('compilerEngine', this.compilerEngine);
        }
        if (event === 'pdflatex') {
            this.compilerEngine = 'pdftex';
            localStorage.setItem('compilerEngine', this.compilerEngine);
        }
        if (event === 'xelatex') {
            this.compilerEngine = 'xetex';
            localStorage.setItem('compilerEngine', this.compilerEngine);
        }
        if (event === 'lualatex') {
            this.compilerEngine = 'luatex';
            localStorage.setItem('compilerEngine', this.compilerEngine);
        }
    }
    updateMainFileName(event) {
        const id = this.selectednode[this.activeTab].nodeid;
        this.editorService.updateFileName(id, event).subscribe(
            res => {
                if (res.type == 'success') {
                    this.notifier.notify('success', res.message);
                } else {
                    this.notifier.notify('error', res.message);
                }
            },
            err => {
                console.log(err);
                this.notifier.notify('error', err);
            }
        );


        // update current main document chrome tab 
        const current_main_file = this.selectednode.find(item => item.real_path == this.mainDocumentFileName);

        if (current_main_file !== undefined) {

            var ids = current_main_file.id;

            var currentTab = document.querySelector('div[data-tab-id="' + ids + '"]');

            this.updateMainDocTab(currentTab, current_main_file);

        }

        // update previous main document chrome tab 
        const previous_main_file = this.selectednode.find(item => item.real_path == this.previousMainFileName);

        if (previous_main_file) {

            var ex_id = previous_main_file.id;

            var previousTab = document.querySelector('div[data-tab-id="' + ex_id + '"]');

            this.updatePreviousTab(previousTab, previous_main_file);
        }


        this.previousMainFileName = this.mainDocumentFileName;
    }

    //Keydown event for ace editor for snippet
    // save functionality for adding snippets
    saveSnippetFile(data) {
        if (data.ctrlKey && data.keyCode == 83) {
            var snippetManager = ace.require('ace/snippets').snippetManager;
            console.log(snippetManager);
            // var m = snippetManager.files[this.editor.$mode.$id];
            // m.snippetText = this.snippettext;
            // snippetManager.unregister(m.snippets);
            // m.snippets = snippetManager.parseSnippetFile(m.snippetText, m.scope);
            // snippetManager.register(m.snippets, 'tex');
            // this.Snippet = m.snippets;
            // let editedValue = '';
            // this.Snippet.forEach(snippet => {
            //     var spaceRemoved = snippet.content.replace(/\s/g, '   ');
            //     this.snippets += '\\n' + 'snippet' + ' ' + snippet.name + '\\n ' + spaceRemoved.replace(/\\/g, '\\\\');

            // });

            // data.preventDefault();
            // this.editorService
            //     .saveSnippet(this.snippets)
            //     .subscribe(datas => {
            //         this.saveValue(datas);
            //     });

        }
    }

    journals: any = [];

    // Open RVClean settings pattern
    openCleanSettingsModal(_modal) {
        this.currentJournalCode = this.selectednode[this.activeTab].journal;
        this.isHideEditor = true;
    }

    // function for listing snippets
    snippetList() {
        var langtools = ace.require("ace/ext/language_tools");
        this.editorService.snippetList().subscribe(
            response => {
                this.snippet_list = response;
                this.dataSource.data = response;
                this.snippetTable = response;
                var snippet = [];
                response.forEach(element => {
                    snippet.push(
                        {
                            caption: element.name,
                            snippet: element.content,
                            meta: element.meta,
                            type: element.type,
                            score: element.score
                        }
                    )
                });
                var rhymeCompleter = {

                    getCompletions: function (_editor, _session, _pos, _prefix, callback) {
                        callback(null, snippet);
                    }
                }
                this.editor.completers = [];
                this.editor.completers.push(rhymeCompleter);
            },
            _error => { }
        )

    }

    sinppetFormClear() {
        this.addSnippets.reset();
    }
    submitted = false;
    // function for adding snippets from datatable
    addSnippet() {
        this.submitted = true;
        if (this.addSnippets.invalid) {
            return;
        }
        this.editorService.addSnippet(this.addSnippets.value).subscribe(
            response => {
                if (response.status === "success") {
                    this.saveValue(response);
                    this.snippetList();
                    this.addSnippets.reset();
                    this.modalRef.hide();
                    this.submitted = false;
                } else {
                    this.notifier.notify(
                        response.status,
                        this.translate.instant(response.message)
                    );
                }
            },
            _error => {
                this.notifier.notify("error", "something went wrong please check");
            }
        );
    }
    // function for update values of snippets
    updateSnippets() {
        this.submitted = true;

        if (this.editSnippet.invalid) {
            return;
        }
        this.editorService.updateSnippet(this.editSnippet.value).subscribe(
            response => {
                if (response.status === "success") {
                    this.saveValue(response);
                    this.snippetList();
                    this.modalRef.hide();
                    this.submitted = false;
                } else {
                    this.saveValue(response);
                }
            },
            _error => {
                console.log("Error");
            }
        );
    }

    snippetClose() {
        this.submitted = false;
    }

    // function for showing values  in edit snippet modal
    editSnippetData(data, modal) {
        this.openModal(modal);
        this.editSnippet.setValue({ name: data.name, content: data.content, type: data.type, score: data.score, id: data._id });

    }

    //  function for  view snippets in datatable    
    viewSnippetData(data, modal) {
        this.openModal(modal);
        this.editorService.getSnippet(data._id).subscribe(
            response => {

                if (response.status === "success") {
                    this.snippetdata = response.content;
                } else {
                    this.saveValue(response);
                }
            },
            _error => {
                console.log("Error");
            }
        );

    }
    // function for removing snippets from datatable      
    removeSnippet(data) {
        this.coolDialogs
            .confirm(this.translate.instant("editor.confirmFelete"), {
                okButtonText: this.translate.instant("editor.delete"),
                cancelButtonText: this.translate.instant("editor.cancel"),
                title: this.translate.instant("editor.delete")
            })
            .subscribe(res => {
                if (res) {
                    //console.log(data._id);
                    this.editorService.removeSnippet(data._id).subscribe(
                        response => {
                            if (response.status === "success") {
                                this.saveValue(response);
                                this.snippetList();
                                this.modalRef.hide();
                            } else {
                                this.saveValue(response);
                            }
                        },
                        _error => {
                            console.log("Error");
                        }
                    );
                } else {
                    console.log('error');
                }
            });
    }

    //function for search snippets in datatable  
    public doFilter = (value: string) => {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }
    disableExtension(_$event, data) {
        var fileName = data;
        var fileNameExtension = this.getFileExtension(fileName.name);
        if (fileNameExtension != 'tex' && fileNameExtension != 'jpg' && fileNameExtension != 'jpeg' && fileNameExtension != 'gif' && fileNameExtension != 'png' && fileNameExtension != 'tif' && fileName.hasChildren == false) {
            this.treevalue = true;
        } else {
            this.treevalue = false;
        }

    }
    getFileExtension(filename) {
        return filename.split('.').pop();
    }

    commentStatusChange() {
        var currentFile = this.activeTab;
        this.isCommentResolved = this.commentType.value;
        if (currentFile > -1) {
            if (this.commentType.value == 'resolved') {
                var data = {
                    path: this.selectednode[currentFile].commentPath,
                    id: this.selectednode[currentFile].nodeid
                };
                this.editorService.getResolvedComments(data).subscribe(
                    response => {
                        this.resolvedComments = response.comments;
                    },
                    _error => {
                        console.error();
                    }
                );
            }
        }
    }

    // get resolved comments of active file
    getResolvedComments() {
        var currentFile = this.activeTab;
        if (currentFile > -1) {
            var data = {
                path: this.selectednode[currentFile].commentPath,
                id: this.selectednode[currentFile].nodeid
            };
            this.editorService.getResolvedComments(data).subscribe(
                response => {
                    this.resolvedComments = response.comments;
                },
                _error => {
                    console.error();
                });
        }
    }

    // show and hide the xml
    xmlEditorVisibility() {
        if (this.xmlCompiling && this.rightPannel == 'viewXml')
            return false;
        else
            return true;
    }

    // show and hide the editor || !this.isHideEditor
    editorVisibility() {
        if (this.LoadImage || this.LoadObj) {
            return "none";
        } else {
            return "block";
        }
    }

    // highlight the resolved comments in editor
    goToResolvedComment(event, range) {
        var target = event.currentTarget;
        var divByClass = Array.from(document.querySelectorAll('.comment-box'));
        divByClass.forEach(element => {
            element.classList.remove('comment-box');
        });
        target.classList.add('comment-box');
        this.editor.selection.setRange(range);
        this.editor.scrollToLine(range.start.row + 1, true, true, function () { });
    }

    rvCleanWidth;
    ngDoCheck() {
        this.screenInnerWidth = window.innerWidth;
        // compile pdf view zoom value
        this.asideWidth = this.asideDivver.nativeElement.clientWidth;
        // this.pdfZoom = this.asideWidth/815;

        // compile pdf view width
        var pdfDiv: any = document.querySelector('.aside-pdf-div');
        pdfDiv.style.width = this.asideWidth + 'px';

        // editor width   
        this.articleWidth = (this.articleTab.nativeElement.clientWidth);
        // navbar width
        this.navBarWidth = this.navTab.nativeElement.clientWidth;
        // console.log(this.articleWidth + this.navBarWidth);

        // editor resizebar full view icon position
        var iconDiv: any = document.querySelector('.resizeDotIcon');
        iconDiv.style.left = this.articleWidth + 1 + 'px';

        var iconDiv: any = document.querySelector('.resizeDotIconNav');
        iconDiv.style.left = this.navBarWidth + 36 + 'px';

        var articleDiv: any = document.querySelector('.resizeBarIcon');
        articleDiv.style.left = this.articleWidth + 'px';

        var navDiv: any = document.querySelector('.resizeBarIconNav');
        navDiv.style.left = this.navBarWidth + 35 + 'px';

        // if(this.isHideEditor){
        //     var rvcleandiv: any = document.querySelector('.rvcleandiv');
        //     // console.log(this.rvCleanTab.nativeElement.clientWidth);
        //     // console.log(this.screenInnerWidth - this.navBarWidth);
        //     this.rvCleanWidth =  this.screenInnerWidth - this.navBarWidth - 10 + 'px';
        //     rvcleandiv.style.width = this.screenInnerWidth - this.navBarWidth - 10 + 'px'; 
        //     // rvcleandiv.style.width = this.rvCleanTab.nativeElement.clientWidth + 'px'; 
        // }
    }
    Expand() {
        this.checkClicked = true;
        this.editorExpand = true;

        const ediotrWidth = this.articleWidth + this.asideWidth + 8;
        // editor width view
        var editorDiv: any = document.querySelector('.main-editor');
        editorDiv.style.width = (ediotrWidth) + 'px';

        const wrapWidth = this.articleWidth / 8.5;
        this.editor.session.setOptions({
            wrap: wrapWidth
        });

        localStorage.setItem('articleWidth', this.articleWidth);
        localStorage.setItem('asideWidth', this.asideWidth);

        const editorZoomWidth = ediotrWidth / 815;
        this.editorPdfZoom = editorZoomWidth;

        this.editors(this.editorOption.wrap = true);
        this.editors(this.editorOption.wrapb = true);

        setTimeout(() => this.editorTabs.layoutTabs(), 100);

        // editor maxximum width view
        var editorDiv: any = document.querySelector('.main-editor');
        editorDiv.style.maxWidth = ediotrWidth + 'px';
        return this.editors(this.editorOption);
    }
    ExpandNav() {
        this.editorExpandNav = true;

        localStorage.setItem('articleWidthNav', this.articleWidth);
        localStorage.setItem('asideTabWidth', this.asideWidth);

        if (this.editorExpand === true) {
            const ediotrWidth = this.screenInnerWidth - 8;
            var editorDiv: any = document.querySelector('.main-editor');
            editorDiv.style.width = (ediotrWidth) + 'px';
            editorDiv.style.maxWidth = ediotrWidth + 'px';
            const wrapWidth = this.articleWidth / 8.5;
            this.editor.resize();
            this.editor.session.setOptions({
                wrap: true
            });
            localStorage.setItem('navBarWidth', this.navBarWidth);
            const maXWidth = { event: this.maxWidth }
            this.widthData = { ...maXWidth }
        }
        this.editors(this.editorOption.wrap = true);
        this.editors(this.editorOption.wrapb = true);
        return this.editors(this.editorOption);
    }
    // CloseValidate() {
    //     this.coolDialogs
    //         .confirm("Your changes will be lost. This action cannot be undone! continue?", {
    //             okButtonText: "Ok",
    //             cancelButtonText: "Cancel",
    //             title: "CONFIRM"

    //         })
    //         .subscribe(res => {
    //             if (res) {
    //                 $("#search_replace_body span.span-for-highlight").contents().unwrap();
    //                 this.modalRef.hide();
    //             } else {
    //                 return false;
    //             }
    //         });
    // }
    // function for collapse  ace editor and setting wrap value
    Collapse() {
        this.checkClicked = false;
        this.editorExpand = false;
        var icondiv: any = document.querySelector('.zoomIcons');
        if (icondiv) {
            icondiv.style.display = 'block';
        }

        if (this.editorExpandNav == false) {
            const widther = (this.screenInnerWidth - this.navBarWidth) / 2;
            // const token:any = localStorage.getItem('articleWidth');
            // const token2:any = localStorage.getItem('asideWidth');
            var editorDiv: any = document.querySelector('.main-editor');
            editorDiv.style.width = widther + 'px';
            // var asideDiv: any = document.querySelector('.aside-pdf-div');
            //     asideDiv.style.width = token2+'px';       
            this.editor.session.setOptions({
                wrap: '50'
            });
        } else {
            const articleWidth: any = localStorage.getItem('articleWidth');
            const asidewidth: any = localStorage.getItem('asideWidth');

            var editorDiv: any = document.querySelector('.main-editor');
            editorDiv.style.width = articleWidth + 'px';

            var asideDiv: any = document.querySelector('.aside-pdf-div');
            asideDiv.style.width = asidewidth + 'px';

            this.editor.session.setOptions({
                wrap: '50'
            });
        }
        setTimeout(() => this.editorTabs.layoutTabs(), 100);
    }
    CollapseNav() {
        this.editorExpandNav = false;
        if (this.editorExpand === true) {
            const navBarWidth: any = localStorage.getItem('navBarWidth');
            // const ediotrWidth = this.articleWidth- token-27;
            const ediotrWidth = this.articleWidth - navBarWidth - 300;
            var editorDiv: any = document.querySelector('.main-editor');
            editorDiv.style.width = ediotrWidth + 'px';
            editorDiv.style.maxWidth = ediotrWidth + 'px';
            // var icondiv: any = document.querySelector('.zoomIcons');
            //     icondiv.style.display = 'none';
        }
        else {
            const width = this.screenInnerWidth;
            var editorDiv: any = document.querySelector('.main-editor');
            editorDiv.style.width = this.articleWidth + 9 + 'px';
            editorDiv.style.maxWidth = this.articleWidth + 9 + 'px';
            // const  pdfDIvver = localStorage.getItem('asideTabWidth');
            // console.log(this.asideWidth);
            // var pdfDiv : any= document.querySelector('.aside-pdf-div');
            //     pdfDiv.style.width = this.asideWidth+'px';
            //     pdfDiv.style.maxWidth = this.asideWidth+'px';   
            var icondiv: any = document.querySelector('.zoomIcons');
            icondiv.style.display = 'block';
        }
    }
    // nav resize bar
    onResizeNav(event: ResizedEvent) {
        const totalScreenWidth = this.screenInnerWidth;
        const navbarWidth = event.newWidth;
        const anotherContainers = (totalScreenWidth - navbarWidth) / 2;

        if (this.editorExpand == true) {
            var editorDiv: any = document.querySelector('.main-editor');
            editorDiv.style.width = this.articleWidth + 'px'
        } else {
            var editorDiv: any = document.querySelector('.main-editor');
            editorDiv.style.width = anotherContainers + 'px';
        }
        var commentDiv = document.querySelectorAll('.comment-main-name');
        if (commentDiv.length) {
            commentDiv.forEach((element, _index) => {
                element.setAttribute('style', "width:" + (this.navBarWidth - 140) + 'px');
            })
        }
    }
    // editor resize bar
    onResized(event: ResizedEvent) {
        const width = ((this.screenInnerWidth - (this.articleWidth + this.navBarWidth)) - 48);
        this.pdfZoom = width / 815;
        const editorZoomWidth = this.articleWidth / 815;
        this.editorPdfZoom = editorZoomWidth;
        this.comparedPdfZoom = width / 815;
        if (event.oldWidth > event.newWidth) {
            this.checkClicked = false;
        } else {
            if (this.editorExpand === false) {
                var editorDiv: any = document.querySelector('.main-editor');
                editorDiv.style.maxWidth = (this.articleWidth + width - 250) + 'px';
            }
        }
        if (!this.checkClicked) {
            // main editor view
            const editorWidth = event.newWidth;
            const wrapWidth = this.articleWidth / 8.5;
            if (this.editor) {
                this.editor.resize();
                this.editor.setOptions({
                    // wrap: wrapWidth,
                    wrap: true,
                    // autoScrollEditorIntoView: true
                });
            }
            // editor pdf view
            const editorZoomWidth = this.articleWidth / 815;
            this.editorPdfZoom = editorZoomWidth;
            // xml editor view
            if (this.xmlEditor) {
                const xmlWidth = this.asideWidth;
                const xmlWrapWidth = xmlWidth / 8;
                this.xmlEditor.setOptions({
                    wrap: xmlWrapWidth
                });
            }
            this.editorExpand = false;
        }
        // for chrome tabs responsive
        if (this.editorCPanelOptions) {
            setTimeout(() => this.editorTabs.layoutTabs(), 100);
        }

    }
    CloseValidate() {

        if (this.accept_reject_changed == true) {
            this.coolDialogs
                .confirm("Your changes will be lost. This action cannot be undone! continue?", {
                    okButtonText: "Ok",
                    cancelButtonText: "Cancel",
                    title: "CONFIRM"

                })
                .subscribe(res => {
                    if (res) {
                        this.undoStack = [];
                        $("#search_replace_body span.span-for-highlight").contents().unwrap();
                        this.modalRef.hide();
                        this.isLoaded = true;
                        this.accept_reject_changed = false;
                    } else {
                        return false;
                    }
                });
        } else {

            this.modalRef.hide();
            this.isLoaded = true;
            this.accept_reject_changed = false;
        }
    }
    textLayerRendered(_event) {

    }
    //function to calcupdflate total pages in 
    afterLoadComplete(pdfData: any) {
        this.totalPages = pdfData.numPages;
        this.isLoaded = true;
        this.scrollPdfToview();
    }
    zoom_in() {
        this.pdfZoom = this.pdfZoom + 0.25;
    }
    zoom_out() {
        if (this.pdfZoom > 0.25) {
            this.pdfZoom = this.pdfZoom - 0.25;
        }
    }
    zoom_in_editor() {
        this.editorPdfZoom = this.editorPdfZoom + 0.25;
    }
    zoom_out_editor() {

        if (this.editorPdfZoom > 0.25) {
            this.editorPdfZoom = this.editorPdfZoom - 0.25;
        }
    }

    // Mapping TreeView fields property with data source properties
    @ViewChild('treevalidate') treevalidate: TreeViewComponent;
    @ViewChild('contextmenu')
    public contextMenu: ContextMenuComponent;
    @ViewChild("fileuploadmodal") fileUploaderModule: TemplateRef<any>;
    public allowDragAndDrop: boolean = true;
    public allowEditing: boolean = false;
    public hierarchicalData;
    public field;
    public names;
    foldersname;
    childicons;
    parentname;
    commentPath;
    //Render the context menu with target as Treeview
    public menuItems: MenuItemModel[] = [
        { text: this.translate.instant("editor.newFolder"), iconCss: 'fa fa-folder-o' },
        { text: this.translate.instant("editor.newFile"), iconCss: 'fa fa-file-o' },
        { text: this.translate.instant("editor.rename"), iconCss: 'fa fa-pencil-square-o' },
        { text: this.translate.instant("editor.Close"), iconCss: 'fa fa-times' },
        { text: this.translate.instant("editor.delete"), iconCss: 'fa fa-trash-o' },
        { text: this.translate.instant('editor.uploadFile'), iconCss: 'fa fa-upload' },
        { text: this.translate.instant("rvclean.replace"), iconCss: "fa fa-refresh" },
        { text: this.translate.instant("editor.extract here"), iconCss: "fa fa-file-archive-o" },
        { text: this.translate.instant("editor.annotate pdf"), iconCss: "fa fa-file-text-o" }
    ];

    // Click event on context menu.
    public menuclick(args: MenuEventArgs) {
        let targetNodeId = this.treevalidate.selectedNodes[0].toString();
        let targetNode = this.treevalidate.getTreeData(targetNodeId)[0];
        if (args.item.text == this.translate.instant("editor.newFolder") || args.item.text == this.translate.instant("editor.newFile")) {
            const targetChildren: any = targetNode.children;
            let id = targetChildren.length + 1;
            let newId = targetNodeId + '-' + id;
            let itemName;
            let item;
            if (args.item.text == this.translate.instant("editor.newFolder")) {
                itemName = this.getItemName(targetChildren, 'New Folder', 'New Folder', 0);
                item = {
                    id: newId,
                    name: itemName,
                    expanded: true,
                    icon: 'folder',
                    children: [],
                    hasChildren: true,
                    folderId: Date.now(),
                    edit: true,
                    view: true,
                    delete: true,
                    rename: true
                };
            } else if (args.item.text == this.translate.instant("editor.newFile")) {
                itemName = this.getItemName(targetChildren, 'newFile', 'newFile', 0);
                item = {
                    id: newId,
                    name: itemName + '.tex',
                    icon: 'tex',
                    hasChildren: false,
                    edit: true,
                    view: true,
                    delete: true,
                    rename: true
                };
            }
            this.treevalidate.addNodes([item], targetNodeId, null);
            var splitedId = newId.split('-');
            let articleIndex: any = splitedId[0];
            let articleChildren: Object = this.treevalidate.getTreeData(articleIndex)[0].children;
            let articleDetails = this.getFileDetails(newId);
            const articleData = {
                articleFolder: articleDetails.articleFolder,
                code: articleDetails.code,
                path: articleDetails.path,
                data: articleChildren,
                type: item.icon,
                name: item.name
            };
            this.editorService.addNode(articleData).subscribe(
                response => {
                    if (response.status == 'error') {
                        this.treevalidate.removeNodes([newId]);
                    } else {
                        this.hierarchicalData = this.updateTreeData(this.hierarchicalData, targetNodeId, item, 'create');
                        this.treevalidate.beginEdit(newId);
                    }
                }
            );
        } else if (args.item.text == this.translate.instant("editor.delete")) {
            this.deleteNode(targetNodeId, targetNode);
        } else if (args.item.text == this.translate.instant("editor.rename")) {
            this.treevalidate.beginEdit(targetNodeId);
        } else if (args.item.text == this.translate.instant("editor.Close")) {
            let articles = JSON.parse(localStorage.getItem("articles"));
            const index: number = articles.indexOf(targetNode.code);
            if (index !== -1) {
                articles.splice(index, 1);
                localStorage.setItem("articles", JSON.stringify(articles));
                this.closeActivetabByDelete(targetNode);
                this.treevalidate.removeNodes([targetNodeId]);
                this.hierarchicalData = this.updateTreeData(this.hierarchicalData, targetNodeId, '', 'delete');
            }
        } else if (args.item.text == this.translate.instant("editor.uploadFile")) {
            this.fileList = {
                status: 'error',
                message: '',
                files: []
            };
            // this.editorService.getAllowedFiles({ id: this.getFileDetails(targetNodeId).code }).subscribe(
            //     response => {
            //         if (response.status == 'success') {
            //             this.allowedFiles = response.files.join(',');
            //         } else {
            //             this.allowedFiles = '';
            //         }
            //     },
            //     error => { }
            // );
            this.getAllowedFiles(this.getFileDetails(targetNodeId).code);
            this.openModal(this.fileUploaderModule);
        } else if (args.item.text == this.translate.instant("rvclean.replace")) {
            this.openModal(this.fileReplaceModal);
            this.fileReplaceAllowedType = '.' + this.getFileExtension(targetNode.name);
        } else if (args.item.text == this.translate.instant("editor.extract here")) {
            this.extractZip(targetNodeId, targetNode);
        } else if (args.item.text == this.translate.instant("editor.annotate pdf")) {
            /* start -- fiding the document path */
            let nodeIdArray = (<string>targetNode.id).split('-')
            let articleChildren = this.articleInfo['article_children']

            this.pathToFile = ''
            nodeIdArray.shift()
            this.findPathToFileRecursively(nodeIdArray, articleChildren)
            /* end -- fiding the document path */

            if (this.pathToFile == '') {
                return
            }

            let PDFToAnnotate = this.env.baseUploadUrl + '/public/articles/article_files/' + this.articleInfo['article_file'] + '/' + this.pathToFile + targetNode.name
            this.viewPDFAnnotation(PDFToAnnotate)
        }
    }

    // Recursive function for Creating the default new folder/file name . 
    getItemName(list, baseName, currentName, count: number) {
        let index;
        if (baseName == 'newFile')
            index = list.findIndex((item) => item.name == (currentName + '.tex'));
        else
            index = list.findIndex((item) => item.name == currentName);
        // Creating new name, if the current name already exist otherwise return the current name.
        if (index == -1) {
            currentName = currentName;
        } else {
            count = count + 1;
            currentName = this.getItemName(list, baseName, baseName + count, count);
        }
        return currentName;
    }
    commentTextareaClick(event) {
        if (event.isTrusted === true) {
            this.textAreaClickCheck = true;
        } else {
            this.textAreaClickCheck = false;
        }
    }

    // Loading the context menu items dynamicaly.
    public beforeopen(args: BeforeOpenCloseMenuEventArgs) {
        this.contextMenu.hideItems([this.translate.instant("editor.extract here"), this.translate.instant("editor.annotate pdf")])
        // console.log(args);
        const nodeId = (args.event.target as HTMLInputElement).parentElement.getAttribute('data-uid');
        this.treevalidate.selectedNodes = [nodeId];
        // console.log(nodeId);
        let nodeData = this.treevalidate.getTreeData(nodeId)[0];
        this.contextMenu.showItems([
            this.translate.instant("editor.Close"),
            this.translate.instant("editor.newFile"),
            this.translate.instant("editor.newFolder"),
            this.translate.instant("editor.rename"),
            this.translate.instant("editor.delete"),
            this.translate.instant("editor.uploadFile"),
            this.translate.instant("rvclean.replace")
        ]);
        // load article menu items, if currently clicked node is article. 
        if (nodeData.code) {
            this.contextMenu.hideItems([this.translate.instant("editor.delete"), this.translate.instant("rvclean.replace")]);
        } else if (nodeData.hasChildren) {
            // Load folder menu items, if the node is folder
            this.contextMenu.hideItems([this.translate.instant("editor.Close"), this.translate.instant("rvclean.replace")]);
        } else if (nodeData.name != this.mainDocumentFileName) {
            // Load file menu items, if it is file
            this.contextMenu.hideItems([
                this.translate.instant("editor.Close"),
                this.translate.instant("editor.newFile"),
                this.translate.instant("editor.newFolder"),
                this.translate.instant("editor.uploadFile")
            ]);

            // enable extract for zip
            if ((<string>nodeData.name).split('.').pop() == 'zip') {
                this.contextMenu.showItems([this.translate.instant("editor.extract here")])
            }
            else if ((<string>nodeData.name).split('.').pop() == "pdf") {
                this.contextMenu.showItems([this.translate.instant("editor.annotate pdf")])
            }

        }
        else {
            args.cancel = true;
        }
    }

    public nodeDrag(args: DragAndDropEventArgs): void {
        if (args.droppedNode.getElementsByClassName('folder').length === 0) args.dropIndicator = 'e-no-drop';
    }
    public dragStop(args: DragAndDropEventArgs): void {
        if (args.droppedNode.getElementsByClassName('folder').length === 0) args.cancel = true;
    }

    getFileIcon(icons) {
        var images = ["tex", "ppt", "docx", "code", "xml", "exe", "zip", "pdf", "txt", "bib", "docx", "doc", "bbl"];
        if (images.indexOf(icons) > -1) {
            return icons;
        } else if (icons == 'png' || icons == 'jpg' || icons == 'jpeg' || icons == 'gif' || icons == 'tif') {
            return 'images';
        } else {
            return 'code';
        }
    }

    // Recursive function for structuring the tree. 
    getChildrens(node, index) {
        console.log(node);
        if (node.hasChildren) {
            var children = [];
            node.children.forEach((child, childrenIndex) => {
                var folderIndex = childrenIndex + 1;
                var icons = child.name.split('.').pop();
                var images = ["tex", "ppt", "docx", "code", "xml", "exe", "zip", "pdf", "txt", "bib", "docx", "doc", "bbl"];
                this.childicons = this.getFileIcon(icons);
                children.push(this.getChildrens(child, index + '-' + folderIndex));
            });
            return {
                id: index,
                name: node.name,
                expanded: true,
                icon: 'folder',
                children: children,
                code: node.code,
                article_file: node.article_file,
                hasChildren: true,
                folderId: node.folderId,
                edit: node.edit,
                view: node.view,
                delete: node.delete,
                rename: node.rename,
                tooltip: node.name
            };
        } else {
            return {
                id: index,
                name: node.name,
                icon: this.getFileIcon(node.name.split('.').pop()),
                expanded: false,
                code: node.code,
                hasChildren: false,
                edit: node.edit,
                view: node.view,
                delete: node.delete,
                rename: node.rename,
                tooltip: node.name
            };
        }
    }

    // add new node to tree data while creating new node
    updateTreeData(data, targetNodeId, item, operation) {
        data.forEach((element, index) => {
            if (element.id == targetNodeId) {
                switch (operation) {
                    case 'create': {
                        element.children.push(item);
                        break;
                    }
                    case 'update': {
                        element.name = item;
                        break;
                    }
                    case 'delete': {
                        data.splice(index, 1);
                        break;
                    }
                    case 'unzip': {
                        // remove the zip
                        data.splice(index, 1)

                        // traverse through the extracted folder structure and append to the end of node
                        let dataLength = data.length
                        item.forEach((elm, i) => {
                            data[dataLength + i] = elm
                        })

                        break;
                    }
                }
            } else if (element.hasChildren) {
                this.updateTreeData(element.children, targetNodeId, item, operation);
            }
        });
        this.get_all_tex_files();
        return data;
    }

    get_all_tex_files() {
        this.editorService.get_all_tex_files(this.zip_file).subscribe(
            res => {
                this.all_tex_files = res;
            },
            err => {
                console.log(err);
            }
        );
    }

    // Edit event on node. Rename operation performed within the function.
    public onNodeEdited(args: NodeEditEventArgs): void {
        let nodeId: any = args.nodeData.id.toString();
        let nodeData = this.treevalidate.getTreeData(nodeId)[0];
        // validating the given name
        let validation = this.checkValidation(nodeData, args.newText);
        if (validation.status) {
            // node details before editing 
            let oldFile = this.getFileDetails(nodeId);
            const leafBeforeRename = this.getLeafNodes(nodeData, []);
            let checkDuplicate = this.checkDuplicate(nodeId, args.newText);
            if (checkDuplicate.status) {
                // rename the given node
                this.treevalidate.getTreeData(nodeId)[0].name = args.newText;
                // node details after editing
                const leafAfterRename = this.getLeafNodes(this.treevalidate.getTreeData(nodeId)[0], []);
                let articleChildren: Object = this.treevalidate.getTreeData(oldFile.id.toString())[0].children;
                let newFileDetails = this.getFileDetails(nodeId);
                const articleData = {
                    articleFolder: newFileDetails.articleFolder,
                    code: newFileDetails.code,
                    oldPath: oldFile.path,
                    newPath: newFileDetails.path,
                    children: articleChildren,
                    type: nodeData.hasChildren,
                    name: args.newText,
                    commentPathOld: oldFile.commentPath,
                    commentPathNew: newFileDetails.commentPath
                };
                this.editorService.renameNode(articleData).subscribe(
                    response => {
                        if (response.status === "success") {
                            args.node.setAttribute('title', args.newText);
                            this.hierarchicalData = this.updateTreeData(this.hierarchicalData, nodeId, args.newText, 'update');
                            this.treevalidate.getTreeData(nodeId)[0].tooltip = args.newText;
                            this.renameActiveFile(this.treevalidate.getTreeData(nodeId)[0], leafBeforeRename, leafAfterRename);
                        } else {
                            args.cancel = true;
                            this.treevalidate.beginEdit(nodeId);
                            this.treevalidate.getTreeData(nodeId)[0].name = args.oldText;
                            this.notifier.notify('error', response.message);
                        }
                    },
                    _error => {
                    }
                );
            } else {
                args.cancel = true;
                this.notifier.notify('error', checkDuplicate.message);
            }
        } else {
            args.cancel = true;
            this.notifier.notify('error', validation.message);
        }
    }

    dataSourceChanged(args: NodeEditEventArgs) {
        args.cancel = true;
    }
    // function for check the validation of given input. 
    checkValidation(node, name) {
        // check input is null or not
        if (name == '')
            return { status: false, message: this.translate.instant("editor.nameRequired") };
        else {
            // validation for file name
            if (!node.hasChildren) {
                if (!/^[\w,\s-]+\.(tex)$/.test(name)) {
                    return { status: false, message: this.translate.instant("editor.validName") };
                } else {
                    return { status: true, message: '' }
                }
            } else {
                return { status: true, message: '' }
            }
        }
    }

    // check whether given name already exist or not 
    checkDuplicate(nodeId, name) {
        // get node details
        let nodeDetails = this.treevalidate.getTreeData(nodeId)[0];
        let result = { status: true, message: '' };
        if (!nodeDetails.code) {
            let node = this.treevalidate.getNode(nodeId);
            let siblings: any = this.treevalidate.getTreeData(node.parentID.toString())[0].children;
            siblings.forEach(element => {
                if ((element.name == name) && (element.id != nodeId)) {
                    result = { status: false, message: this.translate.instant("editor.nameExist") };
                }
            });
        }
        return result;
    }

    // getting the given file details including article code, path etc
    getFileDetails(nodeId: string) {
        let nodePath = "";
        let commentPath = '';
        let node = this.treevalidate.getTreeData(nodeId.toString())[0];
        while (node) {
            if (node.code) {
                var result;
                result = {
                    code: node.code,
                    path: nodePath.slice(0, -1),
                    id: node.id,
                    articleName: node.name,
                    articleFolder: node.article_file,
                    commentPath: commentPath.slice(0, -1)
                };
                return result;
            }
            if (node.hasChildren)
                commentPath = node.folderId + "/" + commentPath;
            else
                commentPath = node.name + "/" + commentPath;
            nodePath = node.name + "/" + nodePath;
            let parentId: any = this.treevalidate.getNode(node.id.toString()).parentID.toString();
            node = this.treevalidate.getTreeData(parentId)[0];
        }
    }

    @ViewChild('defaultupload')
    public uploadObj: UploaderComponent;
    public path: Object = {
        saveUrl: this.env.baseAPIUrl + '/upload-file'
    };
    nodeList = [];
    idList = [];
    uploadingFile;
    fileList;

    // Selecting file. 
    public onFileSelect(args: SelectedEventArgs): void {
        let elem = args.filesData;
        if (elem.length > 0) {
            let targetNodeId = this.treevalidate.selectedNodes[0].toString();
            var formData = new FormData();
            let nodeDetails = this.getFileDetails(targetNodeId);
            formData.append('path', nodeDetails.articleFolder + '/' + nodeDetails.path);
            formData.append('file', elem[0].rawFile);
            formData.append('article', nodeDetails.code);
            this.sharedService.showSpinner('articleUpload');
            this.editorService.validateUploadingFile(formData)
                .subscribe(
                    (success: any) => {
                        this.sharedService.hideSpinner('articleUpload');
                        args.filesData[0].status = success.message;
                        if (success.status != 'success') {
                            args.filesData[0].statusCode = '0';
                            // args.filesData[0].fileSource = success.files.join(',');
                            args.filesData[0].fileSource = success.files;
                            args.filesData[0]['invalidFiles'] = success.files;
                        }
                        else {
                            args.filesData[0].statusCode = '1';
                        }
                        this.fileList = success.files;
                        args.isModified = true;
                    },
                    _error => { }
                );
        }
    }

    // Add aditional data on upload
    uploadingFileSync: EmitType<SelectedEventArgs> = (args: any) => {
        let targetNodeId = this.treevalidate.selectedNodes[0].toString();
        let nodeDetails = this.getFileDetails(targetNodeId);
        let tempNode = this.treevalidate.getTreeData(targetNodeId)[0];
        let targetChildren: any = tempNode.children;
        let numberOfChildren = targetChildren.length + 1;
        // id of new nodes
        this.idList = [];
        // list of new nodes
        this.nodeList = [];
        // Adding uploaded files into the tree
        this.fileList.forEach((element, index) => {
            let count = numberOfChildren + index;
            let itemId = targetNodeId + '-' + count;
            // structuring data for tree
            let node = this.getChildrens(element, itemId);
            this.idList.push(itemId);
            this.nodeList.push(node);
            // adding new node into children
            this.treevalidate.addNodes([node], targetNodeId, null);
        });
        let path;
        if (!nodeDetails.path) {
            path = nodeDetails.articleFolder;
        } else {
            path = nodeDetails.articleFolder + '/' + nodeDetails.path;
        }
        let children = this.treevalidate.getTreeData(nodeDetails.id)[0].children;
        // add aditional data on upload as key value pair
        args.customFormData = [
            { 'path': path },
            { 'id': nodeDetails.code },
            { 'children': JSON.stringify(children) }
        ];
    }

    // File upload failed
    public onUploadFailed(_args: any): void {
        this.treevalidate.removeNodes(this.idList);
    }

    // file upload success
    public onUploadSuccess(_args: any) {
        let targetNodeId = this.treevalidate.selectedNodes[0].toString();
        // Update tree data
        this.nodeList.forEach(element => {
            this.hierarchicalData = this.updateTreeData(this.hierarchicalData, targetNodeId, element, 'create');
        });
        this.getFileList();
    }

    // get file list after file upload
    public getFileList() {
        const id = this.route.snapshot.paramMap.get("id");
        this.editorService.getFolderStructure({ id: [id] }).subscribe(response => {
            if (response.length < 1) {
                this.router.navigate(["my-articles"]);
            } else {
                this.mainDocumentFileName = response[0]['mainDocument'];
                this.editorFileList = response[0]['children'];
            }
        });
    }

    @ViewChild('moveNextStageModal') moveNextStageModal
    @ViewChild('completeAndMerge') completeAndMerge
    openNextStageModal(modal = this.moveNextStageModal) {
        if (this.route.snapshot.paramMap.get("sid") != null) {
            modal = this.completeAndMerge
        }
        let postData = {
            art_ids: [this.selectednode[this.activeTab].nodeid]
        }

        this.sharedService.getDetailsForStageMoveSvs(postData).subscribe(response => {
            if (response.status === 200) {
                this.activeArticleData = response.articles[0];
            }
        });

        this.modalRef = this.modalService.show(modal, {
            animated: true,
            keyboard: true,
            backdrop: true,
            ignoreBackdropClick: true,
        });
    }
    showpdf() {
        this.rightPannel = 'viewPdf';
        this.generatepdfurl = JSON.parse(localStorage.getItem("pdfurl"));
        this.pdfUrl = this.generatepdfurl;
    }
    showComparedPdf() {
        this.rightPannel = 'comparedPdf';
    }
    showxml() {
        console.log(this.text1)
        // this.xmldata = this.text1;//JSON.parse(localStorage.getItem("xmldata"));       
        if (this.text1) {
            this.rightPannel = 'viewXml';
            // this.text1 = this.xmldata;     
        } else {
            this.notifier.notify('error', 'Xml not found! Please generate xml');
        }
        this.xmlEditorOptions = {
            readOnly: true,
            showLineNumbers: true,
            keyboardHandler: null,
            wrapBehavioursEnabled: true,
            autoScrollEditorIntoView: true,
            highlightActiveLine: true,
            wrap: true,
            tooltip:true,
            fontSize: '10pt'
        };
    }

    public noWhitespaceValidator(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    @ViewChild('contextmenuEditor')
    public contextmenuEditor: ContextMenuComponent;

    // ContextMenu items definition
    public menuItems2: MenuItemModel[] = [
        {
            text: 'Cut',
            iconCss: 'fa fa-cut'
        },
        {
            text: 'Copy',
            iconCss: 'fa fa-copy'
        },
        {
            text: 'Paste',
            iconCss: 'fa fa-paste',
        },
        {
            separator: true
        },
        {
            text: 'Go to line',
            iconCss: 'fa fa-arrow-circle-right'
        },
        {
            text: 'Add bookmark',
            iconCss: 'fa fa-bookmark',
        },
        {
            text: 'New comment',
            iconCss: 'e-cm-icons e-comment'
        },
        {
            text: 'Spell check',
            iconCss: 'fa fa-check'
        },
        {
            text: 'Block Code',
            iconCss: 'fa fa-code'
        },
        {
            text: 'Reference',
            iconCss: 'fa fa-code'
        },
        {
            text: 'Change case',
            iconCss: 'fa fa-font',
            items: [
                {
                    text: 'To upper'
                },
                {
                    text: 'To lower'
                },
                {
                    text: 'Title case'
                }
            ]
        }
    ];

    contextMenuClick(_event): void {
        var word = this.editor.getSelectedText();
        // var selection = window.getSelection();
        // if(selection.toString().length == 0)

        if (this.otherArticle == true) {
            this.contextmenuEditor.enableItems(['Cut', 'Copy', 'Paste', 'Go to line', 'Add bookmark', 'New comment', 'Spell check', 'Block Code', 'Reference',
                'Change case'], false);
            return;
        }
        if (word.length === 0) {
            this.contextmenuEditor.enableItems(['Cut', 'Copy', 'New comment', 'Block Code', 'Reference', 'Change case'], false);
        } else {
            this.getBlockCodeJnlPatterns();
            this.populateReferencePopupMenu();
            this.contextmenuEditor.enableItems(['Cut', 'Copy', 'New comment', 'Change case'], true);
            // this.contextmenuEditor.removeItems(['Block Code']);
            // this.contextmenuEditor.insertAfter([{text: 'Block Code',iconCss: 'fa fa-code', items: this.blockPatternArrayList}] , 'New comment');
        }

        if (this.isPdf == false) {
            this.contextmenuEditor.enableItems(['Go to line'], false);
        } else {
            this.contextmenuEditor.enableItems(['Go to line'], true);
        }
        var position = this.editor.selection.getCursor();
        this.misspelledSuggestion = [];
        var flag = false;
        this.misspelledWords.forEach((range) => {
            if (range.contains(position.row, position.column)) {
                this.chatService.checkSuggestion(range.word);
                flag = true;
                this.editor.selection.setSelectionRange(range);
                range.suggetion.forEach((element, index) => {
                    this.misspelledSuggestion.push({ id: 'spellcheck_' + index, text: element });
                });
            }
        });
        if (flag) {
            this.contextmenuEditor.removeItems(['Spell check']);
            this.contextmenuEditor.insertAfter([{ text: 'Spell check', iconCss: 'fa fa-check', items: [] }], 'New comment');
            // this.contextmenuEditor.insertAfter([{text: 'Spell check',iconCss: 'fa fa-check', items: this.misspelledSuggestion}] , 'New comment');
        } else {
            this.contextmenuEditor.hideItems(['Spell check']);
        }
    }
    /** 
     * click event for block code replace
     * @author Yadu Chandran
    */
    selectThisBlockCode(event) {
        this.enableBlockCode = false;
        let block_replace_value = event.id;
        let replaced_block_code = block_replace_value.replace('$1', this.editor.getSelectedText());
        var selectionRange: any = this.editor.getSelectionRange();
        this.editor.session.replace(selectionRange, replaced_block_code);
    }

    onPreCleanKeyDown($event) {
        switch ($event.keyCode) {
            case 38: {
                // Up arrow key click
                if (this.selectedPreClean >= 1) {
                    this.selectedPreClean--;
                    this.scrollToPreClean();
                }
                break;
            }
            case 40: {
                // Down arrow key click
                console.log(this.selectedPreClean);
                console.log(this.search.transform(this.preCleanArrayList, this.preCleanSearchText).length - 1)
                if (this.selectedPreClean < this.search.transform(this.preCleanArrayList, this.preCleanSearchText).length - 1) {
                    this.selectedPreClean++;
                    this.scrollToPreClean();
                }
                break;
            }
            case 13: {
                // Enter key clicked
                // Apply the block code template to selected text
                this.setPreClean(this.search.transform(this.preCleanArrayList, this.preCleanSearchText)[this.selectedPreClean]);

                break;
            }
            default: {
                var preCleanLength = this.search.transform(this.preCleanArrayList, this.preCleanSearchText).length - 1;
                if (preCleanLength > this.selectedPreClean || preCleanLength < 0) {
                    this.selectedPreClean = 0;
                }
                break;
            }
        }
    }


    // Scroll to block code
    scrollToPreClean() {
        let str = 'pre_clean_' + this.selectedPreClean;
        let elmnt = document.getElementById(str);
        elmnt.scrollIntoView();
        window.scrollTo(0, 0);
    }
    referencePatternType
    setPreClean(preClean) {
        this.referencePatternType = preClean.id
        console.log(this.referencePatternType)
        this.isShowPreCleanContextMenu = false;
        const selectedText = this.editor.getSelectedText();
        const selectionRange = this.editor.getSelectionRange();
        if (selectedText == undefined || selectedText.trim().length <= 0) {
            return;
        }
        const preCleanedText = AutoRegexUtilities.onPreClean(selectedText);
        this.editorService.getReferencePatternsByReferenceType(preClean.id).subscribe(
            response => {
                if (response.status != "success") {
                    const matches = AutoRegexUtilities.match('reference', preClean.id, preCleanedText, [], false, [response.data]);
                    if (matches.length > 0) {
                        this.editor.session.replace(
                            selectionRange,
                            matches.map(thisMatch => {
                                if (thisMatch.outputs[0].parameters === "null") {
                                    return thisMatch.outputs[0].output
                                }
                                else {
                                    const parameters = thisMatch.outputs[0].parameters;
                                    const taggedResult = AutoRegexUtilities.parseOutputForTags(preClean.id, parameters);
                                    return taggedResult;
                                }
                            }).join("\n \n"));
                    }
                } else {
                }
            },
            _error => {
                console.log("Error");
            }
        );

    }

    setPostClean() {
        this.isShowPostCleanContextMenu = false;
        const selectedText = this.editor.getSelectedText();
        const selectionRange = this.editor.getSelectionRange();
        if (selectedText == undefined || selectedText.trim().length <= 0) {
            return;
        }
        const postCleanedText = AutoRegexUtilities.onPostClean(selectedText);
        this.editorService.getReferencePatterns().subscribe(

            response => {
                if (response.status != "success") {
                    const matches = AutoRegexUtilities.match('reference', null, postCleanedText, null, true, response.data);
                    if (matches.length > 0) {
                        this.editor.session.replace(
                            selectionRange,
                            matches.map(thisMatch => {
                                return thisMatch.outputs[0].output;
                            }).join("\n \n"));
                    }
                }
                else {
                }
            },
            _error => {
                console.log("Error");
            }
        );
    }

    onThisPreCleanClickEvent(event) {
        //     this.isShowPreCleanContextMenu = false;
        //     let reference_replace_value = event.id;
        //     let replaced_reference_code = reference_replace_value.replace('$1', this.editor.getSelectedText());
        //     var selectionRange: any = this.editor.getSelectionRange();
        //     this.editor.session.replace(selectionRange, replaced_reference_code);
        this.setPreClean({ id: event.id });
        //this.setPreClean({id:reference_replace_value})

    }

    // onThisPostCleanClickEvent(event) {
    //     this.setPostClean({ id: event.id });
    // }

    /** 
      * Populate reference template items for context menu and short cut keys=
      * @author Simi Francis
     */
    populateReferencePopupMenu() {
        const article_id = this.route.snapshot.paramMap.get("id");
        this.journalService.getReferenceCodeJnlPatterns({ article_id: article_id }).subscribe(
            response => {
                this.referenceTemplates = response['data'];
                const menuItemObj = {};
                AutoRegexUtilities.getAutoRegexTypes().forEach((type, _) => {
                    menuItemObj[type["id"]] =
                        {
                            text: type["name"],
                            items: [
                                {
                                    id: "_automagic_reference_" + type['id'],
                                    text: "Automagic"
                                }
                            ]
                        };
                });
                const templateTypes: any[] = [];
                if (this.referenceTemplates.length) {
                    this.referenceTemplates.forEach((thisPattern, _) => {
                        if (menuItemObj.hasOwnProperty(thisPattern['tmplt_type'])) {
                            if (!templateTypes.includes(thisPattern['tmplt_type'])) {
                                templateTypes.push(thisPattern['tmplt_type']);
                                menuItemObj[thisPattern['tmplt_type']].items.push({
                                    text: "Custom References",
                                    id: "_manual_reference_" + thisPattern['tmplt_type']
                                });
                                menuItemObj[thisPattern['tmplt_type']].items.push({
                                    separator: true
                                });
                            }
                            // menuItemObj[thisPattern['tmplt_type']].items.push({
                            //  //   text:thisPattern['tmplt_ref_name'],
                            //     id:thisPattern['_id']
                            // });
                        }
                    });
                }
                const menuItems: MenuItemModel[] = [];
                this.preCleanArrayList = [];
                for (const [key, value] of Object.entries(menuItemObj)) {
                    this.preCleanArrayList.push({ id: key, name: value["text"] });
                    menuItems.push({ id: key, text: value["text"], items: value["items"] })
                    this.cleaningReferenceModalFieldData.push({ Id: key, Game: key });
                }
                this.contextmenuEditor.removeItems(['Reference']);
                this.contextmenuEditor.insertAfter([{ text: 'Reference', iconCss: 'fa fa-code', items: menuItems }], 'New comment');
            },
            _error => { }
        );
    }
    allSavedPattern: any[];
    allSavedPatternTemplates: any[];


    /**
     * edit in modal
     * 
     * @author simi
     */

    onCommentChange(e) {
        console.log(e);
    }

    /**
     * replace with selected suggestion
     * @author simi
     */
    // confirmReplace() {
    //     this.referenceOutput = ""
    //     for (let key of this.referenceOrders[this.selectedCleaningReferencePatternOrderIndex].order) {
    //         const name = this.getParameterById(key);
    //         console.log(name)
    //         if (name == "c_names" || name == "bibitem" || name == "c_a_names") {
    //             this.referenceOutput += "\\" + name + "{${" + name + "}}" + "\\n";
    //         }
    //         else {
    //             this.referenceOutput += "\\info[" + name + "]" + "{${" + name + "}}" + "\\n";
    //         }
    //     }
    //     this.referenceOutput += "\\endref"
    //     this.modalRef.hide();
    // }


    matches = [];
    setAutoMagicSuggestion(matches) {
        this.matches = matches
        this.autoMagicSuggestions = matches;
        this.selectedSuggestionIndexes = new Array(matches.length).fill(0);
    }
    trackByMethod(index: number): any[] {
        return this.autoMagicSuggestions[index].outputs[this.selectedSuggestionIndexes[index]];
    }
    public currentPage = 0;
    nextMatch(index) {
        this.selectedIndex = this.selectedSuggestionIndexes[index];
        if (this.autoMagicSuggestions[index].outputs.length > this.selectedIndex + 1) {
            this.selectedSuggestionIndexes[index] = this.selectedSuggestionIndexes[index] + 1;
        }
    }
    previousMatch(index) {
        let selectedIndex = this.selectedSuggestionIndexes[index];
        if (0 < selectedIndex) {
            this.selectedSuggestionIndexes[index] = this.selectedSuggestionIndexes[index] - 1;
        }
    }
    hasPreviousForMatch(index) {
        let selectedIndex = this.selectedSuggestionIndexes[index];
        return (0 < selectedIndex);
    }
    hasNextForMatch(index) {
        let selectedIndex = this.selectedSuggestionIndexes[index];
        return (this.autoMagicSuggestions[index].outputs.length > selectedIndex + 1);
    }
    /**
     * ends replacing suggestion
     *
     */
    onContextMenu(args: MenuEventArgs) {
        switch (args.item.text) {
            case 'Copy': { this.copy(); break; }
            case 'Cut': { this.cut(); break; }
            case 'Paste': { this.paste(); break; }
            case 'Add bookmark': { this.bookmarkClick(); break; }
            case 'Go to line': { this.forwardSync(); break; }
            case 'New comment': { this.commentMarkerClick(args.event); break; }
            case 'Reference': { break; }
            // case 'Block Code': { break; }
            case 'Spell check': { break; }
            case 'To upper':
            case 'To lower':
            case 'Title case':
                {
                    if (this.editor.getSelectedText().length > 0) {
                        this.changeCase(args)
                    }
                    break
                }
            default: {
                if (args.item.id.split('_')[0] == 'spellcheck') {
                    var selectionRange: any = this.editor.getSelectionRange();
                    this.editor.session.replace(selectionRange, args.item.text);
                } else if (args.item["id"].startsWith('_automagic')) {
                    const argSplit = args.item["id"].split("_automagic_").pop().split("_");
                    const matches = AutoRegexUtilities.match(argSplit[0], argSplit[1], this.editor.getSelectedText());
                    if (matches.length <= 0) {
                        this.notifier.notify(
                            'error',
                            'No pattern matched');
                        return;
                    } else {
                        this.setAutoMagicSuggestion(matches);
                        this.OpenPreviewTmpltModal(this.suggestionModal)
                    }
                } else if (args.item["id"].startsWith('_manual')) {
                    const argSplit = args.item["id"].split("_manual_").pop().split("_");
                    const expressions: any[] = [];
                    if (this.referenceTemplates.length) {
                        this.referenceTemplates.forEach((thisPattern, _) => {
                            expressions.push(thisPattern.reference_template);
                        });
                    }
                    const matches = AutoRegexUtilities.match(argSplit[0], argSplit[1], this.editor.getSelectedText(), expressions);
                    if (matches.length <= 0) {
                        this.notifier.notify(
                            'error',
                            'No pattern matched');
                        return;
                    } else {
                        this.setAutoMagicSuggestion(matches);
                        this.OpenPreviewTmpltModal(this.suggestionModal)
                    }
                } else {
                    const matchedTemplate = (this.referenceTemplates.filter((thisTemplate) => thisTemplate["_id"] === args.item["id"])).shift();
                    if (matchedTemplate !== undefined) {
                        let group = "";
                        if (matchedTemplate["refernce-status"] === true) {
                            group = "reference";
                        }
                        const result = AutoRegexUtilities.match(group, matchedTemplate.tmplt_type, this.editor.getSelectedText(), [matchedTemplate.reference_template]);
                        if (result !== undefined) {
                            this.setAutoMagicSuggestion(result);
                            this.OpenPreviewTmpltModal(this.suggestionModal)
                        } else {
                            //TODO Toast to tell that no match found
                            this.notifier.notify(
                                'error',
                                'No pattern matched');
                            return;
                        }
                    } else {
                        this.selectThisBlockCode(args);
                    }

                }
            }
        }
    }

    cut() {

        var text = this.editor.getCopyText();
        this.editor.execCommand("copy");

        navigator.clipboard.writeText(text);
        this.editor.insert("");
    }

    copy() {

        var text = this.editor.getCopyText();
        this.editor.execCommand("copy");
        navigator.clipboard.writeText(text);

    }

    paste() {

        navigator.clipboard.readText().then(text => {
            this.editor.insert(text);
        });
    }


    bookmarkClick() {
        this.bookmarkForm.reset();
        var range = this.editor.getSelectionRange();
        var pixelPosition = this.editor.renderer.textToScreenCoordinates(range.end.row + 1, 0);
        this.bookmarkPostion(pixelPosition);
    }

    bookmarkPostion(position) {
        var div = document.getElementById('tooltip_006');
        if (div) {
            if (position.pageY + 150 >= $(document).height())
                position.pageY = position.pageY - 180;
            position.pageX = position.pageX + 40;
            div.style.left = position.pageX + 'px';
            div.style.top = position.pageY + 'px';
            div.style.display = "block";
        }
        var input = document.getElementById('bookmarkModal');
        input.focus();
    }

    bookmarkRanges = [];

    addBookmark() {

        if (this.bookmarkForm.valid && this.bookmarkForm.value.trim() != '') {

            var cursorPosition = this.editor.getCursorPosition();

            var range = this.editor.getSelectionRange();
            range.start.column = 0;

            var current_active_document = this.selectednode[this.activeTab];

            var data = {
                name: this.bookmarkForm.value,
                row: cursorPosition.row + 1,
                range: range,
                current_document: current_active_document.name,
                path: current_active_document.path,
                article_id: current_active_document.nodeid
            }

            this.editorService.addBookmark(data).subscribe(
                res => {
                    if (res.status == 'success') {
                        this.notifier.notify('success', res.message);
                        this.closeBookmark();

                        var range = this.editor.getSelectionRange();
                        range.start.column = 0;

                        range['id'] = res._id;
                        range.start = <any>this.editor.session.getDocument().createAnchor(range.start.row, range.start.column);
                        range.end = <any>this.editor.session.getDocument().createAnchor(range.end.row, range.end.column);
                        if (range.end.column == 0) {
                            range.end.column = 15;
                        }
                        range['markerId'] = this.editor.session.addMarker(range, "bookmarkColor", 'fullLine');

                        this.editor.session.selection.clearSelection();
                        this.bookmarkRanges.push(range);

                    } else {
                        this.notifier.notify('error', res.message)
                    }
                    this.getBookmarks(this.selectednode[this.activeTab]);

                },
                err => {
                    console.log(err)
                }
            );
        }
    }

    deleteBookmarkArray = [];

    deleteBookmark(data) {


        const check = Array.isArray(data);

        if (check == true) {
            this.deleteBookmarkArray = this.bookmarkslist
        } else {
            this.deleteBookmarkArray.push(data)
        }

        this.editorService.deleteBookmark(this.deleteBookmarkArray).subscribe(
            res => {

                if (res.status == 'success') {
                    this.notifier.notify('success', res.message);
                } else {
                    this.notifier.notify('error', res.message);
                }
                this.getBookmarks(this.selectednode[this.activeTab]);
                this.editor.session.removeMarker(data._id);

            },
            err => {
                console.log(err);
            }
        )
    }

    closeBookmark() {
        var div = document.getElementById('tooltip_006');
        if (div) {
            div.style.display = "none";
        }
    }

    /**
     * method to change case of selected text from editor, context menu action
     * @param arg :contetMenu arguments
     */
    public changeCase(arg) {
        let txtToReplace = this.editor.getSelectedText()
        switch (arg.item.text) {
            case 'To upper':
                txtToReplace = txtToReplace.toUpperCase()
                break
            case 'To lower':
                txtToReplace = txtToReplace.toLowerCase()
                break
            case 'Title case':
                txtToReplace = txtToReplace.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
                break
        }
        this.editor.getSelectedText().toUpperCase
        let selectionRange: any = this.editor.getSelectionRange();
        this.editor.session.replace(selectionRange, txtToReplace);
    }

    itemBeforeEvent(args: MenuEventArgs) {
        let shortCutSpan: HTMLElement = createElement('span');
        let text: string = args.item.text;
        let shortCutText: string = text === 'Copy' ? ' Ctrl + C' : (text === 'Cut' ? ' Ctrl + X' : (text === 'Paste' ? ' Ctrl + V' : ''));
        shortCutSpan.textContent = shortCutText;
        args.element.appendChild(shortCutSpan);
        shortCutSpan.setAttribute('class', 'editorContextMenuShortcut');
    }

    editorContextBeforeOpen(event): void {
        event.element.setAttribute('id', 'editorContextmenu');
    }

    closeSinppet() {
        this.rightPannel = 'viewPdf';
    }
    @HostListener("window:beforeunload", ["$event"]) unloadNotification($event: any) {

        // uncomment
        this.closeRadarView();

        if (this.selectednode.findIndex(item => item.saved === false) !== -1) {
            $event.returnValue = true;
        }
    }

    // Get details of active tab. If there is any active tab then return the details of same.
    getActiveTab() {
        if (this.editorTabs.hasActiveTab()) {
            var tabid = this.editorTabs.activeTabEl.dataset.tabId;
            return this.selectednode.find(node => node.id == tabid);
        }
    }

    // Add new tab for editor
    addTab(data) {

        if (data.nodeid != this.mainArticleId) {

            switch (data.favicon) {
                case 'image': { data.favicon = this.iconPath + 'image.ico'; break; }
                case 'pdf': { data.favicon = this.iconPath + 'pdf.ico'; break; }
                case 'tex': { data.favicon = this.iconPath + 'otherArticle.ico'; break; }
            }
            this.editorTabs.addTab(data);

        } else if ((data.nodeid == this.mainArticleId) && (data.real_path == this.mainDocumentFileName)) {
            data.favicon = this.iconPath + 'compilerFile.ico';
            this.editorTabs.addTab(data);
        } else {

            switch (data.favicon) {
                case 'image': { data.favicon = this.iconPath + 'image.ico'; break; }
                case 'pdf': { data.favicon = this.iconPath + 'pdf.ico'; break; }
                case 'tex': { data.favicon = this.iconPath + 'source-code.ico'; break; }
            }
            this.editorTabs.addTab(data);
        }

        var currentTab = document.querySelector('div[data-tab-id="' + data.id + '"]');
        currentTab.addEventListener('pointerdown', event => {
            event.stopPropagation();
            var i = this.selectednode.findIndex(node => node.id == data.id);
            var nodedata = this.selectednode.find(node => node.id == data.id);
            this.getTabData(nodedata, i);
            this.editor.session.selection.clearSelection();
        });
        this.setTabCloseEventListener(currentTab, data.id);

        // make tex file as read only or editable
        if (data.nodeid != this.mainArticleId) {
            this.editor.setOptions({
                readOnly: true
            })
        } else {
            this.editor.setOptions({
                readOnly: false
            })
        }

    }

    // Adding click event listner for tab close
    setTabCloseEventListener(tabEl, id) {
        tabEl.querySelector('.chrome-tab-close').addEventListener('click', _event => {
            if (id) {
                var nodedata = this.selectednode.find(node => node.id == id);
                this.closetab(nodedata, tabEl);
            }
        });
    }

    // update editor content depends on the selected file
    updateEditor(currentNode) {

        // make tex file as read only or editable
        if (currentNode.nodeid != this.mainArticleId) {
            this.editor.setOptions({
                readOnly: true
            })
        } else {
            this.editor.setOptions({
                readOnly: false
            })
        }

        if (currentNode.filetype == "image" || currentNode.filetype == "pdf") {
            if (currentNode.filetype == "image") {
                this.LoadImage = true;
                this.LoadObj = false;
            } else {
                this.LoadImage = false;
                this.LoadObj = true;
                this.editorPage = currentNode.cursorPosition;
                if (this.editorPdfViewer) {
                    var pageView = this.editorPdfViewer.pdfViewer._pages[currentNode.cursorPosition - 1];
                    this.editorPdfViewer.pdfViewer._scrollIntoView({
                        pageDiv: pageView.div
                    });
                }
            }
            this.isTex = false;
            //load image 
            this.tabImage = currentNode.imagepath;
        } else if (currentNode.filetype == "tex") {
            this.LoadObj = false;
            this.isTex = true;
            this.LoadImage = false;
            this.editor.setValue(currentNode.content);
            this.highlightComments(currentNode.comments);
            this.getBookmarks(this.selectednode[this.activeTab]);
            if ((currentNode.nodeid == this.compiledNode) && (currentNode.nodepath == this.mainDocumentFileName)) {
                this.editor.session.setAnnotations(this.annotationArray);
            }
            this.editor.selection.moveCursorToPosition({ row: currentNode.cursorPosition, column: 0 });
            this.editor.gotoLine(currentNode.cursorPosition, 0, true);
        }
    }

    // Update editor tab
    updateTab(tabEl, currentNode) {
        var tabData = {
            title: currentNode.name,
            id: currentNode.id,
            tooltip: currentNode.path
        };
        var path = 'assets/file-icons/';
        switch (currentNode.filetype) {
            case 'image': { tabData['favicon'] = path + 'image.ico'; break; }
            case 'pdf': { tabData['favicon'] = path + 'pdf.ico'; break; }
            case 'tex': { tabData['favicon'] = path + 'source-code.ico'; break; }
        }
        this.editorTabs.updateTab(tabEl, tabData);
    }

    // update current editor chrome tab  after main document change
    updateMainDocTab(tabEl, currentNode) {
        var tabData = {
            title: currentNode.name,
            id: currentNode.id,
            tooltip: currentNode.path
        };
        var path = 'assets/file-icons/';
        switch (currentNode.filetype) {
            case 'tex': { tabData['favicon'] = path + 'compilerFile.ico'; break; }
        }
        this.editorTabs.updateTab(tabEl, tabData);

    }

    // update previous editor chrome tabs  after main document change
    updatePreviousTab(tabEl, previousNode) {

        var tabData = {
            title: previousNode.name,
            id: previousNode.id,
            tooltip: previousNode.path
        };
        var path = 'assets/file-icons/';
        switch (previousNode.filetype) {
            case 'tex': { tabData['favicon'] = path + 'source-code.ico'; break; }
        }
        this.editorTabs.updateTab(tabEl, tabData);
    }

    // start tour from a particular step
    // activateTour() {
    //     if (this.userDetails.usr_demo_tour == 'snippet') {
    //         this.loginService.updateEditorTour({ status: 'end' }).subscribe(
    //             success => {
    //                 this.userDetails.usr_demo_tour = 'end';
    //             }
    //         );
    //         // Starting demo tour
    //         // this.tourService.initialize([
    //         //     {
    //         //         stepId: 'snippet-btn',
    //         //         anchorId: 'snippet-btn',
    //         //         content: this.translate.instant("demotour-editor.edit-snippet"),
    //         //         title: this.translate.instant('editor.Edit Snippets'),
    //         //         placement: 'right',
    //         //         // // enableBackdrop: true
    //         //     },
    //         //     // {
    //         //     //     stepId: 'snippet-view',
    //         //     //     anchorId: 'snippet-view',
    //         //     //     content: this.translate.instant("demotour-editor.snippet-view"),
    //         //     //     title: this.translate.instant("demotour-editor.snippet-view-title"),
    //         //     //     placement: 'left',
    //         //     //     // // enableBackdrop: true
    //         //     // }
    //         // ]);
    //         // this.tourService.start();
    //     }
    // }

    leftPanelWidth;

    setupSidePanel() {
        // return;
        var panelTabs: any = document.getElementsByClassName("left-panel-tab");
        this.leftPanelTab.init(panelTabs[0]);

        /* TAB:1 */
        this.leftPanelTab.addTab({
            // title: this.translate.instant('sidebar.workspace'),
            title: '',
            favicon: '<i class="fa fa-folder-open" aria-hidden="true"></i>',
            tooltip: this.translate.instant('sidebar.workspace'),
            id: 'workspace',
            icon_font: true
        });

        /* TAB:2 */
        this.leftPanelTab.addTab({
            title: this.translate.instant('sidebar.settings'),
            favicon: '<i class="fa fa-cogs" aria-hidden="true"></i>',
            tooltip: this.translate.instant('sidebar.settings'),
            id: 'settings',
            icon_font: true
        });

        /* TAB:3 */
        if (this.guestUser !== true) {
            this.leftPanelTab.addTab({
                title: this.translate.instant('sidebar.openarticle'),
                favicon: '<i class="fa fa-folder" aria-hidden="true"></i>',
                tooltip: this.translate.instant('sidebar.openarticle'),
                id: 'open-article',
                icon_font: true
            });
        }

        /* TAB:4 */
        if (this.otherArticle == false) {
            this.leftPanelTab.addTab({
                title: 'Bookmarks',
                favicon: '<i class="material-icons" style="font-size:13px">bookmark</i>',
                tooltip: this.translate.instant('sidebar.bookmarks'),
                id: 'bookmarks',
                icon_font: true
            });
        }

        this.leftPanelTab.addTab({
            title: this.translate.instant('sidebar.comments'),
            favicon: '<i class="material-icons" style="font-size:13px">mode_comment</i>',
            tooltip: this.translate.instant('sidebar.comments'),
            id: 'comments',
            icon_font: true
        });

        /* TAB:5 */
        if (true) {
            this.leftPanelTab.addTab({
                title: 'Chat',
                favicon: '<i class="fa fa-comments" aria-hidden="true"></i>',
                tooltip: 'Chat',
                id: 'chat',
                icon_font: true
            });
        }

        /* TAB:6 */
        if (this.guestUser !== true) {
            this.leftPanelTab.addTab({
                title: this.translate.instant('sidebar.backuphistory'),
                // favicon: '<i class="fa fa-undo" aria-hidden="true"></i>',
                favicon: '<i class="fa fa-arrow-circle-down" aria-hidden="true"></i>',
                tooltip: this.translate.instant('sidebar.backuphistory'),
                id: 'backup',
                icon_font: true
            });
        }

        /* TAB:7 snippets */
        this.leftPanelTab.addTab({
            title: 'Snippets',
            favicon: '<i class="fa fa-code font-weight-bold" aria-hidden="true"></i>',
            tooltip: this.translate.instant('sidebar.snippets'),
            id: 'sidebarSnippetsId',
            icon_font: true
        });

        /* TAB: 8 autosave settings */
        this.leftPanelTab.addTab({
            title: 'Autosave settings',
            favicon: '<i class="fa fa-floppy-o font-weight-bold" aria-hidden="true"></i>',
            tooltip: this.translate.instant('sidebar.autosaveSettings'),
            id: 'sidebarAutosaveId',
            icon_font: true
        });

        /* TAB: 9 notes */
        this.leftPanelTab.addTab({
            title: 'Notes',
            favicon: '<i class="fa fa-sticky-note font-weight-bold" aria-hidden="true"></i>',
            tooltip: this.translate.instant('sidebar.notes'),
            id: 'sidebarNotesId',
            icon_font: true
        });

        /* TAB: 10  */
        this.leftPanelTab.addTab({
            title: 'PDF Annotation',
            favicon: '<i class="fa fa-file-text-o font-weight-bold" aria-hidden="true"></i>',
            tooltip: this.translate.instant('editor.annotate pdf'),
            id: 'sidebarPdfAnnotationId',
            icon_font: true
        });
        /* TAB: 11  */
        this.leftPanelTab.addTab({
            title: 'Reference',
            favicon: '<i class="fa fa-sticky-note font-weight-bold" aria-hidden="true"></i>',
            tooltip: this.translate.instant('sidebar.reference'),
            id: 'sidebarReference',
            icon_font: true
        });

        var panelTabs: any = document.querySelectorAll(".panelsbar");
        var all_left_panel_tabs = document.querySelectorAll(".left-panel-tab .chrome-tab");
        this.leftPanelWidth = '0px';//(panelTabs[0].offsetWidth - (all_left_panel_tabs.length) * 155) + 'px';
        this.leftPanelTab.setCurrentTab(all_left_panel_tabs[0]);
        all_left_panel_tabs.forEach((element) => {
            let tab_id = element.getAttribute('data-tab-id');
            // element.setAttribute("tourAnchor", tab_id);
            element.addEventListener('click', (_el) => {
                // let tab_id = element.getAttribute('data-tab-id');
                switch (tab_id) {
                    case 'workspace': {
                        // this.switchExplorer(); 
                        this.clearNavTab()
                        this.isExplorer = true;
                        this.hideCommentList()
                        break;
                    }
                    case 'settings': {
                        this.switchSettings();
                        this.clearNavTab()
                        this.isSettings = true;
                        this.hideCommentList();
                        // this.activateTour();
                        break;
                    }
                    case 'open-article': {
                        this.openModal(this.contents);
                        this.getAllArticleData();
                        this.clearNavTab()
                        break;
                    }
                    case 'bookmarks': {
                        // this.switchBookmarks();
                        this.clearNavTab()
                        this.isBookmarks = true
                        this.getBookmarks(this.selectednode[this.activeTab]);
                        break;
                    }
                    case 'comments': {
                        this.switchComments(false, false, true);
                        break;
                    }
                    case 'chat': {
                        // this.switchChat();
                        this.clearNavTab()
                        this.isChat = true;
                        this.hideCommentList()
                        break;
                    }
                    case 'backup': {
                        // this.switchBackupHistory();
                        this.clearNavTab()
                        this.isBackupHistory = true;
                        this.getBackup('modal');
                        break;
                    }
                    case 'sidebarSnippetsId': {
                        this.rightPannel = 'viewSnippet'
                        this.clearNavTab()
                        this.isSnippets = true
                        break;
                    }
                    case 'sidebarAutosaveId': {
                        this.clearNavTab()
                        this.isAutosaveSettings = true

                        // appending value to the form
                        if (this.autoSaveSettingsData) {
                            this.autoSaveSettingsFormPatchValue()
                        }

                        break;
                    }
                    case 'sidebarNotesId': {
                        this.clearNavTab()
                        this.isNotes = true
                        break;
                    }
                    case 'sidebarPdfAnnotationId': {
                        this.clearNavTab()
                        this.isPdfAnnotation = true
                        break;
                    }

                    // case 'sidebarReference': {
                    //     // this.rightPannel = 'viewSnippet'
                    //     this.clearNavTab()
                    //     this.isReference = true
                    //     this.hideCommentList()
                    //     break;
                    // }
                }
            })
        });


    }

    public autoSaveSettingsFormPatchValue() {
        this.autoSaveSettingsForm.patchValue({
            enable: this.autoSaveSettingsData.enable,
            interval: this.autoSaveSettingsData.interval,
            show_timer: this.autoSaveSettingsData.show_timer,
        })
    }

    public isSnippets: boolean = false
    public isAutosaveSettings: boolean = false
    public isNotes: boolean = false
    public isPdfAnnotation: boolean = false
    public clearNavTab() {
        this.isExplorer = false
        this.isSettings = false
        this.isDiff = false
        this.isChat = false
        this.isComments = false
        this.isBackupHistory = false
        this.isBookmarks = false
        this.isSnippets = false
        this.isAutosaveSettings = false
        this.isNotes = false
        this.isPdfAnnotation = false
        this.isReference = false
    }

    isChat: boolean;
    isBookmarks: boolean;
    receiverList = [];
    sender;
    chatWindow = [];
    message = new FormControl('', Validators.required);
    activeReceiver = '';
    receiverlist = [];
    chatWindowWidth = 300;
    targetReceiver;
    chatSearch: string = '';

    // switch to chat window
    // switchChat() {
    //     this.isSettings = false;
    //     this.isExplorer = false;
    //     this.isDiff = false;
    //     this.isComments = false;
    //     this.isBackupHistory = false;
    //     this.isChat = true;
    //     this.isBookmarks = false;
    //     this.hideCommentList()
    // }

    // switchBookmarks() {
    //     this.isSettings = false;
    //     this.isExplorer = false;
    //     this.isDiff = false;
    //     this.isComments = false;
    //     this.isBackupHistory = false;
    //     this.isChat = false;
    //     this.isBookmarks = true;
    //     this.getBookmarks(this.selectednode[this.activeTab]);
    // }
    bookmarkslist = [];

    markersList = [];

    noActiveTab;

    getBookmarks(current_active_document) {

        if (current_active_document !== undefined) {
            var data = {

                current_document: current_active_document.name,
                path: current_active_document.path,
                article_id: current_active_document.nodeid
            }

            this.editorService.getBookmarks(data).subscribe(

                res => {

                    this.bookmarkslist = res.bookmarks;

                    // this.bookmarkslist.sort((a, b)=>   a.row - b.row);
                    this.bookmarkslist.reverse();

                    this.bookmarkRanges.forEach(bookmark => {
                        // bookmark.start.detach();
                        // bookmark.end.detach();
                        this.editor.session.removeMarker(bookmark.markerId);
                    });

                    this.bookmarkRanges = [];

                    this.bookmarkslist.forEach(bookmark => {
                        this.bookmarkRanges.push(bookmark.range);
                    });

                    var Range = ace.require("ace/range").Range;

                    this.bookmarkRanges.forEach(element => {

                        var range = new Range(element.start.row, element.start.column, element.end.row, element.end.column);

                        range.start = this.editor.session.getDocument().createAnchor(range.start.row, range.start.column);
                        range.end = this.editor.session.getDocument().createAnchor(range.end.row, range.end.column);

                        if (range.end.column == 0) {
                            range.end.column = 15;
                        }
                        range.markerId = this.editor.session.addMarker(range, "bookmarkColor", 'fullLine');
                        range.id = element._id;

                        this.bookmarkRanges.push(range);
                    });

                },
                err => {
                    console.log(err);
                }
            )
        } else {
            this.bookmarkslist = [];
        }
    }
    scrollBookmark(item) {

        // this.editor.scrollToLine(item.row + 1, true, true, function () { });
        this.editor.gotoLine(item.row, 0, true);
    }

    // listing messages corresponding to the selected receiver
    receiverClick(data) {
        // Set the chat widget position
        var windowElement = document.getElementById('live-chat');
        if (windowElement) {
            this.chatWindowWidth = windowElement.offsetWidth + 50;
        }
        data.unreadMessage = 0;
        this.activeReceiver = data.id;
        // check whether selected receiver already opened or not in chat widget. If not opened then 
        var isExist = this.chatWindow.findIndex((receiver) => data.id == receiver.id);
        if (isExist == -1) {
            var apiData = {
                sender: this.userDetails._id,
                receiver: data.id
            };
            this.editorService.getMessage(apiData).subscribe(
                response => {
                    // If alredy 3 chat widget opened. then close the first one and add new.
                    if (this.chatWindow.length >= 3) {
                        this.chatWindow.splice(0, 1);
                    }
                    this.chatWindow.push({ id: data.id, name: data.name, image: data.image, showWindow: true, messages: response.messages, status: data.status, unreadMessage: 0, imoji: false, messageInput: '' });
                    // setting scroller to the bottom of the chat widget
                    setTimeout(() => {
                        var element = document.getElementById(data.id);
                        if (element)
                            element.scrollTop = element.scrollHeight;
                    }, 10);

                },
                _error => { }
            )
        }
    }

    // sending message to real time server
    sendMessage(data) {
        // if(this.message.valid && (this.message.value.trim() != '')){
        if ((data.messageInput.trim() != '')) {
            var messageData = {
                sender: this.userDetails._id,
                receiver: data.id,
                message: data.messageInput,// this.message.value,
                status: false
            };
            this.chatService.sendMessage(messageData).subscribe(response => {
                if (response.status == 'success') {
                    // notify the send message
                    this.chatService.notifySendMessage(response.data);
                    data.messageInput = '';
                    // this.message.reset();
                }
            });
        }
    }

    // send invitation
    sendInvitation(data) {

        let users = [];
        const check = Array.isArray(data);
        if (check == false) {
            users.push(data);
        } else {
            users = [...data];
        }

        users.forEach((data) => {
            const pname = this.mainArticleName;
            const cuname = this.userDetails.usr_username;
            const user_name = cuname.charAt(0).toUpperCase() + cuname.slice(1);
            const invitationData = {};
            invitationData['sender'] = this.userDetails._id;
            invitationData['sender_name'] = user_name;
            invitationData['sender_image'] = this.userImageId;
            invitationData['receiver'] = data.usr_id;
            invitationData['message'] = user_name + ' wants to share "' + pname + '" article with you.';
            invitationData['link'] = window.location.href;
            invitationData['status'] = false;


            this.chatService.sendInvitation(invitationData).subscribe(
                res => {
                    this.chatService.notifySendInvitation(res.data);
                },
                err => {
                    console.log(err);
                }
            );
        });

    }

    //trigger this function when closing the chat window
    closeChatWindow(index) {
        this.chatWindow.splice(index, 1);
    }


    // listening notification
    invitationListner() {
        this.chatService.getInvitation().subscribe(
            res => {
                var receiver = this.activeList.filter((receiver) => receiver.id == res.sender);
                if (receiver.length > 0) {
                    this.realtimeNotifications.push(res);
                    this.notifier.notify('success', 'You have received a new notification');
                }
            }
        )
    }
    routing(item) {
        const str = item.link;
        const strE = str.split('/');
        const lasy3 = strE.slice(-1);
        window.location.href = item.link;
        // this.router.navigateByUrl("editor/"+lasy3[0]);
        this.getNotifications();
    }
    updateNotfication(item) {
        var apiData = {
            sender: item.sender,
            receiver: item.receiver,
            id: item._id
        };
        this.editorService.updateNotification(apiData).subscribe(
            _res => {
                // console.log(res)
            },
            err => {
                console.log(err)
            }
        )

    }
    // get realtime notifications
    getNotifications() {
        var apiData = {
            receiverId: this.userDetails._id
        };
        this.editorService.getNotifications(apiData).subscribe(
            res => {
                this.realtimeNotifications = res;
            },
            err => {
                console.log(err);
            }
        )
    }


    getUsersByArticle() {

        var apiData = {
            articleId: this.mainArticleId
        };
        this.editorService.getSharedUsers(apiData).subscribe(
            res => {
                // console.log(res);
                res.forEach(user => {
                    this.sharedUsers.push(user.shared_receiver);
                });
                // filtering users
                this.shareArticleToUsers = this.activeUsersList.filter(({ usr_id: id1 }) => !this.sharedUsers.some((id => id === id1)));
                // console.log(this.shareArticleToUsers);

                // this.receiverlist = this.activeUsersList.filter(({usr_id: id1})=> this.chatUsersId.some((id => id === id1)));
                // // console.log(this.receiverlist);
                // this.chatService.requestingOnlineUsers();
            },
            err => {
                console.log(err);
            }
        )
    }

    // real time server listener
    chatListner() {
        this.chatService.getCheckSpelling().subscribe(response => {
            this.misspelledWords.forEach(word => {
                word.start.detach();
                word.end.detach();
                this.editor.session.removeMarker(word.markerId);
            });
            var Range = ace.require("ace/range").Range;
            this.misspelledWords = [];
            response.forEach((element, _index) => {
                // console.log(this.misspelled);
                var position = element.range;
                // console.log(position);
                var range = new Range(position[0], position[1], position[2], position[3]);
                range['markerId'] = this.editor.session.addMarker(range, "spelling-highlight", 'text');
                range.start = <any>this.editor.session.getDocument().createAnchor(range.start.row, range.start.column);
                range.end = <any>this.editor.session.getDocument().createAnchor(range.end.row, range.end.column);
                range['suggetion'] = element.suggestion;
                range['word'] = element.word;
                this.misspelledWords.push(range);
                // var aceDocument = this.editor.session.getDocument();
                // var commentAnchor = aceDocument.createAnchor(0, 0);
            });
        });

        this.chatService.getSuggestion().subscribe(response => {
            response.forEach((element, index) => {
                element['id'] = 'spellcheck_' + index;
            });
            this.contextmenuEditor.removeItems(['Spell check']);
            this.contextmenuEditor.insertAfter([{ text: 'Spell check', iconCss: 'fa fa-check', items: response }], 'New comment');
        });

        // listening replay message
        this.chatService.getMessage().subscribe(response => {
            var receiver = this.receiverList.filter((receiver) => receiver.id == response.chat_sender);
            // if(receiver.length > 0) {
            //     this.notifier.notify('success', 'You have a message from ' + receiver[0].name);
            // }
            // check for corresponding chat window is opened or not. 
            var isExist = this.chatWindow.findIndex((receiver) => (response.chat_receiver == receiver.id) || (response.chat_sender == receiver.id));
            // If chat window is opened then append the message into the same. Otherwise increment unread message in the user listing panel
            if (isExist > -1) {
                this.chatWindow[isExist].messages.push(response);
                // Move the scroll to bottom.
                var element = document.getElementById(this.chatWindow[isExist].id);
                if (element)
                    element.scrollTop = element.scrollHeight - 10;
                if (!this.chatWindow[isExist].showWindow) {
                    this.chatWindow[isExist].unreadMessage = this.chatWindow[isExist].unreadMessage + 1;
                    if (receiver.length > 0) {
                        this.notifier.notify('success', 'You have a message from ' + receiver[0].name);
                    }
                } else {
                    // var messageRead = this.chatWindow.findIndex((receiver) => (response.chat_receiver == receiver.id));
                    var messageRead = this.chatWindow.findIndex((receiver) => (response.chat_sender == receiver.id));
                    if (messageRead != -1) {
                        this.chatService.changeMessageReadStatus({ id: response._id }).subscribe(
                            response => { console.log(response); },
                            error => { console.log(error); }
                        );
                    }
                }
            } else {
                if (receiver.length > 0) {
                    this.notifier.notify('success', 'You have a message from ' + receiver[0].name);
                }
                var receiverIndex = this.receiverList.findIndex((receiver) => (response.chat_sender == receiver.id));
                if (receiverIndex > -1) {
                    this.receiverList[receiverIndex].unreadMessage = this.receiverList[receiverIndex].unreadMessage + 1;
                }
            }
        });

        // listening changes in user status (online, offline)
        this.chatService.getUpdatedStatus().subscribe(data => {
            // find the corresponding user from user list and change the status.
            var disconnectedUser = this.receiverList.findIndex((receiver) => (receiver.id == data.user));
            if (disconnectedUser != -1) {
                this.receiverList[disconnectedUser].status = data.status;
            }
            // find the corresponding user from chat widget and change the status.
            var chataWidgetStatus = this.chatWindow.findIndex((window) => (window.id == data.user));
            if (chataWidgetStatus != -1) {
                this.chatWindow[chataWidgetStatus].status = data.status;
            }
        });

        // listening on active users for indicating user status (online, offline)
        this.chatService.getOnlineUsers().subscribe(users => {
            this.receiverList = [];
            this.activeList = [];

            // for article invitation notifications
            this.activeUsersList.forEach(element => {
                var data = {
                    id: element.usr_id,
                    name: element.usr_first_name,
                    image: element.usr_image,
                    status: users.includes(element.usr_id),
                };
                this.activeList.push(data);
            });
            // structuring the receiver list
            this.receiverlist.forEach(element => {
                var data = {
                    id: element.usr_id,
                    name: element.usr_first_name,
                    image: element.usr_image,
                    status: users.includes(element.usr_id),
                    unreadMessage: 0
                };
                var receiverIndex = this.unreadMessages.findIndex(receiver => receiver._id == element.usr_id);
                if (receiverIndex != -1) {
                    data.unreadMessage = this.unreadMessages[receiverIndex].count;
                }
                this.receiverList.push(data);
            });
        });
    }

    // Change chat widget visibility status. Show unread message if chat window is hidden.
    changeWindowVisibilityStatus(data) {
        if (!data.showWindow) {
            data.unreadMessage = 0;
            setTimeout(() => {
                var element = document.getElementById(data.id);
                element.scrollTop = element.scrollHeight;
            }, 10);
            this.chatService.changeAllMessageReadStatus({ sender: this.userDetails._id, receiver: data.id }).subscribe(
                _response => { },
                _error => { }
            )
        }
        data.showWindow = !data.showWindow;
    }

    @ViewChild("chatfileuploadmodal") chatFileUploadModal: TemplateRef<any>;
    public chatFileUploadPath: Object = {
        saveUrl: this.env.baseAPIUrl + '/send-file'
    };
    chat_upload = new FormControl('', Validators.required);
    chatFile;
    fileValidationResultChat;
    toggled: boolean = false;

    // open modal for uploading file for sending.
    openChatModal(receiver) {
        this.fileValidationResultChat = {
            status: ''
        };
        this.getAllowedFiles(this.route.snapshot.paramMap.get("id"));
        this.targetReceiver = receiver;
        this.openModal(this.chatFileUploadModal);
    }

    //get allowed file extension for file upload
    getAllowedFiles(id) {
        this.editorService.getAllowedFiles({ id: id }).subscribe(
            response => {
                if (response.status == 'success') {
                    this.allowedFiles = response.files.join(',');
                } else {
                    this.allowedFiles = '';
                }
            },
            _error => { }
        );
    }

    // Selecting file for sending in chat widget 
    public onFileSelectChat(args): void {
        if (args.target.files.length > 0) {
            this.chatFile = args.target.files[0];
            // creating and appending formdata for validating the file
            var formData = new FormData();
            formData.append('file', args.target.files[0]);
            formData.append('article', this.route.snapshot.paramMap.get("id"));
            // Validating the selected file
            this.chatService.validateUploadingFileChat(formData)
                .subscribe(
                    (response: any) => {
                        this.fileValidationResultChat = response;
                        if (response.status == 'success') {
                        }
                    });
        }
    }

    // sending file to user
    sendFile() {
        if (this.fileValidationResultChat.status == 'success') {
            // creating form data
            var formData = new FormData();
            // Appending data into the form field
            formData.append('UploadFiles', this.chatFile);
            formData.append('receiver', this.targetReceiver);
            formData.append('sender', this.userDetails._id);
            this.chatService.sendFile(formData).subscribe(
                response => {
                    if (response.status == 'success') {
                        this.modalRef.hide();
                        this.chatService.notifySendMessage(response.result);
                    } else {
                        this.notifier.notify('error', response.message);
                    }
                },
                _error => { }
            );
        } else {
            this.notifier.notify('error', this.fileValidationResultChat.message);
        }
    }

    // Imoji selection
    handleSelection(event, receiver) {
        receiver.messageInput = receiver.messageInput + event.char;
    }

    startInitialTour() {

        //initializing demo tour
        if (!this.userDetails.usr_demo_tour) {
            this.loginService.updateEditorTour({ status: 'end' }).subscribe(
                _success => {
                    this.userDetails.usr_demo_tour = 'end';

                    // Starting demo tour
                    this.startDemoTour();
                }
            );
        }
    }

    // startInitialTour() {
    //     //initializing demo tour

    //     if (!this.userDetails.usr_demo_tour) {
    //         this.loginService.updateEditorTour({ status: 'end' }).subscribe(
    //             success => {
    //                 this.userDetails.usr_demo_tour = 'end';

    //                 // Starting demo tour
    //                 this.startDemoTour();
    //             }
    //         );
    //     }

    // this.tourService.events$.subscribe(x => console.log(x));

    // this.tourService.initialize([
    //     {
    //         //     stepId: 'workspace',
    //         //     anchorId: 'workspace',
    //         //     content: this.translate.instant("demotour-editor.workspace"),
    //         //     title: this.translate.instant("editor-left-panel.workspace"),
    //         //     placement: 'right',
    //         //                 enableBackdrop: true
    //         // }, {
    //         //     stepId: 'settings',
    //         //     anchorId: 'settings',
    //         //     content: this.translate.instant("demotour-editor.settings"),
    //         //     title: this.translate.instant("editor.Settings"),
    //         //     placement: 'right',
    //         //                 enableBackdrop: true
    //         // }, {
    //         //     stepId: 'open-article',
    //         //     anchorId: 'open-article',
    //         //     content: this.translate.instant("demotour-editor.open-article"),
    //         //     title: this.translate.instant("editor-left-panel.open-article"),
    //         //     placement: 'right',
    //         //                 enableBackdrop: true
    //         // }, {
    //         //     stepId: 'comments',
    //         //     anchorId: 'comments',
    //         //     content: this.translate.instant("demotour-editor.comments"),
    //         //     title: this.translate.instant("editor-left-panel.comments"),
    //         //     placement: 'right',
    //         //                 enableBackdrop: true
    //         // }, {
    //         stepId: 'editor',
    //         anchorId: 'editor',
    //         content: this.translate.instant("demotour-editor.editor"),
    //         title: 'Step 1/30 : Editor',
    //         placement: 'right',
    //         enableBackdrop: true
    //     }, {
    //         stepId: 'compile-btn',
    //         anchorId: 'compile-btn',
    //         content: this.translate.instant("demotour-editor.compile-btn"),
    //         title: this.translate.instant("demotour-editor.compile-title"),
    //         placement: 'bottom',
    //         enableBackdrop: true
    //     },
    //     // {
    //     //     stepId: 'generatexml',
    //     //     anchorId: 'generatexml',
    //     //     content: this.translate.instant("demotour-editor.generate-xml"),
    //     //     title: this.translate.instant("demotour-editor.xml-compiler"),
    //     //     placement: 'bottom',
    //     //     // // enableBackdrop: true
    //     // }, 
    //     {
    //         stepId: 'error.warning',
    //         anchorId: 'error.warning',
    //         content: this.translate.instant("demotour-editor.errors"),
    //         title: this.translate.instant("demotour-editor.error-title"),
    //         placement: 'bottom',
    //         enableBackdrop: true
    //     },
    //     {
    //         stepId: 'download',
    //         anchorId: 'download',
    //         content: this.translate.instant("demotour-editor.download"),
    //         title: this.translate.instant('demotour-editor.download-title'),
    //         placement: 'bottom',
    //         enableBackdrop: true
    //     },
    //     {
    //         stepId: 'nextstage',
    //         anchorId: 'nextstage',
    //         content: this.translate.instant("demotour-editor.nextstage"),
    //         title: this.translate.instant("demotour-editor.nextstage-title"),
    //         placement: 'bottom',
    //         enableBackdrop: true
    //     },
    //     {
    //         stepId: 'showpdf',
    //         anchorId: 'showpdf',
    //         content: this.translate.instant("demotour-editor.showpdf"),
    //         title: this.translate.instant("demotour-editor.showpdf-title"),
    //         placement: 'bottom',
    //         enableBackdrop: true
    //     },
    //     {
    //         stepId: 'savePdf',
    //         anchorId: 'savePdf',
    //         content: 'To save current pdf',
    //         title: 'Save PDF',
    //         placement: 'bottom',
    //         enableBackdrop: true
    //     },
    //     {
    //         stepId: 'rvclean',
    //         anchorId: 'rvclean',
    //         content: this.translate.instant("demotour-editor.rvclan"),
    //         title: 'RVClean',
    //         placement: 'bottom',
    //         enableBackdrop: true
    //     },
    //     {
    //         stepId: 'validate',
    //         anchorId: 'validate',
    //         content: this.translate.instant("demotour-editor.validate"),
    //         title: this.translate.instant("demotour-editor.validate-title"),
    //         placement: 'bottom',
    //         enableBackdrop: true
    //     },
    //     {
    //         stepId: 'backup',
    //         anchorId: 'backup',
    //         content: 'Backup',
    //         title: 'Backup',
    //         placement: 'bottom',
    //         enableBackdrop: true
    //     },
    //     {
    //         stepId: 'cleaningTemplate',
    //         anchorId: 'cleaningTemplate',
    //         content: 'clean the template',
    //         title: 'Cleaning template',
    //         placement: 'bottom',
    //         enableBackdrop: true
    //     },
    //     {
    //         stepId: 'notes',
    //         anchorId: 'notes',
    //         content: 'to save notes',
    //         title: 'Notes',
    //         placement: 'bottom',
    //         enableBackdrop: true
    //     },
    //     {
    //         stepId: 'diffPdf',
    //         anchorId: 'diffPdf',
    //         content: 'Select two of them and proceed to get DIFF',
    //         title: 'Diff PDF',
    //         placement: 'bottom',
    //         enableBackdrop: true
    //     },
    //     {
    //         stepId: 'workspace',
    //         anchorId: 'workspace',
    //         content: this.translate.instant("demotour-editor.workspace"),
    //         title: this.translate.instant("editor-left-panel.workspace"),
    //         placement: 'right',
    //         // // enableBackdrop: true
    //     }, {
    //         stepId: 'settings',
    //         anchorId: 'settings',
    //         content: this.translate.instant("demotour-editor.settings"),
    //         title: this.translate.instant("editor.Settings"),
    //         placement: 'right',
    //         // // enableBackdrop: true
    //     }, {
    //         stepId: 'open-article',
    //         anchorId: 'open-article',
    //         content: this.translate.instant("demotour-editor.open-article"),
    //         title: this.translate.instant("editor-left-panel.open-article"),
    //         placement: 'right',
    //         // // enableBackdrop: true
    //     }, {
    //         stepId: 'comments',
    //         anchorId: 'comments',
    //         content: this.translate.instant("demotour-editor.comments"),
    //         title: this.translate.instant("editor-left-panel.comments"),
    //         placement: 'right',
    //         // // enableBackdrop: true
    //     }, {
    //         stepId: 'bookmarks',
    //         anchorId: 'bookmarks',
    //         content: this.translate.instant("demotour-editor.comments"),
    //         title: this.translate.instant("editor-left-panel.comments"),
    //         placement: 'right',
    //         // // enableBackdrop: true
    //     }, {
    //         stepId: 'backup',
    //         anchorId: 'backup',
    //         content: this.translate.instant("demotour-editor.comments"),
    //         title: this.translate.instant("editor-left-panel.comments"),
    //         placement: 'right',
    //         // // enableBackdrop: true
    //     },
    //     // {
    //     //     stepId: 'shareArticle',
    //     //     anchorId: 'shareArticle',
    //     //     content: 'share the article to other users',
    //     //     title: 'Share article',
    //     //     placement: 'bottom',
    //     //     enableBackdrop: true
    //     // },
    //     {
    //         stepId: 'languageselector',
    //         anchorId: 'languageselector',
    //         content: this.translate.instant("demotour-editor.language"),
    //         title: this.translate.instant('demotour-editor.language-title'),
    //         placement: 'bottom',
    //         enableBackdrop: true
    //     },
    //     {
    //         stepId: 'exit-button',
    //         anchorId: 'exit-button',
    //         content: this.translate.instant("demotour-editor.exit-button"),
    //         title: this.translate.instant("demotour-editor.exit-title"),
    //         placement: 'bottom',
    //         enableBackdrop: true
    //     },  //{
    //     //     stepId: 'snippet-btn',
    //     //     anchorId: 'snippet-btn',
    //     //     content: '',
    //     //     title: 'Edit snippet',
    //     //     placement: 'right',
    //     //                 enableBackdrop: true
    //     // }, {
    //     //     anchorId: 'showxml',
    //     //     content: 'Showing xml',
    //     //     title: 'View xml',
    //     //                 enableBackdrop: true
    //     // }, {
    //     //       anchorId: 'add-snippet',
    //     //       content: 'Click here for creating new snippet',
    //     //       title: 'Creating snippet'
    //     //}
    // ]);
    // this.tourService.events$.subscribe(x => console.log(x));

    // this.tourService.initialize$.subscribe((steps: IStepOption[]) => {
    // console.log('tour configured with these steps:', steps);
    // });

    // if (!this.userDetails.usr_demo_tour) {
    //     this.loginService.updateEditorTour({ status: 'snippet' }).subscribe(
    //         success => {
    //             this.userDetails.usr_demo_tour = 'snippet';
    //         }
    //     );
    //     // Starting demo tour
    //     this.tourService.start();
    // }
    // }

    // startTour() {
    //     this.tourService.initialize([
    //         {
    //             stepId: 'workspace',
    //             anchorId: 'workspace',
    //             content: this.translate.instant("demotour-editor.workspace"),
    //             title: this.translate.instant("editor-left-panel.workspace"),
    //             placement: 'right',
    //             // // enableBackdrop: true
    //         }, {
    //             stepId: 'settings',
    //             anchorId: 'settings',
    //             content: this.translate.instant("demotour-editor.settings"),
    //             title: this.translate.instant("editor.Settings"),
    //             placement: 'right',
    //             // // enableBackdrop: true
    //         }, {
    //             stepId: 'open-article',
    //             anchorId: 'open-article',
    //             content: this.translate.instant("demotour-editor.open-article"),
    //             title: this.translate.instant("editor-left-panel.open-article"),
    //             placement: 'right',
    //             // // enableBackdrop: true
    //         }, {
    //             stepId: 'comments',
    //             anchorId: 'comments',
    //             content: this.translate.instant("demotour-editor.comments"),
    //             title: this.translate.instant("editor-left-panel.comments"),
    //             placement: 'right',
    //             // // enableBackdrop: true
    //         }
    //     ]);
    //     this.tourService.start();
    // }

    // array for ui demo tour;
    uiTourArray = {

        editor: { title: 'Editor', text: this.translate.instant("demotour-editor.editor") },
        compile_btn: { title: this.translate.instant("demotour-editor.compile-title"), text: this.translate.instant("demotour-editor.compile-btn") },
        error_warning: { title: this.translate.instant("demotour-editor.error-title"), text: this.translate.instant("demotour-editor.errors") },
        download: { title: this.translate.instant('demotour-editor.download-title'), text: this.translate.instant("demotour-editor.download") },
        nextstage: { title: this.translate.instant("demotour-editor.nextstage-title"), text: this.translate.instant("demotour-editor.nextstage") },
        showpdf: { title: this.translate.instant("demotour-editor.showpdf-title"), text: this.translate.instant("demotour-editor.showpdf") },
        texLog: { title: 'Tex logs', text: 'Click here to see tex file logs' },
        compile_for: { title: 'Compile for', text: 'Click here to generate XML or generate PDF' },
        savePdf: { title: 'Save PDF', text: 'To save current pdf' },
        workspace: { title: this.translate.instant("editor-left-panel.workspace"), text: this.translate.instant("demotour-editor.workspace") },
        settings: { title: this.translate.instant("editor.Settings"), text: this.translate.instant("demotour-editor.settings") },
        open_article: { title: this.translate.instant("editor-left-panel.open-article"), text: this.translate.instant("demotour-editor.open-article") },
        comments: { title: this.translate.instant("editor-left-panel.comments"), text: this.translate.instant("demotour-editor.comments") },
        bookmarks: { title: 'Bookmarks', text: 'Bookmark history' },
        chat: { title: 'Chat', text: 'Chat with latex users' },
        backup_history: { title: 'Backup list', text: 'Backup history' },
        rvclean: { title: 'RVClean settings', text: this.translate.instant("demotour-editor.rvclan") },
        review: { title: 'Review', text: this.translate.instant("demotour-editor.validate") },
        backup: { title: 'Take backup', text: 'Click here to take a backup' },
        diffpdf: { title: 'Diff PDF', text: 'Select two of them and proceed to get DIFF' },
        notifications: { title: 'Notifications123', text: 'Click here to see notifications' },
        articleShare: { title: 'Article share', text: 'Share article to others' },
        languageselector: { title: this.translate.instant('demotour-editor.language-title'), text: this.translate.instant("demotour-editor.language") },
        exit_button: { title: this.translate.instant("demotour-editor.exit-title"), text: this.translate.instant("demotour-editor.exit-button") },
        Reference: { title: this.translate.instant("editor-left-panel.reference"), text: this.translate.instant("demotour-editor.reference") },
    }

    // array for final demo tour after filter
    tourArray = [];

    // start demo tour
    startDemoTour() {


        // pdf loaded and not a guest user
        if (this.isPdf == true && this.guestUser == false) {

            this.tourArray = ['editor', 'compile_btn', 'error_warning', 'download', 'nextstage', 'showpdf', 'compileFor', 'texLog', 'savePdf', 'workspace', 'settings', 'open_article',
                'comments', 'bookmarks', 'backup_history', 'snippet', 'autosave', 'notes', 'backup', 'diffpdf', 'notifications', 'languageselector', 'exit_button'];

        } else if (this.isPdf == true && this.guestUser == true) {

            // pdf loaded and guest user
            this.tourArray = ['editor', 'compile_btn', 'error_warning', 'download', 'showpdf', 'compileFor', 'texLog', 'savePdf', 'workspace', 'settings',
                'comments', 'bookmarks', 'snippet', 'autosave', 'languageselector', 'exit_button']

        } else if (this.isPdf == false && this.guestUser == true) {

            // pdf not loaded and a guest user
            this.tourArray = ['editor', 'compile_btn', 'error_warning', 'compileFor', 'texLog', 'workspace', 'settings',
                'comments', 'bookmarks', 'snippet', 'autosave', 'languageselector', 'exit_button']

        } else {

            // pdf not loaded and not a guest user
            this.tourArray = ['editor', 'compile_btn', 'error_warning', 'nextstage', 'compileFor', 'texLog', 'workspace', 'settings', 'open_article',
                'comments', 'bookmarks', 'backup_history', 'snippet', 'autosave', 'notes', 'backup', 'diffpdf', 'notifications', 'languageselector', 'exit_button']
        }

        // if chat feature enabled
        if (this.editorCPanelOptions.chat == true && !this.guestUser) {

            var index = this.tourArray.indexOf('comments') + 1;
            this.tourArray.splice(index, 0, "chat");
        }
        // if article share feature enabled
        if (this.editorCPanelOptions.articleShare == true && !this.guestUser) {

            var index1 = this.tourArray.indexOf('notifications') + 1;
            this.tourArray.splice(index1, 0, "articleShare");
        }

        if (this.guestUser == false) {

            if (this.hasPermission('rvclean')) {
                var index = this.tourArray.indexOf('notes') + 1;
                this.tourArray.splice(index, 0, "rvclean");
            }
            if (this.hasPermission('review')) {
                var index = this.tourArray.indexOf('backup');
                this.tourArray.splice(index, 0, "review");
            }
        }

        //  additional options for joyride-tour demo
        this.joyrideService.startTour(
            {
                steps: this.tourArray,
                showCounter: true,
                themeColor: '#5a5e60'

            } // Your steps order
        ).subscribe(_x => {

            var elements = document.getElementsByClassName("joyride-step__close");

            Array.from(elements).forEach((element) => {
                element.addEventListener('click', (_e) => this.switchExplorer());
            });

            var elements1 = document.getElementsByClassName("joyride-step__done-button");

            Array.from(elements1).forEach((element) => {
                element.addEventListener('click', (_e) => this.switchExplorer());
            });

            // set workspace tab after tour completes
            var currentTab = document.querySelector('div[data-tab-id="workspace"]');
            this.leftPanelTab.setCurrentTab(currentTab);
        });

    }

    // dynamic left panel tab switch on previous click
    onPrev(key) {

        switch (key) {
            case 'settings':
                this.switchExplorer();
                break;
            case 'open_article':
                this.modalRef.hide();
                this.clearNavTab()
                this.isSettings = true;
                this.switchSettings();
                break;
            case 'comments':

                this.openModal(this.contents);
                this.getAllArticleData();
                break;
            case 'chat':
                this.switchComments(false, false, true);
                break;
            case 'bookmarks':
                if (this.editorCPanelOptions.chat == true) {
                    this.clearNavTab()
                    this.isChat = true;
                    // this.switchChat()
                } else {
                    this.switchComments(false, false, true)
                }
                break;
            case 'backup_history':
                this.clearNavTab()
                this.isBookmarks = true
                break;
            // this.switchBookmarks();
            case 'snippet_table':
                if (this.guestUser == false) {
                    this.clearNavTab()
                    this.isBackupHistory = true;
                    // this.switchBackupHistory();
                } else {
                    this.clearNavTab()
                    this.isBookmarks = true
                }
                this.closeSinppet();
                break;
            case 'autosave':
                this.rightPannel = 'viewSnippet'
                this.clearNavTab();
                this.isSnippets = true;
                break;
            case 'notes':
                this.clearNavTab()
                this.isAutosaveSettings = true
                // appending value to the form
                if (this.autoSaveSettingsData) {
                    this.autoSaveSettingsFormPatchValue()
                }
                break;
            case 'reference':
                this.clearNavTab()
                this.isReference = true
                break
            default:
                break
        }
    }

    // dynamic right panel tab switch on next click

    onNext(key) {

        switch (key) {
            case 'workspace':
                this.clearNavTab()
                this.isSettings = true;
                this.switchSettings()
                break;
            case 'settings':
                if (this.guestUser == true) {
                    this.switchComments(false, false, true);
                } else {
                    this.openModal(this.contents);
                    this.getAllArticleData();
                }
                break;
            case 'open_article':
                this.modalRef.hide();
                this.switchComments(false, false, true);
                break;
            case 'comments':

                if (this.editorCPanelOptions.chat == true && this.guestUser == false) {
                    this.clearNavTab()
                    this.isChat = true;
                    // this.switchChat()
                } else {
                    this.clearNavTab()
                    this.isBookmarks = true
                    // this.switchBookmarks();
                }
                break;
            case 'chat':
                this.clearNavTab()
                this.isBookmarks = true
                // this.switchBookmarks();
                break;
            case 'bookmarks':
                if (this.guestUser == false) {
                    this.clearNavTab()
                    this.isBackupHistory = true;
                    // this.switchBackupHistory();
                } else {
                    this.rightPannel = 'viewSnippet'
                    this.clearNavTab();
                    this.isSnippets = true
                }
                break;
            case 'backup_history':
                this.rightPannel = 'viewSnippet'
                this.clearNavTab();
                this.isSnippets = true;
                break;
            case 'snippet_table':
                this.clearNavTab()
                this.isAutosaveSettings = true
                this.closeSinppet();
                //appending value to the form
                // if (this.autoSaveSettingsData) {
                //     this.autoSaveSettingsFormPatchValue()
                // }
                break;
            case 'autosave':
                if (this.guestUser == false) {
                    this.clearNavTab()
                    this.isNotes = true
                }
                break;
            case 'reference':
                this.clearNavTab()
                this.isReference = true
                break;

            default:
                break;
        }
    }

    // 
    getAllreceiver() {
        var receiverData = {
            sender: this.userDetails._id,
            article: this.route.snapshot.paramMap.get("id")
        }
        this.editorService.getAllUsers().subscribe(
            response => {
                console.log(response);
            },
            _error => { }
        );
    }

    // public tour = new Tour();

    // Demo for angular-ui-tour
    tourDemo() {
        // console.log(uiTour);
    }

    getChatUsersList() {
        var receiverData = {
            sender: this.userDetails._id,
            article: this.route.snapshot.paramMap.get("id")
        }
        this.editorService.getChatReceiver(receiverData).subscribe(
            response => {
                this.receiverlist = this.activeUsersList.filter(({ usr_id: id1 }) => response.shared_user.some((id => id === id1)));
                this.chatService.requestingOnlineUsers();
            },
            _error => { }
        );
    }

    backupData;
    isBackupHistory: boolean = false;
    backupHistory = [];
    targetBackup;
    backupTreeList = [];
    backupTreeField;
    @ViewChild('backuptree')
    public backuptree: TreeViewComponent;
    public cssClass: string = "custom";
    backupImage;
    backupPdf;
    fileType;
    backupPdfZoom = 0.8;
    selectedBackupNode = [];
    backupName = new FormControl('', [this.noWhitespaceValidator, Validators.maxLength(75)], this.checkBackupExist.bind(this));
    backupDownloadUrl = this.env.baseAPIUrl + '/backup-delete';
    isBackupEdit: boolean;
    backupToEdit;
    backupExpansionPath;

    /**
     * Check existance of backup version name
     * @param control 
     * @author Arathy S V
     */
    checkBackupExist(control: AbstractControl) {
        return new Promise((resolve, _reject) => {
            if (control.value != '') {
                const backupData = {
                    name: control.value,
                    user: this.userDetails._id,
                    article: this.selectednode[this.activeTab].nodeid,
                    edit: this.isBackupEdit,
                    backup: this.backupToEdit
                };
                this.editorService.checkBackupExist(backupData).subscribe(
                    response => {
                        if (response.status == 200) {
                            resolve({ 'backupNameTaken': true });
                        } else {
                            resolve(null);
                        }
                    }
                )
            }
        });
    }

    /**
     * Take article backup
     * @Author Arathy S V
    */
    takeBackup() {
        if (this.activeTab != -1 && this.backupName.valid) {
            this.sharedService.showSpinner('articleUpload');
            this.editorService.backup({ id: this.selectednode[this.activeTab].nodeid, user: this.userDetails._id, name: this.backupName.value }).subscribe(
                response => {
                    this.sharedService.hideSpinner('articleUpload');
                    if (response.status == 'success') {
                        this.notifier.notify("success", this.translate.instant('backup.Took backup successfully'));
                        this.backupHistory.unshift(response.backup[0]);
                        this.modalRef.hide();
                    } else {
                        this.notifier.notify('error', this.translate.instant('backup.' + response.message));
                    }
                },
                _error => {
                    // this.sharedService.hideSpinner('articleUpload');
                    // this.notifier.notify('error', error.error.message);
                    // console.log(error.error);
                }
            );
        }
    }

    /**
     * Get backup list
     * @author Arathy S V
     */
    getBackup(_modal) {
        this.editorService.getBackupList({ id: this.selectednode[this.activeTab].nodeid, user: this.userDetails._id }).subscribe(
            response => {
                this.backupHistory = response.backup;
            },
            _error => { }
        );
    }

    /**
     * sidebar switching to backup history
     * @author Arathy S V
     */
    // switchBackupHistory() {
    //     this.isBackupHistory = true;
    //     this.isDiff = false;
    //     this.isExplorer = false;
    //     this.isSettings = false;
    //     this.isComments = false;
    //     this.isChat = false;
    // }

    /**
     * Loading target backup details into modal
     * @author Arathy S V
     */
    loadBackup(modal, backup) {
        this.backupPdfZoom = 0.8;
        this.sharedService.showSpinner('articleUpload');
        this.editorService.getBackup({ backup: backup._id }).subscribe(
            response => {
                if (response.status == 'success') {
                    this.sharedService.hideSpinner('articleUpload');
                    this.backupExpansionPath = response.path;
                    this.modalRef = this.modalService.show(modal, {
                        animated: true,
                        keyboard: true,
                        backdrop: true,
                        ignoreBackdropClick: true,
                        class: 'modal-xl'
                    });
                    this.targetBackup = backup;
                    this.backupTreeList = [];
                    this.fileType = '';
                    const tree = [{
                        name: this.hierarchicalData[0].name,
                        hasChildren: true,
                        children: response.structure
                    }];
                    this.backupTreeList.push(this.getChildrens(tree[0], 1));
                    this.backupTreeField = { dataSource: this.backupTreeList, id: 'id', text: 'name', child: 'children', iconCss: 'icon', code: 'code', article_file: 'article_file', hasChildren: 'hasChildren' };
                    if (response.content) {
                        this.fileType = 'code';
                        this.code = atob(response.content);
                        const baseFile = this.backupTreeList[0].children.find(x => (x.name == response.file));
                        this.selectedBackupNode = [baseFile.id];
                    }
                }
                else
                    this.notifier.notify('error', this.translate.instant('backup.backup loading error'));
            }
        );
    }

    /**
     * Click event on backup treenode
     * @author Arathy S V
     */
    backupTreeNodeClick(node: NodeClickEventArgs) {
        let currentNode: any = this.backuptree.getTreeData(node.node)[0];
        if (currentNode.hasChildren == false) {
            const filePath = this.getFilePath(currentNode.id);
            this.sharedService.showSpinner('articleUpload');
            this.editorService.getBackupFile({ backup: this.targetBackup._id, file: filePath, name: currentNode.name, loadingPath: this.backupExpansionPath }).subscribe(
                response => {
                    this.sharedService.hideSpinner('articleUpload');
                    if (response.status == 'success') {
                        var fileType = this.getFileExtension(currentNode.name);
                        if (this.editorFiles.includes(fileType)) {
                            this.fileType = 'code';
                            this.code = atob(response.content);
                        } else if (this.images.includes(fileType)) {
                            this.fileType = 'image';
                            this.backupImage = this.env.baseUploadUrl + '/public/' + response.file;
                        } else if (fileType == 'pdf') {
                            this.backupPdf = this.env.baseUploadUrl + '/public/' + response.file;
                            this.fileType = 'pdf';
                        }
                    } else {
                        this.fileType = '';
                    }
                },
                _error => { }
            );
        }
    }

    /**
     * Getting file path
     * @param nodeId 
     */
    getFilePath(nodeId: any) {
        let nodePath = "";
        let node = this.backuptree.getTreeData(nodeId)[0];
        while (this.backuptree.getNode(node.id.toString()).parentID) {
            nodePath = node.name + "/" + nodePath;
            let parentId: any = this.backuptree.getNode(node.id.toString()).parentID.toString();
            node = this.backuptree.getTreeData(parentId)[0];
        }
        return nodePath;
    }

    /**
     * Revert to target backup version
     * @arathy Arathy S V
     */
    restoreBackup(version) {
        this.sharedService.showSpinner('articleUpload');
        this.editorService.revertToVersion({ version: version }).subscribe(
            response => {
                this.sharedService.hideSpinner('articleUpload');
                if (response.status == 'success') {
                    localStorage.removeItem("activeFiles");
                    window.location.reload();
                } else {
                    // this.notifier.notify('error', response.message);
                    this.notifier.notify('error', this.translate.instant('backup.' + response.message));
                }
            },
            _error => { }
        );
    }
    //exporting pattern
    exportpattern(args) {
        var arrayToString = JSON.stringify(Object.assign({}, args));  // convert array to string
        var journalcode = this.currentJournalCode;
        this.editorService.downloadFile(arrayToString, journalcode).subscribe(
            response => {
                if (response['status'] == 200) {
                    this.filedownloadUrl = response['value']['destination'] + response['value']['file'];
                    var link = document.createElement("a");
                    link.setAttribute("href", this.downloadFileUrl + this.filedownloadUrl);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            },
            _error => { }
        );
    }


    pdfZoomInBackup() {
        this.backupPdfZoom = this.backupPdfZoom + 0.25;
    }
    pdfZoomOutBackup() {
        if (this.backupPdfZoom > 0.25) {
            this.backupPdfZoom = this.backupPdfZoom - 0.25;
        }
    }

    /**
     * Delete and download backup
     * @param backup 
     * @author Arathy S V
     */
    deleteBackupWithDownload(backup) {
        const data = { backup: backup._id, name: this.hierarchicalData[0].name };
        this.sharedService.showSpinner('backupList');
        this.editorService.downloadBackup(data).subscribe(
            res => {
                const blob = new Blob([res], { type: 'application/octet-stream' });
                saveAs(blob, backup.backup_name + '.zip');
                this.sharedService.hideSpinner('backupList');
                const index = this.backupHistory.indexOf(backup);
                if (index > -1) {
                    this.backupHistory.splice(index, 1);
                }
            }, err => {
                this.sharedService.hideSpinner('backupList');
                var decodedString = String.fromCharCode.apply(null, new Uint8Array(err.error));
                var obj = JSON.parse(decodedString);
                if (obj.status == 'error') {
                    this.notifier.notify('error', this.translate.instant('backup.backup delete error'));
                }
            });
    }

    /**
     * Delete target backup
     * @param backup 
     * @author Arathy S V
     */
    deleteBackupWithoutDownload(backup) {
        const data = { backup: backup._id, name: this.hierarchicalData[0].name };
        this.sharedService.showSpinner('backupList');
        this.editorService.deleteBackup(data).subscribe(
            res => {
                this.sharedService.hideSpinner('backupList');
                if (res.status == 'success') {
                    const index = this.backupHistory.indexOf(backup);
                    if (index > -1) {
                        this.backupHistory.splice(index, 1);
                        this.notifier.notify('success', this.translate.instant('backup.Backup deleted successfully'));
                    }
                } else {
                    this.notifier.notify('error', this.translate.instant('backup.Error while deleting backups'));
                }
            }, _err => {
            });
    }

    /**
     * Delete and download all backup of target article
     * @author Arathy S V
     */
    deleteAllBackupWithDownload() {
        const backupData = {
            'article': this.selectednode[this.activeTab].nodeid,
            'user': this.userDetails._id
        };
        this.sharedService.showSpinner('backupList');
        this.editorService.downloadAllBackup(backupData).subscribe(
            response => {
                this.sharedService.hideSpinner('backupList');
                const blob = new Blob([response], { type: 'application/octet-stream' });
                saveAs(blob, this.hierarchicalData[0].name + '.zip');
                this.backupHistory = [];
            },
            error => {
                this.sharedService.hideSpinner('backupList');
                var decodedString = String.fromCharCode.apply(null, new Uint8Array(error.error));
                var obj = JSON.parse(decodedString);
                if (obj.status == 'error') {
                    this.notifier.notify('error', this.translate.instant('backup.backup delete error'));
                }
            }
        );
    }

    /**
     * Delete all backups of target article
     * @author Arathy S V
     */
    deleteAllBackupWithoutDownload() {
        const backupData = {
            'article': this.selectednode[this.activeTab].nodeid,
            'user': this.userDetails._id
        };
        this.sharedService.showSpinner('backupList');
        this.editorService.deleteAllBackup(backupData).subscribe(
            response => {
                this.sharedService.hideSpinner('backupList');
                if (response.status == 'success') {
                    this.backupHistory = [];
                    this.notifier.notify('success', this.translate.instant('backup.Backup deleted successfully'));

                    this.notifier.notify('error', this.translate.instant('backup.Error while deleting backups'));
                }
            },
            _error => { }
        )
    }

    /**
     * Load popup for editing backup
     * @author Arathy S V
     */
    loadBackupEdit(backup, modal) {
        this.modalRef = this.modalService.show(modal, {
            animated: true,
            keyboard: true,
            backdrop: true,
            ignoreBackdropClick: true
        });
        this.isBackupEdit = true;
        this.backupToEdit = backup._id;
        this.backupName.setValue(backup.backup_name);
    }

    /**
     * Open modal for create backup
     * @author Arathy S V
     */
    openCreateBackupModal(modal) {
        this.isBackupEdit = false;
        this.modalRef = this.modalService.show(modal, {
            animated: true,
            keyboard: true,
            backdrop: true,
            ignoreBackdropClick: true
        });
        this.backupName.setValue('');
    }

    // comparePdfModal(modal) {
    //     this.tmpltModalRef = this.modalService.show(modal, {
    //         animated: true,
    //         keyboard: true,
    //         backdrop: true,
    //         ignoreBackdropClick: true,
    //         class: 'modal-xl'
    //     });
    // }

    /**
     * Update backup name
     * @author Arathy S V
     */
    updateBackup() {
        if (this.activeTab != -1 && this.backupName.valid) {
            const backupData = {
                article: this.selectednode[this.activeTab].nodeid,
                user: this.userDetails._id,
                name: this.backupName.value,
                backup: this.backupToEdit
            };
            this.sharedService.showSpinner('articleUpload');
            this.editorService.editBackup(backupData).subscribe(
                response => {
                    this.sharedService.hideSpinner('articleUpload');
                    if (response.status == 'success') {
                        // find the index of the backup version to update name
                        const index = this.backupHistory.findIndex(backup => (backup._id == this.backupToEdit));
                        if (index > -1) {
                            // Update backup nane in backup list
                            this.backupHistory[index].backup_name = this.backupName.value;
                            this.modalRef.hide();
                            this.notifier.notify('success', this.translate.instant('backup.Backup name updated successfully'));
                        }
                    } else {
                        // this.notifier.notify('error', response.message);
                        this.notifier.notify('error', this.translate.instant('backup.' + response.message));
                    }
                },
                _error => { }
            );
        }
    }

    // close backup loading modal
    closeBackupLoadingModal() {
        this.modalRef.hide();
        this.editorService.removeTempBackup({ backup: this.backupExpansionPath });
    }

    misspelledWords = [];

    /**
     * Spell checking
     * @author Arathy S V
     */
    spellChecking() {
        var allLines = this.editor.session.getDocument().getAllLines();
        // get all visible lines
        var visibleLines = this.editor.session.getLines(this.editor.getFirstVisibleRow(), this.editor.getLastVisibleRow());
        // extract all visible words
        var allVisibleWords = this.misspelled(visibleLines, this.editor.getFirstVisibleRow());
        var misspelling = [];
        // this.chatService.checkSpelling(allVisibleWords.words);
        this.chatService.checkSpelling(allVisibleWords.misspelled);
    }

    /**
     * Check the all lines, and return [start, end]-pairs for all words.
     * @param lines 
     * @param startLine index of the starting line
     * @author Arathy S V
     */
    misspelled(lines, startLine) {
        // replace any character that doesn't make up a word with a space, and then split on space
        var misspelled = [];
        var badPhrases = [];
        var testedWords = [];
        var allWords = [];

        lines.forEach((line, lineIndex) => {
            var phraseWords = line.split(/\s/);
            var words = line.replace(/[^a-zA-Z0-9'\\-]/g, ' ').split(/\s/);

            for (var wordIndex = 0, wordCount = phraseWords.length; wordIndex < wordCount; ++wordIndex) {
                testedWords.push(false);
            }

            // How many words can appear in a phrase that will be checked against
            // the dictionaries
            var maxWordsInPhrase = 7;

            outerloop:
            for (var wordGroupIndex = maxWordsInPhrase; wordGroupIndex > 0; --wordGroupIndex) {
                var i = 0;

                // When checking single words, use the words array. Otherwise use the phraseWords array.
                var checkArray = wordGroupIndex == 1 ? words : phraseWords;

                var lastCheckedWord = 0;

                innerloop:
                for (var wordIndex = 0, wordCount: any = checkArray.length - wordGroupIndex + 1; wordIndex < wordCount; ++wordIndex) {

                    // do this here so the continues down below don't stop us incrementing the value
                    var firstWordLengthWithSpace = checkArray[wordIndex].length + 1;
                    i += firstWordLengthWithSpace;

                    if (wordIndex < lastCheckedWord) {
                        continue;
                    }

                    var checkWord = "";

                    for (var checkWordIndex = wordIndex, checkWordIndexMax = wordIndex + wordGroupIndex; checkWordIndex < checkWordIndexMax; ++checkWordIndex) {

                        if (testedWords[checkWordIndex]) {
                            continue innerloop;
                        }

                        if (checkArray[checkWordIndex].length == 0) {
                            continue innerloop;
                        }

                        if (checkWordIndex != wordIndex) {
                            checkWord += " ";
                        }
                        checkWord += checkArray[checkWordIndex];
                    }

                    // skip non word characters at the start and end of the word or phrase
                    var match = checkWord.match(/^[^a-zA-Z0-9]+/);
                    var startingWhitespace = match != null ? match[0].length : 0;

                    var endMatch = checkWord.match(/[^a-zA-Z0-9]+$/);
                    var endingWhitespace = endMatch != null ? endMatch[0].length : 0;

                    // subtract firstWordLengthWithSpace to account for the fact that it was added
                    // at the start of the loop
                    var start = i + startingWhitespace - firstWordLengthWithSpace;
                    var end = i + checkWord.length - firstWordLengthWithSpace - endingWhitespace;

                    if (start < end && checkWord.trim().length != 0) {

                        var wordConsumed = false;
                        if (wordGroupIndex == 1) {
                            // check for double words
                            if (wordIndex < wordCount - 1 && checkArray[wordIndex + 1] == checkWord) {
                                // don't test the next word
                                testedWords[wordIndex] = testedWords[wordIndex + 1] = true;
                                // this is a bad phrase
                                badPhrases[badPhrases.length] = [start, end + checkArray[wordIndex + 1].length + 1];
                                // } else if (!positiveDictionary.check(checkWord.trim())) {
                            } else {
                                misspelled.push({ word: checkWord, range: [lineIndex + startLine, start, lineIndex + startLine, end] });
                            }
                        }

                        // if (wordConsumed) {
                        // 	// Words will only fall into one dictionary item. Here we make sure that any words in this negative
                        // 	// match don't get used again.
                        // 	for (var checkWordIndex = wordIndex, checkWordIndexMax = wordIndex + wordGroupIndex; checkWordIndex < checkWordIndexMax; ++checkWordIndex) {
                        // 		testedWords[checkWordIndex] = true;
                        // 	}

                        // 	lastCheckedWord = wordIndex + wordGroupIndex;
                        // }
                    }
                }
            }
        });
        // return {misspelled: misspelled, words: allWords};
        return { misspelled: misspelled };
    }

    selected100 = 'suggesting';


    // this.chatService.getCheckSpelling().subscribe(response => {
    //     this.misspelledWords.forEach(word => {
    //         word.start.detach();
    //         word.end.detach();
    //         this.editor.session.removeMarker(word.markerId);
    //     });
    //     var Range = ace.require("ace/range").Range;
    //     this.misspelledWords = [];
    //     response.forEach((element, index) => {
    //         // console.log(this.misspelled);
    //         var position = element.range;
    //         // console.log(position);
    //         var range = new Range(position[0], position[1], position[2], position[3]);
    //         range['markerId'] = this.editor.session.addMarker(range, "spelling-highlight", 'text');
    //         range.start = <any>this.editor.session.getDocument().createAnchor(range.start.row, range.start.column);
    //         range.end = <any>this.editor.session.getDocument().createAnchor(range.end.row, range.end.column);
    //         range['suggetion'] = element.suggestion;
    //         range['word'] = element.word;
    //         this.misspelledWords.push(range);
    //         // var aceDocument = this.editor.session.getDocument();
    //         // var commentAnchor = aceDocument.createAnchor(0, 0);
    //     });
    // });


    @ViewChild("filereplacemodal") fileReplaceModal: TemplateRef<any>;
    public replacePath: Object = {
        saveUrl: this.env.baseAPIUrl + '/replace-file'
    };
    fileReplaceAllowedType;
    replaceFile;

    /**
     * File replace failure
     * @author Arathy S V
     */
    fileReplaceFailed(_$event) {
        this.sharedService.hideSpinner('editorLoader');
        // Get id of selected node in the tree
        let targetNodeId = this.treevalidate.selectedNodes[0].toString();
        // Don't change selected file name. Set selected file name with actual name.
        this.treevalidate.getTreeData(targetNodeId)[0].name = this.replaceFile;
    }

    /**
     * File replace success
     * @author Arathy S V
     */
    fileReplaceSuccess($event) {
        this.sharedService.hideSpinner('editorLoader');
        // get the id of the selected node to replace
        let targetNodeId = this.treevalidate.selectedNodes[0].toString();
        // Set the tooltip of the replaced file  
        this.treevalidate.getTreeData(targetNodeId)[0].tooltip = $event.file.name;
    }

    /**
     * Adding coustom data to form data for file uploading
     * @author Arathy S V
     */
    replaceFileUploading: EmitType<SelectedEventArgs> = (args: any) => {
        this.sharedService.showSpinner('editorLoader');
        // Get the id of the selected file to replace
        let targetNodeId = this.treevalidate.selectedNodes[0].toString();
        // details of the selected file. Details including file path, article code etc
        let nodeDetails = this.getFileDetails(targetNodeId);
        // Get node details of selected file
        let nodeData = this.treevalidate.getTreeData(targetNodeId)[0];
        // Close file from the editor if it is opened
        this.closeActivetabByDelete(nodeData);
        //  Set the name of the file to replace
        this.replaceFile = nodeData.name;
        // Replace the name of the file selected for replace with the name of the file selected for uploading. Extract article children with updated details
        this.treevalidate.getTreeData(targetNodeId)[0].name = args.fileData.name;
        let children = this.treevalidate.getTreeData(nodeDetails.id)[0].children;
        // Adding custom data for uploading
        args.customFormData = [
            { 'article': nodeDetails.code },
            { 'sourceFile': nodeDetails.path },
            { 'comment': nodeDetails.commentPath },
            { 'children': JSON.stringify(children) }
        ];
    }

    /**
     * Trigger when selecting file for uploading(replace)
     * @param args 
     * @author Arathy S V
     */
    public replaceFileSelection(args: SelectedEventArgs): void {
        if (args.filesData) {
            let targetNodeId = this.treevalidate.selectedNodes[0].toString();
            // Check whether given file already exist or not
            let validation = this.checkDuplicate(targetNodeId, args.filesData[0].name);
            if (!validation.status) {
                args.filesData[0].statusCode = '0';
                args.filesData[0].status = validation.message;
            }
        }
    }

    cleaningTemplateData = [];
    tmpltModalRef;
    referModalRef;
    journalList;
    // templateList;
    selectedJournal;
    selectedType;
    isTemplateEdit;
    selectedTemplate;
    tmpltDisplayedColumns = ['tmplt_name', 'tmplt_template', 'journals', 'Action'];
    templateList = new MatTableDataSource();
    formSubmitted = false;
    refformSubmitted = false;
    searchJournal = '';
    tmpltDltConfirmationMsg = this.translate.instant('cleaning-templates.delete-confirmation-message');
    isAllJournalSelected: boolean;
    isAllrefJorunalSlected: boolean;
    @ViewChild('allselectedjournal') private allSelectedJournal: MatOption;

    cleaningTemplateform = new FormGroup({
        clngTmpltName: new FormControl('', [Validators.required, this.noWhitespaceValidator, Validators.maxLength(100)]),
        clngTmpltTemplate: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
        clngTmpltJournal: new FormControl('', Validators.required)
    });

    referencecleaningTemplateform = new FormGroup({
        clngrefTmpltName: new FormControl('', [Validators.required, this.noWhitespaceValidator, Validators.maxLength(100)]),
        clngrefTmpltTemplate: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
        clngrefTmpltJournal: new FormControl('', Validators.required),
        clnrefTmpltType: new FormControl('', Validators.required),
    });
    /**
     * Opening cleaning template modal
     * @param modal 
     * @author Arathy S V
     */
    openCleaningTemplateModal(modal) {
        this.tmpltModalRef = this.modalService.show(modal, {
            animated: true,
            keyboard: true,
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        });
        this.sharedService.showSpinner('articleUpload');
        this.editorService.cleaningTemplateList().subscribe(
            res => {
                this.sharedService.hideSpinner('articleUpload');
                this.templateList.data = res.templates;
            },
            _err => { console.log('Error'); }
        );
        this.editorService.getJournals().subscribe(
            res => {
                this.journalList = res.journals;
            },
            _err => { console.log('Error'); }
        )
    }

    /**
     * Open modal for creating cleaning template
     * @author Arathy S V
     */
    OpenCreateClngTmpltModal(modal) {
        this.modalRef = this.modalService.show(modal, {
            animated: true,
            keyboard: true,
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'create-tmplate-modal'
        });
    }

    removeReferenceOrder(idx) {
        this.referenceOrders.splice(idx, 1);
    }

    openCleaningReferencePatternOrderModal(index) {
        this.selectedCleaningReferencePatternOrderIndex = index;
        this.modalRef = this.modalService.show(this.cleaningReferencePatternOrderModal, {
            animated: true,
            keyboard: true,
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'create-tmplate-modal'
        });
    }

    createNewReferencePatternOrder() {
        if (!this.referenceOrders) {
            this.referenceOrders = [];
        }
        this.selectedCleaningReferencePatternOrderIndex = this.referenceOrders.length;
        this.referenceOrders[this.selectedCleaningReferencePatternOrderIndex] = {
            order: this.referencePatterns.map(data => {
                return data['id'];
            })
        };
        this.openCleaningReferencePatternOrderModal(this.selectedCleaningReferencePatternOrderIndex);
    }

    OpenCreateClngreftModal(modal) {
        this.modalRef = this.modalService.show(modal, {
            animated: true,
            keyboard: true,
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'create-tmplate-modal'
        });
    }

    OpenPreviewTmpltModal(modal) {
        this.modalRef = this.modalService.show(modal, {
            animated: true,
            keyboard: true,
            backdrop: true,
            ignoreBackdropClick: true,
            // class: 'modal-lg'
            class: 'create-tmplate-modal'
        });
    }

    get referenceOrderFormatted() {
        if (!this.referenceOrders) {
            return [];
        }
        return this.referenceOrders.map((item) => {
            return item.order.map((ittem) => {
                return this.getParameterById(ittem)['name']
            }).join(" - ");
        })
    }
    oncleaningReferenceModalFieldDataChange(args): void {
        this.selectedCleaningReferenceType = args.itemData.Id;
        // this.referenceOutput = [];
        this.referenceOrders = []
        this.editorService.getReferencePatternsByReferenceType(args.itemData.Id).subscribe(
            response => {
                if (response.status === "successfull") {
                    this.referencePatterns = response.data.parameters;
                    console.log(this.referencePatterns)
                    this.referenceOrders = response.data.order;
                    this.referenceOutput = response.data.output;
                    if (!this.referenceOutput) {
                        this.referenceOutput = this.referencePatterns.map(data => {
                            return data['id'];
                        });
                    }
                    this.grid.refresh();
                } else {
                    this.referencePatterns = [];
                    this.referenceOutput = [];
                    this.notifier.notify('error', this.translate.instant("rvclean.error-message"));
                }

            },
            _error => {
                this.referencePatterns = [];
                this.referenceOutput = [];
                console.log("Error");
            }
        );
    }




    openCleaningReferenceModal(modal) {

        this.populateReferencePopupMenu();
        this.cleaningReferenceModalFields = { text: 'Game', value: 'Id' };

        this.referModalRef = this.modalService.show(modal, {
            animated: true,
            keyboard: true,
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        });
        this.sharedService.showSpinner('articleUpload');
        this.editorService.cleaningReferenceTemplateList().subscribe(
            res => {
                this.sharedService.hideSpinner('articleUpload');
                this.templateList.data = res.templates;
            },
            _err => { console.log('Error'); }
        );
        this.editorService.getJournals().subscribe(
            res => {
                this.journalList = res.journals;
            },
            _err => { console.log('Error'); }
        )
    }

    // Getting form controls
    get formfields() {
        return this.cleaningTemplateform.controls;
    }
    get refformfields() {
        return this.referencecleaningTemplateform.controls
    }

    /**
     * Creating new cleaning template
     * @author Arathy S V
     */
    createClngTmplt() {
        this.formSubmitted = true;
        if (!this.cleaningTemplateform.valid) {
            return 1;
        } else {
            var data = {
                name: this.cleaningTemplateform.get('clngTmpltName').value,
                template: this.cleaningTemplateform.get('clngTmpltTemplate').value,
                journal: this.cleaningTemplateform.get('clngTmpltJournal').value
            };
            this.sharedService.showSpinner('articleUpload');
            this.editorService.createCleaningTemplate(data).subscribe(
                res => {
                    this.sharedService.hideSpinner('articleUpload');
                    if (res.status == 'validation') {
                        this.notifier.notify('error', res.errors)
                        // if(res.errors.journal)
                        //     this.cleaningTemplateform.get('clngTmpltJournal').errors.required = true;
                    } else if (res.status == 'success') {
                        this.modalRef.hide();
                        this.notifier.notify('success', this.translate.instant('cleaning-templates.creation-success'));
                        this.templateList.data = res.templates;
                        this.searchJournal = '';
                    } else {
                        this.notifier.notify('error', this.translate.instant('cleaning-templates.cration-error'));
                    }
                },
                _err => { console.log('Error'); }
            )
        }
    }
    /**
       * Creating new cleaning  reference template
       * @author simi
       */

    createClngRefer() {
        this.refformSubmitted = true;
        if (!this.referencecleaningTemplateform.valid) {
            return 1;
        } else {
            var data = {
                name: this.referencecleaningTemplateform.get('clngrefTmpltName').value,
                template: this.referencecleaningTemplateform.get('clngrefTmpltTemplate').value,
                journal: this.referencecleaningTemplateform.get('clngrefTmpltJournal').value,
                type: this.referencecleaningTemplateform.get('clnrefTmpltType').value


            };
            this.sharedService.showSpinner('articleUpload');
            this.editorService.createCleaningTemplateReference(data).subscribe(
                res => {
                    this.sharedService.hideSpinner('articleUpload');
                    if (res.status == 'validation') {
                        this.notifier.notify('error', res.errors)
                        // if(res.errors.journal)
                        //     this.cleaningTemplateform.get('clngTmpltJournal').errors.required = true;
                    } else if (res.status == 'success') {
                        this.modalRef.hide();
                        this.notifier.notify('success', this.translate.instant('cleaning-templates.creation-success'));
                        this.templateList.data = res.templates;
                        this.searchJournal = '';
                    } else {
                        this.notifier.notify('error', this.translate.instant('cleaning-templates.cration-error'));
                    }
                },
                _err => { console.log('Error'); }
            )
        }
    }

    /**
     * Load cleaning template details into form for editing
     * @author Arathy S V
     */
    loadTemplate(templateDetails) {
        this.isTemplateEdit = true;
        this.cleaningTemplateform.patchValue({
            clngTmpltName: templateDetails.tmplt_name,
            clngTmpltTemplate: templateDetails.tmplt_template,
            clngTmpltJournal: templateDetails.tmplt_journal
        });
        this.selectedJournal = templateDetails.tmplt_journal;
        this.selectedTemplate = templateDetails._id;
        this.journalSelectionTemplate();
    }


    /**
    * Load cleaning template details into form for editing
    * @author Simi francis
    */
    loadreferTemplate(templateDetails) {
        this.isTemplateEdit = true;
        this.referencecleaningTemplateform.patchValue({
            clngrefTmpltName: templateDetails.tmplt_ref_name,
            clngrefTmpltTemplate: templateDetails.reference_template,
            clngrefTmpltJournal: templateDetails.tmplt_journal,
            clnrefTmpltType: templateDetails.tmplt_type
        });
        this.selectedType = templateDetails.tmplt_type
        this.selectedJournal = templateDetails.tmplt_journal;
        this.selectedTemplate = templateDetails._id;
        this.journalSelectionrefTemplate();
    }

    /**
     * Delete Cleaning template
     * @author Arathy S V
     */
    deleteTemplate(template) {
        this.sharedService.showSpinner('articleUpload');
        this.editorService.deleteCleaningTemplate({ template: template }).subscribe(
            res => {
                this.sharedService.hideSpinner('articleUpload');
                if (res.status == 'success') {
                    this.searchJournal = '';
                    this.templateList.data = res.templates;
                    this.notifier.notify('success', this.translate.instant('cleaning-templates.deletion-success'));
                }
                else
                    this.notifier.notify('error', this.translate.instant('cleaning-templates.deletion-error'));
            },
            _err => { this.notifier.notify('error', this.translate.instant('cleaning-templates.deletion-error')); }
        );
    }
    deleteReference(template) {
        this.sharedService.showSpinner('articleUpload');
        this.editorService.deletereferencetemplate({ template: template }).subscribe(
            res => {
                this.sharedService.hideSpinner('articleUpload');
                if (res.status == 'success') {
                    this.searchJournal = '';
                    this.templateList.data = res.templates;
                    this.notifier.notify('success', this.translate.instant('cleaning-templates.deletion-success'));
                }
                else
                    this.notifier.notify('error', this.translate.instant('cleaning-templates.deletion-error'));
            },
            _err => { this.notifier.notify('error', this.translate.instant('cleaning-templates.deletion-error')); }
        );
    }
    /**
     * Update cleaning template
     * @author Arathy S V
     */
    updateTemplate() {
        this.formSubmitted = true;
        if (!this.cleaningTemplateform.valid) {
            return 1;
        } else {
            var data = {
                id: this.selectedTemplate,
                name: this.cleaningTemplateform.get('clngTmpltName').value,
                template: this.cleaningTemplateform.get('clngTmpltTemplate').value,
                journal: this.cleaningTemplateform.get('clngTmpltJournal').value
            };
            this.sharedService.showSpinner('articleUpload');
            this.editorService.updatetemplate(data).subscribe(
                res => {
                    this.sharedService.hideSpinner('articleUpload');
                    if (res.status == 'success') {
                        this.searchJournal = '';
                        this.modalRef.hide();
                        this.notifier.notify('success', this.translate.instant('cleaning-templates.updation-success'));
                        this.templateList.data = res.templates;
                    } else if (res.status == 'validation') {
                        this.notifier.notify('error', res.message);
                    } else
                        this.notifier.notify('error', this.translate.instant('cleaning-templates.updation-error'));
                },
                _err => { this.notifier.notify('error', this.translate.instant('cleaning-templates.updation-error')); }
            )

        }
    }
    updateReference() {
        this.refformSubmitted = true;
        if (!this.referencecleaningTemplateform.valid) {
            return 1;
        } else {
            var data = {
                id: this.selectedTemplate,
                name: this.referencecleaningTemplateform.get('clngrefTmpltName').value,
                template: this.referencecleaningTemplateform.get('clngrefTmpltTemplate').value,
                journal: this.referencecleaningTemplateform.get('clngrefTmpltJournal').value,
                type: this.referencecleaningTemplateform.get('clnrefTmpltType').value
            };
            this.sharedService.showSpinner('articleUpload');
            this.editorService.updatereferencetemplate(data).subscribe(
                res => {
                    this.sharedService.hideSpinner('articleUpload');
                    if (res.status == 'success') {
                        this.searchJournal = '';
                        this.modalRef.hide();
                        this.notifier.notify('success', this.translate.instant('cleaning-templates.updation-success'));
                        this.templateList.data = res.templates;
                    } else if (res.status == 'validation') {
                        this.notifier.notify('error', res.message);
                    } else
                        this.notifier.notify('error', this.translate.instant('cleaning-templates.updation-error'));
                },
                _err => { this.notifier.notify('error', this.translate.instant('cleaning-templates.updation-error')); }
            )
        }
    }
    openCreateCleaningTemplate(modal) {
        this.OpenCreateClngTmpltModal(modal);
        this.isTemplateEdit = false;
        this.cleaningTemplateform.reset();
        this.selectedJournal = [];
        this.formSubmitted = false;
        this.isAllJournalSelected = false;
    }

    openCreateCleaningReference(modal) {
        this.OpenCreateClngreftModal(modal);
        this.isTemplateEdit = false;
        this.referencecleaningTemplateform.reset();
        this.selectedJournal = [];
        //this.selectedtype = [];
        this.refformSubmitted = false;
        this.isAllrefJorunalSlected = false;
    }

    // Searching in cleaning template table
    public searchTemplate = (value: string) => {
        this.templateList.filter = value.trim().toLocaleLowerCase();
    }

    tmpltSearchByJournal(data) {
        if (data) {
            this.sharedService.showSpinner('articleUpload');
            this.editorService.getCleaningTemplateByJournal(data).subscribe(
                res => {
                    this.sharedService.hideSpinner('articleUpload');
                    this.templateList.data = res.templates;
                }
            )
        } else {
            this.sharedService.showSpinner('articleUpload');
            this.editorService.cleaningTemplateList().subscribe(
                res => {
                    this.sharedService.hideSpinner('articleUpload');
                    this.templateList.data = res.templates;
                },
                _err => { console.log('Error'); }
            );
        }
    }

    // Searching in cleaning template table
    public searchReference = (value: string) => {
        this.templateList.filter = value.trim().toLocaleLowerCase();
    }

    referSearchByJournal(data) {
        if (data) {
            this.sharedService.showSpinner('articleUpload');
            this.editorService.getCleaningReferenceByJournal(data).subscribe(
                res => {
                    this.sharedService.hideSpinner('articleUpload');
                    this.templateList.data = res.templates;
                }
            )
        } else {
            this.sharedService.showSpinner('articleUpload');
            this.editorService.cleaningTemplateList().subscribe(
                res => {
                    this.sharedService.hideSpinner('articleUpload');
                    this.templateList.data = res.templates;
                },
                _err => { console.log('Error'); }
            );
        }
    }

    /**
     * general function to get article details
     * itrated in controller to get current stage(s)
     * as of now, current stage assumes length : 1
     */
    public articleInfo: {};
    mainDocument_fileName;
    article_journal;
    articleFile;
    public getArticleDetails() {
        let postData = {
            articleId: this.route.snapshot.paramMap.get('id')
        }
        this.articleService.getArticleDetails(postData).subscribe(res => {
            console.log(res)
            if (res['status'] == 200) {
                this.articleInfo = res['data']
                this.article_journal = this.articleInfo['article_journal']
                console.log(this.article_journal)
                const mainDocument_name = res['data']['article_mainDocument'];
                this.articleFile = res['data']['article_file']

                var full_name = mainDocument_name.split(".");
                this.mainDocument_fileName = full_name[0].split("/").pop();

                this.editorService.getFigureOptionsSvs(this.articleInfo['article_journal']).subscribe(res => {
                    console.log(res)
                    this.getFigureInfo = res['data']
                })

                if (res['data'].current_stage != undefined &&
                    res['data'].current_stage.length > 0) {
                    this.articleInfo['current_stage'] = res['data'].current_stage[0]
                }

                /** strat notes */
                // get notes and show default(s)
                if (this.articleInfo['current_stage'] != undefined) {
                    let that = this
                    let postData = {
                        article_id: this.articleInfo['_id'],
                        stage_id: this.articleInfo['current_stage'].stage_id
                    }
                    let allNotes = this.getAllNotes(postData);
                    this.buildNoteTree(allNotes)
                    this.getPdfAnnotationMetadata(this.articleInfo['_id'])
                }
                /** end notes */
                // getting get figure options


            }
        })
    }


    public notes: object = {}
    public nthNoteShown: number = 0 // the variable tells last note's position
    getAllNotes(postData) {
        // console.log(postData)
        this.nthNoteShown = 0;

        return this.notesService.getAllNotesSvs(postData).toPromise()
        // .subscribe(res => {
        // this.notes = res['data']
        // return res['data']
        // let that = this
        // Object.keys(this.notes).forEach(function (e) {
        //     if (that.notes[e].show == true) {
        //         that.notes[e].position = { 'transform': 'translate3d(' + (that.nthNoteShown * 15) + 'px, ' + (that.nthNoteShown * 30) + 'px, 0px)' }
        //         that.nthNoteShown++
        //     }
        // })
        // this.notes = res['data']
        // })
    }

    /*
    getPosts() {
        const promise = new Promise((resolve, reject) => {
            const apiURL = this.api;
            this.http
                .get<Post[]>(apiURL)
                .toPromise()
                .then((res: any) => {
                    // Success
                    this.data = res.map((res: any) => {
                        return new Post(
                            res.userId,
                            res.id,
                            res.title,
                            res.body
                        );
                    });
                    resolve();
                },
                    err => {
                        // Error
                        reject(err);
                    }
                );
        });
        return promise;
    }
    */

    /**
     * usecase: in notes notes menu show all(show=true) and hide all (show=false)
     * @param show (true, false)
     */

    public manageNotesModalTitle: string = this.translate.instant('notes.add new note')

    manageNotesForm = new FormGroup({
        noteId: new FormControl(''),
        noteTitle: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        noteContent: new FormControl('', [Validators.required, Validators.maxLength(500)]),
        noteStage: new FormControl('', [Validators.required]),
        noteOpen: new FormControl(''),
        noteColor: new FormControl('')
    })

    /**
     * method ot add/update notes
     */
    public noteAction: string = 'add'
    public reloadNotes: boolean = false//the variable to reload notes on 'show all' notes, trigered while new notes are added
    saveNotes() {
        if (this.manageNotesForm.invalid) {
            return;
        }

        let formData = new FormData();
        formData.append('action', this.noteAction)
        formData.append('noteId', this.manageNotesForm.get('noteId').value)
        formData.append('userId', localStorage.getItem("current_logined_userId"))
        formData.append('article_id', this.selectednode[this.activeTab].nodeid)
        formData.append('noteTitle', this.manageNotesForm.get('noteTitle').value)
        formData.append('noteContent', this.manageNotesForm.get('noteContent').value)
        formData.append('noteStage', this.manageNotesForm.get('noteStage').value)
        formData.append('noteOpen', this.manageNotesForm.get('noteOpen').value)
        formData.append('noteColor', '"' + $('#idNoteBgToSave').val() + '"')

        this.notesService.saveNotesSvs(formData).subscribe(async (res) => {
            if (res['status'] == 200) {
                // this.overWriteNotes(res['data'])

                let element: HTMLElement = document.getElementsByClassName('closeManageNotsModel')[0] as HTMLElement;
                element.click();

                this.notifier.notify('success', this.translate.instant('notes.' + res['msg']))
                // calling methods to load my notes
                // this.getNotes() // notes to the stage hided for now to avoid clumsines
                // this.isMyNotesToShow = false

                let postData = {
                    article_id: this.articleInfo['_id'],
                    stage_id: this.articleInfo['current_stage'].stage_id
                }
                let allNotes = await this.getAllNotes(postData);
                this.buildNoteTree(allNotes)
            }
        })
    }

    public defaultNotes = []
    public positioningNotes(noteData, nthNote) {
        let returnNodeData = []
        Object.keys(noteData).forEach((e) => {
            if (noteData[e].show == true) {
                noteData[e].position = { 'transform': 'translate3d(0px, ' + (nthNote * 30) + 'px, 0px)' }
                // document.getElementById(noteData[e]._id).style.transform = 'translate3d(' + (nthNote * 15) + 'px, ' + (nthNote * 30) + 'px, 0px)';
                nthNote++
                returnNodeData.push(noteData[e]);
            }
        })
        return returnNodeData
    }


    public upcomingStages: any
    getAddNoteMetadata() {
        let postData = {
            articleId: this.selectednode[this.activeTab].nodeid
        }

        this.notesService.getAddNoteMetadataSvs(postData).subscribe(res => {
            if (res['status'] === 200) {
                this.upcomingStages = res['upcoming_stages']
            }
        });
    }

    /**
     * method to set colorselector value
     * @param hexVal: the hex value of color
     */
    public setNoteBg(hexVal) {
        $('#idNoteBgToSave').val(hexVal)
        $(".btn-colorselector").css("background-color", hexVal)
    }

    @ViewChild("manageNotesModel") manageNotesModel;
    editNote(params) {
        this.noteAction = 'edit'
        this.manageNotesModalTitle = this.translate.instant('notes.update new note')
        let that = this
        let editData: {}

        Object.keys(this.myNotes).forEach(e => {
            if (that.myNotes[e]._id == params.noteId) {
                editData = that.myNotes[e]
            }
        })

        this.manageNotesForm.patchValue({
            noteTitle: editData['title'],
            noteContent: editData['content'],
            noteStage: editData['stage_id'],
            noteOpen: editData['show'],
            noteColor: editData['color'],
            noteId: params.noteId,
        });

        this.setNoteBg(editData['color'])//to set color in colorSelector
        // document.getElementById("addNoteButton").click()
        this.manageNotesModel.show()
    }

    deleteNote(params) {
        let that = this
        let postData = {
            noteId: params.noteId,
            articleId: params.articleId,
            stageId: params.stageId
        }
        this.notesService.deleteNoteSvs(postData).subscribe(async res => {
            if (res['status'] == 200) {
                // Object.keys(that.myNotes).forEach(i => {
                //     if (that.myNotes[i]._id == params.noteId) {
                //         delete (this.myNotes[i])
                //         this.notifier.notify('success', this.translate.instant('notes.' + res['msg']))
                //         this.reloadNotes = true
                //     }
                // })

                // // that.positioningNotes(res['data'])
                let postData = {
                    article_id: this.articleInfo['_id'],
                    stage_id: this.articleInfo['current_stage'].stage_id
                }
                let allNotes = await this.getAllNotes(postData);
                this.buildNoteTree(allNotes)
            }
            else {
                this.notifier.notify('error', this.translate.instant(res['msg']))
            }
        })
    }

    // Select all journals
    selectAllJournals(_event) {
        if (this.allSelectedJournal.selected) {
            var allJournals = [];
            allJournals = this.journalList.map(item => item._id);
            allJournals.push(0);
            this.cleaningTemplateform.controls.clngTmpltJournal.patchValue(allJournals);
        } else {
            this.cleaningTemplateform.controls.clngTmpltJournal.patchValue([]);
        }
    }

    onAllChanged(event) {
        if (event)
            this.cleaningTemplateform.controls.clngTmpltJournal.patchValue(this.journalList.map(item => item._id));
        else
            this.cleaningTemplateform.controls.clngTmpltJournal.patchValue([]);
    }
    onAllChangedreference(event) {
        if (event)
            this.referencecleaningTemplateform.controls.clngrefTmpltJournal.patchValue(this.journalList.map(item => item._id));
        else
            this.referencecleaningTemplateform.controls.clngrefTmpltJournal.patchValue([]);
    }

    /**
     * Check whether all journals selected or not. If all selected then set 'select all' option checked otherwise uncheck.
     * @author Arathy S V 
     */
    journalSelectionTemplate() {
        if (this.journalList.length == this.cleaningTemplateform.get('clngTmpltJournal').value.length)
            this.isAllJournalSelected = true;
        else
            this.isAllJournalSelected = false;
    }

    journalSelectionrefTemplate() {
        if (this.journalList.length == this.referencecleaningTemplateform.get('clngrefTmpltJournal').value.length) {
            this.isAllrefJorunalSlected = true;
        } else {
            this.isAllrefJorunalSlected = false;
        }
    }

    /**
     * method to extract zip from tree > context menu action 
     * @param targetNodeId 
     * @param targetNode 
     */
    public extractZip(targetNodeId, _targetNode) {
        let nodeDetails = this.getFileDetails(targetNodeId);

        let data = {
            articleFolder: nodeDetails.articleFolder,
            path: nodeDetails.path,
        };

        this.editorService.extractZipSvs(data).subscribe(
            response => {
                if (response.status == "success") {
                    let targetNodeId = this.treevalidate.selectedNodes[0].toString();
                    let tempNode = this.treevalidate.getTreeData(targetNodeId)[0];
                    let fileList = response.files

                    fileList.forEach((element, index) => {
                        let count = index;
                        let itemId = targetNodeId + '-' + count;
                        // structuring data for tree
                        let node = this.getChildrens(element, itemId);
                        this.idList.push(itemId);
                        this.nodeList.push(node);

                        // start -- adding extracted details as new node
                        let parentTargetNode = targetNodeId.split('-')
                        parentTargetNode.pop()
                        let parentTargetNodeId = parentTargetNode.join('-').toString()

                        this.treevalidate.addNodes([node], parentTargetNodeId, null);
                        this.treevalidate.removeNodes([targetNodeId])
                    });
                    this.hierarchicalData = this.updateTreeData(this.hierarchicalData, targetNodeId, fileList, 'unzip');

                    setTimeout(() => {
                        let postData = {
                            id: this.route.snapshot.paramMap.get("id"),
                            children: this.hierarchicalData[0].children
                        }
                        this.editorService.updateArticleTreeSvs(postData).subscribe(res => {
                            console.log(res)
                        })
                    }, 1000)
                }
                else if (response.status == 'failed') {
                    let warningMsg = this.translate.instant("editor." + response.message)
                    warningMsg += response.files.join(', ')
                    this.notifier.notify('warning', warningMsg);
                }
                else {
                    this.notifier.notify(
                        response.message,
                        this.translate.instant("editor." + response.message)
                    );
                }
            })
    }


    /**
     * pdf page roation  
     * @param  pdfPageNumber 
     */


    private readonly _defaultRotation: IKeyValue;
    private _rotations: IKeyValue[] = [
        { key: 'rot0', value: 0 },
        { key: 'rot90', value: 90 },
        { key: 'rot180', value: 180 },
        { key: 'rot270', value: 270 }];


    pdfPageNumber;

    // pdf click event
    pdfClick(event) {

        // const arr = $(event.target).closest('div').prev('.canvasWrapper').parent('div.page').parent('div.pdfViewer').children();

        // Object.values(arr).forEach(item => {
        //     this.l.push(item)
        // })

        // const x = $(event.target).closest('div').prev('.canvasWrapper').parent('div.page');
        // Object.values(x).forEach(item => {
        //     this.m.push(item)
        // })


        // var filteredKeywords = Object.values(arr).filter((word) => !Object.values(x).includes(word));

        // var filteredKeywords = this.l.filter((word) => !this.m.includes(word));

        // get the page number
        this.pdfPageNumber = parseInt($(event.target).closest('div').prev('.canvasWrapper').parent('div.page').attr('data-page-number'));

    }

    // rotate the required page only
    rotatePage(pageNumber: number, clockwise: boolean) {

        const Cpage: HTMLDivElement = this._host.nativeElement.querySelector(`pdf-viewer`);
        const page: HTMLDivElement = this._host.nativeElement.querySelector(`pdf-viewer .page[data-page-number="${pageNumber}"]`);
        if (page) {
            const oldRotation = this._getRotation(page);
            let newRotationDegrees = oldRotation.value + (clockwise ? 90 : -90);
            // normalize to 0-270  
            while (newRotationDegrees < 0) {
                newRotationDegrees += 360;
            }
            while (newRotationDegrees >= 360) {
                newRotationDegrees -= 360;
            }
            const newRotation = this._rotations.find(r => r.value === newRotationDegrees);
            if (newRotation) {
                this._clearRotation(page);
                this._addClass(page, newRotation.key);
                // todo: figure out the difference between the element's width and height, and adjust the margins
            }
        }
    }

    private _getRotation(elem): IKeyValue {
        const rot = this._rotations.find(kv => this._hasClass(elem, kv.key));
        return rot || this._defaultRotation;
    }

    private _clearRotation(elem) {
        this._rotations.forEach(rot => this._removeClass(elem, rot.key));
    }


    private _hasClass(elem, className) {
        return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ');
    }

    private _removeClass(elem, className) {
        var newClass = ' ' + elem.className.replace(/[\t\r\n]/g, ' ') + ' ';
        if (this._hasClass(elem, className)) {
            while (newClass.indexOf(' ' + className + ' ') >= 0) {
                newClass = newClass.replace(' ' + className + ' ', ' ');
            }
            elem.className = newClass.replace(/^\s+|\s+$/g, '');
        }
    }

    private _addClass(elem, className) {
        if (!this._hasClass(elem, className)) {
            elem.className += ' ' + className;
        }
    }

    public checkOutArticle(event: any, stage_id: string) {
        event.target.disabled = true;
        let post_data = {
            id: this.route.snapshot.paramMap.get("id"),
            stage_id: stage_id
        }

        let that = this
        this.sharedService.checkOutArticleSvs(post_data).subscribe(res => {
            if (res['status'] === 200) {
                this.modalRef.hide();
                this.notifier.notify('success', this.translate.instant('articles.checkedout_success'))
            }
        })
    }

    public checkInArticle(event: any, stage_id: string, jnl_code: string, wflw_code: string, nxt_stage_id: string, wf_stage_id: any) {
        event.target.disabled = true;
        var postData = {
            user_token: this.usertoken.getToken(),
            journal_code: jnl_code,
            workflow_code: wflw_code,
            article_id: this.route.snapshot.paramMap.get("id"),
            stage_id: wf_stage_id,
            current_stage_id: stage_id,
            next_wf_stage_id: nxt_stage_id,
            action: 'checkin'
        }

        this.articleService.moveNextStageSvs(postData).subscribe(
            response => {
                if (response.status === 200) {
                    this.modalRef.hide();
                    this.notifier.notify('success', this.translate.instant('articles.checkedin_success'))
                }
            });
    }

    /**
     * method to check object length from template view
     */
    public isNotEmpty(theObject) {
        if (theObject == undefined) {
            return false
        }
        return Object.keys(theObject).length != 0;
    }

    public showDiffPdfForm: boolean
    public stagePdfs: []
    public diffPdfForm;
    saved_pdfs = [];
    diff_pdf_options = [
        // { name: 'verbose', caption: 'Be verbose' },
        { name: 'skip-identical', caption: 'Only output pages with differences' },
        { name: 'mark-differences', caption: 'Additionally mark differences on left side' },
        { name: 'grayscale', caption: 'Only differences will be in color, unchanged parts will show as gray' }
    ];

    /**
     * author: vishnu
     * method to open diff PDF model and listing possible stages having "stage PDFs"
     * @param modal: model templateid
     */
    openDiffPDFModel(modal) {
        this.showDiffPdfForm = false
        this.pdfStageLimitWarning = null
        this.diffPdfForm = new FormGroup({})
        let postData = {
            art_ids: [this.selectednode[this.activeTab].nodeid]
        }

        // service to get stages that having PDFs
        this.sharedService.getDetailsForDiffPDF(postData).subscribe(response => {
            if (response['status'] === 200) {
                if (Object.keys(response['data']['diffPdfStages']).length > 0 || response['data']['saved_pdfs'].length > 0) {
                    this.showDiffPdfForm = true
                    this.stagePdfs = response['data']['diffPdfStages'];
                    this.saved_pdfs = response['data']['saved_pdfs'];
                    this.saved_pdfs.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
                    let tmpStagePdfs = this.stagePdfs.map((_pdf) => {
                        return new FormControl(false)
                    });
                    let tmpSavedPdfs = this.saved_pdfs.map((_pdf) => {
                        return new FormControl(false)
                    });
                    let tmp_diff_options = this.diff_pdf_options.map((_option) => {
                        return new FormControl(false)
                    });

                    // build form to call diff PDF api
                    this.diffPdfForm = new FormGroup({
                        articleFile: new FormControl(response['data']['articleFile'], [Validators.required]),
                        diffPdfStages: new FormArray(tmpStagePdfs),
                        diffSavedPdfs: new FormArray(tmpSavedPdfs),
                        currentPdf: new FormControl(''),
                        options: new FormArray(tmp_diff_options)
                    });
                }
            }
        });

        // showing model
        this.modalRef = this.modalService.show(modal, {
            animated: true,
            keyboard: true,
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-lg'
        });
    }

    /**
     * author: vishnu
     * method to show warning if morethan 2 stage choose for finding DIFF
     * param: checkbox click event
     */
    public pdfStageLimitWarning: any = null
    public onPdfStageChange(_event) {
        if (document.querySelectorAll<HTMLElement>('input.pdfStageClass:checked').length > 2) {
            this.pdfStageLimitWarning = 'max'
        } else {
            this.pdfStageLimitWarning = null
        }
    }

    /**
     * method to call diff pdf API
     */
    public pdfStageMinLimitWarning = false;
    output_public_path: string;
    comparedPdf: boolean = false;
    compare_url: string;
    diffName: string = 'diff';

    public onDiffPdfFormSubmit() {
        if (document.querySelectorAll<HTMLElement>('input.pdfStageClass:checked').length != 2) {
            this.pdfStageLimitWarning = 'min'
        } else {
            let postData = {
                articleFile: this.diffPdfForm.get('articleFile').value,
                // stages: [],
                // stages_name: [],
                // saveds: [],
                // current : [],
                pdf_data: [],
                diff_pdf_option: []
            }

            this.diffPdfForm.get('diffPdfStages').value.forEach((e, i) => {
                if (e != true) {
                    return
                }
                // postData['stages'].push(this.stagePdfs[i]['value']);
                // postData['stages_name'].push(this.stagePdfs[i]['name']);
                postData['pdf_data'].push({ name: this.stagePdfs[i]['name'], path_type: 'timeline', pdf: this.stagePdfs[i]['value'] + '.pdf' });
            });

            this.diffPdfForm.get('diffSavedPdfs').value.forEach((e, i) => {
                if (e != true) {
                    return
                }
                postData['pdf_data'].push({ name: 'saved', path_type: 'timeline', pdf: 'saved_Pdf/' + this.saved_pdfs[i]['pdf_name'] });
            });

            this.diffPdfForm.get('options').value.forEach((e, i) => {
                if (e != true) {
                    return;
                }
                postData['diff_pdf_option'].push(this.diff_pdf_options[i]['name']);
            });

            const x = this.diffPdfForm.get('currentPdf').value;
            if (x == true) {
                postData['pdf_data'].push({ name: 'current', path_type: 'current', pdf: this.mainDocument_fileName + '.pdf' });
                this.saveCurrentPdf(this.mainDocument_fileName);
            }
            this.modalRef.hide();

            this.sharedService.showSpinner('editorLoader');

            this.editorService.comparePdf(postData).subscribe(
                res => {

                    this.rightPannel = '';
                    setTimeout(() => {
                        this.sharedService.hideSpinner('editorLoader');
                        this.rightPannel = 'comparedPdf';
                        this.comparedPdf = true;
                        this.notifier.notify('success', "Pdf diff successfully generated");

                        // url of compared pdf
                        this.compare_url = res['output_url_path'];
                        // local folder path
                        this.output_public_path = res['output_public_path'];
                    }, 1000);

                },
                err => {
                    console.log(err);
                }
            );

        }
    }

    downloadDiffIcon: boolean = false;

    downloadDiff() {
        this.downloadDiffIcon = true;

        // pdf roatation 
    }

    public autoSaveSettingsForm = new FormGroup({
        enable: new FormControl(false),
        interval: new FormControl(''),
        show_timer: new FormControl(false)
    })

    public autoSaveSettingsData = {
        enable: null,
        interval: null,
        show_timer: null,
    }

    public onAutoSaveSettignsFormSubmit() {
        let isEnable = this.autoSaveSettingsForm.get('enable').value
        // setting validator for interval if it is enabled
        if (isEnable !== true) {
            this.autoSaveSettingsForm.controls['interval'].clearValidators();
            this.autoSaveSettingsForm.controls['interval'].setErrors(null);
        }
        else {
            this.autoSaveSettingsForm.controls['interval'].setValidators([Validators.required]);
            let timeValue = this.autoSaveSettingsForm.get('interval').value

            if (timeValue == null) {
                this.autoSaveSettingsForm.controls['interval'].setErrors({ 'incorrect': true });
            }
            else {
                let timeValueArray = timeValue.split(':')

                // // check if HH:MM:SS format
                // if (timeValueArray.length != 3) {
                //     console.log(timeValueArray)
                //     console.log(timeValueArray.length)
                //     this.autoSaveSettingsForm.controls['interval'].setErrors({ 'incorrect': true });
                // } 
                // // check if time is greatter than 1 minute
                // else 
                if (timeValueArray.length == 2) {
                    timeValueArray[2] = 0;
                }

                let timerSeconds = timeValueArray[0] * 3600 + timeValueArray[1] * 60 + (+timeValueArray[2]);
                if (isNaN(timerSeconds) || timerSeconds < 60) {
                    this.autoSaveSettingsForm.controls['interval'].setErrors({ 'zerovalue': true });
                }
                // }
            }
        }

        if (!this.autoSaveSettingsForm.valid) {
            return
        }

        let postData = this.autoSaveSettingsForm.value

        if (postData['interval'] == null) {
            postData['interval'] = '00:00:00'
        }
        //if value has no second, add it
        else if (postData['interval'].length == 5) {
            postData['interval'] = postData['interval'] + ":00"
        }

        this.editorService.saveAutoSaveSettingsSvs(postData).subscribe(res => {
            if (res['status'] == 200) {
                this.autoSaveSettingsData.enable = postData['enable']
                this.autoSaveSettingsData.interval = postData['interval']
                this.autoSaveSettingsData.show_timer = postData['show_timer']

                if (this.autoSaveSettingsData.enable) {
                    this.startAutoSaveTimer(postData['interval'])
                    this.setTimerPosition()
                }

                this.notifier.notify('success', this.translate.instant('editor.autosave.save success'));
            }
        })
    }

    /**
     * method for countdown timer
     * desc:input interval is converted to seconds. 
     * the second is decremented in a frequency of 1sec.
     * then decremented second is converted back to conventional time of HH:MM:SS
     */
    public activeAutoSaveTimer: any = null
    startAutoSaveTimer(hrs) {
        this.timerPaused = false
        if (this.activeAutoSaveTimer) {
            clearInterval(this.activeAutoSaveTimer);
            this.activeAutoSaveTimer = null;
        }

        hrs = hrs.split(':');
        let sec = (+hrs[0]) * 60 * 60 + (+hrs[1]) * 60 + (+hrs[2])

        this.activeAutoSaveTimer = setInterval(() => {
            if (sec === 0) {
                // uncomment here to run autosave
                this.saveTexFileAndCompile()
                this.autoSaveIn = this.autoSaveSettingsData.interval
                this.startAutoSaveTimer(this.autoSaveSettingsData.interval)
                return
            }

            sec--

            let h = Math.floor(sec / 3600).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
            let m = Math.floor(sec % 3600 / 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
            let s = Math.floor(sec % 3600 % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })

            this.autoSaveIn = [h, m, s].join(':')
        }, 1000)
    }

    public timerPaused = false
    public pauseAutosaveTimer() {
        if (this.activeAutoSaveTimer) {
            clearInterval(this.activeAutoSaveTimer);
            this.activeAutoSaveTimer = null;
            this.timerPaused = true
        }
    }

    /**
     * author:vishnu
     * @param item : enable/showTimer
     * enable: to enable/disable timer
     * showTimer: to hide timer
     */
    public manageAutosaveTimer(item) {
        if (item == 'enable') {
            this.autoSaveSettingsData.enable = false

            clearInterval(this.activeAutoSaveTimer);
            this.activeAutoSaveTimer = null;
        }

        if (item == 'showTimer') {
            this.autoSaveSettingsData.show_timer = false
        }

        let postData = {
            enable: this.autoSaveSettingsData.enable,
            interval: this.autoSaveSettingsData.interval,
            show_timer: this.autoSaveSettingsData.show_timer,
        }

        this.editorService.saveAutoSaveSettingsSvs(postData).subscribe(res => {
            if (res['status'] == 200) {
                this.notifier.notify('success', this.translate.instant('editor.autosave.disabled success'));

                this.autoSaveSettingsFormPatchValue()
            }
        })
    }

    /**
     * author:vishnu
     * desc:calculating position to show timer
     */
    public setTimerPosition() {
        let navWidth: any = "-" + document.getElementById("navTabId").offsetWidth + "px";
        let navHeight: any = document.getElementById("navTabId").offsetHeight;
        let timerHeight: any = 100;//document.getElementById("timerDivId").offsetHeight;
        let timerYpos: any = navHeight - timerHeight + "px";
        this.timerPosition = { transform: "translate3d(" + navWidth + ", " + timerYpos + ", 0px)" }

    }

    // to enable save pdf modal
    show_save_pdf_box() {
        this.savePdfForm.patchValue({
            name: this.mainDocument_fileName
        })
        var div = document.getElementById('save_pdf_form');
        if (div) {
            div.style.display = 'inline';
        }
    }

    // to hide save pdf modal
    hide_save_pdf_box() {
        var div = document.getElementById('save_pdf_form');
        if (div) {
            div.style.display = 'none';
        }
    }

    articleData;
    // save pdf formbuilder
    savePdfForm = this.formBuilder.group({
        name: ["", [Validators.required, this.noWhitespace_Validator, Validators.pattern('^[a-zA-Z0-9-_]+')]]
    });

    // form control check errors
    get check() {
        return this.savePdfForm.controls;
    }

    // to check input having whitespace and show invalid style in input
    public noWhitespace_Validator(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    savePdf_submitted;
    save_pdf_name;

    /**
     * @author: AJAI SAJIN
     * save the current pdf
     * @param name: from savePdfForm input or current checkbox
     */
    async saveCurrentPdf(name) {

        this.savePdf_submitted = true;

        // check save pdf request from save pdf form or current mode checkbox     
        if (name == undefined) {

            if (this.savePdfForm.invalid) {
                return;
            }
            this.save_pdf_name = this.savePdfForm.value.name.replace(/^(_)/, "").trim();
        } else {
            this.save_pdf_name = name;
        }

        let postData = {
            articleId: this.route.snapshot.paramMap.get('id')
        }

        // get current article file name 
        const articleData = await this.articleService.getArticleDetails(postData).pipe().toPromise();

        const mainDocument_name = articleData['data']['article_mainDocument'];
        // split name
        var full_name = mainDocument_name.split(".");
        var name = full_name[0].split("/").pop();

        const data = {
            articleFile: this.articleInfo['article_file'],
            pdf_save_as: this.save_pdf_name,
            article_file_name: name
        }

        this.editorService.saveCurrentPdf(data).subscribe(
            res => {
                this.notifier.notify("success", res['msg']);
                this.getArticleDetails();
                this.hide_save_pdf_box();
            },
            err => {
                console.log(err)
            }
        )

    }

    isHideEditor: boolean = false;
    //Hide editor for displaying RVClean
    hideEditor() {
        this.currentJournalCode = this.selectednode[this.activeTab].journal;
        this.isHideEditor = !this.isHideEditor;
    }

    // Hide the editor and right panel. Show the cleaning pattern grid. 
    isHideEditorEnable() {
        if (this.isHideEditor) {
            return "none";
        } else {
            return "block";
        }
        this.isHideEditor = !this.isHideEditor;
    }
    editorFontSizeForm = this.formBuilder.group({
        editorFontSize: [""]
    });

    editorTabSizeForm = this.formBuilder.group({
        editorTabSize: [""]
    });

    public viewNotes: boolean = false;
    public showNotesOnEditor() {
        this.viewNotes = !this.viewNotes

    }

    public onTreeNodeCheck(event) {
        let actionNoteIds = event.data.map(e => { return e.id })

        if (actionNoteIds.includes('default')) {
            Object.keys(this.notes).filter((e) => {
                if (actionNoteIds.includes(this.notes[e]['_id'])) {
                    if (event.action == 'check') {
                        this.notes[e].show = true
                    } else {
                        this.notes[e].show = false
                    }
                }
            })
        }

        if (actionNoteIds.includes('all')) {
            Object.keys(this.allNotes).filter((e) => {
                if (actionNoteIds.includes(this.allNotes[e]['_id'])) {
                    if (event.action == 'check') {
                        this.allNotes[e].show = true
                    } else {
                        this.allNotes[e].show = false
                    }
                }
            })
        }

        if (actionNoteIds.includes('byMe')) {
            Object.keys(this.myNotes).filter((e) => {
                if (actionNoteIds.includes(this.myNotes[e]['_id'])) {
                    if (event.action == 'check') {
                        this.myNotes[e].show = true
                    } else {
                        this.myNotes[e].show = false
                    }
                }
            })
        }
    }

    searchCountObserver;
    /**
     * Execute "find" command for activating ace editor builtin search and replace
     * Create an observer instance for detectig the changes in search count
     * @author Arathy S V
     */
    findLastMatchSearch() {
        if (this.searchCountObserver) {
            this.searchCountObserver.disconnect();
        }
        this.editor.execCommand("find");
        this.countChangeListener();
    }

    /**
     * Showing confirmation popup for last match in the file. If press 'No' the search box will be hide,
     * otherwise we can move to the next match by clicking next button
     * @author Arathy S V
     */
    stopSearching() {
        this.disableClose = true;
        var config = {
            disableClose: false,
            panelClass: "custom-overlay-pane-class",
            hasBackdrop: false,
            width: "20",
            maxWidth: defaultDialogConfig.maxWidth,
            maxHeight: "",
            color: "grey",
            data: {
                message: this.translate.instant("editor.search-end-file"),
                no: this.translate.instant("No"),
                yes: this.translate.instant("Yes")
            }
        };
        this.dialogRef = this.dialog.open(JazzDialog, config);
        this.dialogRef.beforeClose().subscribe((result: string) => {
            this.lastBeforeCloseResult = result;
            if (result == this.translate.instant("No")) {
                var ace_search = document.querySelectorAll<HTMLElement>(".ace_search");
                ace_search[0].style.display = 'none';
                this.searchCountObserver.disconnect();
            } else if (result == this.translate.instant("Yes")) {
                this.searchCountObserver.disconnect();
                this.countChangeListener();
            }
        });
        this.dialogRef.afterClosed().subscribe((result: string) => {
            this.disableClose = false;
            this.lastAfterClosedResult = result;
            if (result == this.translate.instant("No")) {
                var ace_search = document.querySelectorAll<HTMLElement>(".ace_search");
                ace_search[0].style.display = 'none';
                this.searchCountObserver.disconnect();
            } else if (result == this.translate.instant("Yes")) {
                this.searchCountObserver.disconnect();
                this.countChangeListener();
            }
            this.dialogRef = null;
        });
    }

    /**
     * Search count change event listener
     * @author Arathy S V
     */
    countChangeListener() {
        var searchCount = document.querySelectorAll(".ace_search_counter");
        // create an observer instance function(mutations) 
        this.searchCountObserver = new MutationObserver((_event) => {
            var split = searchCount[0].innerHTML.split('of');
            // Check the last match. If last match occour then show confirmation popup
            if ((split[0].trim() == split[1].trim()) && (split[1].trim() != '1') && (split[1].trim() != '0')) {
                this.stopSearching();
            }
        });
        // configuration of the observer:
        var config = { attributes: true, childList: true, characterData: true };
        // pass in the target node, as well as the observer options
        this.searchCountObserver.observe(searchCount[0], config);
    }
    /**
     * Fetched the article id and created a new entry in db
     * @author Simi Francis
     */
    public postArticleLastActiveEvent() {
        this.articleService.postArticleLastActiveEvent({
            article_id: this.route.snapshot.paramMap.get("id"),
        }).subscribe(_item => { })
    }
    /*
    public postArticleActiveEventEnd(){
        this.articleService.postArticleActiveEventEnd({
            article_id: this.route.snapshot.paramMap.get("id"),
        }).subscribe(item=>{})
        this.router.navigate(["my-articles"]);
    }
    */
    public getPdfAnnotationMetadata(art_id) {
        let postData = {
            art_ids: [art_id]
        }

        // service to get stages that having PDFs
        this.sharedService.getDetailsForDiffPDF(postData).subscribe(response => {
            this.stagePdfs = response['data']['diffPdfStages'];
            this.saved_pdfs = response['data']['saved_pdfs'];
            this.saved_pdfs.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
        })
    }

    wvInstance: any;
    public PDFToAnnotate: string = null;
    public viewer
    public annotateCount: number = 1
    public previousAnnotatePdf = []
    public viewPDFAnnotation(PDFToAnnotate = null) {
        //from context menu
        if (PDFToAnnotate != null) {
            this.PDFToAnnotate = PDFToAnnotate

            this.rightPannel = 'viewPDFAnnotation'
            if (this.previousAnnotatePdf[this.previousAnnotatePdf.length - 1] == this.PDFToAnnotate) {
                return
            }

        }
        else {
            let selectedPdf = document.querySelectorAll<HTMLInputElement>('input.annotPpf:checked')

            if (selectedPdf.length > 1) {
                this.notifier.notify('warning', this.translate.instant('pdf annot.warning only one pdf'));
                return
            }
            else if (selectedPdf.length == 0) {
                this.notifier.notify('warning', this.translate.instant('pdf annot.choose one pdf'));
                return
            }

            let pdfType = selectedPdf[0].attributes['data-pdffrom'].value
            let pdfName = selectedPdf[0].nodeValue

            let articleFile = this.env.baseUploadUrl + '/public/articles/article_timeline/' + this.zip_file

            switch (pdfType) {
                case 'stage':
                    this.PDFToAnnotate = articleFile + '/' + selectedPdf[0].value + '.pdf'
                    break
                case 'saved':
                    this.PDFToAnnotate = articleFile + '/saved_Pdf/' + selectedPdf[0].value
                    break
                default:
                    this.PDFToAnnotate = this.pdfOutput[this.pdfOutput.length - 1]
                    break
            }
        }

        /** */
        let viewerDiv = document.createElement("DIV");
        let attId = document.createAttribute("id");
        attId.value = "viewer" + this.annotateCount;
        viewerDiv.setAttributeNode(attId);
        let attClass = document.createAttribute("class");
        attClass.value = "h-100";
        viewerDiv.setAttributeNode(attClass);

        let outerDiv = document.getElementById("PDFAnnotationContainer");
        outerDiv.innerHTML = "";
        outerDiv.appendChild(viewerDiv);

        /** */
        setTimeout(() => {
            this.viewer = document.getElementById('viewer' + (this.annotateCount - 1))
            WebViewer({
                path: '../../lib',
                initialDoc: this.PDFToAnnotate
            }, this.viewer).then(instance => {
                this.wvInstance = instance;
                instance.setFitMode(instance.FitMode.FitWidth);

                // now you can access APIs through this.webviewer.getInstance()
                // instance.openElements(['notesPanel']);
                // see https://www.pdftron.com/documentation/web/guides/ui/apis for the full list of APIs

                // or listen to events from the viewer element
                // this.viewer.nativeElement.addEventListener('pageChanged', (e) => {
                //     const [pageNumber] = e.detail;
                //     console.log(`Current page is ${pageNumber}`);
                // });

                // or from the docViewer instance
                // instance.docViewer.on('annotationsLoaded', () => {
                //     console.log('annotations loaded');
                // });

                // instance.docViewer.on('documentLoaded', this.wvDocumentLoadedHandler)
            })
        }, 500)

        this.rightPannel = 'viewPDFAnnotation'
        this.previousAnnotatePdf.push(this.PDFToAnnotate)
        this.annotateCount++
    }

    public pathToFile: string
    public findPathToFileRecursively(nodeIdArray, articleChildren) {
        let n = nodeIdArray.shift()
        let nthArticleChildren = articleChildren[n - 1]
        this.pathToFile += nthArticleChildren.name + '/'
        if (nodeIdArray.length != 1) {
            this.findPathToFileRecursively(nodeIdArray, nthArticleChildren.children)
        }
    }

    /*
    wvDocumentLoadedHandler(): void {
		// you can access docViewer object for low-level APIs
		// and access classes defined in the WebViewer iframe
		const Annotations = this.wvInstance;
		const annotManager = this.wvInstance;
		const docViewer = this.wvInstance;
		const rectangle = new Annotations.RectangleAnnotation();
		rectangle.PageNumber = 1;
		rectangle.X = 100;
		rectangle.Y = 100;
		rectangle.Width = 250;
		rectangle.Height = 250;
		rectangle.StrokeThickness = 5;
		rectangle.Author = annotManager.getCurrentUser();
		annotManager.addAnnotation(rectangle);
		annotManager.drawAnnotations(rectangle.PageNumber);
		// see https://www.pdftron.com/api/web/WebViewer.html for the full list of low-level APIs
    }
    */
    enableBlockCode: boolean = false;
    enablereferenceCode: boolean = false;
    blockCodeSearch: string = '';
    @ViewChild('blockCodeElement') blockCodeElement: ElementRef;
    @ViewChild('blockCodeSearchBox') blockCodeSearchBox: ElementRef;

    preCleanArrayList = [];
    isShowPreCleanContextMenu: boolean = false;
    isShowPostCleanContextMenu: boolean = false;
    preCleanSearchText: string = '';
    postCleanSearchText: string = '';
    selectedPreClean = 0;
    selectedPostClean = 0;
    @ViewChild('preCleanElement') preCleanElement: ElementRef;
    @ViewChild('postCleanElement') postCleanElement: ElementRef;
    @ViewChild('preCleanSearchBoxElement') preCleanSearchBoxElement: ElementRef;
    @ViewChild('postCleanSearchBoxElement') postCleanSearchBoxElement: ElementRef;


    /**
     * Apply the block code template to selected text
     * @author Arathy S V
     * @param blockCode
     */
    setBlockCode(blockCode) {
        this.enableBlockCode = false;
        let block_replace_value = blockCode.id;
        let replaced_block_code = block_replace_value.replace('$1', this.editor.getSelectedText());
        var selectionRange: any = this.editor.getSelectionRange();
        this.editor.session.replace(selectionRange, replaced_block_code);
    }



    selectedBlockCode = 0;


    /**
     * Keydown event in block code
     * @author Arathy S V
     */
    selectBlockCode($event) {
        switch ($event.keyCode) {

            case 38: {
                // Up arrow key click
                if (this.selectedBlockCode >= 1) {
                    this.selectedBlockCode--;
                    this.scrollToBlockCode();
                }
                break;
            }
            case 40: {
                // Down arrow key click
                if (this.selectedBlockCode < this.search.transform(this.blockPatternArrayList, this.blockCodeSearch).length - 1) {
                    this.selectedBlockCode++;
                    this.scrollToBlockCode();
                }
                break;
            }
            case 13: {
                // Enter key clicked
                // Apply the block code template to selected text
                this.setBlockCode(this.blockPatternArrayList[this.selectedBlockCode]);
                break;
            }
            default: {
                var blockCodeLength = this.search.transform(this.blockPatternArrayList, this.blockCodeSearch).length - 1;
                if (blockCodeLength > this.selectedBlockCode || blockCodeLength < 0) {
                    this.selectedBlockCode = 0;
                }
                break;
            }
        }
    }


    // Scroll to block code
    scrollToBlockCode() {
        let str = 'bk_' + this.selectedBlockCode;
        console.log(str)
        let elmnt = document.getElementById(str);
        elmnt.scrollIntoView();
        window.scrollTo(0, 0);
    }

    /**
     * params contains: 
        jnlCode
        workflowCode
        articleId
        wf_stage_id
        crntStageId
        actionFrom
        metaData
        sharedId
        articleFile
        mainDocument 
     */
    public isDiffShow: boolean = false
    public differ
    public async fileDiff(params) {
        this.isDiffShow = true
        this.isHideEditor = true

        if (params.metaData != "null") {
            params.metaData.hide()
        }

        /** read files */
        let contentRight = ''
        let contentLeft = ''

        /*** */
        // console.log('1 start, fetching main content')
        /* start -- fetching main document content for merging */
        let xmlhttp = await new XMLHttpRequest();
        xmlhttp.open(
            "GET",
            this.env.baseUploadUrl + "/public/articles/article_files/" + params.articleFile + "/" + params.mainDocument + '?' + new Date().getTime(),
            true
        );

        xmlhttp.onreadystatechange = await function () {
            if (this.readyState == 4 && this.status == 200) {
                contentLeft = this.responseText;
                // console.log('2. got main content')
            }
        };
        xmlhttp.send();

        /* end -- fetching main document content for merging */

        // console.log('3 fetching shared content')

        /* start -- fetching main document content for merging */
        let xmlhttpShrd = await new XMLHttpRequest();
        xmlhttpShrd.open(
            "GET",
            this.env.baseUploadUrl + "/public/articles/article_files/" + params.articleFile + "/" + params.sharedId + '.tex?' + new Date().getTime(),
            true
        );

        xmlhttpShrd.onreadystatechange = await function () {
            if (this.readyState == 4 && this.status == 200) {
                contentRight = this.responseText;
                // console.log('4. got shared content')
            }
        };
        xmlhttpShrd.send();
        /* end -- fetching main document content for merging */

        setTimeout(() => {
            this.differ = new AceDiff({
                ace: document.getElementsByClassName('acediff'), // You Ace Editor instance
                element: '.acediff',
                left: { content: contentLeft },
                right: { content: contentRight },
                mode: LANG,
                diffGranularity: 'specific',
            });
        }, 500);
    }

    public mergeShareToMain(mainDoc, stageId, sharedId) {
        let nodeId = this.selectednode[this.activeTab].nodeid
        this.editorService.saveArticle(
            this.differ.getEditors().left.getValue(),// value: editor content
            null,// artice: tex file name
            null,// path: tex file name
            nodeId,// nodeid: collectionId
            mainDoc,// nodepath: texfile
            null // comments
        ).subscribe(res => {
            if (res.status == 'success') {
                this.completeMerge(nodeId, stageId, sharedId)
            }
        });
    }

    async saveSharedCopyToMerge(stageId, sharedId) {
        let res = await this.editorService
            .saveArticle(
                this.differ.getEditors().right.getValue(),
                this.selectednode[this.activeTab].name,
                this.selectednode[this.activeTab].path,
                this.selectednode[this.activeTab].nodeid,
                this.selectednode[this.activeTab].nodepath,
                this.selectednode[this.activeTab].comments
            )
            .toPromise();

        let saveSharedCopyToMergeFlg = null
        if (res.status == 'success') {
            let postData = {
                id: this.selectednode[this.activeTab].nodeid,
                stageId: stageId,
                sharedId: sharedId
            }
            saveSharedCopyToMergeFlg = await this.editorService.saveSharedCopyToMergeSvs(postData).toPromise();
        } else {
            this.notifier.notify('error', this.translate.instant('articles.share save error'))
        }

        if (saveSharedCopyToMergeFlg.status == '200') {
            this.notifier.notify('success', this.translate.instant('articles.share save success'))
            this.isDiffShow = this.isHideEditor = false
            setTimeout(() => {
                // this.editors(this.editorOption.readonly = true)
                this.router.navigate(['/my-articles'])
                console.log('intimeout')
            }, 2000);
        }
    }

    public completeMerge(artId, stageId, sharedId) {
        let postData = {
            id: artId,
            stageId: stageId,
            sharedId: sharedId
        }

        this.editorService.completeMergeSvs(postData).subscribe(res => {
            if (res['status'] === 200) {
                this.router.navigate(['/my-articles'])
            }
        })
    }

    public async exportTo(destination) {
        let postData = {
            articleId: btoa(this.articleInfo['_id']),
            dest: btoa(destination)
        }
        let res = await this.articleService.exportToSvs(postData).toPromise()
    }

    /**
     * Cleaning of the citation. ie, Ranges splited with commas
     * Eg. \cite{5-10} to \cite{5,6,7,8,9,10}
     * @param event
     * @author Arathy S V 
     */
    citationCleaning(_event) {
        // Regular expression for citation
        // \\cite{(\d+,)*((\d+)\s*\-+\s*(\d+),?)+(,?\d+)*((\d+)\s*\-+\s*(\d+),?)*(\d+)}
        // var rgx =/\\cite{(\d+,)*(\d+)\s*\-+\s*(\d+)(,\d+)*}/g;

        $(".replaced-bg").remove();
        $(".highlight-auto-replace").contents().unwrap();
        $(".replace-button-span").remove();
        var rgx = /\\cite{((\d+)|((\d+)\s*\-{1,2}\s*(\d+))+)(,(\d+)|((\d+)\s*\-{1,2}\s*(\d+))+)*}/g;
        var ace_content = document.querySelectorAll('#search_replace_body')[0].innerHTML;
        // replace the matched citation and render into html page.
        document.querySelectorAll('#search_replace_body')[0].innerHTML = ace_content.replace(rgx, this.cleanCitation);
    }

    /**
     * Replacement function for cleaning citation
     * @author Arathy S V
     */
    cleanCitation(matchedString: string, _p1, _p2, _offset, _string) {
        function rangeSpliting(_match, p4, p5) {
            var expandStr = '';
            for (var i = p4; i < p5; i++) {
                expandStr = expandStr + i + ',';
            }
            expandStr = expandStr + p5;
            return expandStr;
        }
        // Regular expression for finding the ranges within the citation
        var range = /(\d+)\s*\-{1,2}\s*(\d+)/g;
        // Replace the ranges with comma separated values, ie 2-4 to 2,3,4
        if (matchedString.match(range)) {
            var cleaned = matchedString.replace(range, rangeSpliting);
            // Highligh cleaned citations
            return '<span class="cleaned-citation">' + cleaned + '</span>';
            // return cleaned;
        } else
            return matchedString;
    }

    /**
     * Check whether any citation for cleaning exist or not
     * @author Arathy  S V
     */
    checkCitationCleaning() {
        // Rgex for citation
        var rgx = /\\cite{((\d+)|((\d+)\s*\-{1,2}\s*(\d+))+)(,(\d+)|((\d+)\s*\-{1,2}\s*(\d+))+)*}/g;
        var ace_content = document.querySelectorAll('#search_replace_body')[0].innerHTML;
        var matches = ace_content.match(rgx);
        if (matches) {
            for (var i = 0; i < matches.length; i++) {
                var range = /(\d+)\s*\-{1,2}\s*(\d+)/g;
                var cleaned = matches[i].match(range);
                if (cleaned)
                    return false;
            }
        }
        return true;
    }

    // citationCleaning(event){
    //     console.log(event)
    // }

    getActiveArticleData() {
        let postData = {
            art_ids: [this.selectednode[this.activeTab].nodeid]
        }
        this.sharedService.getDetailsForStageMoveSvs(postData).subscribe(response => {
            if (response.status === 200) {
                this.activeArticleData = response.articles[0];
                this.fileDiff({
                    metaData: 'null',
                    articleFile: this.articleInfo['article_file'],
                    mainDocument: this.articleInfo['article_mainDocument'],
                    sharedId: this.shared._id
                })
            }
        });
    }

    async pullFiles(basicApiSettings, getFigOptions) {
        let postData = {
            basicApiSettings: basicApiSettings,
            getFigOptions: getFigOptions,
            articleInfo: {
                id: this.articleInfo['_id'],
                articleFile: this.articleInfo['article_file'],
                articleName: this.articleInfo['article_name']
            }
        }

        let res = await this.editorService.getFigureApiSvs(postData).toPromise()
        if (res['status'] == 200) {
            const id = this.route.snapshot.paramMap.get("id");
            this.editorService.getFolderStructure({ id: [id] }).subscribe(
                response => {
                    console.log(response)
                    this.hierarchicalData = [];
                    response.forEach((element, index) => {
                        this.hierarchicalData.push(this.getChildrens(element, index + 1));
                    });
                    console.log(this.hierarchicalData)
                    this.field = { dataSource: this.hierarchicalData, id: 'id', text: 'name', child: 'children', iconCss: 'icon', code: 'code', htmlAttributes: 'hasAttribute', article_file: 'article_file' };
                }
            )
            console.log(this.field)
            this.notifier.notify('success', this.translate.instant('editor.get figure success'))
        }
        else {
            this.notifier.notify('error', this.translate.instant("editor.get figure error"));
        }
    }

    /*cleaning refrence*/
    @ViewChild('dropdown')
    public dropdownObject: DropDownListComponent;
    refrenceTypeForm: FormGroup;
    referenceOrderListForm: FormGroup
    public autoreactiveplaceholder: String = 'Select reference type';
    public initialPage: PageSettingsModel;

    onCleanReferenceModalActionBeginEvent(args: SaveEventArgs): void {
        const currentGridAction = args.requestType;
        if (currentGridAction === 'add') {
            args.rowData['id'] = this.referencePatterns.length + 1;
        }

        if (currentGridAction === 'save') {
            this.upsertReferencePattern(args.data);
        }

        if (currentGridAction === 'delete') {
            this.removeReferencePatterns(args.data)
        }
    }
    upsertReferencePattern(args: any) {
        this.editorService.upsertParameterForReferencePatterns(
            this.selectedCleaningReferenceType,
            args.id,
            {
                id: args.id,
                name: args.name,
                expression: args.expression,
                is_optional: args.is_optional,
                output: args.output,
            }
        ).subscribe(
            _response => {
                //if (response.status === "success") {
                this.notifier.notify('success', this.translate.instant("rvclean.save-success"));
                ///} 
            },
            _error => {
                this.notifier.notify('error', this.translate.instant("rvclean.error-message"));
            }
        );
    }

    removeReferencePatterns(args: any) {
        this.editorService.removeReferencePatterns(
            this.selectedCleaningReferenceType,
            args[0].id,
            {
                id: args[0].id,
                name: args[0].name,
                expression: args[0].expression,
                is_optional: args[0].is_optional === 'true',
                output: args[0].output
            }
        ).subscribe(
            _response => {
                this.notifier.notify('success', 'Removed successfully');
            },
            _error => {
                this.notifier.notify('error', this.translate.instant("rvclean.error-message"));
            }
        );
    }
    public focusInModal(target: HTMLElement): void {
        console.log(target)
        target.parentElement.classList.add('e-input-focus');
    }

    public focusOutModal(target: HTMLElement): void {

        //TODO Change to submit button
        this.selectedOrder = target["value"]
        console.log(target)
        target.parentElement.classList.remove('e-input-focus');
    }

    public focusIn(target: HTMLElement): void {
        target.parentElement.classList.add('e-input-focus');
    }

    public focusOut(target: HTMLElement): void {
        target.parentElement.classList.remove('e-input-focus');
    }

    saveReferenceCleaningSettings() {
        if (this.selectedCleaningReferenceType) {
            this.editorService.upsertReferencePatterns(this.selectedCleaningReferenceType, {
                output: this.referenceOutput,
                order: this.referenceOrders
            }).subscribe(
                response => {
                    if (response.status === "success") {
                        this.notifier.notify('success', 'Orders & Output Saved successfully');
                    }
                },
                _error => {
                    this.notifier.notify('error', this.translate.instant("rvclean.error-message"));
                });
        }
    }

    change(args: ChangeEventArgs) {
        this.initialPage = { currentPage: args.value };
    }
    beforeBatchSave(_args) {

    }
    setState;
    beforeBatchAdd(e) {
        var val = this.state.projectIdValue + 1
        e.defaultData.OrderID = val
        this.setState({
            projectIdValue: val
        })
    }

    //orderModal
    onDragDropChip(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.referenceOrders[this.selectedCleaningReferencePatternOrderIndex].order, event.previousIndex, event.currentIndex);
    }

    remove(_selectedCleaningReferencePatternOrderIndex, parameter): void {
        const index = this.referenceOrders[this.selectedCleaningReferencePatternOrderIndex].order.indexOf(parameter);
        if (index >= 0) {
            this.referenceOrders[this.selectedCleaningReferencePatternOrderIndex].order.splice(index, 1);
        }
    }

    get referenceOutputText() {
        return AutoRegexUtilities.referenceOutputText(this.referencePatternType, this.referencePatterns, this.referenceOutput)//,this.referencePatternType
    }

    getParameterById(id): any {
        if (!this.referencePatterns) {
            return undefined;
        }
        return this.referencePatterns.find(data => {
            return data['id'] === id;
        });
    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            for (var element of this.referencePatterns) {
                if (element['name'] == value) {
                    this.referenceOrders[this.selectedCleaningReferencePatternOrderIndex].order.push(element['id']);
                }

            }

        }
        // Reset the input value
        if (event.input) {
            event.input.value = '';
        }
    }
    addOutputChips(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            for (var element of this.referencePatterns) {
                if (element['name'] == value) {
                    this.referenceOutput.push(element['id']);
                }

            }
        }
        // Reset the input value
        if (event.input) {
            event.input.value = '';
        }
    }
    onDragDropChipOutputOrder(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.referenceOutput, event.previousIndex, event.currentIndex);
    }


    removeOutputOrder(removepattern): void {
        const index = this.referenceOutput.indexOf(removepattern);

        if (index >= 0) {
            this.referenceOutput.splice(index, 1);

        }
    }

}


import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { ChangeEventArgs } from '@syncfusion/ej2-inputs';
import { MatChipsModule, MatChipInputEvent } from '@angular/material/chips';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { data } from '../data';
export interface Vegetable {
    name: string;
}

@Component({
    selector: "demo-jazz-dialog",
    styles: [
        `
      div {
        background: rgba(0, 0, 0, 0.12);
      }
    `
    ],
    template: `
    <p>{{ data.message }}</p>
    <button
      type="button"
      mat-button
      color="warn"
      (click)="dialogRef.close(data.no)"
    >
      {{ data.no }}
    </button>    
    &nbsp;
    <button
      type="button"
      mat-button
      color="primary"
      (click)="dialogRef.close(data.yes)"
    >
      {{ data.yes }}
    </button>
  `
})
export class JazzDialog {
    private _dimesionToggle = false;
    constructor(
        public dialogRef: MatDialogRef<JazzDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }
}
