import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';
import { UserActivityComponent } from './user-activity/user-activity.component';
import { UsersModule } from '../users/users.module';
import { NotifierModule } from 'angular-notifier';
import { UseractivationComponent } from './useractivation/useractivation.component';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MatButtonModule , MatFormFieldModule, MatInputModule, MatIconModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { SharedModule } from '../shared/shared.module';
// ngx ui loader config values
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsPosition: POSITION.bottomCenter,
  fgsSize: 75,
  fgsColor: '',
  fgsType: SPINNER.threeStrings,
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
  pbColor: ''
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoginRoutingModule,
    UsersModule,
    NotifierModule,   
    MatButtonModule ,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig), 
    SharedModule
  ],
  declarations: [

    LoginComponent,

    UserActivityComponent,

    UseractivationComponent,

    ResetpasswordComponent
  ],
  exports: [
    LoginComponent
  ]
  
})
export class LoginModule { }
