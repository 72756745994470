import { Component, OnInit, ViewChild, ViewEncapsulation, SecurityContext } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, FormArray, FormGroupDirective } from '@angular/forms';
import { UserrolesService } from '../userroles/userroles.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser'
import { NotifierService } from 'angular-notifier';
import { TreeView, TreeViewComponent, NodeKeyPressEventArgs, NodeClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared/shared.service';

export interface type {
    value: string;
    typeName: string;
}
export interface position {
    value: string;
    positionName: string;
    disable: string;
}
@Component({
    selector: 'app-manage-features',
    templateUrl: './manage-features.component.html',
    styleUrls: ['./manage-features.component.scss']
})
export class ManageFeaturesComponent implements OnInit {
    typemenu = false;
    showFunc = false;
    submitted = false
    validator;
    function_key;
    unique_key;
    key;
    checkFuncVal;
    menuTreeElements = [];
    clearFormArray;
    public field: Object;
    public tree: TreeViewComponent;
    @ViewChild('treevalidate') treevalidate: TreeViewComponent;
    @ViewChild('treeview') treeview: TreeViewComponent;
    // to reset mat-inpuy-fields without invalid class
    @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
    public manageFeature: FormGroup;
    term: any; public table;
    editFlag: Boolean = false;
    functionalityForm: FormGroup;

    checkValue = { checked: true };
    constructor(private _fb: FormBuilder,
        private router: Router,
        private notifier: NotifierService,
        public translate: TranslateService,
        public manageFeatureService: UserrolesService,
        private shared_service: SharedService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer
    ) { }
    ngOnInit() {
        this.manageFeature = this._fb.group({
            type: new FormControl(),
            name: new FormControl(),
            url: new FormControl(),
            icon: new FormControl(),
            position: new FormControl(),
            unique_key: new FormControl(),
            feature_id: new FormControl(),
            dynamicform: new FormControl(),
            table: new FormControl()
        });
        this.loadInitialData();
    }
    loadInitialData() {
        this.manageFeatureService.getmanageFeatureKey().subscribe(data => {
            this.unique_key = data['unique_id'] + 1;
            this.function_key = data['f_id'];
            this.validator = Validators.required;
            this.manageFeature = this._fb.group({
                type: ['', [this.validator]],
                name: ['', [this.validator]],
                url: [''],
                icon: ['', [this.validator]],
                position: new FormControl(),
                unique_key: new FormControl(),
                feature_id: new FormControl(),
                itemRows: this._fb.array([this.initItemRows()]),
                checkFunc: false,
                dynamicform: [''],
                table: ['',[this.validator]]
            });
            this.manageFeature.patchValue({
                unique_key: this.unique_key
            });
        });
    }
    get formArr() {
        return this.manageFeature.get('itemRows') as FormArray;
    }
    initItemRows() {
        this.key = Number(this.function_key) + 1;
        this.function_key = this.key;
        return this.functionalityForm = this._fb.group({
            name: [''],
            identifier: [''],
            id: ['F' + this.key]
        });
    }
    getContactsFormGroup(index): FormGroup {
        const formGroup = this.formArr.controls[index] as FormGroup;
        return formGroup;
    }

    addNewRow() {
        this.formArr.push(this.initItemRows());
        this.onClickFunc(this.checkValue);
    }
    deleteRow(index: number) {
        this.formArr.removeAt(index);
    }
    onChangeType(valueType) {
        if (valueType.value == 1) { //menu
            this.typemenu = true;
            this.manageFeature.controls["url"].setValidators(Validators.required);
            this.manageFeature.controls["position"].setValidators(Validators.required);
        }
        else { // feature
            this.typemenu = false;
            this.showFunc = false;
            this.manageFeature.controls["url"].clearValidators();
            this.manageFeature.controls["position"].clearValidators();

            this.manageFeature.controls["url"].updateValueAndValidity();
            this.manageFeature.controls["position"].updateValueAndValidity();
        }
    }
    onClickFunc(checkValue) {
        if (checkValue.checked === true) {
            this.showFunc = true;
            this.manageFeature.controls["url"].setValidators(Validators.required);
            this.functionalityForm.controls['name'].setValidators(Validators.required);
            this.functionalityForm.controls['identifier'].setValidators(Validators.required);
        } else {
            while (this.formArr.length) {
                this.formArr.removeAt(0);
            }
            //   this.clearFormArray = (formArray: FormArray) => {
            //     while (formArray.length !== 0) {
            //       formArray.removeAt(0);
            //     }
            //   };
            this.showFunc = false;
            this.manageFeature.controls["url"].clearValidators();
            this.functionalityForm.controls['name'].clearValidators();
            this.functionalityForm.controls['identifier'].clearValidators();
        }
    }
    types: type[] = [
        { value: '1', typeName: 'Menu' },
        { value: '2', typeName: 'Feature' }
    ];
    positions: position[] = [
        { value: '1', positionName: 'Left menu', disable: 'false' },
        { value: '2', positionName: 'Right menu', disable: 'true' },
        { value: '3', positionName: 'Top bar', disable: 'true' }
    ];

    onSubmit() {
        this.submitted = true;
        if (this.manageFeature.invalid) {
            return;
        }
        this.manageFeatureService.manageFeature(this.manageFeature.value).subscribe(data => {
            this.formGroupDirective.resetForm();
            Object.keys(this.manageFeature.controls).forEach(key => {
                this.manageFeature.controls[key].setErrors(null);
            });
            this.loadInitialData(); // for reseting form data
            this.loadmenu();		// For refreshing treeview
            this.typemenu = false;
            this.showFunc = false;
            this.submitted = false;
            this.notifier.notify('success', 'Data added successfully');
        });
        this.editFlag = false;
    }

    get formfields() {
        return this.manageFeature.controls;
    }

    ngAfterViewInit() {
        this.loadmenu();
    }

    loadmenu() {

        this.menuTreeElements = [];
        this.route.params.subscribe(params => {
            if (typeof (params.id) == "undefined") {
                this.manageFeatureService.getFeatures().subscribe(data => {
                    let me = data['data'].map(data => {
                        if (data.type == 'F') {
                            var parentnode1 = { id: data.unique_id, name: "Feature - " + data.name, edit: true, _id: data._id, isDisabled: true, hasChild: false, expanded: false, expandOn: "Click", iconCss: data.icon };
                            this.menuTreeElements.push(parentnode1);
                        }
                        if (data.type == 'M') {
                            var parentnode = { id: data.unique_id, name: "May access menu - " + data.name, edit: true, _id: data._id, isDisabled: true, hasChild: true, expanded: false, expandOn: "Click", iconCss: data.icon };
                            this.menuTreeElements.push(parentnode);

                            if (data.functionality != undefined) {
                                data.functionality.map(functionality => {
                                    var childnode = { id: functionality.id, name: functionality.name, pid: data.unique_id };
                                    this.menuTreeElements.push(childnode);
                                })
                            }
                        }
                    });

                    this.field = { dataSource: this.menuTreeElements, id: 'id', parentID: 'pid', text: 'name', hasChildren: 'hasChild' };
                });
            }
        });
    }

    // replaceHtmlEntities(str) {
    //   return String(str).replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    // }
    
    onClickEdit(feature_id) {
        this.editFlag = true;
        this.manageFeatureService.getFeaturedata(feature_id).subscribe(
            data => {
                this.manageFeature.reset();
                if (data['type'] == 'M') { //Case when type equal to Menu
                    data['type'] = '1';
                    this.typemenu = true; // to show menu form fields
                    if (data['functionality']) {
                        while (this.formArr.length) {
                            this.formArr.removeAt(0);
                        }
                        this.checkFuncVal = true;
                        this.showFunc = true;
                        for (let i = 0; i < data['functionality'].length; i++) {
                            this.formArr.push(this._fb.group({
                                name: data['functionality'][i]['name'],
                                identifier: data['functionality'][i]['identifier'],
                                id: data['functionality'][i]['id'],
                            }));
                        }
                    }
                    else {
                        while (this.formArr.length) {
                            this.formArr.removeAt(0);
                        }
                        this.addNewRow();
                        this.checkFuncVal = false;
                        this.showFunc = false;
                    }
                    this.manageFeature.patchValue({
                        type: data['type'],
                        icon: data['icon'],
                        name: data['name'],
                        url: data['url'],
                        position: data['position'],
                        dynamicform: data['dynamic_form'],
                        checkFunc: this.checkFuncVal,
                        table: data['save_table'],
                        feature_id: data['_id'],
                    })
                }
                if (data['type'] == 'F') { //Case when type equal to Feature
                    data['type'] = '2';
                    this.typemenu = false;
                    this.showFunc = false;
                    this.manageFeature.patchValue({
                        type: data['type'],
                        icon: data['icon'],
                        name: data['name'],
                        unique_key: data['id'],
                        dynamicform: data['dynamic_form'],
                        table: data['save_table'],
                        feature_id: data['_id'],
                    })
                }

            });
    }
    onClickDelete(feature_id) {
        this.manageFeatureService.deleteFeaturedata(feature_id).subscribe(
            data => {
                this.loadInitialData(); // for reseting form data
                this.loadmenu();		// For refreshing treeview
                this.typemenu = false;
                this.showFunc = false;
                this.manageFeature.reset();
                Object.keys(this.manageFeature.controls).forEach(key => {
                    this.manageFeature.controls[key].setErrors(null);
                });
                this.notifier.notify('success', 'Data deleted successfully');
            });
    }

    buttonchange() {
        this.manageFeature.reset();
        // this.formGroupDirective.resetForm();
        this.editFlag = false;
    }



    icons = [{ name: "icon-user" }, { name: "icon-people" }, { name: "icon-user-female" }, { name: "icon-user-follow" }, { name: "icon-social-dribbble" }, { name: "icon-social-behance" },
    { name: "icon-social-foursqare" }, { name: "icon-social-soundcloud" }, { name: "icon-social-spotify" }, { name: "icon-social-stumbleupon" }, { name: "icon-social-youtube" },
    { name: "icon-social-dropbox" }, { name: "icon-social-linkedin" }, { name: "icon-social-pinterest" }, { name: "icon-social-github" }, { name: "icon-social-gplus" },
    { name: "icon-social-reddit" }, { name: "icon-social-skype" }, { name: "icon-credit-card" }, { name: "icon-paypal" }, { name: "icon-social-tumblr" }, { name: "icon-social-twitter" },
    { name: "icon-social-facebook" }, { name: "icon-social-instagram" }, { name: "icon-calendar" }, { name: "icon-bulb" }, { name: "icon-chart" }, { name: "icon-ban" }, { name: "icon-bubble" },
    { name: "icon-camrecorder" }, { name: "icon-camera" }, { name: "icon-cloud-download" }, { name: "icon-cloud-upload" }, { name: "icon-envelope" }, { name: "icon-eye" }, { name: "icon-flag" },
    { name: "icon-heart" }, { name: "icon-info" }, { name: "icon-key" }, { name: "icon-link" }, { name: "icon-lock" }, { name: "icon-lock-open" }, { name: "icon-magnifier" }, { name: "icon-magnifier-add" },
    { name: "icon-magnifier-remove" }, { name: "icon-paper-clip" }, { name: "icon-paper-plane" }, { name: "icon-power" }, { name: "icon-refresh" },
    { name: "icon-reload" }, { name: "icon-settings" }, { name: "icon-star" }, { name: "icon-symbol-female" }, { name: "icon-symbol-male" }, { name: "icon-target" }, { name: "icon-user" }, { name: "icon-people" },
    { name: "icon-user-female" }, { name: "icon-user-follow" }, { name: "icon-user-following" }, { name: "icon-login" }, { name: "icon-logout" }, { name: "icon-emotsmile" }, { name: "icon-phone" },
    { name: "icon-call-end" }, { name: "icon-call-in" }, { name: "icon-call-out" }, { name: "icon-map" }, { name: "icon-location-pin" }, { name: "icon-direction" }, { name: "icon-directions" },
    { name: "icon-compass" }, { name: "icon-layers" }, { name: "icon-menu" }, { name: "icon-list" }, { name: "icon-options-vertical" }, { name: "icon-options" },
    { name: "icon-arrow-down" }, { name: "icon-arrow-left" }, { name: "icon-arrow-right" }, { name: "icon-arrow-up" }, { name: "icon-arrow-up-circle" },
    { name: "icon-arrow-left-circle" }, { name: "icon-arrow-right-circle" }, { name: "icon-arrow-down-circle" }, { name: "icon-check" },
    { name: "icon-clock" }, { name: "icon-plus" }, { name: "icon-close" }, { name: "icon-trophy" }, { name: "icon-screen-smartphone" }, { name: "icon-screen-desktop" },
    { name: "icon-plane" }, { name: "icon-notebook" }, { name: "icon-mustache" }, { name: "icon-mouse" }, { name: "icon-magnet" }, { name: "icon-energy" },
    { name: "icon-disc" }, { name: "icon-cursor" }, { name: "icon-cursor-move" }, { name: "icon-crop" }, { name: "icon-chemistry" }, { name: "icon-speedometer" }, { name: "icon-shield" },
    { name: "icon-screen-tablet" }, { name: "icon-magic-wand" }, { name: "icon-hourglass" }, { name: "icon-graduation" }, { name: "icon-ghost" }, { name: "icon-game-controller" }, { name: "icon-fire" },
    { name: "icon-eyeglass" }, { name: "icon-envelope-open" }, { name: "icon-envelope-letter" }, { name: "icon-bell" }, { name: "icon-badge" },
    { name: "icon-anchor" }, { name: "icon-wallet" }, { name: "icon-vector" }, { name: "icon-speech" }, { name: "icon-puzzle" }, { name: "icon-printer" }, { name: "icon-present" }, { name: "icon-playlist" },
    { name: "icon-pin" }, { name: "icon-picture" }, { name: "icon-handbag" }, { name: "icon-globe-alt" }, { name: "icon-globe" }, { name: "icon-folder-alt" }, { name: "icon-folder" }, { name: "icon-film" },
    { name: "icon-feed" }, { name: "icon-drop" }, { name: "icon-drawer" }, { name: "icon-docs" }, { name: "icon-doc" }, { name: "icon-diamond" }, { name: "icon-cup" }, { name: "icon-calculator" },
    { name: "icon-bubbles" }, { name: "icon-briefcase" }, { name: "icon-book-open" }, { name: "icon-basket-loaded" }, { name: "icon-basket" }, { name: "icon-bag" }, { name: "icon-action-undo" }, { name: "icon-action-redo" },
    { name: "icon-wrench" }, { name: "icon-umbrella" }, { name: "icon-trash" }, { name: "icon-tag" }, { name: "icon-support" }, { name: "icon-frame" }, { name: "icon-size-fullscreen" }, { name: "icon-size-actual" }, { name: "icon-shuffle" },
    { name: "icon-share-alt" }, { name: "icon-share" }, { name: "icon-rocket" }, { name: "icon-question" }, { name: "icon-pie-chart" }, { name: "icon-pencil" }, { name: "icon-note" }, { name: "icon-loop" }, { name: "icon-home" }, { name: "icon-grid" },
    { name: "icon-graph" }, { name: "icon-microphone" }, { name: "icon-music-tone-alt" }, { name: "icon-music-tone" }, { name: "icon-earphones-alt" }, { name: "icon-earphones" }, { name: "icon-equalizer" }, { name: "icon-like" },
    { name: "icon-dislike" }, { name: "icon-control-start" }, { name: "icon-control-rewind" }, { name: "icon-control-play" }, { name: "icon-control-pause" }, { name: "icon-control-forward" }, { name: "icon-control-end" },
    { name: "icon-volume-1" }, { name: "icon-volume-2" }, { name: "icon-volume-off" }];
    selectIcon(icon) {
        this.manageFeature.patchValue({
            icon: icon
        });
    }
    onClickFuncDynamic(event) {
        // console.log(event);
        if (event.checked === true) {
            this.table = true;
        } else {
            this.table = false;
        }
    }
}
