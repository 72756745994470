import { Injectable } from '@angular/core';
import { ValueTransformer } from '@angular/compiler/src/util';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../service/env.service';
import { stringify } from '@angular/core/src/util';

@Injectable({
    providedIn: 'root'
})

export class NotesService {

    constructor(private http: HttpClient, private env: EnvService) { }

    getAllNotesSvs(data) {
        return this.http.post(this.env.baseAPIUrl + '/get-all-notes', data)
    }

    saveNotesSvs(data) {
        return this.http.post(this.env.baseAPIUrl + '/save-notes', data)
    }

    getAddNoteMetadataSvs(data) {
        return this.http.post(this.env.baseAPIUrl + '/get-add-notes-metadata', data)
    }

    deleteNoteSvs(data) {
        return this.http.post(this.env.baseAPIUrl + '/delete-note', data)
    }
}
