import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../service/env.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient, private env: EnvService) { }
    createUser($data) {
        return this.http.post(this.env.baseAPIUrl + '/saveuser', $data);
    }
    getUserdata($data) {
        return this.http.get(this.env.baseAPIUrl + '/getuser/' + $data);
    }
    updateUser($data) {
        return this.http.post(this.env.baseAPIUrl + '/updateUser', $data);
    }
    updateUserPassword($data) {
        return this.http.post(this.env.baseAPIUrl + '/updateUsePassword', $data);
    }
    deleteProfilePic($data) {
        return this.http.post(this.env.baseAPIUrl + '/deleteProfilePic', { userid: $data });
    }
    deactivateUser($data, flag) {
        return this.http.get(this.env.baseAPIUrl + '/deactivateUser/' + $data + '/' + flag);
    }
    deleteUser($data) {
        return this.http.get(this.env.baseAPIUrl + '/deleteUser/' + $data);
    }
    userActivation($data) {
        return this.http.post(this.env.baseAPIUrl + '/userActivation', $data);
    }
    resetPassword($data) {
        return this.http.post(this.env.baseAPIUrl + '/resetPassword', $data);
    }
    checkActivationLink($data) {
        return this.http.get(this.env.baseAPIUrl + '/checkActivationLink/' + $data);
    }
    checkResetPasswordLink($data) {
        return this.http.get(this.env.baseAPIUrl + '/checkResetPasswordLink/' + $data);
    }
    getUserDetailsMatTable($data,$offset) {
        return this.http.post(this.env.baseAPIUrl + '/getUserDetailsMatTable', { token: $data , data:$offset});
    }

    getChildDetails($data) {
        return this.http.post(this.env.baseAPIUrl + '/getChildDetails', { data: $data});
    }
    getAllParentUsers() {
        return this.http.get(this.env.baseAPIUrl + '/getAllParentUsers');
    }
    getUsersFromRolesSvs($data) {
        return this.http.post(this.env.baseAPIUrl + '/get-users-from-roles', $data);
    }
    
}
