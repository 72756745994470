import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, map } from 'rxjs/operators';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from "@angular/forms";
import { articleUploadsService } from '../articleupload.service';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { UserTokenService } from '../../service/user-token.service';
import { NotifierService } from "angular-notifier";
import { Router } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { MatPaginator, MatSort , MatTableDataSource} from '@angular/material';
import { element } from '@angular/core/src/render3/instructions';
import { SharedService } from '../../shared/shared.service';



@Component({
  selector: 'app-manage-articleupload',
  templateUrl: './manage-articleupload.component.html',
  styleUrls: ['./manage-articleupload.component.scss']
})

export class ManageArticleuploadComponent implements OnInit {

  dtOptions: any = {};

  public displayedColumns = ['article_code', 'article_zip_name', 'created_date', 'delete'];
  public dataSource = new MatTableDataSource();
  @ViewChild(DataTableDirective)
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  

  createArticleUpload:FormGroup;
  articleName= '';
  articleId = '';
  submitted = false;
  editFlag = false;
  editflag = true;
  pic_preview = false;
  public article_list:any =[];
  private readonly notifier: NotifierService;
  constructor(
    private fbb:FormBuilder,
    private articleUploadsService: articleUploadsService,
    private http: HttpClient,
    private router: Router,
    private UserTokenService:UserTokenService,
    public translate: TranslateService,
    notifierService: NotifierService,
    private shared_service: SharedService
  )
  {
    this.notifier = notifierService;
  }
  public popoverMessage_delete:string = this.translate.instant('articles.popover_msg_delete');
  public popoverTitle = "Delete confirmation?";
  httpOptions = {
    headers: new HttpHeaders({
        'Authorization': this.UserTokenService.getToken(),
        'Content-Type' : 'multipart/form-data',
        'Accept' : 'application/json'
    })
};
  ngOnInit() {
    this.createArticleUpload  = this.fbb.group({
      articleId: new FormControl(''),
      articleName: new FormControl('', [Validators.required]),
      articleCode: new FormControl('', [Validators.required]),
      articleFile:  new FormControl('', [Validators.required]),
  });
    this.articleList();
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  get formfields() 
  { 
    return this.createArticleUpload.controls; 
  }
  
    url = '';
    onFileChange(event) {
        if (event.target.files.length > 0) {
            let file = event.target.files[0];
            this.createArticleUpload.get('articleFile').setValue(file);
        }
        // image preview before upload
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = (event : any) => { // called once readAsDataURL is completed
                this.url = event.target.result;
            }
        }
    }
    redirectToDelete(elementid)
    {
      this.articleUploadsService.removeArticle(elementid).subscribe(
        response => {  
          if (response['status'] === '200') {
            this.notifier.notify(response['message_type'], response['message']);
          } else {
            this.notifier.notify(response['message_type'], response['message']);
          }
          this.articleList(); 
        },
         error => {
          this.notifier.notify('error', this.translate.instant('error_occured_msg'));
         }
        
      )
    } 
    changeStatus(element_id,status){

      this.articleUploadsService.changeArticleStatus(element_id,status).subscribe(
        response => {  
          if (response['status'] === '200') {
            this.notifier.notify(response['message_type'], response['message']);
          } else {
            this.notifier.notify(response['message_type'], response['message']);
          }
          this.articleList(); 
        },
         error => {
          this.notifier.notify('error', this.translate.instant('error_occured_msg'));
         }
        
      )
    }
    articleList()
    {
      this.shared_service.showSpinner('articleSpinner');
      this.articleUploadsService.articleList().subscribe(
        response => {
          if (response['status'] === 200) {
            this.article_list = response['articles'];
            this.dataSource.data = response['articles'];
          }
          this.shared_service.hideSpinner('articleSpinner');
        },
        error => {
          this.shared_service.hideSpinner('articleSpinner');
        }
      )
    }
    public doFilter = (value: string) => {
      this.dataSource.filter = value.trim().toLocaleLowerCase();
    }
    
}