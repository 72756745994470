import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/delay';
import { EnvService } from '../service/env.service';

@Injectable({
    providedIn: 'root'
})

export class emailTemplatesService {
    constructor(private http: HttpClient, private env: EnvService) { }
    createTemplate($data: string) {
        return this.http.post(this.env.baseAPIUrl + '/saveEmailTemplate', $data);
    }
    getTemplates($datatableparams: string) {
        return this.http.post(this.env.baseAPIUrl + '/getEmailTemplates', $datatableparams);
    }
    getTemplateDetails($templateId: string) {
        return this.http.post(this.env.baseAPIUrl + '/templateDetails/' + $templateId, '');
    }
    upatetemplateDetails($data: string) {
        return this.http.post(this.env.baseAPIUrl + '/updateTemplate', $data);
    }
    getTemplate($data) {
        return this.http.post(this.env.baseAPIUrl + '/getmailTemplates', $data);
    }
    getactiveTemplate() {
        return this.http.get(this.env.baseAPIUrl + '/getactivemailTemplates');
    }
    getemaillog() {
        return this.http.get(this.env.baseAPIUrl + '/getemailLogs');
    }
    resendEmail($data: string) {
        return this.http.post(this.env.baseAPIUrl + '/resendMail/' + $data, '');
    }
    editSendmaillog($data: string) {
        return this.http.post(this.env.baseAPIUrl + '/editsendmaillog', $data);
    }
    sendEmail($data: string) {
        return this.http.post(this.env.baseAPIUrl + '/sendEmail', $data);
    }
    getuserEmail() {
        return this.http.get<any>(this.env.baseAPIUrl + '/getuserEmail');
    }
    getEmaildata($id: string) {
        return this.http.post<any>(this.env.baseAPIUrl + '/getemaildata/' + $id, '');
    }
    changeTemplateStatus($templateId: string, $status: string) {
        var jsonObject = {};
        jsonObject['tempId'] = $templateId;
        jsonObject['status'] = $status;
        return this.http.post(this.env.baseAPIUrl + '/changeEmailTemplateStatus', jsonObject);
    }
    getUserRole() {
        return this.http.get<any>(this.env.baseAPIUrl + '/getRoleNames');
    }
    getKeywords() {
        return this.http.get(this.env.baseAPIUrl + '/getKeywords');
    }
    deleteTemp($tempId: string) {
        return this.http.post<any>(this.env.baseAPIUrl + '/deleteTemp/' + $tempId, '');
    }
    deletetemplates($data) {
        return this.http.get(this.env.baseAPIUrl + '/deleteTemplates/' + $data);
    }
    activateSelectedtemplates($data) {
        return this.http.get(this.env.baseAPIUrl + '/activateSelectTemplates/' + $data);
    }
    deactivateSelectedtemplates($data) {
        return this.http.get(this.env.baseAPIUrl + '/deactivateSelectTemplates/' + $data);
    }
    geEmailTemplateHooks($data: null) {
        return this.http.post(this.env.baseAPIUrl + '/get-email-template-hooks', $data);
    }

    getEmailTriggerDataSvs() {
        let data = [
            { id: 'arriving', itemName: 'Arriving to the stage' },
            { id: 'completion', itemName: 'On completion' },
            { id: 'checkin', itemName: 'On checkin' }
        ];
        return data;
    }
    
}