import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { WorkflowRoutingModule } from './workflow-routing.module';
import { DataTablesModule } from 'angular-datatables';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedModule } from '../shared/shared.module';
import { AvatarModule } from 'ngx-avatar';
import { MatButtonModule , MatFormFieldModule, MatInputModule, MatIconModule, MatSelectModule } from '@angular/material';	
import { MatTabsModule , MatTableModule , MatPaginatorModule , MatDialogModule} from '@angular/material';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WorkflowComponent } from './workflow.component';
import { WorkflowListComponent } from './workflow-list/workflow-list.component';
import { WorkflowCreateComponent } from './workflow-create/workflow-create.component';
import { MatStepperModule } from '@angular/material';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { FlexLayoutModule } from '@angular/flex-layout';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ModalModule.forRoot(),
    DataTablesModule,
    NgMultiSelectDropDownModule.forRoot(),
    TabsModule.forRoot(),
    NgxPermissionsModule.forChild(), 
    SharedModule,
    AvatarModule,
    MatButtonModule ,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    NgbModule.forRoot(),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger', // set defaults here
      cancelButtonType: 'outline-secondary'
    }),
    WorkflowRoutingModule,
    MatStepperModule,
    PerfectScrollbarModule,
    FlexLayoutModule,
    MatDialogModule
  ],
  declarations: [
    WorkflowComponent,
    WorkflowListComponent,
    WorkflowCreateComponent
  ],
  exports: [
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    // MatStepperModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
})
export class WorkflowModule { }
