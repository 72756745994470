import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../service/env.service';

@Injectable({
    providedIn: 'root'
})
export class ControlPanelService {

    constructor(private http: HttpClient, private env: EnvService) { }

    saveBasicInfoSvs(data) {
        return this.http.post(this.env.baseAPIUrl + '/save-basic-info', data);
    }

    getCPanelDataSvs() {
        return this.http.get(this.env.baseAPIUrl + "/get-cpanel-data");
    }

    getFileTypes() {
        return this.http.get(this.env.baseAPIUrl + "/get_extentions");
    }

    saveFileTypes(data) {
        return this.http.post(this.env.baseAPIUrl + "/save-file-types", data);
    }

    saveEmailSettingsSvs(data) {
        return this.http.post(this.env.baseAPIUrl + '/save-email-settings', data);
    }

    changeEmailDriverStatusSvs(data) {
        return this.http.post(this.env.baseAPIUrl + '/change-email-driver-status', data);
    }

    setDefaultDriverSvs(data) {
        return this.http.post(this.env.baseAPIUrl + '/set-default-driver', data)
    }

    removeDriverSvs(data) {
        return this.http.post(this.env.baseAPIUrl + '/remove-driver', data)
    }
    saveEditorFeaturesSvs(data){
        return this.http.post(this.env.baseAPIUrl + '/save-editor-cp', data)
    }

    getFigureDataSvs(){
        return this.http.get(this.env.baseAPIUrl+'/get-figure-data')
    }

    saveGetFigApiSettingsSvs(data){
        return this.http.post(this.env.baseAPIUrl+'/save-get-fig-api-settings',data)
    }

    addGetFigOptionsSvs(data){
        return this.http.post(this.env.baseAPIUrl+'/get-fig-option',data)
    }

    removeGetFigOptionSvs(id){
        return this.http.delete(this.env.baseAPIUrl+'/get-fig-option/'+id)
    }

    updateGetFigOptionSvs(data){
        return this.http.put(this.env.baseAPIUrl+'/get-fig-option',data)
    }

    changeGetFigOptionStatusSvs(data){
        return this.http.put(this.env.baseAPIUrl+'/get-fig-option-status', data)
    }
}
