import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
// import {PopoverModule} from "ngx-popover";

import { UsersModule } from './users/users.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@angular/http';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthGuard } from './auth.guard';
import { ReactiveFormsModule } from '@angular/forms';
/* 3rd party includes**/
import { NotifierModule } from 'angular-notifier';
import { AngularFileUploaderModule } from "angular-file-uploader";
//import { UserListComponent } from './users/user-list/user-list.component';
import { DataTablesModule } from 'angular-datatables';
import { ModalModule } from 'ngx-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { RequestInterceptorService } from './service/request-interceptor.service';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { InjectableGlobal } from './permissions';
import { UnauthorisedAccessComponent } from './error/UnauthorisedAccess.component';
import { pageNotFoundComponent } from './error/pageNotFound.component';
import { EnvServiceProvider } from './service/env.service.provider';
import { ArticleuploadModule } from './articleupload/articleupload.module';
import { AvatarModule } from "ngx-avatar";
import { TreeModule } from 'angular-tree-component';
import { TabModule, TreeViewModule, BeforeOpenCloseMenuEventArgs, MenuEventArgs, MenuItemModel } from '@syncfusion/ej2-angular-navigations';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgxCoolDialogsModule } from 'ngx-cool-dialogs';
import { MatBadgeModule, MatButtonModule, MatFormFieldControl, MatFormFieldModule, MatInputModule, MatIconModule, MatSelectModule, MatAutocompleteModule } from '@angular/material';
import { MatStepperModule, MatTabsModule, MatTableModule, MatPaginatorModule, MatSortModule } from '@angular/material';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PdfViewerModule } from 'ng2-pdf-viewer'; // <- import OrderModule
import { ContextMenuModule } from '@syncfusion/ej2-angular-navigations';
import { MatDialogModule } from "@angular/material";
import { MatMenuModule } from '@angular/material/menu';
import { FileUploadModule } from 'ng2-file-upload';
import { ArticlePersonalisedComponent } from './article/article-personalised/article-personalised.component';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { TimeAgoPipe } from 'time-ago-pipe';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};
import { PopoverModule } from 'ngx-bootstrap/popover';
import { OrderModule } from 'ngx-order-pipe';
import { AngularResizedEventModule } from 'angular-resize-event';
import { TagInputModule } from 'ngx-chips';
import { AccordionModule } from '@syncfusion/ej2-angular-navigations';
// ngx ui loader config values
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    "bgsColor": "#dd4b7b",
    "bgsOpacity": 0.5,
    "bgsPosition": "bottom-right",
    "bgsSize": 40,
    "bgsType": "ball-spin-clockwise",
    "blur": 5,
    "fgsColor": "#dd4b7b",
    "fgsPosition": "center-center",
    "fgsSize": 60,
    // "fgsType": "square-loader",
    "fgsType": "cube-grid",
    "gap": 24,
    "logoPosition": "center-center",
    "logoSize": 120,
    "logoUrl": "",
    "masterLoaderId": "master",
    "overlayBorderRadius": "0",
    "overlayColor": "rgba(40, 40, 40, 0.8)",
    "pbColor": "#dd4b7b",
    "pbDirection": "ltr",
    "pbThickness": 3,
    "hasProgressBar": true,
    "text": "",
    "textColor": "#FFFFFF",
    "textPosition": "center-center",
    "threshold": 500
};

// Import modules
import { DashboardsModule } from './dashboards/dashboards.module';
import { AppComponent } from './app.component';
// Import containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
//import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { LoginModule } from './login/login.module';
import { LoginComponent } from './views/login/login.component';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { WorkflowModule } from './workflow/workflow.module';

const APP_CONTAINERS = [
    DefaultLayoutComponent
];

import {
    AppAsideModule,
    AppBreadcrumbModule,
    AppHeaderModule,
    AppFooterModule,
    AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';
// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { RouterModule, Routes } from '@angular/router';
import { EditorComponent, JazzDialog } from './editor/editor.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularDraggableModule } from 'angular2-draggable';
//import { ResizableModule } from 'angular-resizable-element';
// import { FileManagerAllModule } from '@syncfusion/ej2-angular-filemanager';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DataTableModule } from "angular-6-datatable";
import { MatCardModule } from '@angular/material/card';
//  import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
// import { DatePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { NumericTextBoxAllModule, UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { DialogModule, TooltipModule } from '@syncfusion/ej2-angular-popups';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
// import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { GrdFilterPipe } from './grd-filter.pipe';
import { ResizableModule } from 'angular-resizable-element';
import { ManageFeaturesComponent } from './manage-features/manage-features.component';
import { NextStageButtonsComponent } from './next-stage-buttons/next-stage-buttons.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SplitterModule } from '@syncfusion/ej2-angular-layouts';
// import { NgxMatPopoverModule } from 'ngx-mat-popover';
import { ReversePipePipe } from './reverse-pipe/reverse-pipe.pipe';
import { CustomScrollDirective } from './custom-scroll.directive';
// import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { CollabService } from './collaboration/collab.service';
import { ChatService } from './collaboration/chat.service';
import { Search } from './editor/search.pipe';
import { MatChipsModule } from '@angular/material/chips';

// import { TourNgBootstrapModule } from 'ngx-tour-ng-bootstrap';
// import { TourNgxBootstrapModule } from "ngx-tour-ngx-bootstrap";
import { ResizableDirective } from './resizable.directive';
import { JoyrideModule, JoyrideStepComponent } from "ngx-joyride";
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { PageService, ToolbarService, EditService, SortService } from '@syncfusion/ej2-angular-grids';
// import { FormControlTxtComponent } from './form-control-txt/form-control-txt.component';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import { FileManagerModule } from '@syncfusion/ej2-angular-filemanager';
import { ControlPanelComponent } from './control-panel/control-panel.component';
import { NotesComponent } from './notes/notes.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RvcleanComponent } from './rvclean/rvclean.component';
// import { MatDynamicFormBuildSettingsComponent } from './mat-dynamic-form-build-settings/mat-dynamic-form-build-settings.component';
export class MyUrlSerializer extends DefaultUrlSerializer implements UrlSerializer {
    /** Converts a `UrlTree` into a url */
    serialize(tree: UrlTree): string {
        return super.serialize(tree).replace(/\((((?!(\/\/)).)*)\)/g, '$1');
    }
}
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
const routes: Routes = [];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { useHash: false }),
        DialogModule,
        TooltipModule,
        ButtonModule,
        ToolbarModule,
        MatAutocompleteModule,
        // GridAllModule,
        NumericTextBoxAllModule,
        BrowserModule,
        MatNativeDateModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AppAsideModule,
        MatCheckboxModule,
        MatSelectModule,
        MatCardModule,
        AppBreadcrumbModule.forRoot(),
        AppFooterModule,
        AppHeaderModule,
        GridModule,
        AppSidebarModule,
        ResizableModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        ChartsModule,
        LoginModule,
        HttpClientModule,
        MatChipsModule,
        HttpModule,
        PdfViewerModule,
        ArticleuploadModule,
        MatSlideToggleModule,
        // ContextMenuModule.forRoot(),
        UsersModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
        AngularFileUploaderModule,
        NgMultiSelectDropDownModule.forRoot(),
        ModalModule.forRoot(),
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        DataTablesModule,
        DropDownListModule,
        NgxPermissionsModule.forRoot(),
        AvatarModule,
        TreeModule.forRoot(),
        TreeViewModule,
        AngularFontAwesomeModule,
        ContextMenuModule,
        AngularDraggableModule,
        SharedModule,
        NgbModule,
        MatButtonModule,
        MatBadgeModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        FileUploadModule,
        MatIconModule,
        MatSelectModule,
        MatTabsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        Ng2SearchPipeModule,
        MatMenuModule,
        MatDialogModule,
        MatStepperModule,
        TabModule,
        TagInputModule,
        OrderModule,
        AngularResizedEventModule,
        FileManagerModule,
        // EJAngular2Module,
        // FileManagerAllModule,
        DataTableModule,
        // FileManagerAllModule,
        // TourMatMenuModule.forRoot(),
        JoyrideModule.forRoot(),
        // TourNgBootstrapModule.forRoot(),
        // TourNgxBootstrapModule.forRoot(),
        ConfirmationPopoverModule.forRoot({
            confirmButtonType: 'danger' // set defaults here
        }),
        // NgxMatPopoverModule,
        WorkflowModule,
        NgxPaginationModule,
        UploaderModule,
        SplitterModule,
        NgxEmojiPickerModule.forRoot(),
        NotifierModule.withConfig({
            theme: 'material',
            position: {
                horizontal: {
                    /**
                     * Defines the horizontal position on the screen
                     * @type {'left' | 'middle' | 'right'}
                     */
                    position: 'left',

                    /**
                     * Defines the horizontal distance to the screen edge (in px)
                     * @type {number}
                     */
                    distance: 12
                },
                vertical: {
                    /**
                     * Defines the vertical position on the screen
                     * @type {'top' | 'bottom'}
                     */
                    position: 'bottom',
                    /**
                     * Defines the vertical distance to the screen edge (in px)
                     * @type {number}
                     */
                    distance: 12
                    /**
                     * Defines the vertical gap, existing between multiple notifications (in px)
                     * @type {number}
                     */
                }
            }
        }),
        NgxCoolDialogsModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient],
            }
        }),
        AngularMultiSelectModule,
        PopoverModule.forRoot(),
        DragDropModule,
        AccordionModule
    ],

    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        P404Component,
        P500Component,
        LoginComponent,
        RegisterComponent,
        UnauthorisedAccessComponent,
        pageNotFoundComponent,
        EditorComponent,
        JazzDialog,
        GrdFilterPipe,
        TimeAgoPipe,
        ManageFeaturesComponent,
        // ArticlePersonalisedComponent
        // ContextMenuComponent,
        ArticlePersonalisedComponent,
        NextStageButtonsComponent,
        ReversePipePipe,
        CustomScrollDirective,
        ResizableDirective,
        // FormControlTxtComponent,

        // ContextMenuComponent
        // WorkflowListComponent
        Search,
        ControlPanelComponent,
        NotesComponent,
        RvcleanComponent,
        // MatDynamicFormBuildSettingsComponent
    ],

    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptorService, multi: true },
        { provide: UrlSerializer, useClass: MyUrlSerializer },
        InjectableGlobal,
        HttpClient,
        EnvServiceProvider,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        CollabService,
        ChatService,
        PageService,
        ToolbarService,
        EditService,
        SortService
    ],
    entryComponents: [JazzDialog, JoyrideStepComponent ],
    exports: [MatTableModule, MatSortModule, MatPaginatorModule],

    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
