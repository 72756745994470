import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { WorkflowService } from '../workflow.service';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { SharedService } from '../../shared/shared.service';
import { SelectionModel } from '@angular/cdk/collections';

class Template {
    _id: string;
    jnl_title: string;
    jnl_short_title: string;
    wfCode: string;
    wfName: string;
    status: boolean;
}

@Component({
    selector: 'app-workflow-list',
    templateUrl: './workflow-list.component.html',
    styleUrls: ['./workflow-list.component.scss']
})
export class WorkflowListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;

    // public displayedColumns = ['select', 'wf_name', 'wf_code', 'title', 'short_title', 'actions'];
    public displayedColumns = ['wf_name', 'wf_code', 'title', 'short_title', 'actions'];
    public dataSource = new MatTableDataSource<Template>();

    public selection = new SelectionModel<Template>(true, []);

    public journal_id: string;
    public workflow_code: string;
    // public search = 'No Data found';
    public totalLengthWorkflow = 5;

    public allselected: Boolean = false;

    delete_child = [];

    constructor(private workFlowSvs: WorkflowService,
        private router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        private notifier: NotifierService,
        private shared_service: SharedService,
        private dialog: MatDialog) { }


    ngOnInit() {
        this.getWorkflow();
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    // filter for search function
    public doFilter = (value: string) => {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }

    // list all workflows
    getWorkflow() {
        this.shared_service.showSpinner('workflowListSpinner');
        this.workFlowSvs.getWorkflowData().subscribe(
            res => {
                this.dataSource.data = res['workflow_data'] as Template[];
                this.shared_service.hideSpinner('workflowListSpinner');
            },
            err => {
                console.log(err);
                this.shared_service.hideSpinner('workflowListSpinner');
            }
        );
    }

    // delete workflow details
    deleteWorkflow(journal_id, wf_code) {
        this.workFlowSvs.deleteWorkflowSvs(journal_id, wf_code).subscribe(
            res => {
                if (res['status'] === 200) {
                    this.notifier.notify('success', this.translate.instant('manage.workflow.workflow_deleted_msg'));
                } else {
                    this.notifier.notify('error', 'Something went wrong !');
                }
                this.getWorkflow();
            },
            err => {
                console.log(err);
            }
        );
    }

    // multiple delete
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        if (numSelected === numRows) {
            this.allselected = true;
        } else {
            this.allselected = false;
        }
        return numSelected === numRows;
    }
    selectedDelete() {
        if (this.selection.selected.length) {
            const el: HTMLElement = this.myDiv.nativeElement;
            el.click();
        } else {
            this.notifier.notify('error', 'Please select some workflows');
        }
    }
    removeSelectedRows() {
        this.selection.selected.forEach(item => {
            this.workFlowSvs.deleteWorkflowSvs(item['_id']['$oid'], item['wf_code']).subscribe(
                res => {
                    // to hide the delete confirmation popup msg after delete
                    const element: HTMLElement = document.getElementsByClassName('close')[0] as HTMLElement;
                    element.click();
                    this.getWorkflow();
                },
                err => {
                    console.log(err);
                }
            );
        });

        if (this.selection.selected.length) {
            this.notifier.notify('success', 'Workflow deleted successfully');
        }
        this.selection = new SelectionModel<Template>(true, []);
    }



}
