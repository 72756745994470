import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../user.service';
import { formvalidator } from '../../form.validator';
import { NotifierService } from 'angular-notifier';
import { Router, NavigationStart, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { AbstractControl } from '@angular/forms';
import { DashboardService } from '../../dashboard.service';
import { UserrolesService } from '../../userroles/userroles.service'
import { UserTokenService } from '../../service/user-token.service';
import { InjectableGlobal } from '../../permissions'
import { EnvService } from '../../service/env.service';
import { TranslateService } from '@ngx-translate/core';
//import { UserTokenService } from '../../service/user-token.service';
@Component({
    selector: 'app-createuser',
    templateUrl: './createuser.component.html',
    styleUrls: ['./createuser.component.scss']
})

export class CreateuserComponent {
    toppings = new FormControl();
    toppingList: string[] = [];
    subscription: Subscription;
    public roles: any;
    route_segment: string;
    profileImageUrl: string;
    edit_user: any;
    topping;
    editFlagger: Boolean = false;
    buttonDisable: Boolean = false;
    constructor(private router: Router,
        private postsdata: UserService,
        private dashdata: DashboardService,
        notifierService: NotifierService,
        private route: ActivatedRoute,
        private userroleService: UserrolesService,
        private usertoken: UserTokenService,
        private env: EnvService,
        private InjectableGlobal: InjectableGlobal,
        public translate: TranslateService,

    ) {
        this.subscription = router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // console.log(router.navigated);
            }
        });

        this.notifier = notifierService;

    }
    // userRoles = userRoles;
    userRoles;
    private sub: Subscription;
    submitted = false;
    public editflag: boolean = true;
    public emailHide: Boolean = false;
    public flag: boolean = true;
    public preview_image: boolean = false;
    public pic_preview: boolean = true;
    public myModelProperty: any;
    public pageName: string = this.translate.instant('user.create_new');
    public buttonName: string = this.translate.instant('submit_button');
    public uploaded_files = this.translate.instant('choose_file_label');
    public current_users_menus;
    selectedFile = File;
    var: string;
    user: object;
    base_url: string;
    BACKUP_TOKEN: any;
    dropdownList = [];
    selectedroles: any = [];
    uniqueNames = []; selectedOption
    public selectedItems = [];
    dropdownSettings = {};
    private readonly notifier: NotifierService;
    salutation = [
        { name: 'Mr', value: 'Mr' },
        { name: 'Mrs', value: 'Mrs' },
        { name: 'Ms', value: 'Ms' },
    ];
    createuserForm = new FormGroup({

        firstName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+'), Validators.maxLength(50), this.noWhitespaceValidator]),
        middleName: new FormControl('', [Validators.maxLength(50)]),
        lastName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+'), this.noWhitespaceValidator]),
        userName: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9@]+'), this.noWhitespaceValidator], this.checkIfUsernameExists.bind(this)),
        email: new FormControl('', [Validators.required, Validators.email, this.noWhitespaceValidator], this.checkIfEmailExists.bind(this)),
        userRole: new FormControl(this.selectedItems, [formvalidator.ValidateUserrole]),
        userId: new FormControl(null),
        userrole_hidden: new FormControl(null),
        avatar: new FormControl(null),
    });

    public noWhitespaceValidator(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    onItemSelect(item: any) {
        this.selectedroles = $.map(item.value, function (value) {
            return [
                value._id
            ]
        })
    }
    onSelectAll(items: any) {
        items.filter((items) => { this.selectedroles.push(items._id) });
        this.selectedroles = this.selectedroles.filter(function (item, index, inputArray) {
            return inputArray.indexOf(item) == index;
        });
    }
    onItemDeSelect(items: any) {
        var index = this.selectedroles.indexOf(items._id);
        this.selectedroles.splice(index, 1);
    }
    onItemDeSelectAll(items: any) {
        this.selectedroles = [];
    }
    navigateTo() {
        this.router.navigate(['users']);
    }

    onSubmit() {
        var that = this;
        this.submitted = true;
        if (this.editflag == true) {
            this.createuserForm.asyncValidator
        }
        // stop here if form is invalid
        if (this.createuserForm.invalid) {
            return;
        }
        this.createuserForm.controls['userrole_hidden'].setValue(this.selectedroles);

        this.BACKUP_TOKEN = this.readLocalStorageValue('BACKUP_TOKEN');

        let input = new FormData();
        input.append('image', this.createuserForm.get('avatar').value);
        input.append('email', this.createuserForm.get('email').value);
        input.append('firstName', this.createuserForm.get('firstName').value);
        input.append('lastName', this.createuserForm.get('lastName').value);
        input.append('middleName', this.createuserForm.get('middleName').value);
        input.append('userId', this.createuserForm.get('userId').value);
        input.append('userName', this.createuserForm.get('userName').value);
        input.append('userRole', this.createuserForm.get('userRole').value);
        input.append('userrole_hidden', this.selectedroles);
        input.append('backup_token', this.BACKUP_TOKEN);
        var data = input;

        this.buttonDisable = true;
        this.postsdata.createUser(data).subscribe(data => {

            if (data['status'] == '200') {
                this.notifier.notify(data['message_type'], data['message']);
                if (this.flag === true || localStorage.getItem('user_active_tab') == '2') {
                    localStorage.setItem('tab_active', 'yes');
                }
                setTimeout(function () {
                    that.navigateTo();
                }, 1000);
            } else {
                this.notifier.notify(data['message_type'], data['message']);
                this.buttonDisable = false;
            }
        });

    }
    readLocalStorageValue(key: string): string {
        return localStorage.getItem(key);
    }
    //---- validation functions------//
    checkIfUsernameExists(control: AbstractControl) {
        var that = this;
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                that.dashdata.checkUsernameExists(control.value).subscribe(ret => {
                    if (ret != "0") {
                        resolve({ 'usernameIsTaken': true });
                    } else {
                        resolve(null);
                    }
                });
            }, 0)
        })
    }
    checkIfEmailExists(control: AbstractControl) {
        var that = this;
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                that.dashdata.checkEmailExists(control.value).subscribe(ret => {
                    if (ret != "0") {
                        resolve({ 'emailIsTaken': true });

                    } else {
                        resolve(null);
                    }
                });
            }, 0)
        })
    }
    get formfields() {
        return this.createuserForm.controls;
    }

    url = '';
    onFileChange(event) {
        this.preview_image = true;
        if (event.target.files.length > 0) {
            let file = event.target.files[0];
            this.createuserForm.get('avatar').setValue(file);
            this.uploaded_files = file.name;
        }
        // image preview before upload
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = (event: any) => { // called once readAsDataURL is completed
                this.url = event.target.result;
            }
        }
    }
    editusername;
    ngOnInit() {
        var that = this;
        this.base_url = this.env.baseUploadUrl;
        that.editflag = true;
        that.editusername = true;
        that.pic_preview = false;
        this.current_users_menus = JSON.parse(atob(localStorage.getItem('MENU_PERMISSIONS')));

        this.userroleService.getUserRolesForUsers(this.usertoken.getToken(), false).subscribe(data => {
            this.roles = data['data'];
            this.dropdownList = this.roles;
            this.toppingList = this.roles;
          //  this.selectedroles='Super Admin';
            //this.topping='Super Admin';
            
            this.dropdownSettings = {
                singleSelection: true,
                idField: '_id',
                textField: 'rl_role_name',
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                itemsShowLimit: 5,
                allowSearchFilter: true,
            };

            this.route.params.subscribe(params => {

                if (typeof (params.id) != "undefined") {
                    this.editFlagger = true;
                    this.postsdata.getUserdata(params.id).subscribe(
                        data => {
                            const resultarr = this.roles.filter((userrole) => {
                                return data['usr_roles'].indexOf(userrole['_id']) != -1
                            }
                            );
                          //  console.log(resultarr);
                            this.selectedroles = resultarr;
                            // this.selectedroles = data['usr_roles'];
                            this.flag = false;
                            that.editusername = false;
                            this.emailHide = true;
                            if (data['usr_image']) {
                                that.editflag = false;
                                that.pic_preview = true;
                            }

                            this.myModelProperty = data['usr_roles'];
                            this.createuserForm.controls['userName'].clearAsyncValidators();
                            this.createuserForm.controls['userName'].updateValueAndValidity();
                            this.pageName = this.translate.instant('edit');
                            this.buttonName = this.translate.instant('update_button');

                            this.profileImageUrl = this.env.baseProfileImageUrl;
                            this.edit_user = data;

                            // to hide email field while edit user details
                            const email = this.createuserForm.get('email');
                            if (this.emailHide === true) {
                                email.disable();
                            } else {
                                email.enable();
                            }

                            this.createuserForm.patchValue({
                                userRole: resultarr,
                                firstName: data['usr_first_name'],
                                middleName: [data['usr_middle_name'] !== 'null' ? data['usr_middle_name'] : ''],
                                lastName: data['usr_last_name'],
                                userName: data['usr_username'],
                                email: data['usr_email'],
                                userId: data['_id']

                            })
                        });
                }
            });
        }
        );
        //  });
    }


}