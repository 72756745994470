import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginModel } from './login-model'
import { Md5 } from 'ts-md5/dist/md5';
import { EnvService } from '../service/env.service';


@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(private http: HttpClient, private env: EnvService) {

    }

    login(username: string, password: any): Observable<LoginModel> {
        const md5 = new Md5();
        // tslint:disable-next-line:max-line-length
        password = md5.appendStr(password).end();
        return this.http.post<LoginModel>(this.env.baseAPIUrl + '/auth/login', { "usr_username": username, "usr_pwd": password });
    }
    // check user is valid/token
    checktokenuser(token: string): Observable<LoginModel> {
        return this.http.post<LoginModel>(this.env.baseAPIUrl + '/auth/check_token_user', JSON.stringify({ token: token }));
    }

    logout(): Observable<LoginModel> {
        return this.http.get<LoginModel>(this.env.baseAPIUrl + '/user/remove_token');
    }

    logout_all(): Observable<any> {
        return this.http.get<any>(this.env.baseAPIUrl + '/user/logout_all');
    }

    logout_selected(token: string): Observable<any> {
        return this.http.post<any>(this.env.baseAPIUrl + '/user/logout_selected', { token: token });
    }

    getProfile(): Observable<any> {
        return this.http.get<any>(this.env.baseAPIUrl + '/user/get_user_data');
    }

    get_proxy_login_roles(): Observable<any> {
        return this.http.get<any>(this.env.baseAPIUrl + '/dashboard/getAllUsersForProxy');
    }

    proxy_login(user_id: any): Observable<any> {
        return this.http.post<any>(this.env.baseAPIUrl + '/proxy_login', { "user_id": user_id });
    }

    getUserActivity(): Observable<LoginModel> {
        return this.http.get<LoginModel>(this.env.baseAPIUrl + '/user/get_user_activity');
    }

    forgotPassword(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/forgotPassword', data);
    }

    updateEditorTour(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/update-editor-tour', data);
    }


}
