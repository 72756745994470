import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from "@angular/forms";
import { articleUploadsService } from '../articleupload.service';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { UserTokenService } from '../../service/user-token.service';
import { NotifierService } from "angular-notifier";
import { Router, ActivatedRoute } from "@angular/router";
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { EnvService } from '../../service/env.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-editarticleupload',
  templateUrl: './editarticleupload.component.html',
  styleUrls: ['./editarticleupload.component.scss']
})
export class EditarticleuploadComponent implements OnInit {

  
  updateArticleUpload: FormGroup;
  articleName = '';
  articleCode = '';
  articleId = '';
  hdnArticleFile = '';
  submitted = false;
  ziperror_valid = false;
  ziperror = false;
  editFlag = true;
  article_exists = false;
  article_status;
  article_id;
  article_folder;
  folder_show =true;
  new_status;
  private readonly notifier: NotifierService;
  nodes;
  options = {};

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private articleUploadsService: articleUploadsService,
    private http: HttpClient,
    private UserTokenService: UserTokenService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    notifierService: NotifierService
  ) {
    this.notifier = notifierService;
  }
  public popoverTitle: string = '';
  public popoverMessage_activate: string = this.translate.instant('articles.popover_message_activate');
  public popoverMessage_deactivate: string = this.translate.instant('articles.popover_message_deactivate');
  public uploaded_files = this.translate.instant('choose_file_label');
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.articleUploadsService.getArticlebyId(params.id).subscribe(
        response => {
          this.uploaded_files = response['result']['article_zip_name'];
          this.article_status = response['result']['article_status'];
          this.article_id = response['result']['_id'];
          this.nodes = response['result']['article_children'];
          this.article_folder = response['result']['article_file'];
          if (response['status'] == 200) {
            this.uploaded_files = response['result']['article_zip_name'];
            this.updateArticleUpload.patchValue({
              articleId: response['result']['_id'],
              articleName: response['result']['article_name'],
              articleCode: response['result']['article_code']
            });
          } else {
            this.notifier.notify(response['message_type'], response['message']);
          }
        },
        error => {
          this.notifier.notify('error', this.translate.instant('error_occured_msg'));
        }
      );

      this.updateArticleUpload = this.formBuilder.group({
        articleId: new FormControl(''),
        articleName: new FormControl('', [Validators.required]),
        articleCode: new FormControl('', [Validators.required]),
        hdnArticleFile: new FormControl(''),
      });
    });
  }
  get formfields() {
    return this.updateArticleUpload.controls;
  }
  onSubmit() {
    this.submitted = true;
    // if (this.updateArticleUpload.invalid || this.article_exists) {
    //   return;
    // }
    const formData = new FormData();
    formData.append('article_name', this.updateArticleUpload.get('articleName').value);
    formData.append('article_code', this.updateArticleUpload.get('articleCode').value);
    formData.append('article_id', this.updateArticleUpload.get('articleId').value);
    formData.append('article_file', this.updateArticleUpload.get('hdnArticleFile').value);
    formData.append('article_folder',this.article_folder);

    
    this.articleUploadsService.updateArticle(formData).subscribe(
      response => {
        if (response['status'] === '200') {
          this.notifier.notify(response['message_type'], response['message']);
          //this.articleList();
          this.router.navigate(['/article-upload'])

        } else {
          this.notifier.notify(response['message_type'], response['message']);
        }
      },
      error => {
        this.notifier.notify('error', this.translate.instant('error_occured_msg'));
      }
    );
  }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      if(file.type == 'application/zip')
      {
        this.ziperror_valid = false;
        this.ziperror = false;
        this.folder_show = false;
        this.uploaded_files = file.name;
        this.updateArticleUpload.get('hdnArticleFile').setValue(file);
      }
      else
      {
        this.ziperror_valid = true;
        this.ziperror = true;
      }
      
    }
  }
  changeStatus(element_id,status){
    if(status == true)
        this.new_status = false;
      else
        this.new_status = true;
    this.articleUploadsService.changeArticleStatus(element_id,this.new_status).subscribe(
      response => { 
        this.article_status = status;
        if (response['status'] === '200') {
          this.notifier.notify(response['message_type'], response['message']);
        } else {
          this.notifier.notify(response['message_type'], response['message']);
        }
      },
       error => {
        this.notifier.notify('error', this.translate.instant('error_occured_msg'));
       }
      
    )
  }
  articleCode_check(value)
  {
    if(value != ''){
      this.articleUploadsService.articleCodecheck(value,this.article_id).subscribe(
        response => {
          if (response['status'] === 400) {
            this.article_exists = true;
            this.notifier.notify(response['message_type'], response['message']);
          } else {
            this.article_exists = false;
          } 
        }, 
        error => {
          this.notifier.notify('error', this.translate.instant('error_occured_msg'));
        }
      );
    }
  }
}
