import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'rvl-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
    @Input() note: any;
    @Input() myNote: any;

    @Output() emitEditNote = new EventEmitter<any>();
    @Output() emitDeleteNote = new EventEmitter<any>();

    constructor() { }

    ngOnInit() {
    }

    trgEditNote(noteId) {
        this.emitEditNote.emit({ noteId: noteId });
    }

    trgDeleteNote(noteId, articleId, stageId) {
        this.emitDeleteNote.emit({ noteId: noteId, articleId: articleId, stageId: stageId });
    }

    /**
     * function to change z-index of note div, to make it visible while clicking
     * @param noteId: id of the note div
     */
    changeNoteZindex(noteId) {
        $('.example-box').css('z-index', 7);
        $("#" + noteId).css('z-index', 999);
    }
}
