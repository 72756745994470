import { Injectable } from '@angular/core';
import { ValueTransformer } from '@angular/compiler/src/util';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { EnvService } from '../service/env.service';
import { stringify } from '@angular/core/src/util';
import { saveAs } from 'file-saver';
import { isJsObject } from '@angular/core/src/change_detection/change_detection_util';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class EditorService {

    constructor(private http: HttpClient, private env: EnvService) { }
    

    getFolderStructure(data) {
        return <any>this.http.post(this.env.baseAPIUrl + '/get-projects', data);
    }

    get_all_tex_files(data) {
        var jsonObject = {};
        jsonObject['zip'] = data;
        return <any>this.http.post(this.env.baseAPIUrl + '/get_all_tex_files', jsonObject);
    }

    addNode(node) {

        return <any>this.http.post(this.env.baseAPIUrl + '/add-node', node);
    }
    fileSave(nodeid, datas) {
        var jsonObject = {};
        jsonObject['node'] = nodeid;
        jsonObject['data'] = datas;

        return <any>this.http.post(this.env.baseAPIUrl + '/file-save', jsonObject);
    }

    renameNode(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/rename-node', data);
    }

    deleteNode(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/delete-node', data);
    }

    getArticle(nodevalues, nodeid, nodepath, commentPath) {
        var jsonObject = {};
        jsonObject['nodevalue'] = nodevalues;
        jsonObject['nodeid'] = nodeid;
        jsonObject['path'] = nodepath;
        jsonObject['commentPath'] = commentPath;

        return this.http.post<any>(this.env.baseAPIUrl + '/get_tex_file', jsonObject);

    }
    getAllArticle(nodevalue) {

        return this.http.post<any>(this.env.baseAPIUrl + '/get_article_file', nodevalue);
    }
    /**
     * api call for pdf/xml generation
     * @returns Generated xml/pdf path
     * @author Yadu Chandran
     * modyfied: vishnu. passed postdata as object from ts
    **/
    generatePDFXML(data) {
        // var jsonObject = {};
        // jsonObject['nodedata'] = nodedata;
        // jsonObject['fileName'] = compilingFileName;
        // jsonObject['compilerName'] = compilerName;
        // jsonObject['compilerEngine'] = compilerEngine;
        // jsonObject['package'] = journalPackage;
        // jsonObject['type'] = type;
        return this.http.post<any>(this.env.baseAPIUrl + '/generate_pdf_xml', data);
    }
    createArticle(articleData) {
        return this.http.post<any>(this.env.baseAPIUrl + '/create-article', articleData);
    }
    downloadPdf(src) {
        return this.http.post<any>(this.env.baseAPIUrl + '/download_pdf', src);
    }
    saveArticle(value, article, nodepath, nodeid, nodepaths, comments) {
        var jsonObject = {};

        jsonObject['article'] = article;
        jsonObject['value'] = value;
        jsonObject['path'] = nodepath;
        jsonObject['nodeid'] = nodeid;
        jsonObject['nodepath'] = nodepaths;
        jsonObject['comments'] = comments;
        return this.http.post<any>(this.env.baseAPIUrl + '/save_article', jsonObject);
    }
    getActiveFiles(activeDetails) {
        return <any>this.http.post(this.env.baseAPIUrl + '/get-active-files', activeDetails);
    }
    download(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/download', data);
    }
    saveSnippet(value) {

        var jsonObject = {};
        jsonObject['value'] = value;
        return this.http.post<any>(this.env.baseAPIUrl + '/save_snippet', jsonObject);
    }
    forwardSync(line, column, nodedata, path) {
        return this.http.post<any>(this.env.baseAPIUrl + '/forward_sync', { line: line, column: column, nodedata: nodedata, path: path });
    }

    backwardSync(x, y, page, nodedata, path_name) {
        return this.http.post<any>(this.env.baseAPIUrl + '/backward_sync', { x: x, y: y, page: page, nodedata: nodedata, path_name: path_name });
    }
    saveRvCleanPatterns(value) {
        return this.http.post<any>(this.env.baseAPIUrl + '/save-rv-clean-patterns', value);
    }
    addSnippet(snippetData) {
        return this.http.post<any>(this.env.baseAPIUrl + '/add-snippet', snippetData);
    }
    snippetList() {
        return <any>this.http.get(this.env.baseAPIUrl + '/get_snippet_list');
    }
    updateSnippet(snippetData) {
        return this.http.post<any>(this.env.baseAPIUrl + '/update_snippet', snippetData);
    }
    removeSnippet(snippetData) {
        var jsonObject = {};
        jsonObject['id'] = snippetData;
        return this.http.post<any>(this.env.baseAPIUrl + '/remove_snippet', jsonObject);
    }
    getSnippet(snippetData) {
        var jsonObject = {};
        jsonObject['id'] = snippetData;
        return this.http.post<any>(this.env.baseAPIUrl + '/get_snippet', jsonObject);
    }

    addComment(commentData) {
        return this.http.post<any>(this.env.baseAPIUrl + '/add-comment', commentData);
    }

    replyMessage(replayMessage) {
        return this.http.post<any>(this.env.baseAPIUrl + '/reply-message', replayMessage);
    }

    resolveComment(commentDetails) {
        return this.http.post<any>(this.env.baseAPIUrl + '/resolve-comment', commentDetails);
    }
    deleteComment(commentId) {
        return this.http.post<any>(this.env.baseAPIUrl + '/comment-delete', commentId);
    }

    getResolvedComments(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/get-resolved-comments', data);
    }

    checkDuplicateArticle(articleCode) {
        return this.http.post<any>(this.env.baseAPIUrl + '/check-duplicate-article', articleCode);
    }

    fileUpload(fileData) {
        return this.http.post<any>(this.env.baseAPIUrl + '/upload-file', fileData);
    }
    jnlShareSave($data) {

        return this.http.post(this.env.baseAPIUrl + '/jnlShareSave', $data);
    }

    validateUploadingFile(file) {
        return this.http.post(this.env.baseAPIUrl + '/file-validation', file);
    }

    getAllRVCleanPatterns(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/get-all-patterns', data);
    }

    getAllowedFiles(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/get-allowed-files', data);
    }

    getAllUsers() {
        return this.http.get<any>(this.env.baseAPIUrl + '/dashboard/getAllUsers');
        // return this.http.post<any>(this.env.baseAPIUrl + '/get-all-receiver', data);
    }
    getMessage(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/get-message', data);
    }
    sendMessage(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/send-message', data);
    }

    updateFileName(id, name) {
        const jsonObject = {};
        jsonObject['id'] = id;
        jsonObject['filename'] = name;
        return this.http.post<any>(this.env.baseAPIUrl + '/update-file-name', jsonObject);
    }

    collabrationShare(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/collabrationLink', data);
    }
    updateNotification(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/update-notification', data);
    }
    getNotifications(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/get-notifications', data);
    }
    getSharedUsers(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/get-shared-users', data);
    }
    guestInvitation(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/guest-user', data);
    }
    guestUserAdd(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/guest-user-add', data);
    }
    getJournalPackageData(id) {
        return this.http.get<any>(this.env.baseAPIUrl + '/get-journal-package/' + id);
    }
    getChatReceiver(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/get-all-receiver', data);
    }

    backup(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/take-backup', data);
    }

    getBackupList(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/get-backup-list', data);
    }

    revertToVersion(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/revert-to-version', data);
    }

    getBackupFile(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/get-backup-file', data);
    }

    getBackup(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/get-backup', data);
    }

    checkBackupExist(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/check-backup-exist', data);
    }

    downloadBackup(data) {
        return this.http.post(this.env.baseAPIUrl + '/backup-download', data, { responseType: 'arraybuffer' });
    }

    deleteBackup(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/backup-delete', data);
    }

    downloadAllBackup(data) {
        return this.http.post(this.env.baseAPIUrl + '/download-all-backup', data, { responseType: 'arraybuffer' });
    }

    deleteAllBackup(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/delete-all-backup', data);
    }

    editBackup(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/edit-backup', data);
    }

    removeTempBackup(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/remove-temp-backup', data).subscribe(
            response => { }
        );
    }

    downloadFile(datas, name) {
        const jsonObject = {};
        jsonObject['value'] = datas;
        jsonObject['name'] = name;
        return this.http.post<any>(this.env.baseAPIUrl + '/downloadFile', jsonObject);
    }

    getStyleGuide(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/get-style-guide', data);
    }

    createCleaningTemplate(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/create-cleaning-template', data);
    }

    cleaningTemplateList() {
        return this.http.get<any>(this.env.baseAPIUrl + '/cleaning-template-list');
    }

    deleteCleaningTemplate(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/delete-template', data);
    }

    updatetemplate(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/update-template', data);
    }

    getCleaningTemplateByJournal(data) {
        return this.http.get<any>(this.env.baseAPIUrl + '/get-cleaning-templates-by-journal/' + data);
    }

    getJournals() {
        return this.http.get<any>(this.env.baseAPIUrl + '/get-journal-list');
    }

    addBookmark(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/add-bookmark', data);
    }

    getBookmarks(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/get-bookmarks', data);
    }
    deleteBookmark(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/delete-bookmark', data);
    }

    extractZipSvs(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/extract-zip', data)
    }

    updateArticleTreeSvs(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/update-article-tree', data)
    }

    downloadCompiledOutputSvs(data) {
        return this.http.post(this.env.baseAPIUrl + '/download-compiled-output', data, { responseType: 'arraybuffer' })
    }

    comparePdf(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/comparePdf', data)
        // return this.http.post<any>('http://localhost:5000/comparePdf', 'hello');
    }

    sharePatternChanges(data){
        return this.http.post<any>(this.env.baseAPIUrl + '/share-pattern-changes', data);
    }

    saveAutoSaveSettingsSvs(data) {
        return this.http.post(this.env.baseAPIUrl + '/save-auto-save-settings', data)
    }
    getAutoSaveSettingsSvs() {
        return this.http.get(this.env.baseAPIUrl + '/get-auto-save-settings')
    }
    saveCurrentPdf(data){
        return this.http.post<any>(this.env.baseAPIUrl + '/saveCurrentPdf', data);
    }
    //reference cleaning starts

    createCleaningTemplateReference(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/create-cleaning-template-reference', data);
    }


    cleaningReferenceTemplateList() {
        return this.http.get<any>(this.env.baseAPIUrl + '/cleaning-reference-template-list');
    }


    updatereferencetemplate(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/update-reference-template', data);
    }


    deletereferencetemplate(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/delete-reference-template', data);
    }

    getCleaningReferenceByJournal(data) {
        return this.http.get<any>(this.env.baseAPIUrl + '/get-cleaning-reference-by-journal/' + data);
    }
    //refernce cleaning ends
    completeMergeSvs(data){
        return this.http.post<any>(this.env.baseAPIUrl + '/complete-merge', data)
    }

    saveSharedCopyToMergeSvs(data){
        return this.http.post<any>(this.env.baseAPIUrl + '/save-shared-copy-to-merge', data)
    }

    getFigureOptionsSvs(data){
        return this.http.get<any>(this.env.baseAPIUrl+'/get-figure-options/'+data)
    }

    getFigureApiSvs(data){
        return this.http.post<any>(this.env.baseAPIUrl+'/get-figure-api',data)
    }

    upsertReferencePatterns(referenceType, value) {
        return this.http.put<any>(this.env.baseAPIUrl + '/reference-patterns/' + referenceType, value);
    }

    upsertParameterForReferencePatterns(referenceType, parameter_id, value) {
        return this.http.put<any>(this.env.baseAPIUrl + '/reference-patterns/' + referenceType + '/parameters/' + parameter_id, value);
    }

    getReferencePatternsByReferenceType(referenceType: string) {
        return this.http.get<any>(this.env.baseAPIUrl + '/reference-patterns/' + referenceType);
    }

    getReferencePatterns() {
        return this.http.get<any>(this.env.baseAPIUrl + '/reference-patterns');
    }

    removeReferencePatterns(referenceType, parameter_id, value) {
        return this.http.delete<any>(this.env.baseAPIUrl + '/reference-patterns/' + referenceType + '/parameters/' + parameter_id, value);
    }

    getReferenceOrderByReferenceType(referenceType: string) {
       // console.log(referenceType)
        return this.http.get<any>(this.env.baseAPIUrl + '/reference-patterns/' + referenceType);
    }
    // getFiles(data){
    //     console.log(data);
    //     return <any>this.http.post(this.env.baseAPIUrl + '/get-files', data); 
    // }
}
