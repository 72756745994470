import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../service/env.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PackageService {

    constructor(private http: HttpClient, private env: EnvService) { }

    getPackageDetails() {
        return this.http.get<any>(this.env.baseAPIUrl + '/get-package-details');
    }

    getJournals() {
        return this.http.get<any>(this.env.baseAPIUrl + '/get-journal');
    }

    addPackage(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/add-package', data);
    }

    checkPackageExist(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/check-package-exist', data);
    }

    validatingPackage(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/validate-package', data);
    }

    deletePackage(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/delete-package', data);
    }

    getPackageDetailsById(packageId) {
        return this.http.post<any>(this.env.baseAPIUrl + '/get-package-by-id', packageId);
    }

    updatePackage(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/update-package', data);
    }

    getAllowedFiles() {
        return this.http.get(this.env.baseAPIUrl + '/get-allowed-package-files');
    }

    saveCompilingOptionsSvs(data) {
        return this.http.post(this.env.baseAPIUrl + '/save-compiling-options', data)
    }

    downloadPackageSvs(data) {
        return this.http.post(this.env.baseAPIUrl + '/download-package', data, { responseType: 'arraybuffer' });
    }
}
