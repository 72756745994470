import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../service/env.service';

@Injectable({
    providedIn: 'root'
})
export class UserrolesService {
    constructor(private http: HttpClient, private env: EnvService) { }
    createRole($data,$value) {
         var jsonObject = {};
        jsonObject['data'] = $data;
         jsonObject['value'] = $value;
       // console.log($value);
        return this.http.post(this.env.baseAPIUrl + '/create_user_role', jsonObject);
        
    }
    getUserroleData($data) { 
        return this.http.get(this.env.baseAPIUrl + '/getUserroleData/' + $data);
    }
    change_rolestatus($roleid: string, $status: string) {
        var jsonObject = {};
        jsonObject['roleid'] = $roleid;
        jsonObject['status'] = $status;
        return this.http.post(this.env.baseAPIUrl + '/change_rolestatus', jsonObject);
    }
    checkUserRoleExists($value: string, $roleid: string) {
        var jsonObject = {};
        jsonObject['role_name'] = $value;
        jsonObject['role_id'] = $roleid;
        return this.http.post(this.env.baseAPIUrl + '/checkIfUserRoleExists', jsonObject);
    }
    checkRoleshortnameExists($value: string, $roleid: string) {
        var jsonObject = {};
        jsonObject['role_shortname'] = $value;
        jsonObject['role_id'] = $roleid;
        return this.http.post(this.env.baseAPIUrl + '/checkRoleshortnameExists', jsonObject);
    }

    getUserRolesRecursively($data, includeCurrentUser) {
        return this.http.post(this.env.baseAPIUrl + '/getUserRolesRecursively', { token: $data, 'includeCurrentUser': includeCurrentUser });
    }
    getUserRolesForUsers($data, includeCurrentUser) {
        return this.http.post(this.env.baseAPIUrl + '/getUserRolesForUsers', { token: $data, 'includeCurrentUser': includeCurrentUser });
    }
    getSubUserList() {
        return this.http.get(this.env.baseAPIUrl + '/getSubUserList');
    }
    delete_userrole($data) {
        return this.http.get(this.env.baseAPIUrl + '/delete_userrole/' + $data);
    }
    activate_rolestatus($roleid: string) {
        var jsonObject = {};
        jsonObject['roleid'] = $roleid;
        return this.http.post(this.env.baseAPIUrl + '/activate_rolestatus', jsonObject);
    }
    deactivate_rolestatus($roleid: string) {
        var jsonObject = {};
        jsonObject['roleid'] = $roleid;
        return this.http.post(this.env.baseAPIUrl + '/deactivate_rolestatus', jsonObject);
    }
    loggedUser() {
        return this.http.get(this.env.baseAPIUrl + '/user/get_user_data');
    }
    getrole_delete($data) {
        return this.http.get(this.env.baseAPIUrl + '/getrole_delete/' + $data);
    }
    allRoles_delete() {
        return this.http.get(this.env.baseAPIUrl + '/allRoles_delete');
    }
    show_deleteRole($data) {
        return this.http.get(this.env.baseAPIUrl + '/show_deleteRole/' + $data);
    }
    getUserRoles($data = null) {
        return this.http.post(this.env.baseAPIUrl + '/getUserRoles', { token: $data });
    }
    get_userroles($data) {
        return this.http.post(this.env.baseAPIUrl + '/get_userroles', $data);
    }
    getRoleNames() {
        return this.http.get(this.env.baseAPIUrl + '/getRoleNames');
    }
    getPreviliegeSvs($data) {
        return this.http.post(this.env.baseAPIUrl + '/get-previliege', $data);
    }
    get_allPrivilages() {
        return this.http.get(this.env.baseAPIUrl + '/get_allPrivilages');
    }
    privilages_edit($data) {
        return this.http.get(this.env.baseAPIUrl  + '/privilages_edit/'+ $data);
    }
    /******* manage feature *************/

    getmanageFeatureKey(){
        return this.http.get(this.env.baseAPIUrl + '/getmanageFeatureKey');
    }
    getFeatures() {
        return this.http.get(this.env.baseAPIUrl + '/getFeatures');
    }
    getFeaturedata($data) {
        return this.http.get(this.env.baseAPIUrl + '/getfeatureData/' + $data);
    }
    manageFeature($data) {
        return this.http.post(this.env.baseAPIUrl + '/create_feature', $data);
    }
    deleteFeaturedata($data) {
        return this.http.get(this.env.baseAPIUrl + '/deletefeatureData/' + $data);
    }   
}
