import { AbstractControl } from '@angular/forms';
import { DashboardService } from './dashboard.service';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';

export class formvalidator {
    constructor(control: AbstractControl, private data: DashboardService) { }
    static ValidatePhone(control: AbstractControl) {
        if (isNaN(control.value)) {
            return {
                phonevalid: true
            }
        }   else {
            return null;
        }
    }
    static ValidateUserrole(control: AbstractControl) {
        return null;
    }
    static ValidateDomain(control: FormControl) {
        if (isNaN(control.value)) {
            // domainvalid: true;
        } else {
            return null;
        }
    }

    /**
     * @author Anoop Sankar
     * @param control 
     * @description Validate domain and IP address together. Used in FTP Details page.
     * If the domain_or_ip field is selected to 'IP Address' then  the validation is done for IP Address.
     * Otherwise the validation will be for Domain
     */
   static validateDomainAndIP: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
        let domain_or_ip = formGroup.controls.domain_or_ip.value;
        let domain = formGroup.controls.domain_name.value;
        let returnValue = null;
        if (domain_or_ip === 'domain') {
            if (domain == null || domain == '') {
                formGroup.controls.domain_name.setErrors({enterDomain: true});
                returnValue = {
                    enterDomain: true
                };
            } else if (!/^(www\.){0,1}([a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1})$/.test(domain)) {
                formGroup.controls.domain_name.setErrors({invalidDomain: true});
                returnValue = {
                    invalidDomain: true
                };
            }
        } else if (domain_or_ip === 'ip') {
            if (domain == null || domain == '') {
                formGroup.controls.domain_name.setErrors({enterIP: true});
                returnValue = {
                    enterIP: true
                };
            } else if (!/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?){1}$/.test(domain)) {
                formGroup.controls.domain_name.setErrors({invalidIP: true});
                returnValue = {
                    invalidIP: true
                };
            }
        }
        return returnValue;
    };
}