import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.css']
})
export class SharedComponent implements OnInit {

  constructor(public translate: TranslateService,  public sharedService: SharedService) {
    translate.addLangs(['gb', 'fr', 'es' , 'ae']);

    const  selectedLang = this.sharedService.getLanguage();
    if (selectedLang === undefined || selectedLang === '' || selectedLang === null) {
      translate.setDefaultLang('gb');
      this.sharedService.setLanguage('gb');
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/gb|fr|es|ae/) ? browserLang : 'gb');
    } else {
      translate.setDefaultLang(selectedLang);
      translate.use(selectedLang.match(/gb|fr|es|ae/) ? selectedLang : 'gb');
    }
  }

  ngOnInit() {
  }

}
