import * as io from 'socket.io-client';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../service/env.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })

export class ChatService {

    private url = 'http://localhost:3000';
    private socket;

    constructor(private http: HttpClient, private env: EnvService){
        // this.socket = io(this.url);
    }

    // connecting to chat server in the port 3000
    connectChatServer(){
        this.socket = io.connect(this.env.realtimeServer, {secure: true});
    }

    // register user. sending user id
    registerUser(userId){
        this.socket.emit('register-user',userId);
    }

    // requesting online users
    requestingOnlineUsers(){
        this.socket.emit('get-online-users');
    }

    // get message 
    getMessage = () => {
        return Observable.create((observer) => {
            this.socket.on('reply-message', (message) => {
                observer.next(message);
            });
        });
    }
    // get invitation 
    getInvitation = () => {
        return Observable.create((observer) => {
            this.socket.on('reply-invitation', (message) => {
                observer.next(message);
            });
        });
    }

    // get online users
    getOnlineUsers = () => {
        return Observable.create((observer) => {
            this.socket.on('online-users', (users) => {
                observer.next(users);
            })
        })
    }

    // listen for updated user status
    getUpdatedStatus = () => {
        return Observable.create((observer) => {
            this.socket.on('change-user-status', data => {
                observer.next(data);
            })
        })
    }

    // send message
    notifySendMessage(messageData){
        this.socket.emit('send-message',messageData);
    }
    // send invitation
    notifySendInvitation(invitationData){
        this.socket.emit('send-invitation', invitationData);
    }

    // logout
    logout(){
        this.socket.emit('logout');
    }

    // Send message to backend
    sendMessage(data){
        return this.http.post<any>(this.env.baseAPIUrl + '/send-message',data);
    }

    // send invitation
    sendInvitation(data){
        return this.http.post<any>(this.env.baseAPIUrl + '/send-invitation', data);
    }

    // Validating the file for sending 
    validateUploadingFileChat(file) {
        return this.http.post<any>(this.env.baseAPIUrl + '/file-validation-chat', file);
    }

    // send file 
    sendFile(data){
        return this.http.post<any>(this.env.baseAPIUrl + '/send-file',data);
    }

    // get unread messages
    getUnreadMessage(data){
        return this.http.post<any>(this.env.baseAPIUrl + '/get-unread-message', data);
    }

    // change message read status
    changeMessageReadStatus(data) {
        return this.http.post<any>(this.env.baseAPIUrl + '/change-message-status', data);
    }

    changeAllMessageReadStatus(data){
        return this.http.post<any>(this.env.baseAPIUrl + '/change-all-message-status',data);
    }

    checkSpelling(data){
        this.socket.emit('check-spelling',data);
    }

    getCheckSpelling = () => {
        return Observable.create((observer) => {
            this.socket.on('check-spelling', data => {
                observer.next(data);
            });
        });
    }

    getSuggestion = () => {
        return Observable.create((observer) => {
            this.socket.on('suggestion', data => {
                observer.next(data);
            });
        });
    }

    checkSuggestion(data){
        this.socket.emit('get-suggestion',data);
    }
}