import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../service/env.service';
@Injectable({
  providedIn: 'root'
})
export class StagesService {

  constructor(private http: HttpClient, private env: EnvService) { }
  getall_userroles() {
    return this.http.get(this.env.baseAPIUrl + '/getall_userroles');
  }
  getall_emailtemplates() {
    return this.http.get(this.env.baseAPIUrl + '/getall_emailtemplates');
  }
  getall_stages($data) {
    return this.http.post(this.env.baseAPIUrl + '/getall_stages', $data);
  }
  create_stage($data) {
    return this.http.post(this.env.baseAPIUrl + '/create_stage', $data);
  }
  delete_stage($id) {
    return this.http.get(this.env.baseAPIUrl + '/delete_stage/' + $id);
  }
  stages_edit($data) {

    return this.http.get(this.env.baseAPIUrl + '/stages_edit/' + $data);
  }
  checkall_stages() {
    return this.http.get(this.env.baseAPIUrl + '/checkall_stages');
  }
  getUserroleData($data) {
    return this.http.get(this.env.baseAPIUrl + '/getUserroleData/' + $data);
  }
  // checking for stage code is existing in db
  checkIfStageCodeExists($data) {
    return this.http.get(this.env.baseAPIUrl + '/checkIfStageCodeExists/' + $data);
  }
  get_EmailTemplate($data) {
    return this.http.get(this.env.baseAPIUrl + '/get_EmailTemplate/' + $data);
  }

  getFirstStageSvs() {
    return this.http.get<any>(this.env.baseAPIUrl + '/get-first-stage')
  }

}
