import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { ArticleService } from '../article/article.service';
import { UserTokenService } from '../service/user-token.service';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { ArticlePersonalisedComponent } from '../article/article-personalised/article-personalised.component';
import { SharedService } from '../shared/shared.service';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl } from '@angular/forms';
import { UserService } from '../users/user.service';

@Component({
    selector: 'app-next-stage-buttons',
    templateUrl: './next-stage-buttons.component.html',
    styleUrls: ['./next-stage-buttons.component.scss'],
    providers: [ArticlePersonalisedComponent]
})
export class NextStageButtonsComponent implements OnInit {

    @Input() jnlCode: any;
    @Input() workflowCode: any;
    @Input() articleId: any;
    @Input() crntStageId: any;
    @Input() crntStage: any;
    @Input() childStages: any;
    @Input() actionFrom: any;
    @Input() metaData: any;
    @Input() shared: any;
    @Input() articleFile: any
    @Input() mainDocument: any
    @Input() allShared: any
    @Input() sharedId: any
    @Input() crntStaegUser: any

    @Output() emitFileDiff = new EventEmitter<any>();
    @Output() emitGetSharedArticles = new EventEmitter<any>();

    constructor(
        private shared_service: SharedService,
        private articleService: ArticleService,
        private usertoken: UserTokenService,
        private notifier: NotifierService,
        private translate: TranslateService,
        private articlePersonalised: ArticlePersonalisedComponent,
        private router: Router,
        private userService: UserService,
    ) { }

    ngOnInit() { }

    public currentLoginedUserId = localStorage.getItem('current_logined_userId')
    privileges = JSON.parse(atob(localStorage.getItem('PRIVILEGES')));
    public disableMoveNextStage: boolean = false;
    public moveNextStage(jnlCode: any, workflowCode: any, articleId: any, stageId: any, crntStageId: any, nextStageId: any, actionFrom: any, metaData: any) {
        this.disableMoveNextStage = true;
        var postData = {
            user_token: this.usertoken.getToken(),
            journal_code: jnlCode,
            workflow_code: workflowCode,
            article_id: articleId,
            stage_id: stageId,
            current_stage_id: crntStageId,
            next_wf_stage_id: nextStageId,
            action: 'completion'
        }

        this.articleService.moveNextStageSvs(postData).subscribe(
            response => {
                if (response.status === 200) {
                    switch (actionFrom) {
                        case 'editor-stage-move':
                            metaData.hide();
                            setTimeout(() => {
                                this.router.navigate(['/my-articles'])
                            }, 1500);
                            break;
                        case 'article-list':
                            this.shared_service.valueChanged.emit(this.articlePersonalised.getMyArticles(actionFrom));
                            break;
                    }
                    this.notifier.notify('success', this.translate.instant('manage.stages.move_next_stage_success') + "'" + response.next_stage_name + "'");
                }
                else if(response.status == 'failed'){
                    this.shared_service.hideSpinner('articlePersonalisedSpinner');
                    this.notifier.notify('warning', this.translate.instant('manage.stages.move_next_stage_failed_shared_incomplete'));
                    this.disableMoveNextStage = false;
                }
            }
        )
    }

    public markComplete(jnlCode: any, workflowCode: any, articleId: any, stageId: any, crntStageId: any, actionFrom: any, metaData: any) {
        let postData = {
            user_token: this.usertoken.getToken(),
            journal_code: jnlCode,
            workflow_code: workflowCode,
            article_id: articleId,
            stage_id: stageId,
            current_stage_id: crntStageId,
            actionFrom: actionFrom,
        }

        this.articleService.markCompleteSvs(postData).subscribe(response => {
            if (response.status === 200) {
                switch (actionFrom) {
                    case 'editor-stage-move':
                        metaData.hide();
                        setTimeout(() => {
                            this.router.navigate(['/my-articles'])
                        }, 1500);
                        break;
                    case 'article-list':
                        this.shared_service.valueChanged.emit(this.articlePersonalised.getMyArticles(actionFrom));
                        break;
                }
                this.notifier.notify('success', this.translate.instant('manage.stages.complete_success'));
            }
        })
    }

    public triggerFileDiff(jnlCode,workflowCode,articleId,wf_stage_id,crntStageId,actionFrom,metaData,sharedId,articleFile,mainDocument){
        let params = {
            jnlCode: jnlCode,
            workflowCode: workflowCode,
            articleId: articleId,
            wf_stage_id: wf_stage_id,
            crntStageId: crntStageId,
            actionFrom: actionFrom,
            metaData: metaData,
            sharedId: sharedId,
            articleFile: articleFile,
            mainDocument: mainDocument
        }

        this.emitFileDiff.emit(params);
    }

    sharedUsers = [];
    activeUsersList = [];
    sharableUsers = [];
    public async triggerReShareButtonClick(sharedData,userRoles){
        this.sharedUsers = []
        if(sharedData != undefined){
            sharedData.forEach(el => {
                this.sharedUsers.push(el.stage_user)
            });
        }

        let usersData = await this.userService.getUsersFromRolesSvs({userRoles:userRoles,sharedUsers:this.sharedUsers}).toPromise()

        this.activeUsersList = usersData['users']
        // this.sharableUsers = this.activeUsersList.filter(({ usr_id: id1 }) => !this.sharedUsers.some((id => id === id1)));
        this.sharableUsers = this.activeUsersList
        
        document.querySelectorAll<HTMLElement>('popover-container.popover-left').forEach((el) => {
            el.style.maxWidth='100%'
            el.style.width='600px'
        });
        this.shared_service.hideSpinner('articlePersonalisedSpinner');
    }

    shareTable: FormGroup = new FormGroup({
        checkboxes: new FormControl(''),
        mainCheckbox: new FormControl('')
    });

    selectedAll;
    clearSelection() {
        this.selectionUserList = [];
        this.selectedAll = false;
        this.shareTable.reset();
    }

    shareReassignTo(users,articleData){
        let postData = {
            userIds:[],
            articleData:articleData
        }

        users.forEach(usr => {
            postData.userIds.push(usr._id)
        })

        this.articleService.shareReassignToSvs(postData).subscribe(res => {
            if(res['status'] == 200){
                // this.getSharedArticles()
                this.emitGetSharedArticles.emit()
                this.clearSelection()
                this.notifier.notify('success', this.translate.instant('articles.reassign success'));
            }
        })
    }

    selectionUserList = [];
    changeHandler(item: any, action='assign') {
        if(action == 'reassign'){
            this.selectionUserList = [];
        }
        
        const id = item.fullName;
        const index = this.selectionUserList.findIndex(u => u.fullName === id);
        if (index === -1) {
            // ADD TO SELECTION
            this.selectionUserList = [...this.selectionUserList, item];
        } else {
            // REMOVE FROM SELECTION
            this.selectionUserList = this.selectionUserList.filter(user => user.fullName !== item.fullName)
        }
    }

    checkIfAllSelected() {
        this.selectedAll = this.sharableUsers.every(function (item: any) {
            return item.selected == true;
        })
    }
}
