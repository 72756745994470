import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../users/user.service';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { EnvService } from '../../service/env.service';

@Component({
    selector: 'app-useractivation',
    templateUrl: './useractivation.component.html',
    styleUrls: ['./useractivation.component.scss']
})
export class UseractivationComponent implements OnInit {

    base_url: string;
    userActivationFormGroup: FormGroup;
    submitted = false;
    user_activated = false;
    link_expired = false;
    selectedId: any;
    post_data: any;
    link_data: any;
    private readonly notifier: NotifierService;
    current_year = (new Date()).getFullYear();

    constructor(
        private formBuilder: FormBuilder,
        private postsdata: UserService,
        notifierService: NotifierService,
        private router: Router,
        private route: ActivatedRoute,
        public env: EnvService
    ) {
        this.notifier = notifierService;
        // password change form
        this.userActivationFormGroup = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&]).{8,}'), this.noWhitespaceValidator]],
            repeatPassword: ['', [Validators.required, this.noWhitespaceValidator]],
            user_deeplink_token: new FormControl(null),
        }, {
                validator: PasswordValidator.validate.bind(this)
            });
    }

    public noWhitespaceValidator(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    ngOnInit() {
        this.base_url = this.env.baseUploadUrl;
        this.route.paramMap.subscribe(params => {
            this.selectedId = params.get("usertoken");
            this.postsdata.checkActivationLink(this.selectedId).subscribe(data => {
                this.link_data = data;
                if (this.link_data.status == 402) {
                    this.link_expired = true;
                    this.user_activated = true;
                }
            });
            this.userActivationFormGroup.patchValue({
                user_deeplink_token: this.selectedId,
            })
        })
    }

    // password creation and user activation submit
    onClickUseractivation() {
        console.log(this.userActivationFormGroup.value);
        var that = this;
        this.submitted = true;
        // // stop here if form is invalid
        if (this.userActivationFormGroup.invalid) {
            return;
        }
        this.postsdata.userActivation(this.userActivationFormGroup.value).subscribe(data => {
            this.post_data = data;
            this.notifier.notify(this.post_data.message_type, this.post_data.message);
            if (this.post_data.status == 200) {
                this.user_activated = true;

            }
            // setTimeout(function () {
            //     that.navigateToLogin();
            // }, 1000);

        });
    }

    navigateToLogin() {
        this.router.navigate(['login']);
    }

}

// password and repeat password check class (validation)
export class PasswordValidator {
    static validate(passwordFormGroup: FormGroup) {
        let password = passwordFormGroup.controls.password.value;
        let repeatPassword = passwordFormGroup.controls.repeatPassword.value;

        if (repeatPassword.length <= 0) {
            return null;
        }

        if (repeatPassword !== password) {
            return {
                doesMatchPassword: true
            };
        }
        return null;
    }
}

