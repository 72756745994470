import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { DashboardService } from './../../dashboard.service';
import { UserService } from '../user.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { UserTokenService } from '../../service/user-token.service';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { InjectableGlobal } from '../../permissions'
import { NotifierService } from 'angular-notifier';
import { Observable, Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { EnvService } from '../../service/env.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { SharedService } from '../../shared/shared.service';
import { datasource } from '../../data';
import { getFilterMenuPostion } from '@syncfusion/ej2-grids';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
interface users {
    usr_username: string;
  }
//import { exists } from 'fs';
@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class UserListComponent implements OnInit {
    public displayedColumns = ['select', 'usr_first_name', 'usr_username', 'usr_email', 'usr_roles', 'usr_created_date', 'usr_status', 'action'];
    public displayedColumns1 = ['select', 'usr_first_name', 'usr_username1', 'usr_email', 'usr_roles', 'usr_created_date', 'usr_status', 'action'];
    public dataSource = new MatTableDataSource();
    public dataSource_new = new MatTableDataSource();
    public myModal;
    public open: BehaviorSubject<boolean> = new BehaviorSubject(false);
    selectForDelete: any;
    activateOrDeactivateFlag: any;
    userdetails: Object;
    @ViewChild(DataTableDirective)
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatSort) sort_new: MatSort;
    @ViewChild('sortCol1') sortCol1: MatSort;
    @ViewChild('sortCol2') sortCol2: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatPaginator) paginator_new: MatPaginator;

    @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;

    dtElement: DataTableDirective;
    users: User[];
    dtTrigger: Subject<DataTablesResponse> = new Subject();
    dtOptions: DataTables.Settings = {};
    subroles = [];
    invited_users = false;
    all_users = true;
    profileImageUrl: string;
    row;
    offset;
    pageSize;

    public popoverTitle_activate: string = 'Activate confirmation?';
    public popoverTitle_deactivate: string = 'Deactivate confirmation?';
    public popoverMessage_activate: string = 'Are you sure to activate this user ?';
    public popoverMessage_deactivate: string = 'Are you sure to deactivate this user ?';

    permissions = [];
    selection_all = new SelectionModel(true, []);
    selection_invited = new SelectionModel(true, []);
    myControl = new FormControl();
    public allselected: Boolean = false;
    userForm:FormGroup
    selectedHoliday: any;
    userName: any;
    fb: any;
    Parentdata=[];
    constructor(
        private permissionsService: NgxPermissionsService,
        private data: DashboardService,
        private userService: UserService,
        private http: HttpClient,
        private user: UserTokenService,
        private router: Router,
        private InjectableGlobal: InjectableGlobal,
        private notifierService: NotifierService,
        private env: EnvService,
        private shared_service: SharedService,
        private formBuilder: FormBuilder
    ) { 

        this.userForm = this.formBuilder.group({
            users: ["", Validators.required]
          });
    }

    httpOptions = {
        headers: new HttpHeaders({
            'Authorization': this.user.getToken()
        })
    };
    selectedTab;
    ngOnInit() {
        this.profileImageUrl = this.env.baseProfileImageUrl;

        // "tab_active" local storage also used to navigate, dashboard "inactive users" tile to
        // the "invited users" table; kindly note that;
        if (localStorage.getItem('tab_active') == 'yes') {
            this.selectedTab = 2;
            this.invited_users = true;
            this.all_users = false;
        }
        localStorage.removeItem('tab_active');

        this.offset = 0;
        let data_limit = {
            'limit': 5,
            'offset': this.offset,
            'user': 'user'
        };
        this.getAllUsers(data_limit);
        this.getAllParentUsers()
        const that = this;
        // this.permissions = JSON.parse(atob(localStorage.getItem('PERMISSIONS')));
        // this.permissionsService.loadPermissions(this.permissions);
        // datatable options
        this.dtOptions = {
            searching: false,
            paging: false,
            serverSide: true,
            processing: true,
            responsive: true,
            info: false,
            ajax: (dataTablesParameters: any, callback) => {
                that.http
                    .post<DataTablesResponse>(
                        this.env.baseAPIUrl + '/user_listing',
                        { dataTablesParameters }, this.httpOptions
                    ).subscribe(resp => {
                        console.log(resp.data);
                        that.users = resp.data;
                        that.subroles = resp.subroles;
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: []
                        });

                    });
            },
            columns: [
                { title: 'Name', data: 'usr_first_name' },
                { title: 'Username', data: 'usr_username' },
                { title: 'Email', data: 'usr_email' },
                { "orderable": false },
                { "orderable": false },
                { "orderable": false },

            ],

        };
    }

    masterToggle() {
        this.isAllSelected_all() ?
            this.selection_all.clear() :
            this.dataSource.data.forEach(row => this.selection_all.select(row));
    }

    masterToggle1() {
        this.isAllSelected_invited() ?
            this.selection_invited.clear() :
            this.dataSource_new.data.forEach(row => this.selection_invited.select(row));
    }

    // users multiple delete
    isAllSelected_all() {
        // const numSelected = this.selection_all.selected.length;
        // const numRows = this.dataSource.data.length;
        // return numSelected === numRows;

        const numSelected = this.selection_all.selected.length;
        const numRows = this.dataSource.data.length;
        if (numSelected === numRows) {
            this.allselected = true;
        } else {
            this.allselected = false;
        }
        return numSelected === numRows;
    }
    allselectedDelete() {
        if (this.selection_all.selected.length) {
            const el: HTMLElement = this.myDiv.nativeElement;
            el.click();
        } else {
            this.notifierService.notify('error', 'Please select some users');
        }
    }
    removeSelectedRows_all() {
        this.selection_all.selected.forEach(item => {
            this.userService.deleteUser(item['_id']).subscribe(data => {
                const element: HTMLElement = document.getElementsByClassName('close2')[0] as HTMLElement;
                element.click();
                this.getAllUsers('');
            });
        });

        if (this.selection_all.selected.length) {
            this.notifierService.notify('success', 'Deleted successfully');
        }
        this.selection_all = new SelectionModel(true, []);
    }

    // invited users multiple delete
    isAllSelected_invited() {
        // const numSelected = this.selection_invited.selected.length;
        // const numRows = this.dataSource_new.data.length;
        // return numSelected === numRows;

        const numSelected = this.selection_invited.selected.length;
        const numRows = this.dataSource_new.data.length;
        if (numSelected === numRows) {
            this.allselected = true;
        } else {
            this.allselected = false;
        }
        return numSelected === numRows;
    }
    selectedDelete() {
        if (this.selection_invited.selected.length) {
            const el: HTMLElement = this.myDiv.nativeElement;
            el.click();
        } else {
            this.notifierService.notify('error', 'Please select some users');
        }
    }
    removeSelectedRows_invited() {
        this.selection_invited.selected.forEach(item => {
            this.userService.deleteUser(item['_id']).subscribe(data => {
                console.log(data);
                // to hide the multiple select delete popup confirmation msg
                if (data['message_type'] = 'success') {
                    const element: HTMLElement = document.getElementsByClassName('close2')[0] as HTMLElement;
                    element.click();
                    this.getAllUsers('');
                }
            });
        });

        if (this.selection_invited.selected.length) {
            this.notifierService.notify('success', 'Deleted successfully');
        }
        this.selection_invited = new SelectionModel(true, []);
    }

    // to uncheck the checkbox while chnaging tabs
    tabChange() {
        this.selection_all.clear();
        this.selection_invited.clear();
    }

    // select_all_users;
    // select_all_user(){
    //     this.dataSource.data.forEach(row => this.selection_all.select(row));
    //     this.select_all_users = true;
    // }
    // deselect_all_user(){
    //     this.selection_all.clear()
    //     this.select_all_users = false;
    // }

    deactivate_all() {
        this.selection_all.selected.forEach(item => {
            this.userService.deactivateUser(item['_id'], 'false').subscribe(data => {
                if (!this.pageSize) {
                    this.pageSize = 5;
                }
                let data1 = {
                    'limit': this.pageSize,
                    'offset': this.offset,
                    'user': 'user'
                };

                this.getAllUsers(data1);
            });
        });

        if (this.selection_all.selected.length) {
            this.notifierService.notify('success', 'User(s) Deactivated successfully');
        } else {
            this.notifierService.notify('error', 'Please select some users');
        }

        this.selection_all = new SelectionModel(true, []);
    }

    activate_all() {
        this.selection_all.selected.forEach(item => {
            this.userService.deactivateUser(item['_id'], 'true').subscribe(data => {
                if (!this.pageSize) {
                    this.pageSize = 5;
                }
                let data1 = {
                    'limit': this.pageSize,
                    'offset': this.offset,
                    'user': 'user'
                };
                this.getAllUsers(data1);
            });
        });


        if (this.selection_all.selected.length) {
            this.notifierService.notify('success', 'User(s) Activated successfully');
        } else {
            this.notifierService.notify('error', 'Please select some users');
        }

        this.selection_all = new SelectionModel(true, []);
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
        var that = this;

    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }
    rerender(): void {
        var that = this;
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
        });
    }
    getRoleNamesFromRoleIds(user_role_json, subroles) {
        var that = this;
        var roleNames = [];
        user_role_json.map(function (key, val, array) {
            if (subroles.includes(key)) {
                roleNames.push(that.InjectableGlobal.rolenames[key]);
            }
        });
        var roles = roleNames.join(`</span>&nbsp;<span class="badge badge-secondary">`);
        return `<span class="badge badge-secondary">${roles}</span>`;
    }

    getUserDetails(user_id) {
        this.userService.getUserdata(user_id).subscribe(data => {
            this.userdetails = data;
        });
    }

    formatDateTime(date: Date): string {
        if (!date) {
            return '';
        }
        return moment(date).format('DD-MMM-YYYY');
    }
    selectUser(id, flag) {
        this.selectForDelete = id;
        this.activateOrDeactivateFlag = flag;
    }
    deactivateUser(userId, flag) {
        this.userService.deactivateUser(userId, flag).subscribe(data => {
            this.offset = 0;
            let data_limit = {
                'limit': 5,
                'offset': this.offset,
                'user': 'user'
            };
            this.getAllUsers(data_limit);
            this.notifierService.notify(data['message_type'], data['message']);
            // this.router.navigate(['/users']);
        });
    }
    deleteUser(userId) {
        this.userService.deleteUser(userId).subscribe(data => {
            if (localStorage.getItem('user_active_tab') == '2') {
                this.offset = 0;
                let data_limit = {
                    'limit': 5,
                    'offset': this.offset,
                    'user': 'invited'
                };
                this.getAllUsers(data_limit);
                this.notifierService.notify(data['message_type'], data['message']);

            }
            if (localStorage.getItem('user_active_tab') == '1') {
                this.offset = 0;
                let data_limit = {
                    'limit': 5,
                    'offset': this.offset,
                    'user': 'user'
                };
                this.getAllUsers(data_limit);
                this.notifierService.notify(data['message_type'], data['message']);
            }
        });
    }
    userId;
    public getAllParentUsers(){
        this.userService.getAllParentUsers( )
            .subscribe(res => {
            console.log(res)
            this.Parentdata=res['result']
            console.log(this.data)
                
            },
                error => {
                    this.shared_service.hideSpinner('usersSpinner');
                }
            )
    }

    public onUserChange(event) {
        this.userId = event.value
        this.offset = 0;
        let data = {
            'limit': 5,
            'offset': this.offset,
            'userId': this.userId
        };
        this.getUsers(data);
    }
    totalLengthUser;
    totalLengthInvitedUser;
    siblings=false
    nochild=false
    public getUsers = (data) => {
        this.shared_service.showSpinner('usersSpinner');
        this.userService.getChildDetails(data)
            .subscribe(res => {
                if (res['siblings'] === true) {
                    console.log(res['siblings'])
                    this.siblings = true;
                    this.nochild = false;
                }
                if (!Object.keys(res['data']).length) {
                    console.log("no data found");
                    this.siblings = false;
                    this.nochild = true;
                }

                this.dataSource.data = res['data'] as User[];
                this.dataSource_new.data = res['data1'] as User[];


                this.subroles = res['subroles'];
                this.dataSource.paginator = this.paginator;
                this.dataSource_new.paginator = this.paginator_new;
                this.dataSource.sort = this.sortCol1;
                this.dataSource_new.sort = this.sortCol2;
                this.totalLengthInvitedUser = res['data1_count'];
                this.totalLengthUser = res['data_count'];
                this.shared_service.hideSpinner('usersSpinner');
            },
                error => {
                    this.shared_service.hideSpinner('usersSpinner');
                }
            )
    }
    public getAllUsers = (data) => {
        this.shared_service.showSpinner('usersSpinner');
        this.userService.getUserDetailsMatTable(this.user.getToken(), data)
            .subscribe(res => {
                if (res['siblings'] === true) {
                    console.log(res['siblings'])
                    this.siblings = true;
                    this.nochild = false;
                }
                if (!Object.keys(res['data']).length) {
                    console.log("no data found");
                    this.siblings = false;
                    this.nochild = true;
                }
                this.dataSource.data = res['data'] as User[];
                this.dataSource_new.data = res['data1'] as User[];
                this.subroles = res['subroles'];
                this.dataSource.paginator = this.paginator;
                this.dataSource_new.paginator = this.paginator_new;
                this.dataSource.sort = this.sortCol1;
                this.dataSource_new.sort = this.sortCol2;
                this.totalLengthInvitedUser = res['data1_count'];
                this.totalLengthUser = res['data_count'];
                this.shared_service.hideSpinner('usersSpinner');
            },
                error => {
                    this.shared_service.hideSpinner('usersSpinner');
                }
            )
    }
    filterName: string;
    filterName1: string;

    change_user_list(event) {
        if (event.tab.textLabel === 'Invited users') {
            this.invited_users = true;
            this.all_users = false;
            localStorage.setItem('user_active_tab', '2');
        } else {
            this.all_users = true;
            this.invited_users = false;
            localStorage.setItem('user_active_tab', '1');
        }
    }
    _setDataSource(indexNumber) {
        console.log(indexNumber)
        setTimeout(() => {
            switch (indexNumber) {

                case 0:
                    this.dataSource.paginator ? this.dataSource.paginator = this.paginator : null;
                    //this.dataSource.sort ? this.dataSource.sort = this.sort : null;
                    break;
                case 1:
                    this.dataSource_new.paginator ? this.dataSource_new.paginator = this.paginator_new : null;
                //this.dataSource_new.sort ? this.dataSource_new.sort = this.sort_new : null;
            }
        });
    }
    public doFilter_user = (value: string) => {
        this.dataSource.filter = value.trim().replace(/\s/g, "").toLocaleLowerCase();
    }

    public doFilter_invited_users = (value: string) => {
        this.dataSource_new.filter = value.trim().replace(/\s/g, "").toLocaleLowerCase();
    }



    getPaginationInvitedUserData(event: any) {
        this.pageSize = event.pageSize;
        this.offset = event.pageSize * event.pageIndex;
        let data = {
            'limit': event.pageSize,
            'offset': this.offset,
            'user': 'invited'
        };
        this.getAllUsers(data);
    }
    getPaginationUserData(event: any) {
        this.pageSize = event.pageSize;
        this.offset = event.pageSize * event.pageIndex;
        let data = {
            'limit': event.pageSize,
            'offset': this.offset,
            'user': 'user'
        };
        this.getAllUsers(data);
    }
}
class User {
    usr_first_name: string;
    usr_username: string;
}
class DataTablesResponse {
    data: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
    subroles: any[]
}