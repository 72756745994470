import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { FormGroup, FormControl } from '@angular/forms';
import { UserrolesService } from '../../userroles/userroles.service';
import { UserTokenService } from '../../service/user-token.service';
import { ArticleService } from '../../article/article.service';
import { TranslateService } from '@ngx-translate/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, startWith, toArray} from 'rxjs/operators';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { LoginService } from '../../login/login.service';
import { interval, Subscription } from 'rxjs';
import { UserService } from '../../users/user.service';
import { EditorService } from "../../editor/editor.service";
import { NotifierService } from 'angular-notifier';
import { Router } from "@angular/router";
import {PageEvent} from '@angular/material/paginator';

@Component({
    selector: 'app-article-personalised',
    templateUrl: './article-personalised.component.html',
    styleUrls: ['./article-personalised.component.scss'],
})
export class ArticlePersonalisedComponent implements OnInit {
    public loginedUser = localStorage.getItem("current_logined_userId");
    public myArticles: any =[];
    public checkoutArticle:any=[];
    public myArticleSearchText: any = null;
    public skip: number = 0;
    public limit: number = 15;
    public UserStatus = "Available"
    public paginate: object = {
        'skip': this.skip,
        'limit': this.limit,
    };
    myArticlesTotal: number = 0;
    public keySelectedDisplayItemsArray: any = [];
    public availableArticleJournal:any =[];
    public guestArticles: any;
    
    /**
     * Returns list of ids from myArticles
     */
    get articleIds(){
        return this.myArticles.map(item=>item._id.$oid)
    };

    /**
     * Stores the last active events of articles
     */
    private articleLastActiveEvents: any = {};

    separatorKeyCodes: number[] = [ENTER, COMMA];
    selectedjournalArticles: string[]=[];
    journalArticles: string[]=[];
    journalArticleInput:string="";
    inputSuggestionControl = new FormControl();
    journalArticleSuggestionsObservable: Observable<string[]>;
    _journalArticleSuggestions: string[];
    public availableJournals: any = [];
    public assignedJournals:any =[];
    articlesCompleted:boolean =  false;
    @ViewChild('journalArticleSuggestionInput') journalArticleSuggestionInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    private _id: any;
    all_users: boolean;
    invited_users: boolean;
    filter_data: boolean;
    article: any;
    assigned: any;
    available: any;
    eventTab: any;
    allResponse: any;
    subscription: Subscription;

    constructor(
        private shared_service: SharedService,
        private userroleService: UserrolesService,
        private usertoken: UserTokenService,
        private articleService: ArticleService,
        public translate: TranslateService,
        private loginService: LoginService,
        private userService: UserService,
        private editorService: EditorService,
        private notifier: NotifierService,
        private router: Router,
    ) {
        //subscribing for any change in stage change data
        this.shared_service.valueChanged.subscribe((data: any) => {
            this.myArticles = data;
        })
        this.journalArticleSuggestionsObservable = this.inputSuggestionControl.valueChanges.pipe(
            startWith(null as string),
            map((textInput: string | null) => textInput ? this.journalArticles.filter(journalArticle => journalArticle.toLowerCase().indexOf(textInput) === 0) : this.journalArticles.slice()),
            map(plans => {
                return plans.filter(plan => 
                    !this.selectedjournalArticles.includes(plan))
            }),
        );
        this.journalArticleSuggestionsObservable.subscribe(list=>{
            this._journalArticleSuggestions=list;
        });
    }
   
    onDragDropChip(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.selectedjournalArticles, event.previousIndex, event.currentIndex);
    }
    onChipInput(): void {
        if(this._journalArticleSuggestions.length === 0) return;
        this.onSelectJournalArticle(this._journalArticleSuggestions[0].trim())
    }
    onSelectJournalArticle(value: string): void {
        this.selectedjournalArticles.push(value);
        this.onSelectedJournalArticlesUpdate();
    }
    onSelectedJournalArticlesUpdate(){
        if(this.journalArticleSuggestionInput){
            this.journalArticleSuggestionInput.nativeElement.value = '';
        }
        this.onArticleJournalSortEdit();
    }
    remove(journalArticle: string): void {
        const index = this.selectedjournalArticles.indexOf(journalArticle);
        if (index >= 0) {
          this.selectedjournalArticles.splice(index, 1);
        }
        this.onArticleJournalSortEdit();
    }
    onArticleJournalSortEdit(){
        this.inputSuggestionControl.setValue(null);
        this.articleAdvanecdFilterForm.get("articleJournalSort").setValue(this.selectedjournalArticles);
    }

    public selectedCurrentStages = [];
    public stageNassigneeSettings = {};

    jnlStages = [];
    selectedJnlStages = [];
    jnlStageSettings = {
        singleSelection: false,
        text: this.translate.instant('articles.select_stages'),
        selectAllText: this.translate.instant('select_all'),
        unSelectAllText: this.translate.instant('clear_all'),
        searchPlaceholderText: this.translate.instant('articles.search_stages'),
        enableSearchFilter: true,
        badgeShowLimit: 5,
        groupBy: "jnl_title",
    };

    activeUsers = [{ id: this.loginedUser, itemName: 'Me' }];
    selectedCheckoutBy = []; 
    selectedArticleSharedTo = []; 
    checkoutbySettings = {
        singleSelection: false,
        text: this.translate.instant('articles.checkout_by'),
        selectAllText: this.translate.instant('select_all'),
        unSelectAllText: this.translate.instant('clear_all'),
        searchPlaceholderText: this.translate.instant('articles.checkout_by'),
        enableSearchFilter: true,
        badgeShowLimit: 3,
    };

    sharedBySettings = {
        singleSelection: false,
        text: this.translate.instant('articles.articlesSharedTo'),
        selectAllText: this.translate.instant('select_all'),
        unSelectAllText: this.translate.instant('clear_all'),
        searchPlaceholderText: this.translate.instant('articles.articlesSharedTo'),
        enableSearchFilter: true,
        badgeShowLimit: 3,
    };

    rolesInData = [];

    selectedRolesIn = [];
    rolesInSettings = {
        singleSelection: false,
        text: "Roles in",
        selectAllText: this.translate.instant('select_all'),
        unSelectAllText: this.translate.instant('clear_all'),
        searchPlaceholderText: this.translate.instant('roles.search_user_roles'),
        enableSearchFilter: true,
        badgeShowLimit: 5,
    };

    selectedDisplayItems = [];
    clnSelectedDisplayItems = [];
    keySelectedDisplayItems = [];
    displayItems = [
        { id: 'zip', itemName: this.translate.instant('articles.article_zip_name') },
        { id: 'roadmap', itemName: this.translate.instant('articles.road_map') },
        { id: 'file_list', itemName: this.translate.instant('articles.file_list') }
    ];
    displayItemsSettings = {
        singleSelection: false,
        text: this.translate.instant("articles.display_items"),
        selectAllText: this.translate.instant('select_all'),
        unSelectAllText: this.translate.instant('clear_all'),
        badgeShowLimit: 3,
    };

    privileges = JSON.parse(atob(localStorage.getItem('PRIVILEGES')));
   
    userRoles = [];

    ngOnInit() {
        console.log(JSON.parse(atob(localStorage.getItem('PRIVILEGES'))))
        this.loginService.getProfile().subscribe(
            res => { 
                this.userRoles = res.user_data.usr_roles;
            }
        );
        this.getJournalStages();
        this.getMyArticles();
        this.getAllUsers();
        this.getAllUserRoles();
        this.getAricleByArticleId();
        this.getArticleJournal();
        this.displayItems.forEach(elem => {
            this.keySelectedDisplayItemsArray.push(elem.id);
        });

        if (localStorage.getItem('article-list-items')) {
            this.keySelectedDisplayItems = JSON.parse(atob(localStorage.getItem('article-list-items')));
        } else {
            this.keySelectedDisplayItems = this.keySelectedDisplayItemsArray;
        }
        if (localStorage.getItem('article-Journal-list')) {
            this.selectedjournalArticles  = JSON.parse(atob(localStorage.getItem('article-Journal-list')));
            this.onSelectedJournalArticlesUpdate();
        }
        this.displayItems.forEach(e => {
            if (this.keySelectedDisplayItems.includes(e.id)) {
                this.selectedDisplayItems.push({ id: e.id, itemName: e.itemName });
                this.clnSelectedDisplayItems = this.selectedDisplayItems;
            }
        });
    }
    
    articleAdvanecdFilterForm = new FormGroup({
        searchText: new FormControl(null),
        currentStageIn: new FormControl(null),
        assigneeIn: new FormControl(null),
        articleSharedTo: new FormControl(null),
        rolesIn: new FormControl(null),
        allArticles: new FormControl(this.privileges.includes('super_admin')),
        completedArticles: new FormControl(null),
        ctrlDisplayItems: new FormControl([]),
        articleJournalSort:new FormControl([])
    });

    public getJournalStages() {
        this.shared_service.getJournalStagesSvs().subscribe(response => {
            if (response['status'] == 200) {
                this.jnlStages = response['jnl_stages'];
            }
        });
    }
    public getArticleJournal(){
        this.shared_service.getArticleJournal().subscribe((response:any) => {
            if (response.status === 200) {
                this.journalArticles.push(...response.articles);
            }
        });
    }

    clicker(){
        this.shared_service.showSpinner('articlePersonalisedSpinner');
    }

    public isShared:boolean = false
    isCompleted:boolean = false
    change_user_list(event){
        this.UserStatus = event.tab.textLabel;
        this.showSharedFilter = false
      
        // if (event.tab.textLabel === "Available") {
        //  this.articleAdvanecdFilterForm.controls['assigneeIn'].setValue("");
        //     this.selectedCheckoutBy=[];
        //     this.filter_data = false;
        //     this.showFilter = false;
        //     this.isShown = this.isShown;
        //     localStorage.setItem('user_active_tab', '1');
        // } if (event.tab.textLabel === "Filter Data") {
        //     this.filter_data = true;
        //     this.isShown = !this.isShown;
        //     localStorage.setItem('user_active_tab', '3');
        // }
        // else {
        //    this.articleAdvanecdFilterForm.controls['assigneeIn'].setValue("");
        //     this.filter_data = false;
        //     this.isShown = false;
        //     this.showFilter = false;
        //     localStorage.setItem('user_active_tab', '2');

        // }
        this.isShared = false
        this.isCompleted = false
        switch (this.UserStatus) {
            case 'Available':
                this.articleAdvanecdFilterForm.controls['assigneeIn'].setValue("");
                this.filter_data = false;
                this.showFilter = false;
                this.isShown = this.isShown;
                break;
            case 'Assigned':
                this.articleAdvanecdFilterForm.controls['assigneeIn'].setValue("");
                this.filter_data = false;
                this.isShown = false;
                this.showFilter = false;
                localStorage.setItem('user_active_tab', '2');
                break;
            case 'Filter Data':
                this.filter_data = true;
                this.isShown = !this.isShown;
                localStorage.setItem('user_active_tab', '3');
                break;
            case 'Shared':
                this.filter_data = false;
                this.showFilter = false;
                this.isShown = false;
                this.isShared = true;
                localStorage.setItem('user_active_tab', '4')
                this.getSharedArticles()
                break
            case 'Completed':
                this.filter_data = false;
                this.showFilter = false;
                this.isShown = false;
                this.isCompleted = true;
                localStorage.setItem('user_active_tab', '5')
                this.getCompletedArticles()
                break

        }
        this.getMyArticles(this.UserStatus)
    }
    public onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }
    public sharedCompletedUsers:any
    public sharedCompletedUsersPromise: Promise<boolean>
    public getMyArticles(actionFrom = null) {
        this.shared_service.showSpinner('articlePersonalisedSpinner');
        var postData = {
            filter: {
                word_match: this.myArticleSearchText,
                params: this.articleAdvanecdFilterForm.value,

            },
            paginate: this.paginate,
            UserStatus: this.UserStatus
        };
        this.shared_service.getMyArticlesSvs(postData).subscribe(
            response => {
                console.log(response)
                if (response['status'] === 200) {
                    let tmpShareDone = []
                    response['my_articles'].forEach((e,i) => {
                        if(e.article_current_stage.shared != undefined){
                            let sharedItems = e.article_current_stage.shared
                            sharedItems.forEach((shared,j) => {
                                let userFullName = this.getUserFullName(shared.stage_user)
                                response['my_articles'][i].article_current_stage.shared[j].usrFullName = userFullName
                                if(shared.end_date != null){
                                    if(tmpShareDone[e._id.$oid]){
                                        tmpShareDone[e._id.$oid].push(userFullName)
                                    }else{
                                        tmpShareDone[e._id.$oid] = [userFullName]
                                    }
                                }
                            });
                        }
                    });

                    this.sharedCompletedUsers = tmpShareDone
                    this.sharedCompletedUsersPromise = Promise.resolve(true)
                    this.myArticles = response['my_articles'];
                    this.getLastActiveEventForArticles();
                    this.myArticlesTotal = response['total_articles'];
                    this.keySelectedDisplayItems = [];
                    this.selectedDisplayItems.forEach(e => {
                        this.keySelectedDisplayItems.push(e.id);
                    });

                    if (actionFrom == 'article-list') {
                        this.shared_service.valueChanged.emit(response['my_articles']);
                    } else if (actionFrom == 'filter-button') {
                        localStorage.setItem('article-list-items', btoa(JSON.stringify(this.keySelectedDisplayItems)));
                        localStorage.setItem('article-Journal-list', btoa(JSON.stringify(this.selectedjournalArticles)));
                    }
                }
                this.shared_service.hideSpinner('articlePersonalisedSpinner');
            },
            error => {
                this.shared_service.hideSpinner('articlePersonalisedSpinner');
            }
        );
    }

    // public getUsersDoneSharing(){
    //     setTimeout(() => {
    //         return 123
    //         return this.sharedCompletedUsers
    //     }, 1000);
    // }
    public getPaginationData(event?: PageEvent) {
        this.skip = event.pageSize * event.pageIndex;
        this.paginate = {
            'skip': this.skip,
            'limit': event.pageSize,
        };

        this.getMyArticles()
    }
    pageEvent:PageEvent
    public paginateMyArticles(event: any) {
   
        this.skip = event.pageSize * event.pageIndex;
      
        this.paginate = {
            'skip': this.skip,
            'limit': event.pageSize,
        };
       
        this.getMyArticles(this.paginate);

    }



    public showFilter = false;
    isShown: boolean = false ; 
    public toggleFilters(tmpShowFilter) {
        this.showFilter = !tmpShowFilter;
    }
    public getAllUserRoles() {
        this.userroleService.get_userroles({}).subscribe(res => {
            if (res['status'] == '200') {
                res['data'].forEach(e => {
                    this.rolesInData.push({ id: e._id, itemName: e.rl_role_name });
                });
            }
        });
    }
    public getAllUsers() {
        this.shared_service.getAllUsersSvs().subscribe(response => {
            if (response.status == 200) {
                this.activeUsers = [];
                response.data.forEach(e => {
                    this.activeUsers.push({ id: e._id, itemName: e.usr_first_name + ' ' + e.usr_last_name });
                });
            }
        });
    }

    

    public resetFilter() {
        setTimeout(() => {
            this.getMyArticles();
            this.selectedDisplayItems = [];
        }, 100);
    }

    triggerStageChange(item: any) {
        switch (item) {
            case 'onDeSelectAllCurrentStageIn':
                this.selectedJnlStages = [];
                break;
            case 'onDeSelectAllRolesIn':
                this.selectedRolesIn = [];
                break;
            case 'onDeSelectAllDisplayItems':
                this.selectedDisplayItems = [];
                break;
            case 'onDeSelectAllCheckoutBy':
                this.selectedCheckoutBy = [];
                break;
        }

        this.getMyArticles();
      
    }
    trigerArticleSharedTo(item: any) {
        switch (item) {
            case 'onDeSelectAllCurrentStageIn':
                this.selectedJnlStages = [];
                break;
            case 'onDeSelectAllRolesIn':
                this.selectedRolesIn = [];
                break;
            case 'onDeSelectAllDisplayItems':
                this.selectedDisplayItems = [];
                break;
            case 'onDeSelectAllCheckoutBy':
                this.selectedArticleSharedTo = [];
                break;
        }

        this.getSharedArticles
    }

    public checkOutArticle(event: any, aid: object, stage_id: string, sharedId:string=null) {
        this.shared_service.showSpinner('articlePersonalisedSpinner');
        event.target.disabled = true;
        this._id = aid
        let post_data = {
            id: aid,
            stage_id: stage_id,
            sharedId:sharedId
        }
        this.shared_service.checkOutArticleSvs(post_data).subscribe(res => {

            if (res['status'] === 200) {
                this.getMyArticles();

            }
            this.shared_service.hideSpinner('articlePersonalisedSpinner');
        }
        )
    }

    public checkInArticle(event: any, aid: object, stage_id: string, jnl_code: string, wflw_code: string, nxt_stage_id: string, wf_stage_id: any) {
        event.target.disabled = true;
        var postData = {
            user_token: this.usertoken.getToken(),
            journal_code: jnl_code,
            workflow_code: wflw_code,
            article_id: aid,
            stage_id: wf_stage_id,
            current_stage_id: stage_id,
            next_wf_stage_id: nxt_stage_id,
            action: 'checkin'
        }

        this.articleService.moveNextStageSvs(postData).subscribe(
            response => {
                if (response.status === 200) {
                    this.getMyArticles();
                }
            });
    }

    getAricleByArticleId() {
        const guestToken = localStorage.getItem('guestToken');

        if (localStorage["guestToken"]) {

            this.articleService.getArticleByArticleId({ guestToken: guestToken }).subscribe(
                res => {
                    this.guestArticles = res;
                },
                err => {
                    console.log(err);
                }
            );
        };
    }
    onClose(event){
        this.selectedCheckoutBy=[]
        this.getMyArticles();
    }


    /**
     * Check whether given user have the permission to access the stage
     * @param array roles
     * @returns Boolean
     * @author Arathy S V
     */
    checkAccessPrivilage(roles) {
        return this.userRoles.some(item => roles.includes(item))
    }


/**
 * Fetch all article id to check where there is an event change
 * @author Simi Francis
 */  
    public getLastActiveEventForArticles() {
        const newEventNotification = interval(10000);
        this.subscription = newEventNotification.subscribe(val => {
            // this.articleService.postFetchLastActiveEventForArticles(this.articleIds).subscribe(
            //     response => {
            //         this.articleLastActiveEvents = response['result']
            //     });
        })
    }
/**
 * check whether the response has an articleId
 * @author Simi Francis
 */
    public isArticleActive(articleId: any){
        return (this.articleLastActiveEvents.hasOwnProperty(articleId));
    }
/**
 * if there is an articleid the return last updated time  of the article
 * @author Simi Francis
 */
    public getArticleLastActiveTime(articleId: any){
        if(this.isArticleActive(articleId)){
            return this.articleLastActiveEvents[articleId]["last_updated_at"];
        }else{
            return null;
        }
    }

    public sharedArticles
    public sharedArticlesTotal
    public getSharedArticles(actionFrom = null) {
        this.shared_service.showSpinner('articlePersonalisedSpinner');
        var postData = {
            filter: {
                word_match: this.myArticleSearchText,
                params: this.articleAdvanecdFilterForm.value,

            },
            paginate: this.paginate,
            UserStatus: this.UserStatus
        };
        this.shared_service.getSharedArticlesSvs(postData).subscribe(
            response => {
                if (response['status'] === 200) {
                    this.sharedArticles = response['my_articles'];
                    this.sharedArticlesTotal = this.sharedArticles.length;

                    this.keySelectedDisplayItems = [];
                    this.selectedDisplayItems.forEach(e => {
                        this.keySelectedDisplayItems.push(e.id);
                    });

                    if (actionFrom == 'article-list') {
                        this.shared_service.valueChanged.emit(response['my_articles']);
                    } else if (actionFrom == 'filter-button') {
                        localStorage.setItem('article-list-items', btoa(JSON.stringify(this.keySelectedDisplayItems)));
                        localStorage.setItem('article-Journal-list', btoa(JSON.stringify(this.selectedjournalArticles)));
                    }
                }
                this.shared_service.hideSpinner('articlePersonalisedSpinner');
            },
            error => {
                this.shared_service.hideSpinner('articlePersonalisedSpinner');
            }
        );
    }

    completedArticles; completedArticlesTotal
    public getCompletedArticles(actionFrom = null) {
        this.shared_service.showSpinner('articlePersonalisedSpinner');
        var postData = {
            filter: {
                word_match: this.myArticleSearchText,
                params: this.articleAdvanecdFilterForm.value,

            },
            paginate: this.paginate,
            UserStatus: this.UserStatus
        };
        this.shared_service.getCompletedArticlesSvs(postData).subscribe(
            response => {
                if (response['status'] === 200) {
                    this.completedArticles = response['my_articles'];
                    this.completedArticlesTotal = this.completedArticles.length;

                    this.keySelectedDisplayItems = [];
                    this.selectedDisplayItems.forEach(e => {
                        this.keySelectedDisplayItems.push(e.id);
                    });

                    if (actionFrom == 'article-list') {
                        this.shared_service.valueChanged.emit(response['my_articles']);
                    } else if (actionFrom == 'filter-button') {
                        localStorage.setItem('article-list-items', btoa(JSON.stringify(this.keySelectedDisplayItems)));
                        localStorage.setItem('article-Journal-list', btoa(JSON.stringify(this.selectedjournalArticles)));
                    }
                }
                this.shared_service.hideSpinner('articlePersonalisedSpinner');
            },
            error => {
                this.shared_service.hideSpinner('articlePersonalisedSpinner');
            }
        );
    }

    public showSharedFilter:boolean = false
    public toggleSharedFilters(){
        this.showSharedFilter = !this.showSharedFilter
    }

    public async getUserFullName(userId){
        let userData:any
        userData = await this.userService.getUserdata(userId).toPromise();

        let userFullName:any = [userData['usr_first_name'],userData['usr_middle_name'],userData['usr_last_name']]
        userFullName = userFullName.filter(item => item)
        return userFullName.join(' ')
    }

    shareTable: FormGroup = new FormGroup({
        checkboxes: new FormControl(''),
        mainCheckbox: new FormControl('')
    });

    sharedUsers = [];
    activeUsersList = [];
    sharableUsers = [];
    isOpen = false
    async triggerShareButtonClick(sharedData,userRoles){
        this.sharedUsers = []
        if(sharedData != undefined){
            sharedData.forEach(el => {
                this.sharedUsers.push(el.stage_user)
            });
        }

        let usersData = await this.userService.getUsersFromRolesSvs({userRoles:userRoles,sharedUsers:this.sharedUsers}).toPromise()

        this.activeUsersList = usersData['users']
        // this.sharableUsers = this.activeUsersList.filter(({ usr_id: id1 }) => !this.sharedUsers.some((id => id === id1)));
        this.sharableUsers = this.activeUsersList

        document.querySelectorAll<HTMLElement>('popover-container.popover-left').forEach((el) => {
            el.style.maxWidth='100%'
            el.style.width='600px'
        });
    }

    selectionUserList = [];

    changeHandler(item: any, action='assign') {
        if(action == 'reassign'){
            this.selectionUserList = [];
        }
        
        const id = item.fullName;
        const index = this.selectionUserList.findIndex(u => u.fullName === id);
        if (index === -1) {
            // ADD TO SELECTION
            this.selectionUserList = [...this.selectionUserList, item];
        } else {
            // REMOVE FROM SELECTION
            this.selectionUserList = this.selectionUserList.filter(user => user.fullName !== item.fullName)
        }
    }

    // replace shareArticleToUsers with sharableUsers
    selectedAll;
    checkIfAllSelected() {
        this.selectedAll = this.sharableUsers.every(function (item: any) {
            return item.selected == true;
        })
    }

    selectAllCheckboxes() {
        for (var i = 0; i < this.sharableUsers.length; i++) {
            this.sharableUsers[i].selected = this.selectedAll;
        }
        if (this.selectedAll === true) {
            this.selectionUserList = this.sharableUsers;
        } else {
            this.selectionUserList = [];
        }
    }

    shareArticleTo(users,articleData){
        let postData = {
            userIds:[],
            articleData:articleData
        }

        users.forEach(usr => {
            postData.userIds.push(usr._id)
        })

        this.articleService.shareArticleToSvs(postData).subscribe(res => {
            if(res['status'] == 200){
                this.getMyArticles()
                this.clearSelection()
                // this.notifier.notify('success', this.post_data.message);
            }
        })
    }

    shareReassignTo(users,articleData){
        let postData = {
            userIds:[],
            articleData:articleData
        }

        users.forEach(usr => {
            postData.userIds.push(usr._id)
        })

        this.articleService.shareReassignToSvs(postData).subscribe(res => {
            if(res['status'] == 200){
                this.getSharedArticles()
                this.clearSelection()
                this.notifier.notify('success', this.translate.instant('articles.reassign success'),);
            }
        })
    }

    clearSelection() {
        this.selectionUserList = [];
        this.selectedAll = false;
        this.shareTable.reset();
    }
    /*
    params:
        actionFrom: "article-list"
        articleFile: "Art161182335727"
        articleId: "601278fdba1bb926872de124"
        crntStageId: "JITS-WF-1_67_900"
        jnlCode: "JITS"
        mainDocument: "jist1000.tex"
        metaData: null
        sharedId: "JITS-WF-1_67_900_456"
        wf_stage_id: "JITS-WF-1_67"
        workflowCode: "JITS-WF-1"
    */
    trgFileDiff(params){
        this.router.navigate(['/editor/'+params['articleId']+'/'+params['sharedId']+'/merge-changes',params])
    }
}
