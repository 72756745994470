import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserTokenService } from './service/user-token.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router/src/router_state';
import { CanActivate, CanActivateChild } from '@angular/router';
import { InjectableGlobal } from './permissions';
import 'rxjs/add/operator/take';
import { EditorService } from "./editor/editor.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private customerService: UserTokenService, private router: Router,
        private route: ActivatedRoute, private InjectableGlobal: InjectableGlobal,
        private editorService: EditorService) {
    }

    public redirect;

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.customerService.isLogged().map((user_data) => {
            if (user_data.status != 200) {

                const url = state.url.split("?");
                this.redirect = url[0];

                let parsedUrl = this.router.parseUrl(state.url).queryParams['inviteToken'];

                if (parsedUrl) {
                    localStorage.setItem('guestToken', parsedUrl);
                    this.editorService.guestUserAdd({ token: parsedUrl }).subscribe(
                        res => {
                            // console.log(res);
                        },
                        err => {
                            console.log(err);
                        }
                    )
                }

                localStorage.setItem('redirectTo', this.redirect);
                this.router.navigateByUrl(this.router.createUrlTree(['/login']));

                return false;
            } else {
                return true;
            }
        }).take(1);
    }

    /**Restrict unauthorised access-By checking if the user have permission to access that page  */
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (localStorage.getItem('ACCESS') != undefined) {
            const accessibleMenus = JSON.parse(atob(localStorage.getItem('ACCESS')));


            if (accessibleMenus.includes("/" + state.url.split('/')[1])) {
                return true;
            }
            else {
                this.router.navigate(['unauthorisedaccess']);
            }
        }
        return true;
    }
}