import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ControlPanelService } from './control-panel.service';
import { NotifierService } from 'angular-notifier';
import { FileUploader } from "ng2-file-upload";
import { EnvService } from '../service/env.service';
import { SharedService } from '../shared/shared.service';
import { Router } from '@angular/router';
import { bind } from '@angular/core/src/render3/instructions';

@Component({
    selector: 'app-control-panel',
    templateUrl: './control-panel.component.html',
    styleUrls: ['./control-panel.component.scss']
})
export class ControlPanelComponent implements OnInit {

    constructor(
        public translate: TranslateService,
        private cPanelService: ControlPanelService,
        private notifierService: NotifierService,
        private env: EnvService,
        public formBuilder: FormBuilder,
        public sharedService: SharedService,
        public router: Router
    ) { }

    public cPanelData: any = null;
    public buttonDisable: boolean = false
    base_url: string = this.env.baseUploadUrl;
    packageTypes = new FormControl('');
    fileList = [];
    selectedFileType;
    selectedFileDestination;
    ngOnInit() {
        this.cPanelService.getFileTypes().subscribe(
            success => {
                this.fileList = success[0].file_extentions;
            },
            error => { }
        );
        this.loadCPanelData();
    }

    _id;

    loadCPanelData() {
        this.cPanelService.getCPanelDataSvs().subscribe(res => {
            if (res['status'] == 200) {
                this.cPanelData = res['data'];
                this._id = this.cPanelData._id;

                if (this.cPanelData.cp_basic_info) {
                    this.cPanelBasicForm.patchValue({
                        productName: this.cPanelData.cp_basic_info.productName,
                        productVersion: this.cPanelData.cp_basic_info.productVersion,
                        productLogoFileName: this.cPanelData.cp_basic_info.productLogo,
                        productLogoMiniFileName: this.cPanelData.cp_basic_info.productLogoMini,
                    });
                    this.logoUploader.clearQueue();
                    this.logoUploaderMini.clearQueue();
                }

                if (this.cPanelData.cp_email_settings) {
                    this.cPanelEmailForm.patchValue({
                        emailDefault: this.cPanelData.cp_email_settings.emailDefault,
                        emailFromAddress: this.cPanelData.cp_email_settings.emailFromAddress,
                        emailFromName: this.cPanelData.cp_email_settings.emailFromName
                    });
                }
                const editorData = res['data'].cp_editor;
                if (editorData) {
                    this.editorForm.patchValue({
                        articleShare: editorData.articleShare,
                        chat: editorData.chat,
                        collaboration: editorData.collaboration,
                        radar: editorData.radar
                    });
                }

            }
        })
    }

    public showPanelSettings: string = null;
    viewCpanelSettings(item) {
        this.showPanelSettings = item;
        if (item == 'journal-package')
            this.loadJournalPackage();
    }

    public logoUploader: FileUploader = new FileUploader({ isHTML5: true });
    public logoUploaderMini: FileUploader = new FileUploader({ isHTML5: true });
    cPanelBasicForm = new FormGroup({
        productName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+'), Validators.maxLength(50)]),
        productVersion: new FormControl(''),
        productLogo: new FormControl(''),
        productLogoFileName: new FormControl(null),
        productLogoMini: new FormControl(''),
        productLogoMiniFileName: new FormControl(null),
    });

    cPanelEmailForm = new FormGroup({
        emailDriverId: new FormControl(''),
        emailDriver: new FormControl('', [Validators.required]),
        emailHost: new FormControl('', [Validators.required]),
        emailPort: new FormControl('', [Validators.required]),
        emailUsername: new FormControl('', [Validators.required]),
        emailPassword: new FormControl('', [Validators.required]),
        emailFromName: new FormControl('', Validators.required),
        emailFromAddress: new FormControl('', [Validators.required, Validators.email]),
        emailDefault: new FormControl(''),
    })

    get getCPanelBasicForm() {
        return this.cPanelBasicForm.controls;
    }

    get getCPanelEmailForm() {
        return this.cPanelEmailForm.controls;
    }

    public validationErrors: any;
    saveBasicInfo() {
        if (this.cPanelBasicForm.invalid)
            return;

        this.validationErrors = {};
        let postData = new FormData();
        let allowedFileTypes = ['png', 'jpg', 'JPG', 'jpeg'];

        if (this.logoUploader.queue.length > 0) {
            if (this.logoUploader.queue.length != 1) {
                let error = this.translate.instant('c panel.basic.product logo') + this.translate.instant('c panel.basic.product logo error exceeds count')
                this.validationErrors.productLogo = error;
            }

            let file = this.logoUploader.queue[0];

            let fileName = file._file.name;
            let fileSize = file._file.size;
            let fileType = file._file.type;
            let fileExtension = fileName.split('.').pop();

            if (allowedFileTypes.indexOf(fileExtension) === -1) {
                let error = allowedFileTypes.join() + this.translate.instant('c panel.basic.product logo error filetypes allowed');
                this.validationErrors.productLogo = error;
            }

            postData.append('productLogo', file._file);
        }

        if (this.logoUploaderMini.queue.length > 0) {
            if (this.logoUploaderMini.queue.length != 1) {
                let error = this.translate.instant('c panel.basic.product logo') + this.translate.instant('c panel.basic.product logo error exceeds count')
                this.validationErrors.productLogoMini = error;
            }

            let file = this.logoUploaderMini.queue[0];

            let fileName = file._file.name;
            let fileSize = file._file.size;
            let fileType = file._file.type;
            let fileExtension = fileName.split('.').pop();

            if (allowedFileTypes.indexOf(fileExtension) === -1) {
                let error = allowedFileTypes.join() + this.translate.instant('c panel.basic.product logo error filetypes allowed');
                this.validationErrors.productLogoMini = error;
            }

            postData.append('productLogoMini', file._file);
        }

        if (Object.keys(this.validationErrors).length != 0)
            return

        postData.append('id', this.cPanelData._id);
        postData.append('productName', this.cPanelBasicForm.get('productName').value);
        postData.append('productVersion', this.cPanelBasicForm.get('productVersion').value);
        postData.append('productLogoFileName', this.cPanelBasicForm.get('productLogoFileName').value);
        postData.append('productLogoMiniFileName', this.cPanelBasicForm.get('productLogoMiniFileName').value);

        this.cPanelService.saveBasicInfoSvs(postData).subscribe(res => {
            if (res['status'] == 200) {
                this.notifierService.notify('success', this.translate.instant('c panel.basic.save success'));
            }
        })
    }

    removeProductLogo() {
        this.cPanelData.cp_basic_info.productLogo = null;
        this.cPanelBasicForm.patchValue({
            productLogoFileName: null,
        });
    }

    /**
     *  Updating denied files types for journal package
     * @author Arathy S V
     */
    saveFileTypes() {
        this.cPanelService.saveFileTypes({ type: this.packageTypes.value }).subscribe(
            response => {
                if (response['status'] == 200) {
                    this.notifierService.notify('success', this.translate.instant('c panel.package.file_selection_success_message'));
                    this.cPanelData.cp_journal_package = this.packageTypes.value;
                }
                else
                    this.notifierService.notify('error', this.translate.instant('c panel.package.file_selection_error_message'));
            },
            error => { }
        )
    }

    /**
     * Loading file types into joural package form 
     * @author Arathy S V
     */
    loadJournalPackage() {
        var fileType;
        if (this.cPanelData.cp_journal_package) {
            fileType = this.fileList.filter((journal) => {
                return this.cPanelData.cp_journal_package.indexOf(journal.name) != -1
            });
            fileType = fileType.map(journal => journal.name);
            this.packageTypes.setValue(fileType);
        }
    }

    removeProductLogoMini() {
        this.cPanelData.cp_basic_info.productLogoMini = null;
        this.cPanelBasicForm.patchValue({
            productLogoMiniFileName: null,
        });
    }

    saveEmailSettings() {
        if (this.cPanelEmailForm.invalid)
            return;

        let postData = new FormData();
        postData.append('id', this.cPanelData._id);

        postData.append('emailDriverId', this.cPanelEmailForm.get('emailDriverId').value)
        postData.append('emailDriver', this.cPanelEmailForm.get('emailDriver').value)
        postData.append('emailHost', this.cPanelEmailForm.get('emailHost').value)
        postData.append('emailPort', this.cPanelEmailForm.get('emailPort').value)
        postData.append('emailUsername', this.cPanelEmailForm.get('emailUsername').value)
        postData.append('emailPassword', this.cPanelEmailForm.get('emailPassword').value)

        postData.append('emailFromAddress', this.cPanelEmailForm.get('emailFromAddress').value)
        postData.append('emailFromName', this.cPanelEmailForm.get('emailFromName').value)
        postData.append('emailDefault', this.cPanelEmailForm.get('emailDefault').value)

        this.cPanelService.saveEmailSettingsSvs(postData).subscribe(res => {
            if (res['status'] == 200) {
                let element: HTMLElement = document.getElementsByClassName('closeDynamicFormModel')[0] as HTMLElement;
                element.click();

                this.loadCPanelData();
                this.notifierService.notify('success', this.translate.instant('c panel.email.save success'));
            }
        })
    }

    changeEmailDriverStatus(status) {
        if (this.cPanelBasicForm.invalid)
            return;

        let postData = new FormData();
        postData.append('id', this.cPanelData._id);
        postData.append('emailEnable', status)

        this.cPanelService.changeEmailDriverStatusSvs(postData).subscribe(res => {
            if (res['status'] == 200) {
                this.notifierService.notify('success', this.translate.instant('c panel.email.save success'));
            }
        })
    }

    public driverDetails = null;
    viewDriver(driverId) {
        if (this.cPanelData == null)
            return

        this.driverDetails = null;
        this.driverDetails = this.cPanelData.cp_email_settings.drivers.find(driver => driver.driver_id == driverId)
    }

    setDefaultDriver(driverId, i) {
        setTimeout(() => {
            let chkStatus = $('#driverStatus-' + i + '-input').attr('aria-checked')

            if (chkStatus == 'true') {
                let postData = { 'id': this.cPanelData._id, driverId: driverId }
                this.cPanelService.setDefaultDriverSvs(postData).subscribe(res => {
                    if (res['status'] == 200) {
                        this.notifierService.notify('success', this.translate.instant('c panel.email.driver change success'))
                        this.loadCPanelData()
                    }
                })
            } else {
                this.notifierService.notify('warning', this.translate.instant('c panel.email.driver change warning'))
                this.loadCPanelData()
            }

        }, 100)
    }

    getDriverDetails(driverId) {
        this.driverDetails = null;
        this.viewDriver(driverId)

        if (this.driverDetails != null) {
            this.cPanelEmailForm.patchValue({
                emailDriverId: this.driverDetails.driver_id,
                emailDriver: this.driverDetails.driver,
                emailHost: this.driverDetails.host,
                emailPort: this.driverDetails.port,
                emailUsername: this.driverDetails.username,
                emailPassword: this.driverDetails.password,
                emailFromName: this.driverDetails.from_name,
                emailFromAddress: this.driverDetails.from_address,
                emailDefault: this.driverDetails.default
            })
        }
    }

    removeDriver(driverId) {
        this.cPanelService.removeDriverSvs({ driverId: driverId, id: this.cPanelData._id }).subscribe(res => {
            if (res['status'] == 200) {
                this.notifierService.notify('success', this.translate.instant('c panel.email.delete driver success'))
                this.loadCPanelData()
            }
        })
    }

    editorForm = this.formBuilder.group({
        articleShare: [false],
        chat: [false],
        collaboration: [false],
        radar: [false],
        id: ['']
    });
    get f() { return this.editorForm.controls; }

    onSubmit() {
        this.editorForm.controls['id'].setValue(this._id);
        this.cPanelService.saveEditorFeaturesSvs(this.editorForm.value).subscribe(
            res => {
                if (res['status'] == 200) {
                    this.notifierService.notify("success", 'Editor options saved successfully');
                }
                if (res['status'] == 400) {
                    this.notifierService.notify("error", 'Some error occured');
                }
                this.viewCpanelSettings(null)
            },
            err => {
                console.log(err);
            }
        );
    }

    getFigApiSettingsForm = new FormGroup({
        getFigEnableApi: new FormControl(''),
        getFigHost: new FormControl(''),
        getFigUsername: new FormControl(''),
        getFigPassword: new FormControl(''),
        getFigPort: new FormControl(''),
        getFigRoot: new FormControl(''),
    })

    public journalData
    public getFigureData:Object
    async ngAfterViewInit(){
        let data = await this.cPanelService.getFigureDataSvs().toPromise()

        if(data['data'].cp_get_figure != undefined){
            this.getFigureData = data['data'].cp_get_figure
            this.patchValueGetFigApiSettingsForm()
        }

        this.sharedService.getJournalsSvs(null).subscribe(
            response => {
                if (response.status === 200) {
                    this.journalData = response.journal_data;
                }
            }
        );
    }

    public patchValueGetFigApiSettingsForm(){
        this.getFigApiSettingsForm.patchValue({
            getFigEnableApi: this.getFigureData['basic_api_settings'].enable,
            getFigHost: this.getFigureData['basic_api_settings'].host,
            getFigUsername: this.getFigureData['basic_api_settings'].username,
            getFigPassword: this.getFigureData['basic_api_settings'].password,
            getFigPort: this.getFigureData['basic_api_settings'].port,
            getFigRoot: this.getFigureData['basic_api_settings'].root,
        })
    }

    submitGetFigApiSettingsForm(){
        let postData = {
            getFigEnableApi: this.getFigApiSettingsForm.get('getFigEnableApi').value,
            getFigHost: this.getFigApiSettingsForm.get('getFigHost').value,
            getFigUsername: this.getFigApiSettingsForm.get('getFigUsername').value,
            getFigPassword: this.getFigApiSettingsForm.get('getFigPassword').value,
            getFigPort: this.getFigApiSettingsForm.get('getFigPort').value,
            getFigRoot: this.getFigApiSettingsForm.get('getFigRoot').value,
        }

        let res = this.cPanelService.saveGetFigApiSettingsSvs(postData).toPromise()
        if(res['status'] = 200){
            this.notifierService.notify('success', this.translate.instant('c panel.get-figures.save api settings success'));
        }
    }

    resetGetFigApiSettingsForm(){
        this.getFigApiSettingsForm.reset()
        if(this.getFigureData['basic_api_settings'].host != undefined){
            this.patchValueGetFigApiSettingsForm()
        }        
    }

    public fileTypes = [
        {code:'zip',name:'Zip'},
        {code:'pdf',name:'PDF'},
    ]

    public fileDestination = [
        {code:'compile',name:'Compile'},
        {code:'source',name:'Source'},
    ]

    public selectedJournal = [];
    public getFigOptionForm = new FormGroup({
       // journal :new FormControl('', [Validators.required]),
       journal: new FormControl(this.selectedJournal),
        remotePath: new FormControl('', [Validators.required]),
        fileName: new FormControl('', [Validators.required]),
        fileType: new FormControl('', [Validators.required]),
        destination: new FormControl('', [Validators.required]),
        extract: new FormControl(''),
        optionName: new FormControl('', [Validators.required]),
        enable: new FormControl(true)
    })

    @ViewChild("addGetFigOptionModal") addGetFigOptionModal
    
    async onAddGetFigOptions(){
        let postData = {
            journal: this.getFigOptionForm.get('journal').value,
            remotePath: this.getFigOptionForm.get('remotePath').value,
            fileName: this.getFigOptionForm.get('fileName').value,
            fileType: this.getFigOptionForm.get('fileType').value,
            destination: this.getFigOptionForm.get('destination').value,
            extract: this.getFigOptionForm.get('extract').value,
            optionName: this.getFigOptionForm.get('optionName').value,
            enable: this.getFigOptionForm.get('enable').value,
        }

        let res
        if(this.getFigOptionId == null){
            res = await this.cPanelService.addGetFigOptionsSvs(postData).toPromise()
        }else{
            postData['id'] = this.getFigOptionId
            res = await this.cPanelService.updateGetFigOptionSvs(postData).toPromise()
        }


        if(res['status'] == 200){
            this.notifierService.notify('success', this.translate.instant('c panel.get-figures.option.add option success'))

            this.addGetFigOptionModal.hide()
            this.getFigOptionId = null

            let data = await this.cPanelService.getFigureDataSvs().toPromise()
            if(data['data'].cp_get_figure != undefined){
                this.getFigureData = data['data'].cp_get_figure
            }
        }
    }

    async removeGetFigOption(id){
        let res = await this.cPanelService.removeGetFigOptionSvs(id).toPromise()

        if(res['status'] == 200){
            this.notifierService.notify('success', this.translate.instant('c panel.get-figures.remove option success'))

            let data = await this.cPanelService.getFigureDataSvs().toPromise()
            if(data['data'].cp_get_figure != undefined){
                this.getFigureData = data['data'].cp_get_figure
            }
        }
    }

    public getFigOptionId:string = null
    getFigOptionDetails(id){
        console.log(id)
        console.log(this.getFigureData)
        this.getFigureData['get_fig_options'].forEach(opt => {
            if(opt.id == id){
                this.getFigOptionForm.patchValue({
                    journal: opt.journal,
                    remotePath: opt.remote_path,
                    fileName: opt.file_name,
                    fileType: opt.file_type,
                    destination: opt.destination,
                    extract: opt.extract,
                    optionName: opt.option_name,
                    enable: opt.enable
                })
                console.log(this.getFigOptionForm)
                this.getFigOptionId = id
                return
            }
        });
    } 

    public async changeGetFigOptionStatus(id,status){
        let postData = {
            optionId: id,
            optionStatus: status
        }
        let res = await this.cPanelService.changeGetFigOptionStatusSvs(postData).toPromise()

        if(res['status'] == 200){
            this.notifierService.notify('success', this.translate.instant('c panel.get-figures.option.status change success'))

            let data = await this.cPanelService.getFigureDataSvs().toPromise()
            if(data['data'].cp_get_figure != undefined){
                this.getFigureData = data['data'].cp_get_figure
            }
        }
    }

    public viewGetFigOption:any = null
    public showGetFigOption(optionId){
        this.getFigureData['get_fig_options'].forEach(opt => {
            if(opt.id == optionId){
                opt['journalData'] = this.journalData.find(jnl => {
                    if(jnl.jnl_code == opt.journal) return true
                });

                opt['fileTypeValue'] = this.fileTypes.find(item => {
                    if(item.code == opt.file_type) return true
                })
                this.viewGetFigOption = opt
                return
            }
        });

    }

}
