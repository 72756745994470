import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverse',
  pure: false
})
export class ReversePipePipe implements PipeTransform {

    transform(values: any) {
        if (values) {
          return values.slice().reverse();
        }
    }
}
