import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponent } from './shared.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [SharedComponent],
  exports: [
    TranslateModule,
    NgxSpinnerModule,
  ]
})
export class SharedModule { }
 