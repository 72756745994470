import { Injectable, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../service/env.service';
import { ResolveEnd } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';

const LANGUAGE = 'LANGUAGE';
@Injectable({
    providedIn: 'root'
})
export class SharedService {

    public searchData = '';
    private spinnerDefaultConfig;
    // public myArticles: asOb;

    private myArticles: any;
    private myArticlesSource = new BehaviorSubject(this.myArticles);
    myArticlesData = this.myArticlesSource.asObservable();
    @Output() public valueChanged: EventEmitter<any> = new EventEmitter();
    constructor(
        private spinner: NgxSpinnerService,
        private http: HttpClient,
        private env: EnvService
    ) {
        this.spinnerDefaultConfig = {
            fullScreen: false,
            // type: 'ball-clip-rotate-multiple',
            type: 'timer',
            size: 'medium',
            bdColor: 'rgba(228,229,230, .8)',
            color: 'rgba(32,168,216, 1)',
        };
    }
    setLanguage(lang: string): void {
        localStorage.setItem(LANGUAGE, lang);
    }

    getLanguage() {
        return localStorage.getItem(LANGUAGE);
    }

    showSpinner(spinnerName: string) {
        setTimeout(() => {
            this.spinner.show(spinnerName, this.spinnerDefaultConfig);
        }, 30);
    }

    hideSpinner(spinnerName: string) {
        setTimeout(() => {
            this.spinner.hide(spinnerName);
        }, 500);
    }
    getJournalsSvs(journalId: any = null) {
        return this.http.get<any>(this.env.baseAPIUrl + "/get-journals/" + journalId);
    }

    getBasicStageDataSvs(data: any) {
        return this.http.post<any>(this.env.baseAPIUrl + "/get-basic-stage-data", data);
    }

    getDetailsForStageMoveSvs(data: any) {
        return this.http.post<any>(this.env.baseAPIUrl + "/get-details-for-stage-move", data);
    }
    
    getArticleJournal() {
        return this.http.get(this.env.baseAPIUrl + '/articleJournals');
    }

    getMyArticlesSvs($data): Observable<any> {
        return this.http.post(this.env.baseAPIUrl + '/get-my-articles', $data);
    }

    getMyArticles() {
        return this.myArticles;
    }

    getAllUsersSvs(): Observable<any> {
        return this.http.get(this.env.baseAPIUrl + '/dashboard/getAllUsers')
    }

    getJournalStagesSvs(data: any = null) {
        return this.http.post(this.env.baseAPIUrl + '/get-journal-stages', data);
    }

    public checkOutArticleSvs($data: object) {
        return this.http.post<any>(this.env.baseAPIUrl + '/checkout-article', $data);
    }

    public getDetailsForDiffPDF($data: any = null) {
        return this.http.post(this.env.baseAPIUrl + '/get-details-for-diff-pdf', $data)
    }

    getSharedArticlesSvs($data): Observable<any> {
        return this.http.post(this.env.baseAPIUrl + '/get-shared-articles', $data);
    }

    getCompletedArticlesSvs($data): Observable<any> {
        return this.http.post(this.env.baseAPIUrl + '/get-completed-articles', $data);
    }
}
