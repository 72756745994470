import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserTokenService } from './service/user-token.service';
import { languageArray } from './app.global';
import { SharedService } from './shared/shared.service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  public languageArray = languageArray;
  constructor(private router: Router, public translate: TranslateService,
    private user: UserTokenService, public sharedService: SharedService) {
    translate.addLangs(['gb', 'fr', 'es', 'ae']);

    const selectedLang = this.sharedService.getLanguage();

    if (selectedLang === undefined || selectedLang === '' || selectedLang === null) {

      translate.setDefaultLang('gb');
      this.sharedService.setLanguage('gb');
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/gb|fr|es|ae/) ? browserLang : 'gb');
    } else {

      translate.setDefaultLang(selectedLang);
      translate.use(selectedLang.match(/gb|fr|es|ae/) ? selectedLang : 'gb');
    }

  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
